import api from '../services/api';
import { currentDigibookIdSelector } from '../selectors/digibooks';
import { SHARING_GET_TEACHER_SHARED_SOLUTION_NODES } from './actionNames';

export const getTeacherSharedSolutionNodes = () => async (dispatch, getState) => {
  const digibookId = currentDigibookIdSelector(getState());
  const sharedSolutionNodes = await api.get(`/studio/user/shared-solutions/${digibookId}`);

  dispatch({
    type: SHARING_GET_TEACHER_SHARED_SOLUTION_NODES,
    payload: {
      sharedSolutions: sharedSolutionNodes.data,
    },
  });
};
