const fabrictypes = {
  ANSWERPDF: 'answerpdf',
  ANSWERGROUP: 'answergroup',
  ANSWERSTEPPERICONS: 'answersteppericons',
  BOOKPDF: 'bookpdf',
  BOOKSHADOW: 'bookshadow',
  LINKAREA: 'linkArea',
  MARKINGS: 'markings',
  RECTWITHTEXT: 'rectWithText',
  MANUALPDF: 'manualpdf',
  MANUALSHADOW: 'manualshadow',
  SOLUTIONBOOKPDF: 'solutionbookpdf',
  SOLUTIONANSWERPDF: 'solutionpageanswerspdf',
};

export default fabrictypes;
