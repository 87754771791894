import get from 'get-value';
import { createSelector } from 'reselect';
import { getActiveNodes } from './toc';
import { getCurrentModuleId } from './digibooks';

export const getWhitepageById = (state, whitepageId) => {
  return get(state, ['whitepages', 'byId', whitepageId]);
};

const getFavoriteWhitepagesByModule = state => get(state, ['whitepages', 'favorites', 'byModuleId']);

export const getWhitepage = createSelector(
  getWhitepageById,
  getFavoriteWhitepagesByModule,
  getCurrentModuleId,
  (whitepage, favoritesByModule, moduleId) => {
    if (!whitepage) return whitepage;

    const favoritesForModule = get(favoritesByModule, [moduleId]);
    const isFavorite = (favoritesForModule || []).indexOf(whitepage.id) > -1;
    return { ...whitepage, isFavorite };
  },
);

export const getWhitepageIdsGroupedByTocNodeId = (state, tocId) => get(state, ['whitepages', 'byTocNodeId', tocId]);

export const getWhitepagesForActiveNodes = state => {
  // grouped by nodeId and not chapterNodeId
  const activeNodes = getActiveNodes(state);
  const whitepagesGroupedByChapterNode = activeNodes.reduce((acc, node) => {
    const whitepageIdsForNode = getWhitepageIdsGroupedByTocNodeId(state, node.id);
    if (!whitepageIdsForNode) return acc;
    whitepageIdsForNode.forEach(id => {
      const whitepage = getWhitepage(state, id);

      acc[whitepage.nodeId] = acc[whitepage.nodeId] ? [...acc[whitepage.nodeId], whitepage] : [whitepage];
    });

    return acc;
  }, {});

  return whitepagesGroupedByChapterNode;
};
