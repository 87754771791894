import get from 'get-value';
import { createSelector } from 'reselect';

import ViewMode from '../enums/viewmode';
import { getCurrentPageNumber, getViewMode } from './navigation';
import { getCurrentDigibook, getTotalPagesForDigibook } from './digibooks';
import { getIsSolutionsPageVisible } from './player';
import { calculateSpreadForPageNumbers } from '../utils/calculateSpreadForPageNumbers';
import { calculateSpreadForPage, getVisiblePagesInSpread } from '../utils/spreadUtilities';

/**
 * @deprecated Filtering out the pages of the spread causes us to lose necessary context.
 */
export const getAmountOfPagesToShow = state => {
  const viewmode = getViewMode(state);

  if (viewmode === ViewMode.SPREAD) {
    const currentPageNumber = getCurrentPageNumber(state);
    const totalPages = getTotalPagesForDigibook(state);
    return calculateSpreadForPageNumbers([currentPageNumber], totalPages).length;
  }

  return 1;
};

/**
 * @deprecated Filtering out the pages of the spread causes us to lose necessary context.
 */
export const getPageNumbersToShow = createSelector(
  //
  getCurrentPageNumber,
  getAmountOfPagesToShow,
  (currentPage, amountOfPagesToShow) => {
    if (currentPage === undefined) return [];

    if (amountOfPagesToShow === 2) {
      return currentPage % 2 === 0 ? [currentPage, currentPage + 1] : [currentPage - 1, currentPage];
    }

    return [currentPage];
  },
);

/**
 * @description Gets the current spread according to the current page.
 */
export const getCurrentSpread = createSelector(
  //
  getCurrentPageNumber,
  getTotalPagesForDigibook,
  (currentPage, totalPages) => calculateSpreadForPage(totalPages, currentPage),
);

/**
 * @description Hides the non visible pages from the current spread.
 */
export const getVisiblePages = createSelector(
  //
  getCurrentSpread,
  getCurrentPageNumber,
  getViewMode,
  (spread, currentPage, viewMode) => getVisiblePagesInSpread(spread, currentPage, viewMode),
);

export const getAnswerLayerShownFor = state => get(state, ['navigation', 'answerLayerShownFor']);

export const getAnswerLayerPagesToShow = createSelector(
  //
  getPageNumbersToShow,
  getAnswerLayerShownFor,
  getIsSolutionsPageVisible,
  getCurrentDigibook,
  (pageNumbersToShow, answerLayerPages, isSolutionsPageVisible, digibook) => {
    if (pageNumbersToShow.length === 0) return pageNumbersToShow;

    const pagesWhereAnswersShouldBeShown = pageNumbersToShow.filter(page => digibook.allowedRanges?.some(range => range.from <= page && range.to >= page));

    if (isSolutionsPageVisible && pagesWhereAnswersShouldBeShown.length > 0) return pageNumbersToShow;

    return pageNumbersToShow.map(pn => (answerLayerPages.shapes.some(x => x.page === pn) ? pn : null));
  },
);

export const getAnswerShapesForCurrentPages = createSelector(
  //
  getPageNumbersToShow,
  getAnswerLayerShownFor,
  (pageNumbersToShow, answerLayerMapper) =>
    pageNumbersToShow.reduce((acc, page) => {
      acc[page] = answerLayerMapper.shapes.filter(shape => shape.page === page);
      return acc;
    }, {}),
);

const getMarkingsForPage = state => get(state, ['tools', 'markingsForPage']);

const inPagesFilter = pn => o => o.pages.includes(pn);

export const getMarkingsForCurrentPages = createSelector(
  //
  getPageNumbersToShow,
  getMarkingsForPage,
  (pageNumbersToShow, markings = []) => markings.filter(inPagesFilter(pageNumbersToShow[0])).map(marking => marking.shape),
);
