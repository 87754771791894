import React, { useRef } from 'react';
import { arrayOf, func, number, shape } from 'prop-types';
import { useSelector } from 'react-redux';

import { getCurrentMathTool } from '../../../../../selectors/tools';
import { getCurrentDigibook } from '../../../../../selectors/digibooks';
import { getPlayerMode } from '../../../../../selectors/player';

import Tools from '../../../../../enums/tools';
import Ruler from './ruler/Ruler';
import DraftingCompass from './drafting-compass/DraftingCompass';
import useMathToolPositioning from './useMathToolPositioning';
import PlayerMode from '../../../../../enums/playerMode';
import SetSquare from './set-square/SetSquare';

const A4_LANDSCAPE_DIMENSIONS = { height: 210, width: 297 };

export default function MathTools({ bookDimensions, pagesRendered, viewPortTransform, onDraftingCompassTempDraw, onDraftingCompassFinishedDrawing, setFreeDrawingStrategy }) {
  const currentMathTool = useSelector(getCurrentMathTool);

  const ref = useRef(null);

  const setCalculateCenter = useMathToolPositioning({ currentMathTool, ref, viewPortTransform, bookDimensions, pagesRendered });

  const pageDimensions = useSelector(state => {
    const playerMode = getPlayerMode(state);

    return playerMode === PlayerMode.BOOK ? getCurrentDigibook(state).pageDimensions : A4_LANDSCAPE_DIMENSIONS;
  });

  // Hot reload fallback
  const pixelsPerMM = bookDimensions.width / pageDimensions.width || 5.6;

  return (
    <div id="math-tools" ref={ref} style={{ transform: `scale(${viewPortTransform[0]})` }} data-pixels-per-mm={pixelsPerMM} data-testid="math-tools">
      {currentMathTool === Tools.RULER && (
        <Ruler pixelsPerMM={pixelsPerMM} viewPortTransform={viewPortTransform} setFreeDrawingStrategy={setFreeDrawingStrategy} setCalculateCenter={setCalculateCenter} />
      )}
      {currentMathTool === Tools.SET_SQUARE && (
        <SetSquare pixelsPerMM={pixelsPerMM} viewPortTransform={viewPortTransform} setFreeDrawingStrategy={setFreeDrawingStrategy} setCalculateCenter={setCalculateCenter} />
      )}
      {currentMathTool === Tools.DRAFTING_COMPASS && (
        <DraftingCompass
          pixelsPerMM={pixelsPerMM}
          viewPortTransform={viewPortTransform}
          ref={ref}
          onTempDraw={onDraftingCompassTempDraw}
          onDrawingFinished={onDraftingCompassFinishedDrawing}
          setCalculateCenter={setCalculateCenter}
        />
      )}
    </div>
  );
}

MathTools.propTypes = {
  bookDimensions: shape({
    width: number,
    height: number,
  }).isRequired,
  pagesRendered: arrayOf(number).isRequired,
  viewPortTransform: arrayOf(number).isRequired,
  onDraftingCompassTempDraw: func.isRequired,
  onDraftingCompassFinishedDrawing: func.isRequired,
  setFreeDrawingStrategy: func.isRequired,
};
