import React from 'react';
import classNames from 'classnames';
import { bool, string, arrayOf, shape } from 'prop-types';
import BookSwitcherItem from './bookSwitcherItem';

const BookSwitcher = ({ open, digibookApps, superModuleId, currentDigibookId }) => (
  <ul
    className={classNames('pbb-submodule__dropdown', {
      'pbb-submodule__dropdown--active': open,
    })}
  >
    {digibookApps.map((book, index) => (
      <BookSwitcherItem book={book} key={book.digibookId || index} superModuleId={superModuleId} isCurrent={currentDigibookId === book.digibookId} />
    ))}
  </ul>
);

BookSwitcher.propTypes = {
  open: bool,
  digibookApps: arrayOf(
    shape({
      appId: string,
      digibookId: string,
      name: string,
      bookType: string,
      niceName: string,
      cover: string,
      hasLicence: bool,
    }),
  ),
  superModuleId: string.isRequired,
  currentDigibookId: string.isRequired,
};

BookSwitcher.defaultProps = {
  open: false,
  digibookApps: [],
};

export default BookSwitcher;
