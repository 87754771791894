import { useRef, useState } from 'react';
import { applySizeBoundaries, degreesToRadians, rotatePoint } from '../utils';

export default function useSetSquareResizing({ initialSize, maxSize, minSize, viewPortTransform, offset, degrees }) {
  const [size, setSize] = useState(initialSize);

  const start = useRef();

  const handleMove = evt => {
    if (!start.current) return;
    evt.preventDefault();

    const { clientX, clientY } = evt.changedTouches ? evt.changedTouches[0] : evt;
    const angle = degrees < 180 ? degrees : degrees - 360;

    const { x } = rotatePoint({ x: clientX, y: clientY }, degreesToRadians(-angle), start.current);
    const transformationScale = viewPortTransform[0] * offset;

    setSize(applySizeBoundaries(size + (x - start.current.x) / transformationScale, minSize, maxSize));
  };

  const handleStop = evt => {
    handleMove(evt);
    start.current = undefined;

    document.body.removeEventListener('mousemove', handleMove);
    document.body.removeEventListener('mouseup', handleStop);

    document.body.removeEventListener('touchmove', handleMove);
    document.body.removeEventListener('touchend', handleStop);
  };

  const handleStart = evt => {
    evt.preventDefault();

    const { clientX, clientY } = evt.touches ? evt.touches[0] : evt;
    start.current = { x: clientX, y: clientY };

    document.body.addEventListener('mousemove', handleMove);
    document.body.addEventListener('mouseup', handleStop);

    document.body.addEventListener('touchmove', handleMove);
    document.body.addEventListener('touchend', handleStop);
  };

  return {
    resize: handleStart,
    size,
  };
}
