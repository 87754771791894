/* const toDate = epochTimeStamp => {
  const date = new Date(0);
  date.setUTCSeconds(epochTimeStamp);
  return date;
}; */

const fromDate = date => Math.floor(date.getTime() / 1000);

export default {
  fromDate,
  // toDate,
};
