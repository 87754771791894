/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';

class DeleteAnnotationPlugin {
  init(editor) {
    this.jodit = editor;

    Config.prototype.controls.delete = {
      name: 'delete',
      icon: 'delete',
      exec: () => this.handleDelete(),
    };
  }

  handleDelete() {
    const annotation = this.jodit.options.initialAnnotation;
    const { onDelete } = this.jodit.options;

    if (onDelete && annotation) {
      onDelete(annotation);
    }
  }

  destruct() {}
}

Jodit.plugins.add('deleteannotation', DeleteAnnotationPlugin);
