const DIALOG_HEADER_HEIGHT = 85;
const DIALOG_PADDING = 25;
const H5P_CONTROLS_HEIGHT = 36;
const H5P_ACTIONS_HEIGHT = 25;
const H5P_HIDDEN_BUT_READ_HEIGHT = 1;
const IFRAME_MAX_HEIGHT = window.innerHeight - DIALOG_PADDING * 2 - DIALOG_HEADER_HEIGHT - H5P_CONTROLS_HEIGHT - H5P_ACTIONS_HEIGHT - H5P_HIDDEN_BUT_READ_HEIGHT;

export function calculateDialogWidth(width, height) {
  if (height > IFRAME_MAX_HEIGHT) {
    const aspectRatio = width / height;

    return Math.floor(IFRAME_MAX_HEIGHT * aspectRatio);
  }

  return width;
}
