import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import StarIcon from '@pelckmans/business-components/icons/Star';
import StarOutlineIcon from '@pelckmans/business-components/icons/StarOutline';

export default function FavoriteButton({ isActive, onClick, className }) {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
      data-testid="btn-favorite"
      title={isActive ? t('components.favoriteButton.active.tooltip') : t('components.favoriteButton.inactive.tooltip')}
    >
      {isActive ? <StarIcon /> : <StarOutlineIcon />}
    </button>
  );
}

FavoriteButton.propTypes = {
  isActive: bool,
  onClick: func.isRequired,
  className: string,
};

FavoriteButton.defaultProps = {
  isActive: false,
  className: undefined,
};
