export default {
    SYSADMIN: 'sysadmin',
    SUPPORT: 'support',
    PRODUCTION: 'production',
    EDITOR: 'editor',
    PUBLISHER: 'publisher',
    STUDENT: 'student',
    TEACHER: 'teacher',
    SALES: 'sales',
    EXTERNAL: 'external',
};
