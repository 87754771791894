import React from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/intl';
import TocNodeTitle from '../../sidebar/drawer/tocnode-title';

const Info = props => {
  const {
    note: { createdAt, updatedAt },
    tocNode,
  } = props;

  const [t] = useTranslation();
  const formattedUpdatedAt = formatDate(new Date(updatedAt));

  return (
    <div className="pbb-modal__info-wrapper pbb-modal__info-wrapper--no-edit" data-testid="wrapper-info">
      <div className="pbb-modal__type">
        <div className="pbb-modal__type-icon">
          <i className="icon-pin" />
        </div>
      </div>
      <div className="pbb-modal__info">
        <div className="pbb-modal__title-wrapper">
          <div className="pbb-modal__title">{formattedUpdatedAt}</div>
        </div>
        <table className="pbb-modal__specs">
          <tbody>
            <tr>
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.createdAt')}</td>
              <td className="pbb-modal__specs-info">{formatDate(new Date(createdAt))}</td>
            </tr>
            {createdAt !== updatedAt && (
              <tr data-testid="updated-at-info">
                <td className="pbb-modal__specs-title">{t('tabs.info.labels.updatedAt')}</td>
                <td className="pbb-modal__specs-info">{formattedUpdatedAt}</td>
              </tr>
            )}
            <tr>
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.linkedTo')}</td>
              <td className="pbb-modal__specs-info">
                <TocNodeTitle tocNode={tocNode} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Info.propTypes = {
  note: shape({
    createdAt: string.isRequired,
    updatedAt: string,
  }).isRequired,
  tocNode: shape({
    id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
};

export default Info;
