import audienceTypes from '../enums/audience-types'

const makeAudiencesSummary = audiences => {
    const summary = audiences.reduce((acc, audience) => {
        const { audienceType, assignment } = audience;

        switch (audienceType) {
            case audienceTypes.SCHOOL:
                acc.school = true;
                break;
            case audienceTypes.SMARTSCHOOLSCHOOL:
                acc.school = true;
                break;
            case audienceTypes.GROUP:
                acc.class = true;
                break;
            case audienceTypes.MEMBER:
                acc.student = true;
                break;
            default:
                break;
        }

        if (assignment) acc.assignment = true;
        if (assignment?.repeatable) acc.repeatable = true;

        return acc;
    }, {
        school: false,
        class: false,
        student: false,
        assignment: false,
        repeatable: false,
    });

    return `school: ${summary.school}, `
        .concat(`class: ${summary.class}, `)
        .concat(`student: ${summary.student}, `)
        .concat(`assignment: ${summary.assignment}, `)
        .concat(`repeatable: ${summary.repeatable}`);
};

export const buildShareSingleMaterial = args => ({
    category: 'Sharing',
    action: 'Share Single Material',
    label: args.medialinkName,
    dimension1: args.module,
    dimension7: args.role,
    dimension10: `${makeAudiencesSummary(args.options)}, bulk: false`,
    dimension12: args.medialinkType,
});

export const buildUnshareSingleMaterial = args => ({
    category: 'Sharing',
    action: 'Unshare Single Material',
    label: args.medialinkName,
    dimension1: args.module,
    dimension7: args.role,
    dimension10: `${makeAudiencesSummary(args.options)}, bulk: false`,
    dimension12: args.medialinkType,
});

export const buildStopShareSingleMaterial = args => ({
    category: 'Sharing',
    action: 'Stop Share Single Material',
    label: args.medialinkName,
    dimension1: args.module,
    dimension7: args.role,
    dimension10: `${makeAudiencesSummary(args.options)}, bulk: false`,
    dimension12: args.medialinkType,
});

export default {
    buildShareSingleMaterial,
    buildUnshareSingleMaterial,
    buildStopShareSingleMaterial,
};
