import CloseInCircleIcon from '@pelckmans/business-components/icons/CloseInCircle';
import React from 'react';
import { flushSync, render } from 'react-dom';

function renderToString(elem) {
  const div = document.createElement('div');

  flushSync(() => {
    render(elem, div);
  });

  return div.innerHTML;
}

export const whitePageHeaderCloseIcon = renderToString(<CloseInCircleIcon width="80" height="80" fill="#004d62" />);
