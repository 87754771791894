import { NOTES_DELETE_NOTE_SUCCESS, NOTES_FETCH_BY_NODEID_SUCCESS, NOTES_SAVE_NOTE_SUCCESS } from './actionNames';
import api from '../services/api';
import { getCurrentModuleId } from '../selectors/digibooks';
import { getVisibleChapterNodeIds } from '../selectors/toc';
import { getNoteIdsGroupedByTocNodeId } from '../selectors/notes';

const saveNoteSuccess = note => ({
  type: NOTES_SAVE_NOTE_SUCCESS,
  payload: { note },
});

export const saveNote = note => {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = getCurrentModuleId(state);
    const { data } = note.id
      ? await api.put(`/studio/user/modules/${moduleId}/table-of-content/${note.nodeId}/notes/${note.id}`, {
          note: note.note,
        })
      : await api.post(`/studio/user/modules/${moduleId}/table-of-content/${note.nodeId}/notes`, {
          note: note.note,
        });

    dispatch(saveNoteSuccess(data));
  };
};

export function retrieveNotesByNodeId(nodeId) {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = getCurrentModuleId(state);
    const response = await api.get(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/notes`);

    return dispatch({
      type: NOTES_FETCH_BY_NODEID_SUCCESS,
      payload: {
        nodeId,
        notes: response.data.data,
      },
    });
  };
}

export function retrieveNotesForCurrentPages() {
  return (dispatch, getState) => {
    const state = getState();
    const chapterIds = getVisibleChapterNodeIds(state) || [];
    const promises = chapterIds.map(nodeId => (getNoteIdsGroupedByTocNodeId(state, nodeId) ? Promise.resolve() : dispatch(retrieveNotesByNodeId(nodeId))));

    return Promise.all(promises);
  };
}

const deleteNoteSuccess = ({ noteId, nodeId }) => ({
  type: NOTES_DELETE_NOTE_SUCCESS,
  payload: {
    noteId,
    nodeId,
  },
});

export const deleteNote = ({ noteId, nodeId }) => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);

  await api.delete(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/notes/${noteId}`);

  dispatch(deleteNoteSuccess({ noteId, nodeId }));
};
