import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import getMyClassesLink from '../../../../../../../utils/getMyClassesLink';

export default function useOptionsMenuActionsTranslations(slideSetName) {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      header: {
        title: slideSetName,
      },
      sharingDisabled: t('shareManager.sharingDisabled'),
      sharingEnabled: t('shareManager.sharingEnabled'),
      cancel: t('shareManager.cancel'),
      save: t('shareManager.save'),
      rangeSeparator: t('shareManager.rangeSeparator'),
      overrideRangeWarning: t('shareManager.overrideRangeWarning'),
      noGroupsTitle: t('shareManager.noGroupsTitle'),
      noGroupsMessage: t('shareManager.noGroupsMessage', {
        myClassesLink: getMyClassesLink(),
      }),
      close: t('shareManager.close'),
      search: t('shareManager.search'),
      noSearchResults: t('shareManager.noSearchResults'),
      asAssignment: t('shareManager.assignments.asAssignment'),
      withRetry: t('shareManager.assignments.withRetry'),
      choosePeriod: t('shareManager.assignments.choosePeriod'),
      multiple: t('shareManager.assignments.multiple'),
      onlyExerciseCanBeSharedAsAssignment: t('shareManager.assignments.onlyExerciseCanBeSharedAsAssignment'),
      stopCurrentAssignmentsWhenSharingWithNone: t('shareManager.assignments.stopCurrentAssignmentsWhenSharingWithNone'),
      stopSharingCurrentAssignment: t('shareManager.assignments.stopSharingCurrentAssignment'),
    }),
    [t, slideSetName],
  );
}
