import api from '../api';
import ltiApi from '../lti-api';
import getAccessToken from './bearer-token';

export function configureRefreshTokenInterceptor(dispatch) {
  const refreshTokenInterceptor = config => {
    if (config.skipAuthorization || config.headers.Authorization || /^([a-z][a-z\d+\-.]*:)?\/\//i.test(config.url)) return Promise.resolve(config);

    return getAccessToken(dispatch).then(accessToken => {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
      }
      return config;
    });
  };

  api.interceptors.request.use(config => refreshTokenInterceptor(config));
  ltiApi.interceptors.request.use(config => refreshTokenInterceptor(config));
}
