import React, { useEffect } from 'react';
import { string, oneOf, node } from 'prop-types';
import BookExclamationMarkIcon from '@pelckmans/business-components/icons/BookExclamationMark';

import Severity from '../enums/severity';

import { toggleLoadingScreen } from '../utils/loadingScreen';

function Error({ title, description, children, severity }) {
  useEffect(() => {
    toggleLoadingScreen();
  }, []);

  return (
    <div className="pbb-no-access">
      <div className="pbb-no-access__wrapper">
        <div className={`pbb-no-access__icon ${severity}`}>
          <BookExclamationMarkIcon />
        </div>
        <div className="pbb-no-access__message">{title}</div>
        <div className="pbb-no-access__explanation">{description}</div>
        {children}
      </div>
    </div>
  );
}

Error.propTypes = {
  title: string,
  description: string,
  children: node,
  severity: oneOf(Object.values(Severity)),
};

Error.defaultProps = {
  title: undefined,
  description: undefined,
  children: undefined,
  severity: Severity.ERROR,
};

export default Error;
