import { useMemo } from "react";
import { postEvent } from "../api/analyticsApi";

export default function useAnalytics() {
  const capture = async event => {
    try {
      await postEvent(event);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return useMemo(() => ({ capture }), []);
}
