import throttle from 'lodash/throttle';
import { useRef, useState } from 'react';

import { applySizeBoundaries, degreesToRadians, rotatePoint } from '../utils';

const minSizeInMM = 20;
const maxSizeInMM = 500;

export default function useDraftingCompassResize({ pixelsPerMM, viewPortTransform, degrees }) {
  const [radius, setRadius] = useState(30 * pixelsPerMM);

  const scale = viewPortTransform[0];
  const isResizing = useRef(false);
  const start = useRef(0);

  const minSizePx = minSizeInMM * pixelsPerMM;
  const maxSizePx = maxSizeInMM * pixelsPerMM;

  const handleMove = throttle(e => {
    if (isResizing.current) {
      const { clientX, clientY } = e.changedTouches?.[0] || e;

      /**
       * Rotate points on opposite angle so we compare points on x axis.
       */
      const angle = degrees < 180 ? degrees : degrees - 360;
      const { x } = rotatePoint({ x: clientX, y: clientY }, degreesToRadians(-angle), start.current);

      const newRadius = radius + (x - start.current.x) / scale;

      const adjustedRadius = applySizeBoundaries(newRadius, minSizePx, maxSizePx);

      setRadius(adjustedRadius);
    }
  }, 1000 / 60);

  function handleStop() {
    if (isResizing.current) {
      isResizing.current = false;
      start.current = 0;

      document.body.removeEventListener('mousemove', handleMove);
      document.body.removeEventListener('mouseup', handleStop);

      document.body.removeEventListener('touchmove', handleMove);
      document.body.removeEventListener('touchend', handleStop);
    }
  }

  function handleStart(e) {
    const { clientX, clientY } = e.touches?.[0] || e;
    start.current = { x: clientX, y: clientY };
    isResizing.current = true;

    document.body.addEventListener('mousemove', handleMove);
    document.body.addEventListener('mouseup', handleStop);

    document.body.addEventListener('touchmove', handleMove);
    document.body.addEventListener('touchend', handleStop);
  }

  return {
    resize: handleStart,
    radius,
  };
}
