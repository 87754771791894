import api from '../services/api';
import { POP_UP_MANUAL_FETCH_SUCCESS } from './actionNames';

function getPopUpManualSuccess(data) {
  return {
    type: POP_UP_MANUAL_FETCH_SUCCESS,
    payload: data,
  };
}

export function getPopUpManual(securedFileId) {
  return async dispatch => {
    const response = await api.get(`/studio/secured-files/${securedFileId}`);

    return dispatch(getPopUpManualSuccess(response.data));
  };
}
