import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { arrayOf, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Collapsible from '../../../../../../../../components/collapsible';
import TocNodeTitle from '../../../tocnode-title';
import UserMaterialListItem from './UserMaterialListItem';
import userMaterialPropType from './user-material-prop-type';
import AddMaterialButton from '../../AddMaterialButton';
import UserMaterialOptionsModal from './UserMaterialOptionsModal';
import { getModuleById } from '../../../../../../../../selectors/module';
import { isTeacherAlike } from '../../../../../../../../selectors/user';

function UserMaterialList({ allowCollapsing, teacherFeaturesEnabled, userMaterials, nodeId, moduleId, showOnlyFavoriteMaterial }) {
  const [t] = useTranslation();
  const [selectedMedia, setSelectedMedia] = useState();
  const [isOptionsModalActive, setIsOptionsModalActive] = useState(false);
  const [selectSharedOptionInShareManager, setSelectSharedOptionInShareManager] = useState(false);
  const [activeTabForOptionsModal, setActiveTabForOptionsModal] = useState();
  const module = useSelector(state => getModuleById(state, moduleId));
  const isTeacher = useSelector(isTeacherAlike);

  const openOptionsModal = (media, activeTab, selectSharedOption = false) => {
    setActiveTabForOptionsModal(activeTab);
    setSelectSharedOptionInShareManager(selectSharedOption);
    setSelectedMedia(media);
    setIsOptionsModalActive(true);
  };

  return (
    <>
      {isTeacher && <AddMaterialButton moduleId={moduleId} nodeId={nodeId} showOnlyFavoriteMaterial={showOnlyFavoriteMaterial} openOptionsModal={openOptionsModal} />}
      {userMaterials.length > 0 && (
        <Collapsible depth={2} className="shared-materials" forceEnable={allowCollapsing}>
          <Collapsible.Item>
            <Collapsible.Title>
              <span className="pbb-collapsible__title-text">
                <span className="pbb-collapsible__title">
                  <TocNodeTitle
                    tocNode={{
                      id: 'user-media',
                      title: teacherFeaturesEnabled ? t('drawer.material.sharedMedia') : t('drawer.material.sharedByTeacher'),
                    }}
                  />
                </span>
              </span>
            </Collapsible.Title>
            <Collapsible.Content>
              <ul className="pbb-list pbb-list--borders">
                {userMaterials.map(item => (
                  <UserMaterialListItem userMaterial={item} key={item.id} isOwner={teacherFeaturesEnabled} openOptionsModal={openOptionsModal} moduleId={moduleId} />
                ))}
              </ul>
            </Collapsible.Content>
          </Collapsible.Item>
        </Collapsible>
      )}
      {isOptionsModalActive &&
        selectedMedia &&
        createPortal(
          <UserMaterialOptionsModal
            userMaterial={selectedMedia}
            onClose={() => setIsOptionsModalActive(false)}
            isOwner={teacherFeaturesEnabled}
            module={module}
            selectSharedOptionInShareManager={selectSharedOptionInShareManager}
            activeTab={activeTabForOptionsModal}
          />,
          document.body,
        )}
    </>
  );
}

UserMaterialList.propTypes = {
  allowCollapsing: bool.isRequired,
  teacherFeaturesEnabled: bool.isRequired,
  userMaterials: arrayOf(userMaterialPropType).isRequired,
  nodeId: string.isRequired,
  moduleId: string.isRequired,
  showOnlyFavoriteMaterial: bool.isRequired,
};

export default UserMaterialList;
