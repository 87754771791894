import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { string, shape, bool, number } from 'prop-types';
import { useTranslation } from 'react-i18next';

import ActiveNodeInfo from './activeNodeInfo';
import OpenInPButton from './openInPButton';
import SlideBackButton from './slideBackButton';

import { getActiveNodeToHighlight } from '../../../../../../selectors/toc';
import { getModuleById } from '../../../../../../selectors/module';
import { buildModuleInPUrl } from '../../../../../../utils/buildModuleInPUrl';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import GoogleAnalyticsContext from '../../../../../../contexts/GoogleAnalyticsContext';
import { getModuleForDigibook } from '../../../../../../selectors/digibooks';

export const SlidingButton = props => {
  const {
    i18n: { language },
  } = useTranslation();
  const { activeNode = {}, superModule = {}, module, slideOutDelay } = props;

  const [sliderState, setSliderState] = useState(false);
  const [buttonHidden, setButtonHidden] = useState(true);
  const animationTimer = useRef();
  const hideButtonTimer = useRef();

  const analytics = useAnalytics();

  const { ga } = useContext(GoogleAnalyticsContext);

  useEffect(() => {
    setButtonHidden(false);
    setTimeout(() => {
      setSliderState(true);
    }, slideOutDelay);

    animationTimer.current = setTimeout(() => {
      setSliderState(false);
      hideButtonTimer.current = setTimeout(() => setButtonHidden(true), 300);
    }, 2000 + slideOutDelay);

    return () => {
      clearTimeout(hideButtonTimer.current);
      clearTimeout(animationTimer.current);
    };
  }, [slideOutDelay]);

  const changeSliderState = nextValue => {
    if (animationTimer.current) {
      clearTimeout(animationTimer.current);
      animationTimer.current = undefined;
    }
    if (hideButtonTimer.current) {
      clearTimeout(hideButtonTimer.current);
      hideButtonTimer.current = undefined;
    }
    if (!nextValue) {
      setSliderState(nextValue);
      hideButtonTimer.current = setTimeout(() => setButtonHidden(true), 300);
    } else {
      setButtonHidden(false);
      animationTimer.current = setTimeout(() => setSliderState(nextValue));
    }
  };

  const [t] = useTranslation();

  function formatActiveNode() {
    if (activeNode.id === 'general') return t('drawer.toc.general');
    return activeNode.title;
  }

  const goToModuleInP = async () => {
    await analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: module.id,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEBAR,
    });

    if (ga) {
      ga.event({
        category: 'Digibook',
        action: 'Return From Digibook',
        label: formatActiveNode(),
        dimension1: module.niceName,
      });
    }

    window.location.assign(buildModuleInPUrl(language, superModule, module.slug, activeNode));
  };

  const buttonTitle = !sliderState && buttonHidden ? t('slider.show') : t('slider.hide');

  return (
    <div className="pbb-sidebar__method">
      <button
        data-testid="button-toggle"
        type="button"
        className={classNames('pbb-sidebar__method-image', { 'logo-from-module': module?.styling?.logo })}
        onClick={() => changeSliderState(!sliderState)}
        style={{ backgroundImage: module?.styling?.logo && `url('${module.styling.logo}')` }}
        title={buttonTitle}
      >
        {buttonTitle}
      </button>
      <div
        data-testid="sliding-button"
        id="method"
        className={classNames('pbb-slider-back', {
          'pbb-slider-back--active': sliderState,
          'pbb-slider-back--hidden': buttonHidden,
        })}
      >
        {activeNode.id && <ActiveNodeInfo prefix={activeNode.displayPrefix ? activeNode.prefix : ''} title={formatActiveNode()} />}
        {superModule.id && <OpenInPButton onClick={goToModuleInP} />}
        <SlideBackButton onClick={() => changeSliderState(false)} />
      </div>
    </div>
  );
};

SlidingButton.propTypes = {
  // Own Props
  // eslint-disable-next-line react/no-unused-prop-types
  superModuleId: string,
  activeNode: shape({
    displayPrefix: bool,
    prefix: string,
    title: string,
    id: string,
  }),
  slideOutDelay: number,

  // Connected Props
  superModule: shape({
    subjectSlug: string,
    methodSlug: string,
    slug: string,
    id: string,
  }),
  module: shape({
    id: string.isRequired,
    slug: string.isRequired,
    niceName: string.isRequired,
  }),
};

SlidingButton.defaultProps = {
  superModuleId: undefined,
  activeNode: undefined,
  superModule: undefined,
  slideOutDelay: 0,
  module: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  activeNode: getActiveNodeToHighlight(state),
  superModule: ownProps.superModuleId && getModuleById(state, ownProps.superModuleId),
  module: getModuleForDigibook(state),
});

export default connect(mapStateToProps)(SlidingButton);
