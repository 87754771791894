export default class PDFService {
  static renderOnCanvas(page, canvas, scale, background) {
    const viewport = page.getViewport({ scale });

    canvas.width = viewport.width; // eslint-disable-line no-param-reassign
    canvas.height = viewport.height; // eslint-disable-line no-param-reassign
    const canvasContext = canvas.getContext('2d');

    const renderTask = page.render({
      canvasContext,
      viewport,
      background,
      enableWebGL: true,
    });

    return renderTask;
  }
}
