import { ERROR_OCCURRED } from '../actions/actionNames';

export default function errorReducer(state = {}, action) {
  switch (action && action.type) {
    case ERROR_OCCURRED: {
      const { error } = action.payload;
      return {
        current: error,
      };
    }
    default:
      return state;
  }
}
