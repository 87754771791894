import axios from 'axios';

import api from './api';

export default class PageTextProvider {
  constructor(digibookId, token, cache) {
    this.digibookId = digibookId;
    this.token = token;
    this.cache = cache;
  }

  /**
   * @param {String} layer The layer name for the text (cover|backcover|book|answer)
   * @param {Number[]} pageNumbers The pageNumbers for which the text have to be fetched.
   *
   * @returns {Promise} Promise which resolves when the requested text are loaded.
   */
  async getTextForPages(layer, pageNumbers) {
    const text = pageNumbers.map(pageNum => this.cache.get(`${layer}${pageNum}`));
    if (text.every(t => t)) return Promise.resolve(text.map((json, i) => ({ json, pageNum: pageNumbers[i] })));

    const from = Math.min(...pageNumbers.filter(x => x));
    const to = Math.max(...pageNumbers.filter(x => x));
    const pageRange = from === to ? from : `${from}-${to}`;

    const { data } = await api.get(`/studio/digibooks/${this.digibookId}/${layer.toLowerCase()}/${pageRange}/text`, {
      headers: { Authorization: `Bearer ${this.token}` },
    });

    await Promise.all(
      Object.keys(data).map(async pageNum => {
        const { data: json } = await axios.get(data[pageNum]);
        this.cache.add(`${layer}${pageNum}`, json);
      }),
    );

    return pageNumbers.map(pageNum => (pageNum ? { json: this.cache.get(`${layer}${pageNum}`), pageNum } : { json: null, pageNum }));
  }
}
