import { fabric } from 'fabric';

import { getI18n } from 'react-i18next';
import FabricTypes from '../../../enums/fabrictype';
import Tools from '../../../enums/tools';

const disableControls = {
  selectable: false,
  hasBorders: false,
  hasControls: false,
  hasRotatingPoint: false,
};

function setHoverStates(obj, hoverColor, getCurrentTool, activeTooltipTextTranslationKey, inactiveTooltipTextTranslationKey) {
  const activeColor = '#65BA7C';
  const backgroundColor = '#77A1AA';
  obj.on({
    mouseover: e => {
      if (getCurrentTool() === Tools.POINTER) {
        const background = e.target.getObjects()[0];
        const lightBulbIcon = e.target.getObjects()[3];

        background.set({ fill: hoverColor }); // background van de svg
        if (lightBulbIcon) lightBulbIcon.set({ fill: e.target.active ? activeColor : hoverColor }); // binnenste lampje.

        const i18n = getI18n();
        const t = i18n.t.bind(i18n);
        if (e.target.active) {
          e.target.canvas.upperCanvasEl.title = t(activeTooltipTextTranslationKey);
        } else {
          e.target.canvas.upperCanvasEl.title = t(inactiveTooltipTextTranslationKey);
        }
        e.target.canvas.requestRenderAll();
      } else {
        e.target.set({ hoverCursor: 'crosshair' });
      }
    },
    mouseout: e => {
      if (!e.target) return;
      if (getCurrentTool() === Tools.POINTER) {
        const background = e.target.getObjects()[0];
        const lightBulbIcon = e.target.getObjects()[3];
        e.target.canvas.upperCanvasEl.removeAttribute('title');
        background.set({ fill: backgroundColor }); // background van de svg
        if (lightBulbIcon) lightBulbIcon.set({ fill: e.target.active ? activeColor : backgroundColor }); // binnenste lampje.

        e.target.canvas.requestRenderAll();
      } else {
        e.target.set({ hoverCursor: 'crosshair' });
      }
    },
  });
}

function setShadows(objects) {
  objects.forEach(obj => {
    obj.set(
      'shadow',
      new fabric.Shadow({
        color: 'rgba(0, 77, 98, 0.5)',
        offsetX: 0,
        offsetY: 5,
        blur: 15,
      }),
    );
  });
}

/**
 * @description Decides if an answer stepper is out of bounds for the clipPath
 * @param {Number} scaledShapeWidth
 * @param {Number} scaledShapeLeft
 * @param {Object} clipPath
 * @param {Boolean} isFirstPage
 * @param {Boolean} isSinglePage
 * @param {Boolean} isRightPage
 */
function isAnswerStepperOutOfBounds(scaledShapeWidth, scaledShapeLeft, clipPath, isStandalonePage, isSinglePage, isRightPage) {
  if (isStandalonePage || !isSinglePage) return false;

  const scaledShapeRight = scaledShapeLeft + scaledShapeWidth;

  const leftCoordinateOfPage = isRightPage ? clipPath.width : 0;
  const rightCoordinateOfPage = isRightPage ? clipPath.width * 2 : clipPath.width;

  if (!isRightPage && scaledShapeRight > rightCoordinateOfPage) {
    const scaledShapeLeftWidth = rightCoordinateOfPage - scaledShapeLeft;
    const scaledShapeRightWidth = scaledShapeRight - rightCoordinateOfPage;
    return scaledShapeRightWidth > scaledShapeLeftWidth;
  }

  if (isRightPage && scaledShapeLeft < leftCoordinateOfPage) {
    const scaledShapeLeftWidth = leftCoordinateOfPage - scaledShapeLeft;
    const scaledShapeRightWidth = scaledShapeRight - leftCoordinateOfPage;
    return scaledShapeLeftWidth > scaledShapeRightWidth;
  }

  return false;
}

/**
 * @description A factory function to create the answer stepper objects with their necessary handlers attached.
 * @param {String} id
 * @param {Object} icons
 * @param {Boolean} active
 * @param {Function} getCurrentTool
 * @param {Function} handleMouseUp
 * @param {Object} opts
 *
 * @returns {Array} AnswerStepperObjects The array of the SVG's to show
 */
export default function createAnswerStepper(id, icons, active, handleMouseUp, getCurrentTool, canGoNext, canGoPrev, onNext, onPrev, opts) {
  const { size, left, top, clipPath, isStandalonePage, isSinglePage, isRightPage, ...rest } = opts;

  if (isAnswerStepperOutOfBounds(size, left, clipPath, isStandalonePage, isSinglePage, isRightPage)) {
    return null;
  }

  const { middle, prev, next } = icons;
  const backgroundColor = '#77A1AA';
  const middleHoverColor = '#004D62';
  const arrowHoverColor = '#65BA7C';

  const stepper = [];

  middle.scaleToWidth(size).set({
    id,
    left,
    top,
    active,
    hoverCursor: 'pointer',
    meta: FabricTypes.ANSWERSTEPPERICONS,
    ...disableControls,
    ...rest,
  });

  middle.on({
    mouseup: () => {
      handleMouseUp(id);
    },
  });

  if (active) {
    const disabledColor = '#E4ECEE';

    next.scaleToHeight(middle.getScaledHeight() - 10).set({
      left: left + middle.getScaledWidth() / 2,
      top: top + 5,
      meta: FabricTypes.ANSWERSTEPPERICONS,
      hoverCursor: 'pointer',
      ...disableControls,
    });

    prev.scaleToHeight(middle.getScaledHeight() - 10).set({
      left: left - prev.getScaledWidth() + middle.getScaledWidth() / 2,
      top: top + 5,
      meta: FabricTypes.ANSWERSTEPPERICONS,
      hoverCursor: 'pointer',
      ...disableControls,
    });

    next.on({
      mouseup: () => {
        if (canGoNext) onNext();
      },
    });

    prev.on({
      mouseup: () => {
        if (canGoPrev) onPrev();
      },
    });

    if (!canGoNext) {
      next.getObjects()[0].set({ fill: disabledColor });
    } else {
      setHoverStates(next, arrowHoverColor, getCurrentTool, '', 'answerStepper.buttons.next.active.tooltip');
    }
    if (!canGoPrev) {
      prev.getObjects()[0].set({ fill: disabledColor });
    } else {
      setHoverStates(prev, arrowHoverColor, getCurrentTool, '', 'answerStepper.buttons.previous.active.tooltip');
    }

    stepper.push(next, prev);
  } else {
    const lightBulb = middle.getObjects()[3];
    lightBulb.set({ fill: backgroundColor });
  }

  setHoverStates(middle, middleHoverColor, getCurrentTool, 'answerStepper.buttons.middle.active.tooltip', 'answerStepper.buttons.middle.inactive.tooltip');
  stepper.push(middle);

  setShadows(stepper);
  return stepper;
}
