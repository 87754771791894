import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@pelckmans/business-components/icons/ArrowLeft';
import ArrowRightIcon from '@pelckmans/business-components/icons/ArrowRight';
import { gotoPreviousPage, gotoNextPage } from '../../../../../actions/navigation';
import { canGotoPreviousPage, canGotoNextPage } from '../../../../../selectors/navigation';
import { PageEventContext } from '../../../context/PageEventContext';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../enums/analytics';

export const Navigation = ({ previousPageButtonEnabled, nextPageButtonEnabled, previous, next }) => {
  const [t] = useTranslation();

  const { capturePageEvent } = useContext(PageEventContext);

  const handlePrevious = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEBAR);
    previous();
  };

  const handleNext = () => {
    capturePageEvent(ANALYTICS_EVENT_SUBLOCATIONS.SIDEBAR);
    next();
  };

  return (
    <div className="pbb-sidebar__navigation">
      <button type="button" onClick={handlePrevious} title={t('sideBar.navigation.buttons.backward.tooltip')} disabled={!previousPageButtonEnabled} className="pbb-sidebar__button">
        <ArrowLeftIcon className="pbb-sidebar__icon arrow-left" />
      </button>
      <button type="button" onClick={handleNext} title={t('sideBar.navigation.buttons.forward.tooltip')} disabled={!nextPageButtonEnabled} className="pbb-sidebar__button">
        <ArrowRightIcon className="pbb-sidebar__icon arrow-right" />
      </button>
    </div>
  );
};

Navigation.propTypes = {
  previousPageButtonEnabled: bool,
  nextPageButtonEnabled: bool,
  previous: func.isRequired,
  next: func.isRequired,
};

Navigation.defaultProps = {
  previousPageButtonEnabled: true,
  nextPageButtonEnabled: true,
};

const mapDispatchToProps = {
  previous: gotoPreviousPage,
  next: gotoNextPage,
};

const mapStateToProps = state => ({
  previousPageButtonEnabled: canGotoPreviousPage(state),
  nextPageButtonEnabled: canGotoNextPage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
