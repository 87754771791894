import React, { useState } from 'react';
import { bool, func, number, string, shape } from 'prop-types';
import { Button } from '@pelckmans/business-components/components/button';
import { useDispatch } from 'react-redux';
import CheckMarkIcon from '@pelckmans/business-components/icons/CheckMark';
import CloseIcon from '@pelckmans/business-components/icons/Close';
import { useTranslation } from 'react-i18next';
import { setAnnotationSetName } from '../../../../../actions/annotationSets';

const KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

const minLength = 1;
const maxLength = 50;

export default function EditAnnotationSetNameField({ annotationSet, setAnnotationSetInEditMode }) {
  const [value, setValue] = useState(annotationSet.name);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const save = () => {
    dispatch(setAnnotationSetName(annotationSet.id, value, t('annotationSets.templateDefaultName')));
    setAnnotationSetInEditMode(null);
  };

  const cancel = () => {
    setAnnotationSetInEditMode(null);
  };

  const onKeyUp = e => {
    if (value && e.key === KEYS.ENTER) {
      save();
    }
    if (e.key === KEYS.ESCAPE) {
      cancel();
    }
  };

  return (
    <>
      <input
        className="text-field"
        value={value}
        data-testid="editable-input"
        onChange={e => setValue(e.target.value)}
        onKeyUp={onKeyUp}
        type="text"
        minLength={minLength}
        maxLength={maxLength}
      />
      <div className="actions">
        <Button type="button" disabled={value.length < minLength} onClick={save} className="action" variant="primary" title={t('actions.save')} outline>
          <CheckMarkIcon />
        </Button>
        <Button type="button" onClick={cancel} className="action" variant="danger" title={t('actions.cancel')} outline>
          <CloseIcon />
        </Button>
      </div>
    </>
  );
}

EditAnnotationSetNameField.propTypes = {
  annotationSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    path: string,
    isActive: bool.isRequired,
  }).isRequired,
  setAnnotationSetInEditMode: func.isRequired,
};
