import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { medialink } from '../schemas';
import {
  MEDIALINKS_FETCH_BY_IDS_SUCCESS,
  MEDIALINKS_FETCH_BY_NODEID_SUCCESS,
  MEDIALINKS_ADD_FAVORITE_SUCCESS,
  MEDIALINKS_REMOVE_FAVORITE_SUCCESS,
  RETRIEVE_FAVORITES_SUCCESS,
  RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS,
  MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS,
  ADD_CATEGORY_TO_HIDE_SUCCESS,
  REMOVE_CATEGORY_TO_HIDE_SUCCESS,
  MEDIALINK_SHARED,
  MEDIALINK_SET_EXERCISE_SESSION_RESULT,
} from '../actions/actionNames';

const initialState = {
  byId: {},
  byTocNodeId: {},
  favorites: {
    byModuleId: {},
  },
  categories: {
    byModuleId: {},
  },
  materialCategoriesToHide: {
    byModuleId: {},
  },
  nodesFetched: [],
};

function processMedialinks(medialinks) {
  const mlMapper = ml => {
    const {
      id,
      name,
      amountOfItems,
      kind,
      file,
      moduleId,
      wordList,
      externalMedia,
      edumaticExercise,
      eduhintExercise,
      miniDialog,
      contentType,
      updatedAt,
      group,
      exerciseCategories,
      score,
      miniSite,
      shares,
      assignments,
      jwplayer,
    } = ml;

    // reduces the hierarchy to an array of the node-ids of the hierarchy
    const hierarchy = ml.hierarchy.map(item => item.id);
    const icon = contentType && contentType.iconClassName ? contentType.iconClassName : 'icon-file-media';
    const obj = {
      id,
      name,
      amountOfItems,
      hierarchy,
      kind,
      icon,
      moduleId,
      updatedAt,
      group,
      contentType,
    };

    if (contentType) {
      obj.printable = contentType.printable;
      obj.isExercise = contentType.isExercise;
      obj.imagePreview = contentType.imagePreview;

      obj.contentType = contentType;
    }

    if (file) obj.file = file;
    if (externalMedia) obj.externalMedia = externalMedia;
    if (wordList) obj.wordList = wordList;
    if (edumaticExercise) obj.edumaticExercise = edumaticExercise;
    if (eduhintExercise) obj.eduhintExercise = eduhintExercise;
    if (miniDialog) obj.miniDialog = miniDialog;
    if (exerciseCategories) obj.exerciseCategories = exerciseCategories;
    if (score) obj.score = score;
    if (miniSite) obj.miniSite = miniSite;
    if (shares) obj.shares = shares;
    if (assignments) obj.assignments = assignments;
    if (jwplayer) obj.jwplayer = jwplayer;

    return obj;
  };
  return normalize(medialinks.map(mlMapper), [medialink]);
}

export default function medialinksReducer(state = initialState, action) {
  switch (action && action.type) {
    case MEDIALINKS_FETCH_BY_NODEID_SUCCESS: {
      const { nodeId, medialinks } = action.payload;
      const normalized = processMedialinks(medialinks);

      return update(state, {
        byId: { $merge: normalized.entities.medialinks || {} },
        byTocNodeId: { [nodeId]: { $set: normalized.result } },
        nodesFetched: { $push: [nodeId] },
      });
    }
    case MEDIALINKS_FETCH_BY_IDS_SUCCESS: {
      const { medialinkIds, medialinks } = action.payload;
      const normalized = processMedialinks(medialinks);
      const normalizedMedialinks = normalized.entities.medialinks || {};

      // put the medialinks in toc state if not already in there.
      const tocNodesToUpdate = medialinks.reduce((acc, ml) => {
        const nodeId = ml.hierarchy[ml.hierarchy.length - 1].id;

        if (!state.nodesFetched.includes(nodeId)) {
          if (!acc[nodeId]) acc[nodeId] = []; // first item
          acc[nodeId] = [...acc[nodeId], ml.id];
        }

        return acc;
      }, {});

      const updateObj = {
        byId: {
          $merge: medialinkIds.reduce((acc, id) => {
            acc[id] = normalizedMedialinks[id] || null;
            return acc;
          }, {}),
        },
        byTocNodeId: {
          $merge: tocNodesToUpdate,
        },
      };
      return update(state, updateObj);
    }
    case RETRIEVE_FAVORITES_SUCCESS: {
      const { favorites, moduleId } = action.payload;

      return update(state, {
        favorites: {
          byModuleId: {
            [moduleId]: { $set: favorites ? favorites.mediaLinks : [] },
          },
        },
      });
    }
    case MEDIALINKS_ADD_FAVORITE_SUCCESS: {
      const { medialinkId, moduleId } = action.payload;
      if (state.favorites.byModuleId[moduleId].indexOf(medialinkId) < 0) {
        return update(state, { favorites: { byModuleId: { [moduleId]: { $push: [medialinkId] } } } });
      }
      return state;
    }
    case MEDIALINKS_REMOVE_FAVORITE_SUCCESS: {
      const { medialinkId, moduleId } = action.payload;
      const idx = state.favorites.byModuleId[moduleId].indexOf(medialinkId);
      if (idx > -1) {
        return update(state, { favorites: { byModuleId: { [moduleId]: { $splice: [[idx, 1]] } } } });
      }
      return state;
    }
    case RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS: {
      const { materialCategoriesToHide, moduleId } = action.payload;
      return update(state, { materialCategoriesToHide: { byModuleId: { [moduleId]: { $set: materialCategoriesToHide || [] } } } });
    }
    case ADD_CATEGORY_TO_HIDE_SUCCESS: {
      const { categoryId, moduleId } = action.payload;
      return update(state, {
        materialCategoriesToHide: {
          byModuleId: {
            [moduleId]: (categoriesForModule = []) =>
              update(categoriesForModule, {
                $push: [categoryId],
              }),
          },
        },
      });
    }
    case REMOVE_CATEGORY_TO_HIDE_SUCCESS: {
      const { categoryId, moduleId } = action.payload;
      const idx = state.materialCategoriesToHide.byModuleId[moduleId].indexOf(categoryId);
      if (idx > -1) {
        return update(state, { materialCategoriesToHide: { byModuleId: { [moduleId]: { $splice: [[idx, 1]] } } } });
      }
      return state;
    }
    case MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS: {
      const { moduleId, contentTypes } = action.payload;
      const categories = new Set(contentTypes.map(ct => (ct.category || '').toUpperCase()));
      return update(state, { categories: { byModuleId: { [moduleId]: { $set: [...categories] } } } });
    }
    case MEDIALINK_SHARED: {
      const { medialinkId, shares, assignments } = action.payload;

      return update(state, {
        byId: { [medialinkId]: { $merge: { shares, assignments } } },
      });
    }
    case MEDIALINK_SET_EXERCISE_SESSION_RESULT: {
      const { mediaLinkId, result } = action.payload;

      const { score, amountOfItems } = result;

      return update(state, {
        byId: { [mediaLinkId]: { $merge: { score: { ...score, amountOfItems } } } },
      });
    }
    default: {
      return state;
    }
  }
}
