import Tools from './tools';

const sizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
};

export const brushSizes = {
  [Tools.PENCIL]: {
    xs: 2,
    sm: 8,
    md: 16,
    lg: 32,
  },
  [Tools.MARKER]: {
    xs: 8,
    sm: 24,
    md: 36,
    lg: 96,
  },
  [Tools.CLASSIC_ERASER]: {
    sm: 12,
    md: 24,
    lg: 48,
  },
  [Tools.ANNOTATION]: {
    xs: 19,
    sm: 26,
    md: 52,
    lg: 104,
  },
};

export default sizes;
