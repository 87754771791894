import React from 'react';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { EditableTextField } from '@pelckmans/business-components/components/editable-text-field';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import { ProgressionBar } from '@pelckmans/business-components/components/progression-bar';
import { getTOCNodeById } from '../../../../../../../../selectors/toc';
import { formatDate } from '../../../../../../../../utils/intl';
import { getFileType } from '../../../../../../../../utils/filetype';
import { saveUserMedia } from '../../../../../../../../actions/userMedia';
import userMaterialPropType from './user-material-prop-type';
import { formatTeacherName } from '../../../../../../../../utils/nameFormatting';
import { getProvisionalScorePercentage } from '../../../../../../../../utils/scores';

const UserMaterialInfo = ({ userMaterial, isOwner, editable, title }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const tocNode = useSelector(state => getTOCNodeById(state)(userMaterial.node));
  const formattedUpdatedAt = formatDate(new Date(userMaterial.lastUpdatedAt));

  const updateUserMedia = data => dispatch(saveUserMedia(userMaterial, data));
  const updateName = name => updateUserMedia({ name });

  const getTocNodeTitle = node => (node.displayPrefix ? `${node.prefix} ${node.title}` : node.title);

  const isMaterialShared = (isOwner ? userMaterial.shared : userMaterial.shares && userMaterial.shares.length > 0) || Boolean(userMaterial.assignment);
  const scorePercentage = !userMaterial.needsGrading && userMaterial.score && getProvisionalScorePercentage(userMaterial);

  const getInfoLabel = um => {
    if (um.href) return t('userMaterialModal.url.label');
    if (um.lti) return t('userMaterialModal.bookwidget.label');

    return t(`fileTypeDescriptions.${getFileType(um.file.mimeType)}`);
  };

  return (
    <div className="pbb-modal__info-wrapper pbb-modal__info-wrapper--no-edit" data-testid="wrapper-info">
      <div className="pbb-modal__type">
        <div className="pbb-modal__type-icon">
          <MaterialIcon materialType="user-material" />
        </div>
      </div>
      <div className="pbb-modal__info">
        <div className="pbb-modal__title-wrapper">
          {!title && editable ? (
            <EditableTextField initialValue={userMaterial.name} onSave={updateName} title={t('options.editName')} className="pbb-modal__title" minLength={1} />
          ) : (
            <div className="pbb-modal__title">{title || userMaterial.name}</div>
          )}
        </div>
        <table className="pbb-modal__specs">
          <tbody>
            <tr data-testid="updated-at-info">
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.updatedAt')}</td>
              <td className="pbb-modal__specs-info">{formattedUpdatedAt}</td>
            </tr>
            <tr>
              <td className="pbb-modal__specs-title">{t('options.tocLevel')}</td>
              <td className="pbb-modal__specs-info">{getTocNodeTitle(tocNode)}</td>
            </tr>

            {isOwner && (
              <>
                <tr className="pbb-modal__specs-spacer" />
                <tr>
                  <td className="pbb-modal__specs-title">{t('sharing.shared.label')}</td>
                  <td className="pbb-modal__specs-info">{isMaterialShared ? t('sharing.shared.yes') : t('sharing.shared.no')}</td>
                </tr>
              </>
            )}

            {!isOwner && isMaterialShared && (
              <>
                <tr className="pbb-modal__specs-spacer" />
                <tr>
                  <td className="pbb-modal__specs-title">{t('sharing.shared_by')}</td>
                  <td className="pbb-modal__specs-info">{formatTeacherName(userMaterial.owner)}</td>
                </tr>
              </>
            )}

            <tr className="pbb-modal__specs-spacer" />
            <tr>
              <td className="pbb-modal__specs-title">{t('options.category')}</td>
              <td className="pbb-modal__specs-info">{t('userMediaOptions.category')}</td>
            </tr>
            <tr>
              <td className="pbb-modal__specs-title">{t('options.fileType')}</td>
              <td className="pbb-modal__specs-info">{getInfoLabel(userMaterial)}</td>
            </tr>

            <tr className="pbb-modal__specs-spacer" />
            {userMaterial.userMaxScore && (
              <>
                <tr data-testid="score">
                  <td className="pbb-modal__specs-title">{t('options.scoreResult')}</td>
                  <td className="pbb-modal__specs-info">
                    {userMaterial.score ? (
                      <ProgressionBar
                        achievable={userMaterial.score.achievable}
                        achieved={userMaterial.score.achieved}
                        maximum={userMaterial.score.maximum}
                        pending={userMaterial.needsGrading ? userMaterial.score.maximum : 0}
                        translations={userMaterial.needsGrading ? { pending: t('businessComponents.ltiExerciseModal.confirmationModals.body.progression.pending') } : undefined}
                      />
                    ) : (
                      t('options.noExercises')
                    )}
                  </td>
                </tr>
                {scorePercentage && (
                  <tr data-testid="score-percentage">
                    <td className="pbb-modal__specs-title">{t('options.scorePercentage')}</td>
                    <td className="pbb-modal__specs-info">{scorePercentage}</td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UserMaterialInfo.propTypes = {
  userMaterial: userMaterialPropType.isRequired,
  isOwner: bool.isRequired,
  editable: bool.isRequired,
  title: string,
};

UserMaterialInfo.defaultProps = {
  title: undefined,
};

export default UserMaterialInfo;
