import React, { useRef } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@pelckmans/business-components/components/button';
import { useTranslation } from 'react-i18next';

import DialogHeader from '../common/header';
import { calculateDialogWidth } from '../../../../../utils/h5p';

function H5PModal({ medialink, onClose, moveToFront }) {
  const {
    name,
    icon,
    externalMedia: { width, height, embedId, href, reload },
  } = medialink;

  const calculatedWidth = calculateDialogWidth(width, height);

  const fullScreen = !(width && height);

  const H5PSource = embedId ? `https://h5p.org/h5p/embed/${embedId}` : href;

  const iframeRef = useRef();

  const reloadIframe = () => {
    if (iframeRef.current) {
      iframeRef.current.src = href;
    }
  };

  const { t } = useTranslation();

  const reloadButton = reload ? (
    <Button type="button" onClick={reloadIframe} variant="primary">
      {t('actions.reload')}
    </Button>
  ) : (
    undefined
  );

  return (
    <div className="pbb-modal__wrapper">
      <div className="ngdialog-overlay" />
      <div
        className={classNames('pbb-modal-no-drag', 'pbb-modal-h5p', { 'pbb-modal-h5p--fullScreen': fullScreen })}
        style={{ width: calculatedWidth }}
        role="presentation"
        onClick={moveToFront}
      >
        <div className="pbb-modal__dialog">
          <div className="pbb-modal__content" data-testid="h5p-modal-content">
            <DialogHeader close={onClose} icon={icon} title={name} action={reloadButton} />
            <div className="pbb-modal__body" data-testid="h5p-modal-body">
              <div className="pbb-modal__media">
                {fullScreen ? (
                  <div className="pbb-modal__iframe-wrapper">
                    <iframe className="iframe--fill iframe--no-border" title={name} src={H5PSource} allow="fullscreen" ref={iframeRef} />
                  </div>
                ) : (
                  <iframe className="iframe--fill iframe--no-border" title={name} src={H5PSource} allow="fullscreen" ref={iframeRef} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

H5PModal.propTypes = {
  medialink: shape({
    name: string.isRequired,
    icon: string.isRequired,
    externalMedia: shape({
      width: number,
      height: number,
      embedId: string,
      href: string,
      reload: bool,
    }).isRequired,
  }).isRequired,
  onClose: func.isRequired,
  moveToFront: func.isRequired,
};

export default H5PModal;
