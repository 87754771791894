import React, { useState } from 'react';

import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import StarIcon from '@pelckmans/business-components/icons/Star';
import StarOutlineIcon from '@pelckmans/business-components/icons/StarOutline';
import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';
import { bool, shape, string } from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import NotLicensedModal from '../../../../dialogs/NotLicensedModal';

export default function UnlicensedWhitePageListItem({ whitepage }) {
  const [notLicensedVisible, setNotLicensedVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {notLicensedVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessContent.title')} message={t('notLicensed.accessContent.message')} onClose={() => setNotLicensedVisible(false)} />,
          document.body,
        )}
      <li className="pbb-list__item unlicensed-whitepage-list-item">
        <div className="pbb-list__link-wrap pbb-list__link-wrap--disabled">
          <button type="button" className="pbb-list__link" title={whitepage.title} onClick={() => setNotLicensedVisible(true)}>
            <MaterialIcon materialType="whitepage" paperType={whitepage.paperType} className="pbb-list__link-icon" />
            <div className="pbb-list__link-text">{whitepage.title}</div>
            <div className="pbb-list__action">
              {whitepage.isFavorite ? <StarIcon /> : <StarOutlineIcon />}
              <EllipsisVerticalIcon />
            </div>
          </button>
        </div>
      </li>
    </>
  );
}

UnlicensedWhitePageListItem.propTypes = {
  whitepage: shape({
    title: string.isRequired,
    paperType: string.isRequired,
    isFavorite: bool.isRequired,
  }).isRequired,
};
