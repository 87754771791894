/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import { fabric } from 'fabric';
import FabricTypes from '../../../enums/fabrictype';

const CustomPencilBrush = fabric.util.createClass(fabric.PencilBrush, {
  initialize: function(canvas, paintableAreaDimensions, freeDrawingStrategy) {
    this.canvas = canvas;
    this._points = [];
    this._prevPointer = undefined;
    this.paintableAreaDimensions = paintableAreaDimensions;

    this.freeDrawingStrategy = freeDrawingStrategy;
  },

  _isPointOnBook: function(pointer) {
    return pointer.x >= 0 && pointer.y >= 0 && pointer.x <= this.paintableAreaDimensions.width && pointer.y <= this.paintableAreaDimensions.height;
  },

  _isCreatingNewPath: function() {
    return this._points.length > 0;
  },

  _clampPointToEdge: function(pointer) {
    const p = new fabric.Point(pointer.x, pointer.y);
    if (p.x < 0) p.x = 0;
    if (p.y < 0) p.y = 0;
    if (p.x > this.paintableAreaDimensions.width) p.x = this.paintableAreaDimensions.width;
    if (p.y > this.paintableAreaDimensions.height) p.y = this.paintableAreaDimensions.height;
    return p;
  },

  setFreeDrawingStrategy: function(strategy) {
    this.freeDrawingStrategy = strategy;
  },

  onMouseDown: function(pointer) {
    const p = new fabric.Point(pointer.x, pointer.y);
    if (this.freeDrawingStrategy) this.freeDrawingStrategy.initInfo(this.width);
    if (!this._isPointOnBook(p)) return;

    if (this.freeDrawingStrategy) {
      p.setFromPoint(this.freeDrawingStrategy.clampPointer(pointer));
    }

    this._startNewPath(p);
  },

  onMouseMove: function(pointer, { e }) {
    const p = new fabric.Point(pointer.x, pointer.y);

    if (!this._isPointOnBook(pointer) && this._isCreatingNewPath()) {
      this._completePath(this._clampPointToEdge(this._prevPointer));
    } else if (this._isPointOnBook(pointer) && !this._isCreatingNewPath()) {
      if (!this.freeDrawingStrategy) {
        this._startNewPath(this._clampPointToEdge(this._prevPointer));
      } else if (this.freeDrawingStrategy && !this.freeDrawingStrategy.shouldCompletePath(e, this.canvas.upperCanvasEl)) {
        p.setFromPoint(this.freeDrawingStrategy.clampPointer(p));
        this._startNewPath(p);
      }
    } else if (this._isPointOnBook(pointer) && this._isCreatingNewPath()) {
      let complete = false;

      if (this.freeDrawingStrategy) {
        complete = this.freeDrawingStrategy.shouldCompletePath(e, this.canvas.upperCanvasEl);
        p.setFromPoint(this.freeDrawingStrategy.clampPointer(p));
      }

      if (complete) this._completePath(this._prevPointer);
      else this._addPoint(p);
    }

    this.canvas.clearContext(this.canvas.contextTop);
    if (this._points.length > 0) this._render();

    this._prevPointer = p;
  },

  onMouseUp: function(evt) {
    const { pointer } = evt;
    const p = new fabric.Point(pointer.x, pointer.y);

    if (this._isPointOnBook(p) && this._isCreatingNewPath()) {
      if (this.freeDrawingStrategy) {
        p.setFromPoint(this.freeDrawingStrategy.clampPointer(pointer));
      }

      this._completePath(p);
    }
  },

  _startNewPath: function(pointer) {
    this._setBrushStyles(this.canvas.contextTop);
    this._setShadow();

    this._addPoint(pointer);
  },

  _completePath: function(pointer) {
    if (pointer) {
      const p = this._clampPointToEdge(pointer);

      this._addPoint(p);
    }

    if (this.freeDrawingStrategy) this.freeDrawingStrategy.reset();
    this._finalize();
    this._points = [];
  },

  _finalize: function() {
    if (this.decimate) {
      this._points = this.decimatePoints(this._points, this.decimate);
    }

    const pathData = this.convertPointsToSVGPath(this._points).join('');

    if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
      // do not create 0 width/height paths, as they are
      // rendered inconsistently across browsers
      // Firefox 4, for example, renders a dot,
      // whereas Chrome 10 renders nothing
      this.canvas.requestRenderAll();
      return;
    }

    const path = this.createPath(pathData);

    // fire event 'path:created'
    // we fire it now, because after the addwithupdate, the path's coordinates are altered
    this.canvas.fire('path:created', { path: path });

    // we add the marking directly into the markingsgroup to prevent flicker
    const markingsGroup = this.canvas.getObjects().find(x => x.meta === FabricTypes.MARKINGS);
    markingsGroup.addWithUpdate(path);
    this.canvas.renderAll();
    this.canvas.clearContext(this.canvas.contextTop);
    this.canvas.fire('path:rendered');
  },

  _render: function() {
    if (this._points.length < 1) return;
    this.callSuper('_render');
  },
});

export default CustomPencilBrush;
