import api from './api';

import LayerNames from '../enums/layername';
import Errors from '../enums/error-messages';

export default class PageImageProvider {
  constructor(digibookId, token, cache) {
    this.digibookId = digibookId;
    this.token = token;
    this.cache = cache;
    this.currentImageRenders = Object.values(LayerNames).reduce((acc, layerName) => {
      acc[layerName] = [];

      return acc;
    }, {});
  }

  /**
   * @description Is used to clear from current renders when it is done loading
   * @param {HTMLImageElement} image The HTMLImageElement
   * @param {String} layer The layer name for the image (cover|backcover|book|answer)
   */
  clearImageFromRendersForLayer(image, layer) {
    this.currentImageRenders[layer] = this.currentImageRenders[layer].filter(x => x !== image);
  }

  /**
   * @description cancel all the current renders for a specified layer.
   * @param {String} layer The layer name for the image (cover|backcover|book|answer)
   */
  cancelImageRendersForLayer(layer) {
    this.currentImageRenders[layer].forEach(image => {
      image.src = ''; // eslint-disable-line no-param-reassign
    });

    this.currentImageRenders[layer] = [];
  }

  /**
   * @description Is used to clear from current renders when it is done loading
   */
  cancelAllImageRenders() {
    Object.values(LayerNames).forEach(layer => {
      this.cancelImageRendersForLayer(layer);
    });
  }

  /**
   * @param {String} layer The layer name for the image (cover|backcover|book|answer)
   * @param {Number[]} pageNumbers The pageNumbers for which the images have to be fetched.
   *
   * @returns {Promise} Promise which resolves when the requested images are loaded.
   */
  async getImagesForPages(layer, pageNumbers) {
    this.cancelImageRendersForLayer(layer);

    const images = pageNumbers.map(pageNum => this.cache.get(`${layer}${pageNum}`));

    if (images.every(image => image)) return Promise.resolve(images);

    const from = Math.min(...pageNumbers);
    const to = Math.max(...pageNumbers);
    const pageRange = from === to ? from : `${from}-${to}`;

    return api.get(`/studio/digibooks/${this.digibookId}/${layer.toLowerCase()}/${pageRange}`, { headers: { Authorization: `Bearer ${this.token}` } }).then(res =>
      Promise.all(
        pageNumbers.map(pageNum => {
          const cachedImage = this.cache.get(`${layer}${pageNum}`);
          if (cachedImage) return Promise.resolve(cachedImage);

          const image = new Image();

          image.crossOrigin = 'anonymous';
          image.dataset.page = pageNum;

          return new Promise((resolve, reject) => {
            image.onload = () => {
              this.clearImageFromRendersForLayer(image, layer);
              resolve(image);

              this.cache.add(`${layer}${pageNum}`, image);
            };

            image.onerror = () => {
              this.clearImageFromRendersForLayer(image, layer);

              if (image.getAttribute('src') === '') reject(new Error(Errors.IMAGE_LOAD_CANCELLED));

              resolve(undefined);
            };

            image.src = res.data[pageNum];
            this.currentImageRenders[layer].push(image);
          });
        }),
      ),
    );
  }
}
