import get from 'get-value';
import { createSelector } from 'reselect';

import { getVisibleChapterNodeIds, getActiveNodesForMediaLinks, getTocNodesInTocOrder } from './toc';

export const getMedialinkById = (state, id) => get(state, ['medialinks', 'byId', id]);

const getFavoritesByModuleState = state => get(state, ['medialinks', 'favorites', 'byModuleId']);

export const getMedialink = createSelector(getMedialinkById, getFavoritesByModuleState, (medialink, favoritesByModule) => {
  if (!medialink) return medialink; // there is a difference between null and undefined !! (null is fetched , but no result, undefined is not fetched yet)
  const favoritesForModule = get(favoritesByModule, [medialink.moduleId]);
  const isFavorite = (favoritesForModule || []).indexOf(medialink.id) > -1;
  return { ...medialink, isFavorite };
});

export const areMedialinksForChapterNodeFetched = (state, nodeId) => get(state, ['medialinks', 'nodesFetched']).includes(nodeId);
export const getMedialinksForTocNode = (state, nodeId) => get(state, ['medialinks', 'byTocNodeId', nodeId]);

export const areMedialinksForVisibleChapterNodesFetched = state => {
  const nodes = getVisibleChapterNodeIds(state);
  const allFetched = nodes.reduce((acc, nodeId) => acc && areMedialinksForChapterNodeFetched(state, nodeId), true);
  return allFetched;
};

const getMedialinksForCurrentPages = state => {
  const visibleTOCNodes = getActiveNodesForMediaLinks(state); // nodes are in order
  // loop over all the visible nodes. (all levels);
  const medialinks = visibleTOCNodes.reduce((acc, node) => {
    const chapterNodeId = node.hierarchy && node.hierarchy[0] ? node.hierarchy[0] : node.id;
    const mlsForChapterNode = get(state, ['medialinks', 'byTocNodeId', chapterNodeId]);
    if (mlsForChapterNode) {
      const expandedMedialinks = mlsForChapterNode.map(id => getMedialink(state, id));
      const medialinksForNode = expandedMedialinks.filter(ml => node.id === ml.hierarchy[ml.hierarchy.length - 1]);
      acc.push(...medialinksForNode);
    }
    return acc;
  }, []);

  return medialinks;
};

export const getMedialinksForCurrentPagesGroupedByNode = state => {
  const result = getMedialinksForCurrentPages(state).reduce((acc, medialink) => {
    const nodeId = medialink.hierarchy[medialink.hierarchy.length - 1];

    if (acc[nodeId]) {
      acc[nodeId].push(medialink);
    } else {
      acc[nodeId] = [medialink];
    }

    return acc;
  }, {});

  return result;
};

/**
 * Get media link details for the given ids in order that they are in Table of Content
 *
 * @param {*} state The Redux State
 * @param {String[]} medialinkIds medialink ids
 */
export function getMedialinksInTocOrder(state, medialinkIds) {
  const tocNodes = getTocNodesInTocOrder(state);

  const mlIds = tocNodes.reduce((ids, node) => {
    const medialinksForTocNode = getMedialinksForTocNode(state, node.id);
    if (medialinksForTocNode) ids.push(...medialinksForTocNode.filter(ml => medialinkIds.includes(ml)));

    return ids;
  }, []);

  return mlIds.map(id => getMedialink(state, id));
}

export const getMedialinkCategoriesForModule = (state, moduleId) => get(state, ['medialinks', 'categories', 'byModuleId', moduleId]);
export const getMaterialCategoriesToHide = (state, moduleId) => get(state, ['medialinks', 'materialCategoriesToHide', 'byModuleId', moduleId]);
