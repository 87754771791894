/* eslint-disable react/no-danger */
import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool, shape, oneOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { Button } from '@pelckmans/business-components/components/button';
import { ShareManager } from '@pelckmans/business-components/components/share-manager';
import classNames from 'classnames';
import Alert from '../../../../../../../../components/alert';
import Tabs from '../../../../../dialogs/tabs/tabs';
import Tab from '../../../../../dialogs/tabs/tab';
import UserMaterialInfo from './UserMaterialInfo';
import { deleteUserMedia, setUserMediaShared } from '../../../../../../../../actions/userMedia';
import userMaterialPropType from './user-material-prop-type';
import { tokenGetter } from '../../../../../../../../services/api';
import { errorOccurred } from '../../../../../../../../actions/error';
import { STUDIO_GROUP } from '../../../../../../../../constants/constants';
import { BulkSharingMessage } from '../../../../../dialogs/options/bulk-sharing-message';
import { getCurrentSuperModuleId } from '../../../../../../../../selectors/digibooks';
import { getRoles } from '../../../../../../../../selectors/user';
import { highestRole } from '../../../../../../../../utils/roles';
import { trackShareMaterial } from '../../../../../../../../ga-events/sharing-helpers';
import GoogleAnalyticsContext from '../../../../../../../../contexts/GoogleAnalyticsContext';
import { sharingGaEventArgs } from '../../../../../../../../ga-events';
import { getFeatureToggleFor } from '../../../../../../../../utils/feature-toggle';
import featureToggle from '../../../../../../../../enums/feature-toggle';
import getMyClassesLink from '../../../../../../../../utils/getMyClassesLink';

const tabs = {
  SHARING: 'sharing',
  INFO: 'info',
  DELETE: 'delete',
};

const UserMaterialOptionsModal = ({ onClose, userMaterial, isOwner, module, selectSharedOptionInShareManager, activeTab }) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [currentTab, setCurrentTab] = useState(tabs.INFO);
  const [isModalVisible, setIsModalVisible] = useState();

  const featureToggles = useSelector(state => state.featureToggles);
  const isSharingFeatureEnabled = getFeatureToggleFor(featureToggle.SHARING_MATERIAL);

  const sharingIsEnabled = isSharingFeatureEnabled && isOwner;
  const sharedOrAssignment = userMaterial.shared || userMaterial.assignment;
  const isLicensed = module.licenses.hasDigitalManualLicenseForTeacher;
  const showSharingTab = sharingIsEnabled && (isLicensed || sharedOrAssignment);

  useEffect(() => {
    setCurrentTab(showSharingTab ? tabs.SHARING : tabs.INFO);
    setIsModalVisible(!showSharingTab);
  }, [showSharingTab]);

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  const handleDelete = useCallback(() => {
    dispatch(deleteUserMedia(userMaterial));
    onClose();
  }, [onClose, userMaterial, dispatch]);

  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);

  const { ga } = useContext(GoogleAnalyticsContext);

  const roles = useSelector(state => getRoles(state));
  const superModuleId = useSelector(getCurrentSuperModuleId);
  const moduleId = userMaterial.module;
  const nodeId = userMaterial.node;

  const onSave = (id, newShareInfo, oldShareInfo) => {
    const shared = (newShareInfo?.audiences || []).some(a => !a?.assignment);
    const assignment = (newShareInfo?.audiences || []).find(a => a.assignment)?.assignment;

    dispatch(setUserMediaShared(id, shared, assignment));

    if (ga) {
      trackShareMaterial({
        newAudiences: newShareInfo?.audiences || [],
        oldAudiences: oldShareInfo?.audiences || [],
        moduleName: module.name,
        material: { name: userMaterial.name, kind: 'user-material' },
        role: highestRole(roles),
        onShare: payload => {
          ga.event(sharingGaEventArgs.buildShareSingleMaterial(payload));
        },
        onUnshare: payload => {
          ga.event(sharingGaEventArgs.buildUnshareSingleMaterial(payload));
        },
        onStop: payload => {
          ga.event(sharingGaEventArgs.buildStopShareSingleMaterial(payload));
        },
      });
    }

    onClose();
  };

  const showModal = useCallback(() => setIsModalVisible(true), []);

  return (
    <div
      className={classNames('pbb-modal-user-media', {
        'pbb-modal-wrapper--hidden': !isModalVisible,
        'pbb-modal-user-media--owner': isOwner,
      })}
    >
      <ModalDialog id="user-media-options-modal" onClose={onClose} closeViaEscape zIndex={10001} closeTitle={t('actions.close')}>
        <Dialog.Header data-testid="dialog-header" title={userMaterial.name} startAdornment={<i role="img" className="icon-bb-material-own" />} />
        <Dialog.Body data-testid="dialog-body">
          <div className="pbb-modal__body">
            <Tabs onClick={setCurrentTab} activeTab={currentTab}>
              {showSharingTab && <Tab title={t('tabs.sharing')} id={tabs.SHARING} icon="icon-bb-label-share" />}
              <Tab title={t('tabs.info.title')} id={tabs.INFO} icon="icon-bb-modal-info" />
              {isOwner && <Tab title={t('tabs.delete')} id={tabs.DELETE} icon="icon-trash" />}
            </Tabs>
            <div className="pbb-modal__body-wrapper pbb-modal__body-wrapper--no-pb">
              {showSharingTab && (
                <div
                  className={classNames('pbb-share-manager', {
                    'pbb-share-manager--hidden': currentTab !== tabs.SHARING,
                  })}
                >
                  <ShareManager
                    moduleId={moduleId}
                    nodeId={nodeId}
                    materialType="user-material"
                    materialId={userMaterial.id}
                    tokenAccessor={tokenAccessor}
                    onCancel={onClose}
                    onSave={(newShareInfo, oldShareInfo) => onSave(userMaterial.id, newShareInfo, oldShareInfo)}
                    onError={handleError}
                    onGroupsFetched={showModal}
                    translations={{
                      sharingDisabled: t('shareManager.sharingDisabled'),
                      sharingEnabled: t('shareManager.sharingEnabled'),
                      cancel: t('shareManager.cancel'),
                      save: t('shareManager.save'),
                      rangeSeparator: t('shareManager.rangeSeparator'),
                      overrideRangeWarning: t('shareManager.overrideRangeWarning'),
                      noGroupsTitle: t('shareManager.noGroupsTitle'),
                      noGroupsMessage: t('shareManager.noGroupsMessage', {
                        myClassesLink: getMyClassesLink(),
                      }),
                      close: t('shareManager.close'),
                      search: t('shareManager.search'),
                      noSearchResults: t('shareManager.noSearchResults'),
                      asAssignment: t('shareManager.assignments.asAssignment'),
                      choosePeriod: t('shareManager.assignments.choosePeriod'),
                      multiple: t('shareManager.assignments.multiple'),
                      withRetry: t('shareManager.assignments.withRetry'),
                      onlyExerciseCanBeSharedAsAssignment: t('shareManager.assignments.onlyExerciseCanBeSharedAsAssignment'),
                      stopCurrentAssignmentsWhenSharingWithNone: t('shareManager.assignments.stopCurrentAssignmentsWhenSharingWithNone'),
                      stopSharingCurrentAssignment: t('shareManager.assignments.stopSharingCurrentAssignment'),
                    }}
                    enableAssignment={featureToggles.ASSIGNMENTS}
                    enableMaterialAsAssignment={featureToggles.MATERIAL_ASSIGNMENTS}
                    language={language}
                    isScorableLti={Boolean(userMaterial.lti && userMaterial.userMaxScore)}
                    enableSharing={sharingIsEnabled && (sharedOrAssignment || selectSharedOptionInShareManager)}
                    studioGroup={STUDIO_GROUP}
                  >
                    <BulkSharingMessage superModuleId={superModuleId} nodeId={nodeId} />
                  </ShareManager>
                </div>
              )}
              {!isLicensed && !sharedOrAssignment && currentTab === tabs.SHARING && (
                <div className="bc-notification__notification">
                  <span dangerouslySetInnerHTML={{ __html: t('sharing.no_dhlk') }} />
                </div>
              )}
              {currentTab === tabs.INFO && <UserMaterialInfo isOwner={isOwner} userMaterial={userMaterial} editable={isOwner} />}
              {currentTab === tabs.DELETE && <Alert message={t('userMediaOptions.alerts.delete')} />}
            </div>
          </div>
        </Dialog.Body>
        {currentTab !== tabs.SHARING && (
          <Dialog.Footer data-testid="dialog-footer">
            <Button onClick={onClose} data-testid="btn-close">
              {t('actions.close')}
            </Button>
            {currentTab === tabs.DELETE && (
              <Button onClick={() => handleDelete()} data-testid="btn-delete" variant="danger">
                {t('actions.delete')}
              </Button>
            )}
          </Dialog.Footer>
        )}
      </ModalDialog>
    </div>
  );
};

UserMaterialOptionsModal.propTypes = {
  onClose: func.isRequired,
  userMaterial: userMaterialPropType.isRequired,
  isOwner: bool.isRequired,
  module: shape({
    licenses: shape({
      hasDigitalManualLicenseForTeacher: bool,
    }).isRequired,
  }).isRequired,
  selectSharedOptionInShareManager: bool,
  activeTab: oneOf([tabs.SHARING, tabs.INFO, tabs.DELETE]),
};

UserMaterialOptionsModal.defaultProps = {
  selectSharedOptionInShareManager: false,
  activeTab: undefined,
};

export default UserMaterialOptionsModal;
