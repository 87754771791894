import React from 'react';
import { node } from 'prop-types';

const Toolbar = React.forwardRef(({ children }, ref) => (
  <div ref={ref} className="pbb-wysiwyg-tools" data-testid="wysiwyg-toolbar">
    {children}
  </div>
));

Toolbar.propTypes = {
  children: node,
};

Toolbar.defaultProps = {
  children: [],
};

export default Toolbar;
