/**
 * @typedef {{x: number, y: number}} Point
 * @typedef {[Point, Point]} Line
 * @typedef {[Point, Point, Point, Point]} Rectangle
 */

/**
 * @description Returns either the minimum, maximum or the given size depending on where the size lies in the bounds.
 *
 * @param {Number} size
 * @param {Number} min
 * @param {Number} max
 * @returns {Number} The new size respecting it's boundaries
 */
export function applySizeBoundaries(size, min, max) {
  const lowerBoundary = Math.max(min, size);
  const upperBoundary = Math.min(max, lowerBoundary);

  return upperBoundary;
}

export const normalizeRotation = degrees => (degrees < 0 ? degrees + 360 : degrees);
export const radiansToDegrees = radians => (radians * 180) / Math.PI;
export const degreesToRadians = degrees => degrees * (Math.PI / 180);

/**
 * @param {Point} pointToCalibrate
 * @param {Point} referencePoint
 * @returns {Point} calibrated point referenced to referencePoint
 */
export function calibratePoint(pointToCalibrate, referencePoint) {
  return {
    x: pointToCalibrate.x - referencePoint.x,
    y: pointToCalibrate.y - referencePoint.y,
  };
}

/**
 * @description calculates the angle is degrees between 2 given points.
 *
 * @param {Point} calibratedPoint a point which is calibrated to a reference point
 */
export function calculateAngleInDegrees(calibratedPoint) {
  const angleRadians = Math.atan2(calibratedPoint.y, calibratedPoint.x);

  const angleInDegrees = radiansToDegrees(angleRadians);

  const normalizedRotation = normalizeRotation(angleInDegrees);

  return {
    normalizedRotation,
    denormalizedRotation: Math.round(angleInDegrees),
  };
}

/**
 *
 * @param {Point} point point to rotate
 * @param {number} rotationInRadians
 * @param {Point} center center of rotation
 * @returns {Point}
 */
export function rotatePoint(point, rotationInRadians, center = { x: 0, y: 0 }) {
  const { x: cx, y: cy } = calibratePoint(point, center);

  const x = cx * Math.cos(rotationInRadians) - cy * Math.sin(rotationInRadians);
  const y = cy * Math.cos(rotationInRadians) + cx * Math.sin(rotationInRadians);

  return { x: x + center.x, y: y + center.y };
}

/**
 *
 * @param {Point} p1
 * @param {Point} p2
 * @returns {number}
 */
export function distanceBetweenPoints(p1, p2) {
  return Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
}

/**
 * calculates the area of a triangle using Heron's formula: https://en.wikipedia.org/wiki/Heron%27s_formula
 *
 * @param {number} d1
 * @param {number} d2
 * @param {number} d3
 */
export function triangleArea(d1, d2, d3) {
  const s = (d1 + d2 + d3) / 2; // semi-perimeter

  return Math.sqrt(s * (s - d1) * (s - d2) * (s - d3));
}

/**
 * https://joshuawoehlke.com/detecting-clicks-rotated-rectangles/
 *
 * @param {Point} point pointer
 * @param {Rectangle} rectangle
 * @returns true if point is in specified rectangle
 */
export function isPointInRectangle(point, rectangle) {
  const rectangleArea = Math.round((distanceBetweenPoints(rectangle[0], rectangle[1]) * distanceBetweenPoints(rectangle[0], rectangle[3]) + Number.EPSILON) * 1000) / 1000;

  const triangleAreas = [
    triangleArea(distanceBetweenPoints(rectangle[0], point), distanceBetweenPoints(rectangle[1], point), distanceBetweenPoints(rectangle[0], rectangle[1])),
    triangleArea(distanceBetweenPoints(rectangle[1], point), distanceBetweenPoints(rectangle[2], point), distanceBetweenPoints(rectangle[1], rectangle[2])),
    triangleArea(distanceBetweenPoints(rectangle[2], point), distanceBetweenPoints(rectangle[3], point), distanceBetweenPoints(rectangle[2], rectangle[3])),
    triangleArea(distanceBetweenPoints(rectangle[3], point), distanceBetweenPoints(rectangle[0], point), distanceBetweenPoints(rectangle[3], rectangle[0])),
  ];

  const totalTriangleArea = Math.round((triangleAreas.reduce((sum, area) => sum + area, 0) + Number.EPSILON) * 1000) / 1000;

  return totalTriangleArea <= rectangleArea;
}

/**
 *
 * @param {Point} p
 * @param {Line} segment
 * @returns {Point}
 */
// https://stackoverflow.com/a/74134734
export function findNearestPointOnSegment(p, segment) {
  const [a, b] = segment;

  const atob = { x: b.x - a.x, y: b.y - a.y };
  const atop = { x: p.x - a.x, y: p.y - a.y };
  const len = atob.x * atob.x + atob.y * atob.y;
  const dot = atop.x * atob.x + atop.y * atob.y;
  const t = Math.min(1, Math.max(0, dot / len));

  return {
    x: a.x + atob.x * t,
    y: a.y + atob.y * t,
  };
}

/**
 * @param {Point} p1
 * @param {Point} p2
 * @returns functions to calculate x/y value given y/x respectively
 */
export function generateLineEquations(p1, p2) {
  const slope = (p2.y - p1.y) / (p2.x - p1.x);

  const calcX = y => (y - p1.y) / slope + p1.x;
  const calcY = x => slope * (x - p1.x) + p1.y;

  return { calcX, calcY };
}

/**
 *
 * @param {Point} pointer
 * @param {Line} line
 * @param {Number} rotationInDegrees
 * @returns true if point lies above the given line
 */
export function isPointAboveLine(pointer, line, rotationInDegrees) {
  const { calcX, calcY } = generateLineEquations(line[0], line[1]);

  if (rotationInDegrees === 0) return pointer.y <= calcY(pointer.x);
  if (rotationInDegrees > 0 && rotationInDegrees < 90) return pointer.x >= calcX(pointer.y) && pointer.y <= calcY(pointer.x);
  if (rotationInDegrees === 90) return pointer.x >= calcX(pointer.y);
  if (rotationInDegrees > 90 && rotationInDegrees < 180) return pointer.x >= calcX(pointer.y) && pointer.y >= calcY(pointer.x);
  if (rotationInDegrees === 180) return pointer.y >= calcY(pointer.x);
  if (rotationInDegrees > 180 && rotationInDegrees < 270) return pointer.x <= calcX(pointer.y) && pointer.y >= calcY(pointer.x);
  if (rotationInDegrees === 270) return pointer.x <= calcX(pointer.y);
  if (rotationInDegrees > 270 && rotationInDegrees < 360) return pointer.x <= calcX(pointer.y) && pointer.y <= calcY(pointer.x);

  return false;
}
