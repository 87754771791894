import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';

import {
  getMedialinksForCurrentPagesGroupedByNode,
  areMedialinksForVisibleChapterNodesFetched,
  getMedialinkCategoriesForModule,
  getMaterialCategoriesToHide,
} from '../../../../../../selectors/medialinks';
import { getTeacherFeaturesEnabledFor, getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { getActiveNodesForMediaLinks } from '../../../../../../selectors/toc';
import { getModuleById } from '../../../../../../selectors/module';

import Footer from './footer';
import Alert from '../../../../../../components/alert';
import MaterialList from './MaterialList';
import MaterialFilterSettings from './MaterialFilterSettings';

import Collapsible from '../../../../../../components/collapsible';
import { getUserMediaForCurrentPagesGroupedByNode } from '../../../../../../selectors/userMedia';

function Material() {
  const [isMaterialFilterSettingsOpen, setMaterialFilterSettingsOpen] = useState(false);
  const [t] = useTranslation();

  const moduleId = useSelector(state => getCurrentModuleId(state));
  const module = useSelector(state => getModuleById(state, moduleId));
  const medialinksGroupedByNode = useSelector(state => getMedialinksForCurrentPagesGroupedByNode(state));
  const medialinksFetched = useSelector(state => areMedialinksForVisibleChapterNodesFetched(state));
  const teacherFeaturesEnabled = useSelector(state => getTeacherFeaturesEnabledFor(state));
  const medialinkCategories = useSelector(state => getMedialinkCategoriesForModule(state, moduleId));
  const activeMedialinkNodes = useSelector(state => getActiveNodesForMediaLinks(state));

  const materialCategoriesToHide = useSelector(state => getMaterialCategoriesToHide(state, moduleId));
  const userMediaGroupedByNode = useSelector(state => getUserMediaForCurrentPagesGroupedByNode(state));

  if (isMaterialFilterSettingsOpen) {
    return (
      <MaterialFilterSettings
        medialinkCategories={medialinkCategories}
        close={() => setMaterialFilterSettingsOpen(false)}
        teacherFeaturesEnabled={teacherFeaturesEnabled}
        moduleId={moduleId}
        materialCategoriesToHide={materialCategoriesToHide}
      />
    );
  }

  return (
    <>
      <span data-testid="material-title" className="pbb-drawer__intro">
        {t('drawer.material.title')}
      </span>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-material" data-testid="material-list">
            {activeMedialinkNodes.length ? (
              <Collapsible>
                {activeMedialinkNodes.map(node => {
                  const medialinksGroup = (medialinksGroupedByNode || {})[node.id];
                  const userMediaGroup = (userMediaGroupedByNode || {})[node.id];

                  return (
                    <MaterialList
                      medialinks={medialinksGroup}
                      userMedia={userMediaGroup}
                      tocnode={node}
                      moduleId={moduleId}
                      teacherFeaturesEnabled={teacherFeaturesEnabled}
                      medialinksFetched={medialinksFetched}
                      openFilterSettings={() => setMaterialFilterSettingsOpen(true)}
                      materialCategoriesToHide={materialCategoriesToHide}
                      key={node.id}
                    />
                  );
                })}
              </Collapsible>
            ) : (
              <Alert message={t('drawer.material.no_material')} />
            )}
          </div>
        </div>
      </SimpleBar>
      <Footer openFilterSettings={() => setMaterialFilterSettingsOpen(true)} materialCategoriesToHide={materialCategoriesToHide} module={module} />
    </>
  );
}

export default React.memo(Material);
