import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import qs from 'query-string';

import classNames from 'classnames';
import { PORTAAL_URL, STUDIO_GROUP } from '../constants/constants';
import api from '../services/api';
import { buildModuleInPUrl } from '../utils/buildModuleInPUrl';
import PortaalLogo from './portaal-logo';

function BackToPButton({ location }) {
  const { superModuleId } = qs.parse(location.search);
  const [superModuleInfo, setSuperModuleInfo] = useState(undefined);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const url = superModuleInfo ? buildModuleInPUrl(language, superModuleInfo, superModuleInfo.slug) : PORTAAL_URL;

  useEffect(() => {
    const getModuleInfo = async () => {
      const response = await api.get(`/studio/modules/${superModuleId}`);
      setSuperModuleInfo(response.data); // should this error, it will be catched by the GlobalErrorBoundary
    };

    if (!superModuleInfo && superModuleId) getModuleInfo(superModuleId);
  }, [superModuleId, superModuleInfo]);

  const showLogo = ['SO', 'ES'].includes(STUDIO_GROUP);

  return (
    <a className={classNames('pbb-no-access__return', { 'pbb-no-access__return--link': !showLogo })} href={url} title={!showLogo ? t('backToP.text') : t('backToP.title')}>
      <span>{t('backToP.text')}</span>
      {showLogo && <PortaalLogo inverted />}
    </a>
  );
}

BackToPButton.propTypes = {
  location: shape({
    search: string,
  }).isRequired,
};

export default withRouter(BackToPButton);
