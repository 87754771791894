import React from 'react';
import { Dialog, ModalDialog } from '@pelckmans/business-components/components/dialog';
import { Button } from '@pelckmans/business-components/components/button';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';

const ID = 'delete-text-confirmation';

export default function DeleteTextConfirmationModal({ onDelete, onClose }) {
  const { t } = useTranslation();

  return (
    <ModalDialog className="delete-confirmation" id={ID} onClose={onClose} size="small">
      <Dialog.Header startAdornment={<BinIcon />} title={t('annotationTool.delete.delete-modal.title')} />
      <Dialog.Body>
        <div className="bc-notification__notification">{t('annotationTool.delete.delete-modal.message')}</div>
      </Dialog.Body>
      <Dialog.Footer>
        <Button onClick={onClose}>{t('actions.cancel')}</Button>
        <Button onClick={onDelete} variant="danger">
          {t('actions.deleteAll')}
        </Button>
      </Dialog.Footer>
    </ModalDialog>
  );
}

DeleteTextConfirmationModal.propTypes = {
  onClose: func.isRequired,
  onDelete: func.isRequired,
};
