import React from 'react';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setCurrentMathTool } from '../../../../../../../actions/tools';

import closeIcon from '../../../../../../../../assets/images/math-tool-close.svg';
import RoundedImageButton from '../../components/RoundedImageButton';
import getSizes from '../sizes';

const STROKE_WIDTH = 2;

export default function CrossHair({ pixelsPerMM }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { BUTTON_RADIUS, CENTER, CROSSHAIR_OUTER_CIRCLE_RADIUS } = getSizes(pixelsPerMM);
  const CROSSHAIR_INNER_RADIUS = BUTTON_RADIUS;
  const LINE_CENTER_OFFSET = CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.3;
  const LINE_HEIGHT = CROSSHAIR_OUTER_CIRCLE_RADIUS * 1.2;
  const MASK_RADIUS = CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.2;
  const DOT_RADIUS = CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.1;

  const handleClose = () => {
    dispatch(setCurrentMathTool(undefined));
  };

  return (
    <>
      <RoundedImageButton
        x={CENTER + LINE_CENTER_OFFSET + LINE_HEIGHT - BUTTON_RADIUS}
        y={-BUTTON_RADIUS}
        size={BUTTON_RADIUS * 2}
        src={closeIcon}
        title={t('actions.close')}
        onClick={handleClose}
        data-testid="close-button"
      />
      <g id="crosshair" data-drag>
        <g id="crosshair-circles">
          <mask id="circle-mask">
            <circle cx={CENTER} cy={CENTER} r={CROSSHAIR_INNER_RADIUS} stroke="none" fill="white" />
            <circle cx={CENTER} cy={CENTER} r={MASK_RADIUS} fill="black" />
          </mask>
          <circle className="stroke" cx={CENTER} cy={CENTER} r={CROSSHAIR_OUTER_CIRCLE_RADIUS} strokeWidth={STROKE_WIDTH} fill="none" />
          <circle cx={CENTER} cy={CENTER} r={CROSSHAIR_INNER_RADIUS} stroke="none" fill="rgba(217,238,243, .75)" mask="url(#circle-mask)" />
          <circle id="center-dot" className="stroke" cx={CENTER} cy={CENTER} r={DOT_RADIUS} fill="rgba(0, 77, 98, .75)" />
        </g>

        <g id="crosshair-lines">
          <line className="stroke" x1={CENTER} y1={CENTER + LINE_CENTER_OFFSET} x2={CENTER} y2={CENTER + LINE_CENTER_OFFSET + LINE_HEIGHT} strokeWidth={STROKE_WIDTH} />
          <line className="stroke" x1={CENTER} y1={CENTER - LINE_CENTER_OFFSET} x2={CENTER} y2={CENTER - LINE_CENTER_OFFSET - LINE_HEIGHT} strokeWidth={STROKE_WIDTH} />

          <line className="stroke" x1={CENTER + LINE_CENTER_OFFSET} y1={CENTER} x2={CENTER + LINE_CENTER_OFFSET + LINE_HEIGHT} y2={CENTER} strokeWidth={STROKE_WIDTH} />
          <line className="stroke" x1={CENTER - LINE_CENTER_OFFSET} y1={CENTER} x2={CENTER - LINE_CENTER_OFFSET - LINE_HEIGHT} y2={CENTER} strokeWidth={STROKE_WIDTH} />
        </g>
      </g>
    </>
  );
}

CrossHair.propTypes = {
  pixelsPerMM: number.isRequired,
};
