/* eslint-disable react/no-unused-state */
import React from 'react';
import debounce from 'lodash/debounce';
import getDisplayName from './utils/display-name';

const withDimensions = Component =>
  class extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static displayName = `withDimensions(${getDisplayName(Component)})`;

    constructor(props) {
      super(props);

      this.state = {
        height: 0,
        width: 0,
      };

      this.containerRef = React.createRef();
      this.debounceResize = debounce(this.resize, 250);
    }

    componentDidMount() {
      this.resize();
      window.addEventListener('resize', this.debounceResize);
    }

    componentWillUnmount() {
      this.unmounting = true;
      window.removeEventListener('resize', this.debounceResize);
      this.debounceResize.cancel();
    }

    resize = () => {
      if (this.unmounting) return;

      if (window.pageYOffset < 0) window.scrollTo(0, 0);

      const element = this.containerRef.current;
      if (element) {
        this.setState({
          height: element.clientHeight,
          width: element.clientWidth,
        });
      }
    };

    render() {
      return (
        <div ref={this.containerRef} className="pbb-book--container full-height" style={{ flexGrow: 1, minWidth: 0 }}>
          <Component {...this.props} dimensions={this.state} />
        </div>
      );
    }
  };

export default withDimensions;
