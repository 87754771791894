import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import { flushSync, render } from 'react-dom';

import { UserSettingsContext } from '../../../context/user-settings-context';
import NotoSansMono from '../../../../../../assets/fonts/noto-sans-mono.ttf';
import Playfair from '../../../../../../assets/fonts/playfair.ttf';
import OpenSans from '../../../../../../assets/fonts/open-sans.ttf';
import Playwrite from '../../../../../../assets/fonts/playwrite.ttf';
import MyriadPro from '../../../../../../assets/fonts/myriad-pro-regular.ttf';
import Spinner from '../../../../../../assets/images/spinner.svg';
import TextAnnotations from '../../../../../components/text-annotations/text-annotations';
import { addFontToPDF, canvasToBase64 } from '../utils';

function renderToString(elem) {
  const div = document.createElement('div');

  flushSync(() => {
    render(elem, div);
  });

  return div.innerHTML;
}

function openPrintWindowViaIframeHack(source, removeLoader, callbackFn) {
  const iframe = document.createElement('iframe');
  iframe.style.position = 'fixed';
  // "visibility: hidden" would trigger safety rules in some browsers like safari，
  // in which the iframe display in a pretty small size instead of hidden.
  // here is some little hack ~
  iframe.style.width = '1px';
  iframe.style.height = '1px';
  iframe.style.opacity = '0.01';
  iframe.setAttribute('data-testid', 'print-iframe');

  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

  if (isSafari) {
    // fallback in safari
    iframe.onload = () => {
      try {
        iframe.contentWindow.document.execCommand('print', false, null);
      } catch (e) {
        iframe.contentWindow.print();
      }
    };
  }

  iframe.src = source;

  removeLoader();

  document.body.appendChild(iframe);

  const cleanup = () => {
    if (callbackFn) callbackFn();

    document.body.removeChild(iframe);

    window.removeEventListener('focus', cleanup);
  };

  window.addEventListener('focus', cleanup);
}

function useWhitepagePrinting({ currentTool, fabricService, fabricServiceMarkings, annotations, paper, whitepage }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { sidebarAnchor } = useContext(UserSettingsContext);

  const print = useCallback(async () => {
    setIsLoading(true);

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
      format: 'a4',
      unit: 'px',
    });

    const docPixelWidth = doc.internal.pageSize.getWidth();
    const docPixelHeight = doc.internal.pageSize.getHeight();

    await addFontToPDF(doc, NotoSansMono, 'NotoSansMono-Regular.ttf', 'NotoSansMono', 'normal');
    await addFontToPDF(doc, Playfair, 'Playfair-Regular.ttf', 'Playfair', 'normal');
    await addFontToPDF(doc, OpenSans, 'OpenSans-Regular.ttf', 'Open Sans', 'normal');
    await addFontToPDF(doc, Playwrite, 'Playwrite.ttf', 'Playwrite', 'normal');
    await addFontToPDF(doc, MyriadPro, 'myriad-pro-regular.ttf', 'myriad-pro', 'normal');

    const canvasWhitepage = fabricService.current.fabricCanvas;
    const whitepageImageData = await canvasToBase64(canvasWhitepage, paper.width, paper.height);

    doc.addImage(whitepageImageData, 'PNG', 0, 0, docPixelWidth, docPixelHeight);

    const canvasWhitepageMarkings = fabricServiceMarkings.current.fabricCanvas;
    const whitepageMarkingsImageData = await canvasToBase64(canvasWhitepageMarkings, paper.width, paper.height);

    // Hotfix to avoid extra top padding on text elements: https://github.com/niklasvh/html2canvas/issues/2829
    const hotfixStyle = document.createElement('style');
    hotfixStyle.innerHTML = `img { display: inline !important; }`;
    document.head.appendChild(hotfixStyle);

    const scale = docPixelWidth / paper.width;

    doc.html(
      `
          ${[...document.getElementsByTagName('style')].map(e => e.outerHTML).join('')}
          ${renderToString(
            <TextAnnotations
              annotations={annotations}
              addAnnotation={() => {}}
              editAnnotation={() => {}}
              selectedAnnotation={null}
              setSelectedAnnotationId={() => {}}
              removeAnnotation={() => {}}
              viewportTransform={[scale, 0, 0, scale, 0, 0]}
              pageHeight={paper.height}
              pageWidth={paper.width}
              isSinglePage
              isRightPage={false}
              isStandalonePage
              isSolutionsPageVisible={false}
              currentTool={currentTool}
              sidebarAnchor={sidebarAnchor}
            />,
          )}
        `,
      {
        callback: docWithHTML => {
          docWithHTML.addImage(whitepageMarkingsImageData, 'PNG', 0, 0, docPixelWidth, docPixelHeight);
          // Causes chrome to give the correct title to the whitepage on print. Does not work in other browsers.
          docWithHTML.setProperties({
            title: whitepage.title,
          });
          docWithHTML.autoPrint();

          openPrintWindowViaIframeHack(
            docWithHTML.output('bloburl'),
            () => setIsLoading(false),
            () => document.head.removeChild(hotfixStyle),
          );
        },
        x: 0,
        y: 0,
      },
    );
  }, [annotations, currentTool, fabricService, fabricServiceMarkings, paper.height, paper.width, sidebarAnchor, whitepage.title]);

  useEffect(() => {
    const eventListener = () => {
      print();
    };

    document.addEventListener('print-whitepage', eventListener);

    return () => {
      document.removeEventListener('print-whitepage', eventListener);
    };
  }, [print]);

  if (!isLoading) return null;

  return (
    <div data-testid="print-loader" className={`pbb-print-loader pbb-print-loader--${sidebarAnchor}`}>
      <img src={Spinner} alt={t('book.loading')} />
    </div>
  );
}

export default useWhitepagePrinting;
