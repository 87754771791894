import {
  INITIALIZE_SUCCESS,
  MARKING_CREATED,
  SET_CURRENTPAGE,
  MARKINGS_LOADED,
  MARKINGS_DELETE_FOR_SPREAD,
  MARKINGS_CREATED,
  SET_ACTIVE_ANNOTATION_SET,
} from '../actions/actionNames';

import UserMarkingsProviderService from '../services/UserMarkingsProviderService';
import { getPageNumbersToShow, getMarkingsForCurrentPages } from '../selectors/rendering';
import { getTotalPagesForDigibook } from '../selectors/digibooks';
import { calculateSpreadForPageNumbers } from '../utils/calculateSpreadForPageNumbers';
import { getActiveAnnotationSet } from '../selectors/annotationSets';

function createStorageKeyForPages(pageNumbersToShow, totalPages) {
  return calculateSpreadForPageNumbers(pageNumbersToShow, totalPages).join('-');
}

// eslint-disable-next-line import/prefer-default-export
export const usermarkings = ({ getState, dispatch }) => {
  const userMarkingsProviderService = new UserMarkingsProviderService();

  async function loadMarkingsForCurrentVisiblePages() {
    const state = getState();
    const pageNumbersToShow = getPageNumbersToShow(state);
    const totalPages = getTotalPagesForDigibook(state);

    // books without spreads have INITIALIZE_SUCCESS before the totalPages is set.
    if (pageNumbersToShow.length > 0 && totalPages !== undefined) {
      const spread = createStorageKeyForPages(pageNumbersToShow, totalPages);
      const markings = await userMarkingsProviderService.getMarkingsForSpread(spread);
      if (Object.keys(markings).length > 0) {
        dispatch({ type: MARKINGS_LOADED, payload: { pages: calculateSpreadForPageNumbers(pageNumbersToShow, totalPages), markings } });
      }
    }
  }

  return next => async action => {
    // first dispatch the action.
    next(action);

    // now do something for actions that have impact on/from user markings.
    const state = getState();
    switch (action.type) {
      case SET_ACTIVE_ANNOTATION_SET: {
        const { annotationSet } = action.payload;
        await userMarkingsProviderService.getSpreadsWithMarkingsForAnnotationSet(annotationSet.id, loadMarkingsForCurrentVisiblePages);
        break;
      }
      case INITIALIZE_SUCCESS: {
        const { payload } = action;
        const { id: digibookId } = payload;
        const { systemToken } = payload.entities.digibooks[digibookId];
        const activeAnnotationSet = getActiveAnnotationSet(state);

        userMarkingsProviderService.initialize(digibookId, systemToken, loadMarkingsForCurrentVisiblePages, activeAnnotationSet.id);
        break;
      }
      case SET_CURRENTPAGE: {
        loadMarkingsForCurrentVisiblePages();
        if (userMarkingsProviderService) userMarkingsProviderService.flushPendingUploads();
        break;
      }
      case MARKING_CREATED:
      case MARKINGS_CREATED: {
        const { payload } = action;
        const totalPages = getTotalPagesForDigibook(state);
        const spread = createStorageKeyForPages(payload.pages, totalPages);
        const markings = getMarkingsForCurrentPages(state);
        userMarkingsProviderService.storeMarkingsForSpread(spread, markings);
        break;
      }
      case MARKINGS_DELETE_FOR_SPREAD: {
        const { pages } = action.payload;
        const totalPages = getTotalPagesForDigibook(state);
        const spread = createStorageKeyForPages(pages, totalPages);

        userMarkingsProviderService.removeAllMarkingsForSpread(spread);
        break;
      }
      default:
    }
    return true;
  };
};
