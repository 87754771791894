import React from 'react';
import { string, node, func } from 'prop-types';

function CustomSelect({ children, value, onChange, testId }) {
  return (
    <div className="pbb-custom-select">
      <select data-testid={testId} onChange={onChange} value={value}>
        {children}
      </select>
    </div>
  );
}

CustomSelect.propTypes = {
  children: node.isRequired,
  value: string.isRequired,
  testId: string.isRequired,
  onChange: func.isRequired,
};

export default CustomSelect;
