import { FEATURE_TOGGLES_RETRIEVED } from "../actions/actionNames";

export default function reducer(state = {}, action) {
  switch (action && action.type) {
    case FEATURE_TOGGLES_RETRIEVED: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
