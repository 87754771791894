import { schema } from 'normalizr';

export const pageranges = new schema.Entity('pageRanges', {}, { idAttribute: 'nodeId' });

export const file = new schema.Entity('files');

export const module = new schema.Entity('modules', {
  logo: file,
});

export const securedFile = new schema.Entity('securedFiles');

export const digibook = new schema.Entity('digibooks', {
  module,
  cover: securedFile,
  backCover: securedFile,
  bookLayer: securedFile,
  answerLayer: securedFile,
  manual: securedFile,
});

export const medialink = new schema.Entity('medialinks');

export const tocnode = new schema.Entity('nodes');
tocnode.define({ nodes: new schema.Array(tocnode) });

export const sharedMedia = new schema.Entity('sharedMedia');

export const note = new schema.Entity('notes');

export const whitepage = new schema.Entity('whitepages');
