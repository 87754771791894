import React from 'react';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { func, string } from 'prop-types';

import { useTranslation } from 'react-i18next';
import { PORTAAL_URL } from '../../../../constants/constants';

export default function NotLicensedModal({ title, message, onClose }) {
  const { t } = useTranslation();

  return (
    <ModalDialog id="not-licensed-modal" closeTitle={t('actions.close')} closeOnClickOutside closeViaEscape onClose={onClose} size="small">
      <Dialog.Header title={title} />
      <Dialog.Body>
        <div className="pbb-modal__body">
          <p>{message}</p>
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <a href={`${PORTAAL_URL}dashboard?showLicenseActivation`} className="bc-button__button bc-button__primary">
          {t(`notLicensed.actions.activateLicense`)}
        </a>
      </Dialog.Footer>
    </ModalDialog>
  );
}

NotLicensedModal.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
  onClose: func.isRequired,
};
