import { LTI_API_URI, STUDIO_GROUP, LANGUAGE } from '../../../constants/constants';
import getPlatformName from '../../../utils/platform-name';

const buildUrl = (toolName, queryString) => `${LTI_API_URI}/${toolName}/login-url?${queryString.toString()}`;

const buildQueryString = module =>
  new URLSearchParams({
    module_id: module.id,
    module_name: module.name,
    subject_name: module.subjectName,
    platform_name: getPlatformName(STUDIO_GROUP),
    locale: LANGUAGE,
  });

const buildAddMaterialUrl = (toolName, module) => {
  const queryString = buildQueryString(module);

  queryString.set('target_link_uri', 'https://www.bookwidgets.com/lti/create');

  return buildUrl(toolName, queryString);
};

export { buildAddMaterialUrl };
