import get from 'get-value';
import { getActiveNodes } from './toc';

export const getNoteById = (state, noteId) => get(state, ['notes', 'byId', noteId]);

export const getNoteIdsGroupedByTocNodeId = (state, tocId) => get(state, ['notes', 'byTocNodeId', tocId]);

export const getNotesForActiveNodes = state => {
  // grouped by nodeId and not chapterNodeId
  const activeNodes = getActiveNodes(state);
  const notesGroupedByChapterNode = activeNodes.reduce((acc, node) => {
    const noteIdsForNode = getNoteIdsGroupedByTocNodeId(state, node.id);
    if (!noteIdsForNode) return acc;
    noteIdsForNode.forEach(id => {
      const note = getNoteById(state, id);

      acc[note.nodeId] = acc[note.nodeId] ? [...acc[note.nodeId], note] : [note];
    });

    return acc;
  }, {});

  return notesGroupedByChapterNode;
};
