import React from 'react';
import { number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import closeIcon from '../../../../../../../../assets/images/math-tool-close.svg';
import resizeIcon from '../../../../../../../../assets/images/math-tool-resize.svg';
import rotateIcon from '../../../../../../../../assets/images/math-tool-rotate.svg';
import { setCurrentMathTool } from '../../../../../../../actions/tools';
import RoundedImageButton from '../../components/RoundedImageButton';

export default function Actions({ halfOfOffset, onResize, onRotate, pixelsPerMM, rulerHeight, x }) {
  const dispatch = useDispatch();

  const [t] = useTranslation();

  const BUTTON_SIZE = pixelsPerMM * 6;

  const handleClose = () => {
    dispatch(setCurrentMathTool(undefined));
  };

  return (
    <g id="actions" data-testid="actions">
      <RoundedImageButton
        id="ruler-close-button"
        data-testid="close-button"
        src={closeIcon}
        size={BUTTON_SIZE}
        x={x}
        y={-halfOfOffset}
        onClick={handleClose}
        title={t('actions.close')}
      />
      <RoundedImageButton
        className="resize-button"
        data-testid="resize-button"
        src={resizeIcon}
        size={BUTTON_SIZE}
        x={x}
        y={rulerHeight / 2 - BUTTON_SIZE / 2}
        title={t('mathTool.actions.resize')}
        onMouseDown={onResize}
        onTouchStart={onResize}
      />
      <RoundedImageButton
        data-testid="rotate-button"
        id="ruler-rotate-button"
        src={rotateIcon}
        size={BUTTON_SIZE}
        x={x}
        y={rulerHeight - halfOfOffset}
        title={t('mathTool.actions.rotate')}
        onMouseDown={onRotate}
        onTouchStart={onRotate}
      />
    </g>
  );
}

Actions.propTypes = {
  halfOfOffset: number.isRequired,
  onResize: func.isRequired,
  onRotate: func.isRequired,
  pixelsPerMM: number.isRequired,
  rulerHeight: number.isRequired,
  x: number.isRequired,
};
