import api from '../services/api';

import {
  RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS,
  ADD_CATEGORY_TO_HIDE_SUCCESS,
  REMOVE_CATEGORY_TO_HIDE_SUCCESS,
  RETRIEVE_FAVORITES_SUCCESS,
  SET_INITIAL_TOOLBAR_POSITION,
} from './actionNames';

import { setViewMode } from './navigation';

export function retrieveUserFavoritesForModule(moduleId) {
  return async dispatch => {
    const response = await api.get(`/studio/user/modules/${moduleId}/favorites-v2`);

    return dispatch({
      type: RETRIEVE_FAVORITES_SUCCESS,
      payload: {
        moduleId,
        favorites: response.data[moduleId],
      },
    });
  };
}

export function addUserCategoryToHide(moduleId, categoryId) {
  return async dispatch => {
    try {
      await api.put(`/studio/user/modules/${moduleId}/codex-player-settings/filtered-media-content-type-categories`, { category: categoryId });
    } catch (e) {
      return null;
    }
    return dispatch({ type: ADD_CATEGORY_TO_HIDE_SUCCESS, payload: { moduleId, categoryId } });
  };
}

export function removeUserCategoryToHide(moduleId, categoryId) {
  return async dispatch => {
    try {
      await api({
        method: 'DELETE',
        url: `/studio/user/modules/${moduleId}/codex-player-settings/filtered-media-content-type-categories`,
        data: { category: categoryId },
      });
    } catch (e) {
      return null;
    }
    return dispatch({ type: REMOVE_CATEGORY_TO_HIDE_SUCCESS, payload: { moduleId, categoryId } });
  };
}

export function retrievePlayerSettingsFor(moduleId) {
  return async dispatch => {
    const { data } = await api.get(`/studio/user/modules/${moduleId}/codex-player-settings`);
    if (data.playerViewMode) dispatch(setViewMode(data.playerViewMode));
    if (data.mediaContentTypeCategoriesToHide) {
      dispatch({ type: RETRIEVE_CATEGORIES_TO_HIDE_SUCCESS, payload: { moduleId, materialCategoriesToHide: data.mediaContentTypeCategoriesToHide } });
    }
    if (data.toolbarPosition) dispatch({ type: SET_INITIAL_TOOLBAR_POSITION, payload: { toolbarPosition: data.toolbarPosition } });
  };
}
