import React from 'react';
import { number } from 'prop-types';
import RulerImage from '../../../../../../../../assets/images/set-square-ruler.svg';
import ProtractorImage from '../../../../../../../../assets/images/set-square-protractor.svg';

const IMAGE_WIDTH = 520;
const RULER_MASK_HEIGHT = 5.1;
const PROTRACTOR_SIZE = 0.53;

export default function Markings({ size, pixelsPerMM, padding }) {
  const offset = size - (IMAGE_WIDTH * pixelsPerMM) / 2;

  const maskHeight = (RULER_MASK_HEIGHT * pixelsPerMM) / 2;
  const rulerMaskPadding = padding - 1;
  const numbersMaskPadding = padding - 6;

  return (
    <g id="set-square-markings" data-testid="markings">
      <clipPath id="set-square-ruler-clip-path">
        <rect x={rulerMaskPadding} y={0} width={2 * (size - rulerMaskPadding)} height={maskHeight} fill="white" />
        <rect x={numbersMaskPadding} y={maskHeight - 2} width={2 * (size - numbersMaskPadding)} height={maskHeight + 5} fill="white" />
        <circle cx={size} cy={0} r={size * PROTRACTOR_SIZE} fill="white" />
      </clipPath>
      <image data-testid="ruler" x={offset} width={IMAGE_WIDTH * pixelsPerMM} href={RulerImage} clipPath="url(#set-square-ruler-clip-path)" />

      <clipPath id="set-square-protractor-clip-path">
        <path d={`M0 ${maskHeight * 2} L${size * 2} ${maskHeight * 2} L${size} ${size} Z`} />
      </clipPath>
      <image data-testid="protractor" width={size * 2} height={size} href={ProtractorImage} clipPath="url(#set-square-protractor-clip-path)" />
    </g>
  );
}

Markings.propTypes = {
  padding: number.isRequired,
  size: number.isRequired,
  pixelsPerMM: number.isRequired,
};
