import React from 'react';
import { number, string, oneOfType, node, func, bool } from 'prop-types';
import classNames from 'classnames';
import DialogHeader from '../common/header';

const getStyle = (width, height, isInFront) => ({
  width: width + 25 + 25, // padding left + padding right
  height: height + 25 + 25 + 85, //  padding left + padding right + header height
  maxWidth: '100%',
  maxHeight: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: isInFront ? 10000 : 1, // override ngdialog zindex
  padding: 25,
});

const Modal = ({ width, height, icon, title, children, close, moveDialogToFront, isInFront, className }) => (
  <>
    <div className="ngdialog-overlay" />
    <div
      className={classNames('pbb-modal pbb-modal-no-drag', className)}
      style={getStyle(width, height, isInFront)}
      onClick={moveDialogToFront}
      role="presentation"
      data-testid="modal"
    >
      <div className="pbb-modal__dialog">
        <div className="pbb-modal__content">
          <DialogHeader close={close} icon={icon} title={title} />
          <div className="pbb-modal__body">
            <div className="pbb-modal__media">{children}</div>
          </div>
        </div>
      </div>
    </div>
  </>
);

Modal.propTypes = {
  width: oneOfType([string, number]),
  height: oneOfType([string, number]),
  children: node,
  title: string.isRequired,
  icon: string,
  close: func.isRequired,
  moveDialogToFront: func.isRequired,
  isInFront: bool,
  className: string,
};

Modal.defaultProps = {
  width: 560,
  height: 345,
  icon: undefined,
  isInFront: false,
  children: undefined,
  className: undefined,
};

export default Modal;
