import { TRANSPARENT } from '../constants/constants';

const colorNames = {
  GREEN: 'green',
  YELLOW: 'yellow',
  ORANGE: 'orange',
  RED: 'red',
  BLUE: 'blue',
  PURPLE: 'purple',
  BLACK: 'black',
  GREY: 'grey',
};

export const backgroundColors = {
  WHITE: 'white',
  TRANSPARENT: 'transparent',
};

export const colors = {
  green: 'rgb(103, 194, 55)',
  yellow: 'rgb(248, 231, 28)',
  orange: 'rgb(246, 176, 39)',
  red: 'rgb(214, 3, 30)',
  blue: 'rgb(77, 172, 198)',
  purple: 'rgb(197, 15, 228)',
  black: 'rgb(0, 0, 0)',
  grey: 'rgb(230, 230, 230)',
};

const opaqueColors = {
  green: 'rgba(103, 194, 55, 0.25)',
  yellow: 'rgba(248, 231, 28, 0.25)',
  orange: 'rgba(246, 176, 39, 0.25)',
  red: 'rgba(214, 3, 30, 0.25)',
  blue: 'rgba(77, 172, 198, 0.25)',
  purple: 'rgba(197, 15, 228, 0.25)',
  black: 'rgba(0, 0, 0, 0.25)',
  grey: 'rgba(230, 230, 230, 0.25)',
};

export const pencilColors = colors;

export const markerColors = opaqueColors;

export const textMarkerColors = { ...opaqueColors, transparent: TRANSPARENT };

export const annotationTextColors = colors;

export const annotationBackgroundColors = {
  white: 'rgb(255, 255, 255)',
  transparent: TRANSPARENT,
};

export default colorNames;
