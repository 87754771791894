import React from 'react';
import classNames from 'classnames';
import { shape, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PORTAAL_URL } from '../../../../../../../constants/constants';
import useAnalytics from '../../../../../../../hooks/useAnalytics';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../../enums/analytics';

const buttonStyle = {
  display: 'inline-block',
  borderRadius: '20px',
  backgroundColor: 'grey',
  paddingInline: '8px',
  lineHeight: '1.5rem',
  fontSize: '12px',
  color: 'white',
  alignSelf: 'center',
};

const infoStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

const BookSwitcherLine = ({ book }) => {
  const { t } = useTranslation();

  return (
    <div className="pbb-bookpicker__item-wrapper">
      <div className="pbb-bookpicker__image-wrapper">
        {book.cover && (
          <div
            alt="Cover"
            className="pbb-bookpicker__image"
            style={{
              background: `url('${book.cover}') top center / contain no-repeat`,
            }}
          />
        )}
      </div>
      <div className="pbb-bookpicker__info" style={infoStyles}>
        <div>
          <span className="p-submodule__subtitle">{book.bookType}</span>
          <span className="p-submodule__title">{book.niceName}</span>
        </div>
        {!book.isLicensed && <span style={buttonStyle}>{t('bookSwitcher.activate')}</span>}
      </div>
    </div>
  );
};

const BookSwitcherItem = ({ book, superModuleId, isCurrent }) => {
  const href = `/digibook/${book.digibookId}?superModuleId=${superModuleId}`;

  const analytics = useAnalytics();

  const handleClick = async e => {
    e.preventDefault();

    if (book.isLicensed) {
      await Promise.all([
        analytics.capture({
          eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
          objectId: superModuleId,
          subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.BOOK_SWITCHER,
        }),
        analytics.capture({
          eventType: ANALYTICS_EVENT_TYPES.DIGIBOOK_ACCESSED,
          objectId: book.digibookId,
          subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.BOOK_SWITCHER,
        }),
      ]);
      window.location.assign(href);
    } else {
      window.location.assign(PORTAAL_URL);
    }
  };

  return (
    <li
      data-testid="book-switcher-item"
      className={classNames('pbb-bookpicker__item', {
        'pbb-bookpicker__item--current': isCurrent,
        'pbb-bookpicker__item--inactive': !book.isLicensed,
      })}
    >
      {isCurrent ? (
        <BookSwitcherLine book={book} />
      ) : (
        <button type="button" onClick={e => handleClick(e)}>
          <BookSwitcherLine book={book} />
        </button>
      )}
    </li>
  );
};

BookSwitcherLine.propTypes = {
  book: shape({
    id: string,
    name: string,
    bookType: string,
    niceName: string,
    logo: string,
    isLicensed: bool,
  }).isRequired,
};

BookSwitcherItem.propTypes = {
  book: shape({
    id: string,
    name: string,
    bookType: string,
    niceName: string,
    logo: string,
    isLicensed: bool,
  }).isRequired,
  superModuleId: string.isRequired,
  isCurrent: bool.isRequired,
};

export default BookSwitcherItem;
