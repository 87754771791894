import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Jodit } from 'jodit';
import { useTranslation } from 'react-i18next';
import { css } from 'jodit/esm/core/helpers';
import { Dom } from 'jodit/esm/core/dom';
import JoditInternalEditor from './jodit-react';

import { updateHandlebars, updateFontSizeIcon } from './utils';

import { FONT_SIZES, LINE_HEIGHTS, SPECIAL_CHARACTERS } from './constants';

const joditOptions = [
  'fontsizedown',
  'fontsize',
  'fontsizeup',
  'fontcolor',
  'fontbackgroundcolor',
  '|',
  'bold',
  'italic',
  'strikethrough',
  'underline',
  '|',
  'lineHeight',
  'align',
  'ol',
  'ul',
  '|',
  'symbols',
  '|',
  'superscript',
  'subscript',
  'backgroundcolor',
  'delete',
];

function JoditEditor({ annotation, onChange, onResize, editorRef, scale, onDelete, lang }) {
  /**
   * Jodit remounts the editor causing clunky behaviour when the value changes,
   * so we use it in uncontrolled mode and update the annotations in our state accordingly
   */
  const [initialAnnotation] = useState(annotation);
  const mounted = useRef(false);
  const { t } = useTranslation();

  const placeholderWidth = lang === 'fr' ? 300 : 250;

  const onBackgroundColorChange = useCallback(
    color => {
      onChange({ backgroundColor: color });
    },
    [onChange],
  );

  const config = useMemo(
    () => ({
      readonly: false, // Allow editing
      placeholder: t('annotationTool.placeHolder'), // Custom placeholder which can be translated using react i18n
      buttons: [...joditOptions], // Button config
      toolbarAdaptive: false,
      minHeight: 0, // A minimum height.
      minWidth: placeholderWidth,
      width: initialAnnotation.resized ? initialAnnotation.width : 'max-content', // Allow stretching to the max of your content, until editor has been resized
      statusbar: false, // Hides bottom status bar
      toolbarSticky: false, // Makes sure toobar keeps it's original position when scrolling out of view
      defaultActionOnPaste: 'insert_only_text',
      defaultColor: 'black',
      toolbarButtonSize: 'large',
      controls: {
        align: {
          list: {
            left: 'Align Left',
            center: 'Align Center',
            right: 'Align Right',
            justify: 'Align Justify',
          },
        },
        fontsize: {
          list: Jodit.atom(FONT_SIZES),
          isDisabled: (_, button) => FONT_SIZES.indexOf(Number(button.state.value)) === -1,
        },
        lineHeight: {
          list: Jodit.atom(LINE_HEIGHTS),
        },
        ul: { list: undefined },
        ol: { list: undefined },
      },
      usePopupForSpecialCharacters: true,
      specialCharacters: SPECIAL_CHARACTERS,
      events: {
        afterInit(editor) {
          // eslint-disable-next-line no-param-reassign
          editorRef.current = editor;
          updateFontSizeIcon(editor);
        },
        change() {
          const { textContent } = editorRef.current.workplace;

          if (textContent === '' || textContent === t('annotationTool.placeHolder')) {
            css(editorRef.current.container, {
              minWidth: placeholderWidth,
            });

            return;
          }

          css(editorRef.current.container, {
            minWidth: 250,
          });
        },
      },
      link: {
        // for now we don't want to process any links automatically.
        processVideoLink: false,
        processPastedLink: false,
      },
      initialAnnotation,
      onDelete,
      onBackgroundColorChange,
      backgroundColor: initialAnnotation.backgroundColor,
      t,
      extraPlugins: ['deleteannotation', 'translations', 'fontcolor', 'fontbackgroundcolor', 'backgroundcolor'],
    }),
    [initialAnnotation, t, editorRef, onDelete, onBackgroundColorChange, placeholderWidth],
  );

  useEffect(() => {
    function handler(width, height) {
      updateHandlebars();
      onResize(width, height);
    }
    editorRef.current.e.on('resized', handler);

    return () => {
      editorRef.current.e.off('resized', handler);
    };
  }, [onResize, editorRef]);

  function onEditorChange(value) {
    onChange({ text: value, width: editorRef.current.workplace.clientWidth, height: editorRef.current.workplace.clientHeight });
    updateHandlebars();
  }

  useEffect(() => {
    if (editorRef.current.selection.isFocused()) return;

    if (editorRef.current.ew.getSelection().rangeCount) {
      const lastTextNode = Dom.last(editorRef.current.editor, node => Dom.isText(node));
      if (lastTextNode) {
        editorRef.current.selection.setCursorIn(lastTextNode, false);
      }
    }
    editorRef.current.selection.save(true);
    editorRef.current.focus();
    editorRef.current.selection.restore();
  });

  useEffect(() => {
    if (!annotation.resized) return;

    // Force height to be set properly.
    [editorRef.current.workplace, editorRef.current.iframe, editorRef.current.editor].map(elm => elm && css(elm, 'minHeight', annotation.height));
  }, [editorRef, annotation]);

  useEffect(() => {
    updateHandlebars();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    editorRef.current.renderedScale = scale;
  }, [editorRef, scale]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const toolbarBox = document.querySelector('.jodit-toolbar__box');

    if (toolbarBox) {
      const inversedScale = 1 / scale;

      toolbarBox.style.transform = `scale(${inversedScale})`;
      toolbarBox.style.top = `-${44 + 20 * inversedScale}px`;
    }
  }, [scale]);

  return (
    <div className="editor-container">
      <JoditInternalEditor id={annotation.id} config={config} value={initialAnnotation.text} onChange={onEditorChange} />
      <div className="handlebar" data-testid="handlebar-left" data-position="left" />
      <div className="handlebar" data-testid="handlebar-right" data-position="right" />
      <div className="handlebar" data-testid="handlebar-bottom" data-position="bottom" />
      <div className="handlebar" data-testid="handlebar-top" data-position="top" />
    </div>
  );
}

export default forwardRef((props, ref) => <JoditEditor {...props} editorRef={ref} />);

JoditEditor.propTypes = {
  annotation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  editorRef: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  lang: PropTypes.oneOf(['nl', 'fr']).isRequired,
};
