/* eslint-disable import/prefer-default-export */

/**
 * @deprecated Can be resolved by using new selectors (getVisiblePages, getCurrentSpread)
 */
export const calculateSpreadForPageNumbers = (arrPages, totalPages) => {
  if (arrPages.length === 2) {
    return arrPages;
  }
  if (arrPages.length === 1) {
    const isCover = arrPages[0] === 0;
    const isFirstPage = arrPages[0] === 1;
    const isLastPage = arrPages[0] === totalPages;
    const isBackCover = arrPages[0] === totalPages + 1;

    const hasEvenAmountOfPages = totalPages % 2 === 0; // when book has even page count, last page is single page

    if (isCover || isFirstPage || isBackCover || (isLastPage && hasEvenAmountOfPages)) {
      return [arrPages[0]];
    }
    return arrPages[0] % 2 === 0 ? [arrPages[0], arrPages[0] + 1] : [arrPages[0] - 1, arrPages[0]];
  }
  throw new Error('wrong number of arguments');
};
