import { useEffect, useState } from "react";
import api from "../../../../services/api";

export default function useVisibleAnswerSets(digibook, visiblePages) {
  const [answerSetsForVisiblePages, setAnswerSetsForVisiblePages] = useState([]);

  useEffect(() => {
    async function fetchAnswerSets() {
      if (!visiblePages) return;

      const pageNumbersToFetch = visiblePages.filter(x => x!== null).join('-');
      const { data: { data } } = await api.get(`/studio/digibooks/${digibook.id}/${pageNumbersToFetch}/answer-sets`);

      setAnswerSetsForVisiblePages(data);
    }

    fetchAnswerSets();
  }, [digibook, visiblePages])

  return answerSetsForVisiblePages;
}
