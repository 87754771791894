import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIsPopUpManualVisible } from '../../../../selectors/player';
import { getCurrentDigibook } from '../../../../selectors/digibooks';
import { getPopUpManual } from '../../../../actions/popUpManual';
import { getPopUpManualUrl } from '../../../../selectors/popUpManual';
import ManualDialog from './ManualDialog';

export default function PopUpManual() {
  const isPopUpManualVisible = useSelector(getIsPopUpManualVisible);
  const digibook = useSelector(getCurrentDigibook);
  const popUpManualUrl = useSelector(getPopUpManualUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPopUpManualVisible || !digibook.manual || popUpManualUrl) return;

    dispatch(getPopUpManual(digibook.manual));
  }, [digibook, dispatch, isPopUpManualVisible, popUpManualUrl]);

  if (!isPopUpManualVisible || !popUpManualUrl) return null;

  return <ManualDialog src={popUpManualUrl} />;
}
