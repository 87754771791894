const MAX_DEPTH = 2;

const isNodeInRange = range => node => {
  if (node.fromPage >= range.from && node.fromPage <= range.to) return true;
  if (node.toPage >= range.from && node.toPage <= range.to) return true;
  if (range.from >= node.fromPage && range.to <= node.toPage) return true;
  return false;
};

const toId = x => x.id;

const inRangeForDepth = (topLevelNode, childNodes, range, depth, acc = []) => {
  const inRange = isNodeInRange(range);

  if (depth < 1) return inRange(topLevelNode) ? [topLevelNode, ...acc].map(toId) : acc.map(toId);

  const childNodesAtDepth = childNodes.filter(x => x.hierarchy.length === depth);
  const childNodesInRange = [...childNodesAtDepth.filter(inRange), ...acc];

  const maxChildTo = Math.max(...childNodesInRange.map(x => x.toPage));

  if (topLevelNode.toPage > maxChildTo && range.to > maxChildTo) return inRangeForDepth(topLevelNode, childNodes, range, depth - 1, childNodesInRange);

  return childNodesInRange.map(toId);
};

const getChildNodes = (tocNode, tocNodes, acc = []) => {
  if (tocNode.nodes) {
    const nextAcc = [...acc, ...tocNode.nodes];
    const childNodes = tocNode.nodes.map(tocNodeId => tocNodes[tocNodeId]);

    return childNodes.flatMap(childNode => getChildNodes(childNode, tocNodes, nextAcc));
  }
  return acc;
};

export const getChildNodesForTopLevel = (tocNode, tocNodes) => [...new Set(getChildNodes(tocNode, tocNodes))];

const getTocNodesForRange = (range, tocNodes) => {
  const inRange = isNodeInRange(range);
  const topLevelNodes = Object.values(tocNodes)
    .filter(n => n.hierarchy.length === 0)
    .filter(inRange);

  return topLevelNodes.flatMap(node => {
    if (!node.nodes) return inRange(node) ? [node.id] : [];

    const childNodes = getChildNodesForTopLevel(node, tocNodes).map(nodeId => tocNodes[nodeId]);

    return inRangeForDepth(node, childNodes, range, MAX_DEPTH);
  });
};

const getTocNodesForRanges = (ranges, tocNodes) => [...new Set(ranges.flatMap(range => getTocNodesForRange(range, tocNodes)))];

export { getTocNodesForRange, getTocNodesForRanges };
