import React from 'react';
import { number } from 'prop-types';
import { MATH_TOOL_STROKE_WIDTH } from '../../constants';

export default function Backdrop({ size }) {
  const path = `M0 0 L${size * 2} 0 L${size} ${size} Z`;

  return (
    <g id="set-square-backdrop" data-testid="backdrop">
      <path d={path} strokeLinejoin="round" stroke="currentColor" strokeWidth={MATH_TOOL_STROKE_WIDTH} fill="rgba(217,238,243, .75)" />
    </g>
  );
}

Backdrop.propTypes = {
  size: number.isRequired,
};
