import update from 'immutability-helper';
import { ANNOTATION_SETS_FETCH_SUCCESS, SET_ACTIVE_ANNOTATION_SET, SET_CREATE_ANNOTATION_SET_LOADING } from '../actions/actionNames';

export const initialState = {
  annotationSets: [],
  activeAnnotationSet: undefined,
  createAnnotationSetLoading: false,
};

export default function annotationSetsReducer(state = initialState, action) {
  switch (action?.type) {
    case ANNOTATION_SETS_FETCH_SUCCESS: {
      const { annotationSets } = action.payload;

      return update(state, {
        annotationSets: { $set: annotationSets },
        activeAnnotationSet: { $set: annotationSets.find(set => set.isActive) },
      });
    }
    case SET_ACTIVE_ANNOTATION_SET: {
      const { annotationSet } = action.payload;

      return update(state, {
        activeAnnotationSet: { $set: { ...annotationSet, isActive: true } },
      });
    }
    case SET_CREATE_ANNOTATION_SET_LOADING: {
      return update(state, {
        createAnnotationSetLoading: { $set: action.payload },
      });
    }
    default: {
      return state;
    }
  }
}
