import { MaintenancePage } from '@pelckmans/business-components/components/maintenance-page';
import { string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as constants from '../constants/constants';
import useQueryParams from '../hooks/useQueryParams';
import api from '../services/api';
import { toggleLoadingScreen } from '../utils/loadingScreen';

function Maintenance({ title, message }) {
  const [maintenanceMessage, setMaintenanceMessage] = useState('');

  const queryParams = useQueryParams();

  useEffect(() => {
    toggleLoadingScreen();
  }, []);

  useEffect(() => {
    const fetchMaintenanceMessage = async () => {
      try {
        const mm = await api.get(`/studio-groups/${constants.STUDIO_GROUP}/maintenance-message`);
        setMaintenanceMessage(mm.data);
      } catch {
        setMaintenanceMessage(null);
      }
    };
    fetchMaintenanceMessage();
  }, []);

  return (
    <MaintenancePage
      translations={
        maintenanceMessage?.isActive
          ? {
              title: maintenanceMessage.title,
              message: maintenanceMessage.message,
            }
          : {
              title,
              message,
            }
      }
      redirectUrl={queryParams.get('redirectUrl')}
      studioGroup={constants.STUDIO_GROUP}
    />
  );
}

Maintenance.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
};

export default Maintenance;
