import get from 'get-value';
import { getActiveNodesForMediaLinks } from './toc';

export const getUserMedia = (state, userMediaId) => get(state, ['userMedia', 'byId', userMediaId]);

const getUserMediaForCurrentPage = state => {
  const visibleTOCNodes = getActiveNodesForMediaLinks(state);

  const userMedia = visibleTOCNodes.reduce((acc, node) => {
    const chapterNodeId = node.id;

    const userMediaForNode = get(state, ['userMedia', 'byTocNodeId', chapterNodeId]);

    if (userMediaForNode) {
      const expandedMedia = userMediaForNode.map(mediaId => getUserMedia(state, mediaId));

      const mediaForNode = expandedMedia.filter(ml => node.id === ml.node);
      acc.push(...mediaForNode);
    }

    return acc;
  }, []);

  return userMedia;
};

const getFavoritesByModuleState = state => get(state, ['userMedia', 'favorites', 'byModuleId']);

export const getUserMediaForCurrentPagesGroupedByNode = state => {
  const favoritesByModule = getFavoritesByModuleState(state);

  const result = getUserMediaForCurrentPage(state).reduce((acc, media) => {
    const nodeId = media.node;
    const moduleId = media.module;

    const favoritesForModule = get(favoritesByModule, [moduleId]);

    const isFavorite = favoritesForModule ? favoritesForModule.includes(media.id) : false;

    if (acc[nodeId]) {
      acc[nodeId].push({ ...media, isFavorite });
    } else {
      acc[nodeId] = [
        {
          ...media,
          isFavorite,
        },
      ];
    }

    return acc;
  }, {});

  return result;
};
