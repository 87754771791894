import React, { useEffect, useRef } from 'react';
import { number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import DrawingPointer from './DrawingPointer';
import RoundedImageButton from '../../components/RoundedImageButton';

import resizeIcon from '../../../../../../../../assets/images/math-tool-resize.svg';
import rotateIcon from '../../../../../../../../assets/images/math-tool-rotate.svg';
import getSizes from '../sizes';
import { MATH_TOOL_STROKE_WIDTH } from '../../constants';

const BUTTON_PADDING = 5;
const TEXT_MASK_PADDING = 5;

export default function RadiusLine({ pixelsPerMM, radius, onResize, onRotate, degrees, degreesDisplay, onDraw }) {
  const { t } = useTranslation();
  const textRef = useRef();
  const textMaskRef = useRef();

  const { CENTER, CROSSHAIR_OUTER_CIRCLE_RADIUS, BUTTON_RADIUS } = getSizes(pixelsPerMM);

  const buttonSize = BUTTON_RADIUS * 2;

  const displayRadius = (radius / pixelsPerMM / 10).toFixed(1).replace('.', ',');

  useEffect(() => {
    textMaskRef.current.setAttribute('width', Math.round(textRef.current.getBBox().width) + TEXT_MASK_PADDING * 2);

    // Safari can not handle transforms on SVG text elements. Therefor we have to calculate half the width ourselves.
    textRef.current.parentElement.style.transform = `translateX(-${textRef.current.getBBox().width / 2}px)`;
    // because in FireFox svg elements in a mask do not have any values for their width and height we calculate translateX using the text width and padding
    textMaskRef.current.style.transform = `translateX(-${textRef.current.getBBox().width / 2 + TEXT_MASK_PADDING}px)`;
  });

  return (
    <g id="radius-line-container" data-testid="radius-line-container" style={{ transformOrigin: 'left center', transform: `rotate(${degrees}deg)`, transformBox: 'fill-box' }}>
      <mask id="radius-line-mask" maskUnits="userSpaceOnUse">
        <rect x={CENTER + radius - 10} y={CENTER - BUTTON_RADIUS - 15} width={20} height={BUTTON_RADIUS * 2 + 30} fill="white" />
        <rect x={CENTER} y={CENTER - 5} width={radius} height={10} fill="white" />
        <rect
          id="text-mask-item"
          data-testid="text-mask-item"
          x={CENTER + CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.3 + radius / 2}
          y={CENTER - 5}
          height={10}
          fill="black"
          ref={textMaskRef}
        />
        <circle cx={CENTER + radius} cy={CENTER} r={BUTTON_RADIUS} fill="black" />
        <rect
          x={CENTER + radius - BUTTON_PADDING - BUTTON_RADIUS}
          y={CENTER - BUTTON_RADIUS - BUTTON_PADDING}
          width={BUTTON_PADDING}
          height={buttonSize + 2 * BUTTON_PADDING}
          rx={BUTTON_RADIUS + BUTTON_PADDING}
          ry={BUTTON_RADIUS + BUTTON_PADDING}
          fill="black"
        />
        <rect x={CENTER} y={CENTER - 10} height={20} width={CROSSHAIR_OUTER_CIRCLE_RADIUS} fill="black" />
      </mask>

      <g id="radius-line" data-drag>
        <rect x={CENTER + CROSSHAIR_OUTER_CIRCLE_RADIUS} y={CENTER - 5} width={radius - CROSSHAIR_OUTER_CIRCLE_RADIUS} height={10} stroke="rgba(0,0,0,0)" fill="rgba(0,0,0,0)" />
        <line className="stroke--grey" x1={CENTER} x2={CENTER + radius} y1={CENTER} y2={CENTER} strokeWidth={MATH_TOOL_STROKE_WIDTH} mask="url(#radius-line-mask)" />
        <g>
          <text data-testid="radius-and-degrees" ref={textRef} x={CENTER + CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.3 + radius / 2} y={CENTER} dy="0.3em" fontSize={BUTTON_RADIUS * 1.1}>
            r=<tspan data-testid="radius">{displayRadius}</tspan>
            {radius / pixelsPerMM / 10 >= 3 && (
              <>
                &nbsp;&nbsp;θ=<tspan data-testid="angle">{degreesDisplay}°</tspan>
              </>
            )}
          </text>
        </g>
      </g>

      <line
        className="stroke"
        x1={CENTER + radius}
        y1={CENTER - BUTTON_RADIUS - BUTTON_PADDING}
        x2={CENTER + radius}
        y2={CENTER + BUTTON_RADIUS + BUTTON_PADDING}
        mask="url(#radius-line-mask)"
        strokeWidth={MATH_TOOL_STROKE_WIDTH}
      />

      <g id="actions">
        <rect
          className="stroke"
          x={CENTER + radius - BUTTON_PADDING - BUTTON_RADIUS}
          y={CENTER - BUTTON_RADIUS - BUTTON_PADDING}
          width={3 * buttonSize + 4 * BUTTON_PADDING}
          height={buttonSize + 2 * BUTTON_PADDING}
          rx={BUTTON_RADIUS + BUTTON_PADDING}
          ry={BUTTON_RADIUS + BUTTON_PADDING}
          fill="none"
          strokeWidth={MATH_TOOL_STROKE_WIDTH}
        />
        <DrawingPointer cx={CENTER + radius} cy={CENTER} radius={BUTTON_RADIUS} onDraw={onDraw} />
        <RoundedImageButton
          data-testid="drafting-compass-rotate"
          src={rotateIcon}
          size={buttonSize}
          x={CENTER + radius + BUTTON_RADIUS + BUTTON_PADDING}
          y={CENTER - BUTTON_RADIUS}
          onMouseDown={onRotate}
          onTouchStart={onRotate}
          title={t('mathTool.actions.rotate')}
        />
        <RoundedImageButton
          className="resize-button"
          data-testid="drafting-compass-resize"
          src={resizeIcon}
          size={buttonSize}
          x={CENTER + radius + BUTTON_RADIUS + BUTTON_PADDING + buttonSize + BUTTON_PADDING}
          y={CENTER - BUTTON_RADIUS}
          onMouseDown={onResize}
          onTouchStart={onResize}
          title={t('mathTool.actions.resize')}
        />
      </g>
    </g>
  );
}

RadiusLine.propTypes = {
  pixelsPerMM: number.isRequired,
  radius: number.isRequired,
  onResize: func.isRequired,
  onRotate: func.isRequired,
  onDraw: func.isRequired,
  degrees: number.isRequired,
  degreesDisplay: number.isRequired,
};
