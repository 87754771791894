import React, { useMemo } from 'react';
import { number, func } from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { getCurrentTool, getTools } from '../../../../../../../selectors/tools';
import Tools from '../../../../../../../enums/tools';
import { pencilColors, markerColors } from '../../../../../../../enums/colors';
import { MATH_TOOL_STROKE_WIDTH } from '../../constants';

const COLORS = {
  [Tools.PENCIL]: pencilColors,
  [Tools.MARKER]: markerColors,
};

export default function DrawingPointer({ cx, cy, radius, onDraw }) {
  const { t } = useTranslation();
  const currentTool = useSelector(getCurrentTool);
  const tools = useSelector(getTools);
  const currentColor = tools[currentTool]?.color;

  const fill = useMemo(() => {
    if (currentTool === Tools.PENCIL) return COLORS[currentTool][currentColor];
    if (currentTool === Tools.MARKER) return COLORS[currentTool][currentColor].replace(/(\d?(\.)?\d*)\)$/, '1)'); // removes opacity from marker color

    return 'none';
  }, [currentColor, currentTool]);

  return (
    <g id="drawing-pointer" data-testid="drawing-pointer" onMouseDown={onDraw} onTouchStart={onDraw}>
      <circle className="stroke" cx={cx} cy={cy} r={radius / 3} strokeWidth={MATH_TOOL_STROKE_WIDTH} fill={fill} />
      <circle className="stroke" cx={cx} cy={cy} r={radius} strokeWidth={MATH_TOOL_STROKE_WIDTH} fill="none">
        <title>{t('mathTool.actions.draw')}</title>
      </circle>
    </g>
  );
}

DrawingPointer.propTypes = {
  cx: number.isRequired,
  cy: number.isRequired,
  radius: number.isRequired,
  onDraw: func.isRequired,
};
