import React from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@pelckmans/business-components/components/alert';
import { buildModuleInPUrl } from '../../../../../utils/buildModuleInPUrl';
import { getModuleById } from '../../../../../selectors/module';
import { getTOCNodeById } from '../../../../../selectors/toc';
import { getModuleForDigibook } from '../../../../../selectors/digibooks';

const BulkSharingMessage = ({ nodeId, superModuleId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const featureToggles = useSelector(state => state.featureToggles);
  const node = useSelector(state => getTOCNodeById(state)(nodeId));
  const superModule = useSelector(state => getModuleById(state, superModuleId));
  const module = useSelector(getModuleForDigibook);

  const getOpenInPMessage = () => {
    if (featureToggles.BULK_SHARING) {
      if (superModule) {
        return t('sharing.bulkSharingMessageWithLink', {
          portaalUrl: buildModuleInPUrl(language, superModule, module.slug, node),
        });
      }
      return t('sharing.bulkSharingMessage');
    }
    return null;
  };

  const openInPMessage = getOpenInPMessage();

  return openInPMessage ? (
    <Alert severity="info" className="p-bulk-sharing-info">
      <>
        <i role="img" className="bc-icon bc-icon-info" />
        <div className="p-bulk-sharing-info-message" title={openInPMessage} dangerouslySetInnerHTML={{ __html: openInPMessage }} />
      </>
    </Alert>
  ) : null;
};

BulkSharingMessage.propTypes = {
  nodeId: string.isRequired,
  superModuleId: string,
};

BulkSharingMessage.defaultProps = {
  superModuleId: undefined,
};

export { BulkSharingMessage };
