/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import { fabric } from 'fabric';
import FabricTypes from '../../../enums/fabrictype';

import CustomPencilBrush from './fabric-custom-pencil-brush';

const EraserBrush = fabric.util.createClass(CustomPencilBrush, {
  color: 'white',
  strokeLineCap: 'round',

  initialize: function(canvas, paintableAreaDimensions, freeDrawingStrategy) {
    this.callSuper('initialize', canvas, paintableAreaDimensions, freeDrawingStrategy);
    this.createPatternFromBookPages();
  },

  createPatternFromBookPages: function() {
    const patternCanvas = fabric.util.createCanvasElement();

    const patternCtx = patternCanvas.getContext('2d');
    const bookPages = this.canvas.getObjects().filter(x => x.meta === FabricTypes.BOOKPDF);

    // set canvas dimensions based on bookPages
    patternCanvas.width = bookPages.reduce((acc, page) => acc + page.width, 0); // sum of widths

    patternCanvas.height = bookPages[0].height;

    // set background of the canvas to white since received images are transparent.
    patternCtx.fillStyle = 'white';
    patternCtx.fillRect(0, 0, patternCanvas.width, patternCanvas.height);

    // render bookPages
    bookPages.forEach(page => {
      // save pushes the current state of the context (translations/scale onto a stack).
      patternCtx.save();

      /**
       * We translate the canvas before drawing to apply the same offset which is set on fabric Images when they are copied to the main canvas.
       * This is done in the override for the drawCacheOnCanvas method on fabric.Image in the fabric-service.
       */
      patternCtx.translate(page.renderOffsetX, page.renderOffsetY);
      patternCtx.drawImage(page._originalElement, page.left, page.top);

      // restore restores the state of the context to the last saved state.
      patternCtx.restore();
    });

    // create a pattern that can be used as strokeStyle property on a canvas.
    this.pattern = this.canvas.contextTop.createPattern(patternCanvas, 'no-repeat');

    // clean up canvas
    patternCtx.clearRect(0, 0, patternCanvas.width, patternCanvas.height);
    patternCanvas.width = 0;
    patternCanvas.height = 0;
  },

  getPattern: function() {
    // called by fabric.PatternBrush
    return this.pattern;
  },

  /**
   * Sets brush styles
   */
  _setBrushStyles: function(context) {
    this.callSuper('_setBrushStyles', context);
    this.canvas.contextTop.strokeStyle = this.getPattern();
  },

  createPath: function(pathData) {
    // when rendered as a path, just draw a black stroke.
    return new fabric.Path(pathData, {
      fill: null,
      originX: 'center',
      originY: 'center',
      selectable: false,
      stroke: 'yellow',
      strokeDashArray: this.strokeDashArray,
      strokeLineCap: this.strokeLineCap,
      strokeLineJoin: this.strokeLineJoin,
      strokeWidth: this.width,
      globalCompositeOperation: 'destination-out',
    });
  },
});

export default EraserBrush;
