import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import PopUpManualOnNL from '@pelckmans/business-components/icons/Manual';
import PopUpManualOnFR from '@pelckmans/business-components/icons/ManualGP';

const getSource = language => (language === 'fr' ? <PopUpManualOnFR /> : <PopUpManualOnNL />);

export const PopUpManualButton = ({ isEnabled, onClick }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const icon = getSource(language);
  const tooltip = isEnabled ? 'dock.buttons.manualOff.tooltip' : 'dock.buttons.manualOn.tooltip';

  return (
    <button type="button" onClick={onClick} title={t(tooltip)} className={isEnabled ? 'pbb-active' : undefined}>
      {icon}
    </button>
  );
};

PopUpManualButton.propTypes = {
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};
