import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { bool, shape, string } from 'prop-types';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import { openWhitepage, toggleWhitepageFavorite } from '../../../../../../../actions/whitepages';
import FavoriteButton from '../../../../buttons/FavoriteButton';
import DrawerOptionsMenu from '../../DrawerOptionsMenu';
import { openWhitepageOptionsModal } from '../../../../../../../actions/dialog';

export default function LicensedWhitePageListItem({ whitepage, isActive, nodeId }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const launchWhitepage = whitepageId => dispatch(openWhitepage(whitepageId));
  const openWhitepageOptions = (tocNodeId, noteId, activeTab) => dispatch(openWhitepageOptionsModal(tocNodeId, noteId, activeTab));
  const toggleFavorite = wp => dispatch(toggleWhitepageFavorite(wp));

  return (
    <li className="pbb-list__item">
      <div className="pbb-list__link-wrap">
        <button
          type="button"
          className={classNames('pbb-list__link', {
            'pbb-list__link--active': isActive,
          })}
          onClick={() => launchWhitepage(whitepage.id)}
          title={whitepage.title}
        >
          <MaterialIcon materialType="whitepage" paperType={whitepage.paperType} className="pbb-list__link-icon" />
          <div className="pbb-list__link-text" data-testid="whitepage-title-text">
            {whitepage.title}
          </div>
        </button>
        <div className="pbb-list__actions">
          <FavoriteButton onClick={() => toggleFavorite(whitepage)} isActive={whitepage.isFavorite} className="pbb-list__action" />
          <DrawerOptionsMenu>
            <MenuItem onClick={() => openWhitepageOptions(nodeId, whitepage.id, 'info')}>
              <InfoIcon />
              <span>{t('tabs.info.title')}</span>
            </MenuItem>
            <MenuItem onClick={() => openWhitepageOptions(nodeId, whitepage.id, 'delete')}>
              <BinIcon />
              <span>{t('tabs.delete')}</span>
            </MenuItem>
          </DrawerOptionsMenu>
        </div>
      </div>
    </li>
  );
}

LicensedWhitePageListItem.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    title: string.isRequired,
    paperType: string.isRequired,
    isFavorite: bool,
  }).isRequired,
  isActive: bool.isRequired,
  nodeId: string.isRequired,
};
