import jwt from 'jwt-decode';

import Storage from '../services/storage';
import StorageKey from '../services/storage-keys';

import { AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from '../actions/actionNames';

function initialState() {
  const accessToken = Storage.get(StorageKey.ACCESS_TOKEN);
  const refreshToken = Storage.get(StorageKey.REFRESH_TOKEN);

  const state = {
    isAuthenticated: !!accessToken,
  };

  if (accessToken) {
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
    state.user = jwt(accessToken);
  }

  return state;
}

export default function authReducer(state = initialState(), action) {
  switch (action && action.type) {
    case AUTHENTICATE_SUCCESS:
    case REFRESH_TOKEN_SUCCESS: {
      const { accessToken, refreshToken } = action.payload;

      Storage.set(StorageKey.ACCESS_TOKEN, accessToken);
      Storage.set(StorageKey.REFRESH_TOKEN, refreshToken);

      const user = jwt(accessToken);

      return {
        isAuthenticated: true,
        user,
        accessToken,
        refreshToken,
      };
    }
    case AUTHENTICATE_FAILURE: {
      const { err } = action.payload;

      return {
        isAuthenticated: false,
        err,
      };
    }
    case REFRESH_TOKEN_FAILURE: {
      Storage.remove(StorageKey.ACCESS_TOKEN);
      Storage.remove(StorageKey.REFRESH_TOKEN);

      return {
        isAuthenticated: false,
      };
    }
    default:
      return state;
  }
}
