import { useCallback, useEffect, useState } from 'react';

export default function useMathToolPositioning({ bookDimensions, currentMathTool, ref, viewPortTransform, pagesRendered }) {
  const [resetPosition, setResetPosition] = useState(true);
  const [calculateCenter, setCalculateCenter] = useState(undefined);

  useEffect(() => {
    if (!currentMathTool) return undefined;
    if (!ref.current) return undefined;

    const currentRef = ref.current;

    let isMoving;

    let offsetLeft = 0;
    let offsetTop = 0;

    const handleMove = evt => {
      if (!isMoving) return;

      evt.preventDefault();

      // calculate the new cursor position:
      const clientX = evt.changedTouches ? evt.changedTouches[0].clientX : evt.clientX;
      const clientY = evt.changedTouches ? evt.changedTouches[0].clientY : evt.clientY;

      const deltaOffsetLeft = clientX - offsetLeft;
      const deltaOffsetTop = clientY - offsetTop;

      offsetLeft = clientX;
      offsetTop = clientY;

      currentRef.style.top = `${currentRef.offsetTop + deltaOffsetTop}px`;
      currentRef.style.left = `${currentRef.offsetLeft + deltaOffsetLeft}px`;

      currentRef.dataset.top = currentRef.offsetTop + deltaOffsetTop;
      currentRef.dataset.left = currentRef.offsetLeft + deltaOffsetLeft;
    };

    const handleStop = evt => {
      handleMove(evt);

      const mathTool = document.getElementById(currentMathTool);
      if (mathTool) mathTool.classList.remove('dragging');

      isMoving = false;

      document.body.removeEventListener('mousemove', handleMove);
      document.body.removeEventListener('mouseup', handleStop);

      document.body.removeEventListener('touchmove', handleMove);
      document.body.removeEventListener('touchend', handleStop);
    };

    const handleStart = evt => {
      evt.preventDefault();

      offsetLeft = evt.touches ? evt.touches[0].clientX : evt.clientX;
      offsetTop = evt.touches ? evt.touches[0].clientY : evt.clientY;

      isMoving = true;

      const mathTool = document.getElementById(currentMathTool);

      if (mathTool) mathTool.classList.add('dragging');

      document.body.addEventListener('mousemove', handleMove);
      document.body.addEventListener('mouseup', handleStop);

      document.body.addEventListener('touchmove', handleMove);
      document.body.addEventListener('touchend', handleStop);
    };

    const moveElems = document.querySelectorAll('[data-drag]');

    moveElems.forEach(moveElem => moveElem.addEventListener('mousedown', handleStart));

    moveElems.forEach(moveElem => moveElem.addEventListener('touchstart', handleStart));

    return () => {
      moveElems.forEach(moveElem => moveElem.removeEventListener('mousedown', handleStart));

      moveElems.forEach(moveElem => moveElem.removeEventListener('touchstart', handleStart));
    };
  }, [currentMathTool, ref]);

  const centerTool = useCallback(() => {
    if (!calculateCenter) return;

    const currentRef = ref.current;

    const { offsetLeft, offsetTop } = calculateCenter(currentRef, viewPortTransform);

    currentRef.style.top = `${offsetTop}px`;
    currentRef.style.left = `${offsetLeft}px`;

    currentRef.dataset.top = offsetTop;
    currentRef.dataset.left = offsetLeft;
    setResetPosition(false);
  }, [calculateCenter, ref, viewPortTransform]);

  useEffect(() => {
    if (!currentMathTool) return;

    setResetPosition(true);
  }, [currentMathTool]);

  useEffect(() => {
    if (!currentMathTool || !centerTool) return;

    if (resetPosition) {
      centerTool();
    }
  }, [centerTool, currentMathTool, resetPosition]);

  const [prevViewPortTransform, setPrevViewPortTransform] = useState(viewPortTransform);

  useEffect(() => {
    setPrevViewPortTransform(viewPortTransform);
  }, [viewPortTransform]);

  useEffect(() => {
    if (!currentMathTool) return;
    if (viewPortTransform === prevViewPortTransform) return;

    const currentRef = ref.current;

    /**
     * The offsetTop and offsetLeft properties are rounded which causes the math tool to jump.
     * The style properties keep their part after the comma.
     */
    const previousToolTop = Number(currentRef.style.top.split('px')[0]);
    const previousToolLeft = Number(currentRef.style.left.split('px')[0]);

    const offsetTopOnBook = previousToolTop - prevViewPortTransform[5];
    const percentageTopOnBook = offsetTopOnBook / (bookDimensions.height * prevViewPortTransform[0]);
    const newOffsetTop = bookDimensions.height * viewPortTransform[0] * percentageTopOnBook + viewPortTransform[5];

    const offsetLeftOnBook = previousToolLeft - prevViewPortTransform[4];
    const percentageLeftOnBook = offsetLeftOnBook / (bookDimensions.width * prevViewPortTransform[0]);
    const newOffsetLeft = bookDimensions.width * viewPortTransform[0] * percentageLeftOnBook + viewPortTransform[4];

    currentRef.style.top = `${newOffsetTop}px`;
    currentRef.style.left = `${newOffsetLeft}px`;

    currentRef.dataset.top = newOffsetTop;
    currentRef.dataset.left = newOffsetLeft;
  }, [bookDimensions, currentMathTool, prevViewPortTransform, ref, viewPortTransform]);

  const [prevPagesRendered, setPrevPagesRendered] = useState(pagesRendered);

  useEffect(() => {
    if (pagesRendered !== prevPagesRendered) {
      setResetPosition(true);
    }

    setPrevPagesRendered(pagesRendered);
  }, [pagesRendered, prevPagesRendered, centerTool]);

  return fn => setCalculateCenter(() => fn);
}
