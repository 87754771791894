export const ROOT_CLASS = 'pbb-preloader';
export const FADE_CLASS = `${ROOT_CLASS}__fade`;

export function toggleLoadingScreen(toggleOn) {
  const LOADING_ELEMENT = document.querySelector(`.${ROOT_CLASS}`);

  if (!LOADING_ELEMENT) return;

  if (toggleOn) {
    LOADING_ELEMENT.classList.remove(FADE_CLASS);
    LOADING_ELEMENT.style.display = 'flex';
  } else {
    LOADING_ELEMENT.classList.add(FADE_CLASS);
    setTimeout(() => {
      LOADING_ELEMENT.style.display = 'none';
    }, 500); // fade out animation takes half a second
  }
}
