import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'get-value';
import LinkAreaLinkTypes from '../../../../enums/linkarealinktype';
import api from '../../../../services/api';
import { getVisibleChapterNodeIds } from '../../../../selectors/toc';
import { onStoreError } from '../../../../store';
import { retrieveMedialinksByIdsSuccess } from '../../../../actions/medialinks';

export default function useVisibleLinkAreas(digibook, visiblePages) {
  const [linkAreasForVisiblePages, setLinkAreasForVisiblePages] = useState([]);

  useEffect(() => {
    async function fetchLinkAreas() {
      if (!visiblePages) return;

      const pageNumbersToFetch = visiblePages.filter(x => x !== null).join('-');
      const {
        data: { data },
      } = await api.get(`/studio/digibooks/${digibook.id}/${pageNumbersToFetch}/linkareas`);

      setLinkAreasForVisiblePages(data);
    }

    fetchLinkAreas();
  }, [digibook, visiblePages]);

  const mediaLinksById = useSelector(state => get(state, ['medialinks', 'byId']));

  const unknownMediaLinkIds = useMemo(
    () =>
      linkAreasForVisiblePages.reduce((set, { linkType, mediaLinks = [] }) => {
        if (linkType === LinkAreaLinkTypes.MEDIALINKS) {
          mediaLinks.forEach(x => {
            /**
             * 💡The MEDIALINKS_FETCH_BY_IDS_SUCCESS reduces not returned mediaLinks,
             * for instance for mediaLinks targeting teachers when you are a student, as null so you could
             * use it to see if you've already tried to fetch it (cache).
             */
            if (mediaLinksById[x] === undefined) set.add(x);
          });
        }

        return set;
      }, new Set()),
    [linkAreasForVisiblePages, mediaLinksById],
  );

  const nodesFetched = useSelector(state => get(state, ['medialinks', 'nodesFetched']));

  /**
   * 🧨 We have to wait until the main medialink api call is finished,
   * otherwise we'll execute a search for all medialinks even if they are in one of the current chapters.
   */
  const areAllVisibleChapterNodesFetched = useSelector(state => {
    const visibleChapterNodeIds = getVisibleChapterNodeIds(state);
    return visibleChapterNodeIds.every(nodeId => nodesFetched.includes(nodeId));
  });

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchMedialinksById() {
      const { module: moduleId } = digibook;

      const medialinkIds = [...unknownMediaLinkIds];

      const params = new URLSearchParams();
      params.append('ids', medialinkIds);
      params.append('includeScore', true);

      try {
        const {
          data: { data },
        } = await api.get(`/studio/modules/${moduleId}/mediaLinks?${params.toString()}`);

        dispatch(retrieveMedialinksByIdsSuccess({ medialinkIds, medialinks: data }));
      } catch (e) {
        dispatch(onStoreError(e));
      }
    }

    if (areAllVisibleChapterNodesFetched && unknownMediaLinkIds.size > 0) fetchMedialinksById();
  }, [digibook, unknownMediaLinkIds, areAllVisibleChapterNodesFetched, dispatch]);

  const visibleLinkAreas = useMemo(() => {
    if (unknownMediaLinkIds.size > 0) return undefined;

    const filtered = linkAreasForVisiblePages.filter(({ linkType, mediaLinks = [] }) => {
      if (linkType !== LinkAreaLinkTypes.MEDIALINKS) return true;

      return mediaLinks.filter(id => mediaLinksById[id]).length > 0;
    });

    return filtered;
  }, [unknownMediaLinkIds, linkAreasForVisiblePages, mediaLinksById]);

  return visibleLinkAreas;
}
