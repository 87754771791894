/**
 * Try to extract status info
 * @export
 * @param {*} err
 * @returns {{status: Number, statusText: String, [message]: String}}
 */
export default function tryExtractStatusInfo(err) {
  if (!err.response) return undefined;

  const { status, statusText, data } = err.response;

  return {
    status,
    statusText,
    message: data && data.message,
  };
}
