import React, { useState, useContext } from 'react';
import { string } from 'prop-types';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@pelckmans/business-components/components/button';
import DialogHeader from '../dialogs/common/header';
import PDFViewer from '../../../../components/pdf-viewer';
import { getCurrentDigibook } from '../../../../selectors/digibooks';
import { moveMediaDialogToFront } from '../../../../actions/dialog';
import { getOpenDialogs } from '../../../../selectors/dialogs';
import PopUpManualIconNL from '../../../../../assets/images/pop-up-manual-off-nl.svg';
import PopUpManualIconFR from '../../../../../assets/images/pop-up-manual-off-fr.svg';
import { getActiveNodeToHighlight } from '../../../../selectors/toc';
import { UserSettingsContext } from '../../context/user-settings-context';
import AnchorPosition from '../../../../enums/anchorposition';

const getResizeConfig = () => ({
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: true,
  bottomRight: true,
  bottomLeft: false,
  topLeft: false,
});

export default function ManualDialog({ src }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { sidebarAnchor } = useContext(UserSettingsContext);

  const INITIAL_DIALOG_WIDTH = window.innerWidth / 3;
  const INITIAL_DIALOG_HEIGHT = window.innerHeight - 80;
  const INITIAL_DIALOG_X = sidebarAnchor === AnchorPosition.LEFT ? 56 : window.innerWidth - INITIAL_DIALOG_WIDTH - 66;
  const INITIAL_DIALOG_Y = 20;

  const [{ x, y }, setPosition] = useState({ x: INITIAL_DIALOG_X, y: INITIAL_DIALOG_Y });
  const [{ width, height }, setSize] = useState({ width: INITIAL_DIALOG_WIDTH, height: INITIAL_DIALOG_HEIGHT });
  const dispatch = useDispatch();
  const digibook = useSelector(getCurrentDigibook);
  const dialog = useSelector(getOpenDialogs).find(d => d.id === 'pop-up-manual');
  const activeNode = useSelector(getActiveNodeToHighlight);

  function findManualFrom(highlightedNodeId, pageRanges) {
    const range = pageRanges.find(r => highlightedNodeId === r.nodeId);
    return range?.manualFrom;
  }

  const pdfPageToShow = findManualFrom(activeNode?.id, digibook.pageRanges);
  const moveDialogToFront = () => dispatch(moveMediaDialogToFront('pop-up-manual'));

  const setManualPage = () => {
    if (!activeNode) return;

    const manualPageToShow = findManualFrom(activeNode.id, digibook.pageRanges);

    const iframe = document.querySelector('#viewer iframe');
    if (!iframe) return;
    if (!iframe.contentWindow.PDFViewerApplication) return;

    iframe.contentWindow.PDFViewerApplication.page = manualPageToShow;
  };

  return (
    <Rnd
      className="pbb-modal pbb-modal--media manual-dialog"
      position={{ x, y }}
      minWidth={300}
      key="pop-up-manual"
      minHeight={120}
      onDragStop={(_e, d) => setPosition({ x: d.x, y: d.y })}
      bounds="parent"
      enableResizing={getResizeConfig()}
      dragHandleClassName="draggable"
      size={{ width, height }}
      onResizeStart={() => document.querySelector('body').classList.add('react-draggable-transparent-selection')}
      onResizeStop={(_e, _d, ref) => {
        document.querySelector('body').classList.remove('react-draggable-transparent-selection');
        setSize({ width: ref.offsetWidth, height: ref.offsetHeight });
      }}
      resizeHandleClasses={{ bottomRight: 'resizeHandle' }}
      style={{ zIndex: dialog.isInFront ? 99 : 1 }}
      onDragStart={moveDialogToFront}
      onClick={moveDialogToFront}
      role="dialog"
    >
      <div className="pbb-modal__dialog">
        <div className="pbb-modal__content">
          <DialogHeader
            draggable
            icon={<img src={language === 'FR' ? PopUpManualIconFR : PopUpManualIconNL} className="icon" alt="icon manual" />}
            title={t('popUpManual.title')}
            close={() => dispatch({ type: 'TOGGLE_POP_UP_MANUAL' })}
            action={
              activeNode && (
                <Button className="manual-dialog__action" variant="primary" size="small" onClick={setManualPage} disabled={!findManualFrom(activeNode.id, digibook.pageRanges)}>
                  <span title={t('popUpManual.goToNode', { chapterTitle: activeNode.title })}>{t('popUpManual.goToNode', { chapterTitle: activeNode.title })}</span>
                </Button>
              )
            }
          />
          <div className="pbb-modal__body">
            <PDFViewer src={src} page={pdfPageToShow} />
          </div>
          <footer className="pbb-modal__footer draggable">
            <span className="pbb-modal__drag" title={t('mediaDialog.footer.buttons.move.tooltip')}>
              <i className="icon-bb-modal-drag" />
            </span>
            <span className="pbb-modal__resize" data-testid="modal-resize">
              <i className="icon-bb-modal-resize" />
            </span>
          </footer>
        </div>
      </div>
    </Rnd>
  );
}

ManualDialog.propTypes = {
  src: string.isRequired,
};
