import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router';
import { getCurrentPageNumber } from '../../selectors/navigation';

/**
 * Ensures your current page is saved in browser history.
 * This allows users navigating with the back button to their last page from external sites.
 */
export default function useUrlPageUpdate() {
  const match = useRouteMatch();
  const currentPage = useSelector(getCurrentPageNumber);

  useEffect(() => {
    if (currentPage === undefined) return;
    if (match.params.page !== currentPage) {
      window.history.replaceState(
        {},
        '',
        generatePath(match.path, {
          ...match.params,
          page: currentPage,
        }) + window.location.search,
      );
    }
  }, [currentPage, match]);

  return null;
}
