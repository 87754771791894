import React from 'react';
import classNames from 'classnames';
import { func, string, node, bool, arrayOf } from 'prop-types';
import DialogHeader from '../common/header';

const FullScreenMediaDialog = ({ close, title, breadcrumbs, moveDialogToFront, children, icon, action, footer, withPadding, className, ...rest }) => (
  <>
    <div className="ngdialog-overlay" />
    <div
      data-testid="fullscreen-dialog"
      className={classNames('pbb-modal pbb-modal--lg', { 'pbb-modal--no-footer': !footer }, className)}
      onClick={moveDialogToFront}
      role="presentation"
      {...rest}
    >
      <div className="pbb-modal__dialog pbb-modal--dialog-centered">
        <div className="pbb-modal__content">
          <DialogHeader close={close} title={title} icon={icon} action={action} breadcrumbs={breadcrumbs} />
          <div className="pbb-modal__body">
            <div
              className={classNames('pbb-modal__body-wrapper', {
                'pbb-modal__body-wrapper--no-padding': !withPadding,
              })}
            >
              {children}
            </div>
          </div>
          {footer && <div className="pbb-modal__footer">{footer}</div>}
        </div>
      </div>
    </div>
  </>
);

FullScreenMediaDialog.propTypes = {
  title: string.isRequired,
  close: func,
  moveDialogToFront: func,
  icon: string,
  children: node,
  action: node,
  footer: node,
  withPadding: bool,
  breadcrumbs: arrayOf(node),
  className: string,
};

FullScreenMediaDialog.defaultProps = {
  children: undefined,
  icon: undefined,
  close: undefined,
  action: undefined,
  moveDialogToFront: () => {},
  footer: undefined,
  withPadding: false,
  breadcrumbs: undefined,
  className: undefined,
};

export default FullScreenMediaDialog;
