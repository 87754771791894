import React, { useState, useCallback } from 'react';
import { node, func, bool, shape, arrayOf, oneOf, oneOfType, number, string } from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const CollapsibleContent = ({ children }) => (
  <div className="pbb-collapsible__content" data-testid="collapsible-content">
    {children}
  </div>
);

CollapsibleContent.propTypes = {
  children: oneOfType([() => null, node]).isRequired,
};

const CollapsibleTitle = ({ children, toggleCollapse, title }) => {
  return toggleCollapse ? (
    <button type="button" className="pbb-collapsible__title-wrap" data-testid="collapsible-title" onClick={toggleCollapse} title={title}>
      {children}
      <span className="pbb-collapsible__accordeon">
        <i className="icon-bb-arrow-down" />
      </span>
    </button>
  ) : (
    <div className="pbb-collapsible__title-wrap" data-testid="collapsible-title">
      {children}
    </div>
  );
};

CollapsibleTitle.propTypes = {
  children: node.isRequired,
  toggleCollapse: func,
  title: string,
};

CollapsibleTitle.defaultProps = {
  toggleCollapse: undefined,
  title: '',
};

const CollapsibleItem = ({ children, disableCollapse }) => {
  const [t] = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);
  return (
    <li
      data-testid="collapsible-item"
      className={classNames('pbb-collapsible__item', {
        'pbb-collapsible__item--active': disableCollapse ? true : !isCollapsed,
      })}
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          toggleCollapse: disableCollapse ? null : toggleCollapse,
          title: isCollapsed ? t('collapsible.expand') : t('collapsible.collapse'),
        }),
      )}
    </li>
  );
};

CollapsibleItem.propTypes = {
  children: oneOfType([
    shape({
      type: oneOf([CollapsibleTitle, CollapsibleContent]),
    }),
    arrayOf(
      shape({
        type: oneOf([CollapsibleTitle, CollapsibleContent]),
      }),
    ),
  ]).isRequired,
  disableCollapse: bool,
};

CollapsibleItem.defaultProps = {
  disableCollapse: false,
};

const Collapsible = ({ depth, className, forceEnable, children }) => {
  const nodes = React.Children.toArray(children).length;

  return (
    <ul className={classNames('pbb-collapsible', { [`pbb-collapsible--level-${depth}`]: depth }, className)} data-testid="collapsible">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          disableCollapse: !forceEnable && nodes <= 1,
        }),
      )}
    </ul>
  );
};

Collapsible.propTypes = {
  children: oneOfType([
    shape({
      type: oneOf([CollapsibleItem]),
    }),
    arrayOf(
      shape({
        type: oneOf([CollapsibleItem]),
      }),
    ),
    node,
    // node is added because <MaterialList /> wraps Collapsible.Item
    // remember to pass through disableCollapse from MaterialList to Collapsible.Item
  ]).isRequired,
  depth: number,
  className: string,
  forceEnable: bool,
};

Collapsible.defaultProps = {
  depth: undefined,
  className: undefined,
  forceEnable: false,
};

Collapsible.Item = CollapsibleItem;
Collapsible.Title = CollapsibleTitle;
Collapsible.Content = CollapsibleContent;

export { CollapsibleItem, CollapsibleTitle, CollapsibleContent };
export default Collapsible;
