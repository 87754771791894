/* eslint-disable consistent-return */
/**
 * This component is a copy of the jodit-react component from the jodit-react package.
 * We copied the component in order to be able to use the ESM exports of Jodit which includes the config module we need to make custom plugins.
 */
import React, { useEffect, useRef, forwardRef, useLayoutEffect } from 'react';
import { func, number, object, string } from 'prop-types';
import { Jodit } from 'jodit';

import './include.jodit';

const { isFunction } = Jodit.modules.Helpers;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const JoditEditor = forwardRef(({ className, config, id, name, onBlur, onChange, tabIndex, value, editorRef }, ref) => {
  const textArea = useRef(null);

  useLayoutEffect(() => {
    if (ref) {
      if (isFunction(ref)) {
        ref(textArea.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = textArea.current;
      }
    }
  }, [textArea, ref]);

  useEffect(() => {
    const element = textArea.current;
    const jodit = Jodit.make(element, config);

    textArea.current = jodit;

    if (isFunction(editorRef)) {
      editorRef(jodit);
    }

    return () => {
      if (jodit) {
        jodit.destruct();
      }

      textArea.current = element;
    };
  }, [config, editorRef]);

  const preClassName = usePrevious(className);

  useEffect(() => {
    const classList = textArea.current?.container?.classList;

    if (preClassName !== className && typeof preClassName === 'string') {
      preClassName.split(/\s+/).forEach(cl => classList?.remove(cl));
    }

    if (className && typeof className === 'string') {
      className.split(/\s+/).forEach(cl => classList?.add(cl));
    }
  }, [className, preClassName]);

  useEffect(() => {
    if (textArea.current.workplace) {
      textArea.current.workplace.tabIndex = tabIndex || -1;
    }
  }, [tabIndex]);

  useEffect(() => {
    if (!textArea.current.events || (!onBlur && !onChange)) {
      return;
    }

    const onBlurHandler = e => onBlur && onBlur(textArea.current.value, e);
    const onChangeHandler = v => onChange && onChange(v);

    // adding event handlers
    textArea.current.events.on('blur', onBlurHandler).on('change', onChangeHandler);

    return () => {
      // Remove event handlers
      textArea.current?.events?.off('blur', onBlurHandler).off('change', onChangeHandler);
    };
  }, [onBlur, onChange]);

  useEffect(() => {
    const updateValue = () => {
      if (textArea.current && textArea?.current?.value !== value) {
        textArea.current.value = value;
      }
    };

    if (textArea.current) {
      if (textArea.current.isReady) {
        updateValue();
      } else {
        textArea.current.waitForReady().then(updateValue);
      }
    }
  }, [value]);

  return (
    <div className="jodit-react-container">
      <textarea defaultValue={value} name={name} id={id} ref={textArea} />
    </div>
  );
});

JoditEditor.displayName = 'JoditEditor';

JoditEditor.propTypes = {
  className: string,
  config: object,
  id: string,
  name: string,
  onBlur: func,
  onChange: func,
  editorRef: func,
  tabIndex: number,
  value: string,
};

JoditEditor.defaultProps = {
  className: undefined,
  config: undefined,
  id: undefined,
  name: undefined,
  onBlur: undefined,
  onChange: undefined,
  editorRef: undefined,
  tabIndex: undefined,
  value: undefined,
};

export default JoditEditor;
