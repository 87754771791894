import update from 'immutability-helper';
import shortid from 'shortid';

import ViewMode from '../enums/viewmode';
import ZoomLevel from '../enums/zoomLevel';
import ShapeActions from '../enums/shapeActions';
import { normalizeFloat } from '../utils/normalizeFloat';

import {
  SET_VIEWMODE,
  SET_CURRENTPAGE,
  REVEAL_PARTIAL_ANSWER,
  HIDE_PARTIAL_ANSWER,
  ZOOM_IN,
  ZOOM_OUT,
  ZOOM_TO_FIT,
  DISABLE_ZOOM_TO_FIT_MODE,
  REVEAL_STEPPER_ANSWER,
  HIDE_STEPPER_ANSWER,
  TOC_NODE_CLICKED,
  SET_ZOOM_LEVEL,
  SHOW_ALL_ANSWERS,
  HIDE_ALL_ANSWERS,
  TOGGLE_MANUAL,
} from '../actions/actionNames';

import { splitShapeByPages } from '../utils/shape-splitter';
import { calculateSpreadForPageNumbers } from '../utils/calculateSpreadForPageNumbers';

const initialState = {
  viewMode: ViewMode.SPREAD,
  answerLayerShownFor: {
    shapes: [],
  },
  zoomLevel: ZoomLevel.BASE_ZOOM_LEVEL,
  zoomToFitMode: true,
};

export default function navigationReducer(state = initialState, action) {
  switch (action && action.type) {
    case SET_VIEWMODE: {
      return update(state, {
        viewMode: { $set: action.payload.viewMode },
        zoomLevel: { $set: ZoomLevel.BASE_ZOOM_LEVEL },
        zoomToFitMode: { $set: true },
      });
    }
    case ZOOM_IN: {
      const nextZoomLevel = state.zoomLevel + ZoomLevel.ZOOM_DELTA;
      const correctedZoomLevel = nextZoomLevel > ZoomLevel.MAX_ZOOM_LEVEL ? 3 : nextZoomLevel;
      return update(state, {
        zoomLevel: {
          $set: normalizeFloat(correctedZoomLevel),
        },
      });
    }

    case ZOOM_OUT: {
      const nextZoomLevel = state.zoomLevel - ZoomLevel.ZOOM_DELTA;
      const correctedZoomLevel = nextZoomLevel < ZoomLevel.MIN_ZOOM_LEVEL ? 0.1 : nextZoomLevel;
      return update(state, {
        zoomLevel: {
          $set: normalizeFloat(correctedZoomLevel),
        },
      });
    }

    case ZOOM_TO_FIT: {
      return update(state, {
        zoomLevel: { $set: ZoomLevel.BASE_ZOOM_LEVEL },
        zoomToFitMode: { $set: true },
      });
    }

    case SET_ZOOM_LEVEL: {
      const { zoomLevel } = action.payload;
      return update(state, {
        zoomLevel: { $set: zoomLevel },
        zoomToFitMode: { $set: false },
      });
    }

    case DISABLE_ZOOM_TO_FIT_MODE: {
      return update(state, {
        zoomToFitMode: { $set: false },
      });
    }

    case SET_CURRENTPAGE: {
      return update(state, {
        clickedTocNode: { $set: undefined },
        currentPage: { $set: action.payload.pageNumber },
        zoomLevel: { $set: ZoomLevel.BASE_ZOOM_LEVEL },
        zoomToFitMode: { $set: true },
      });
    }
    case TOC_NODE_CLICKED: {
      return update(state, {
        clickedTocNode: { $set: action.payload.node },
      });
    }
    case SHOW_ALL_ANSWERS: {
      const { pageNumbers, totalPages } = action.payload;
      const spread = calculateSpreadForPageNumbers(pageNumbers, totalPages);

      const revealShapes = pageNumbers.map(page => ({
        id: shortid.generate(),
        page,
        top: 0,
        left: spread.findIndex(pageNum => pageNum === page) === 0 ? 0 : 0.5,
        width: 0.5,
        height: 1,
        action: ShapeActions.REVEAL,
      }));

      return update(state, {
        answerLayerShownFor: {
          shapes: {
            $push: revealShapes,
          },
        },
      });
    }

    case HIDE_ALL_ANSWERS: {
      const {
        answerLayerShownFor: { shapes },
      } = state;
      const { pageNumbers } = action.payload;

      return update(state, {
        answerLayerShownFor: {
          shapes: {
            $set: shapes.filter(x => !pageNumbers.includes(x.page)),
          },
        },
      });
    }
    case REVEAL_PARTIAL_ANSWER:
    case HIDE_PARTIAL_ANSWER: {
      const { shape, totalPages, pages } = action.payload;
      const shapes = splitShapeByPages(shape, calculateSpreadForPageNumbers(pages, totalPages));

      const shapesToAdd = shapes.map(rect => ({
        ...rect,
        id: shortid.generate(),
        action: action.type === REVEAL_PARTIAL_ANSWER ? ShapeActions.REVEAL : ShapeActions.HIDE,
      }));

      return update(state, {
        answerLayerShownFor: {
          shapes: {
            $push: shapesToAdd,
          },
        },
      });
    }
    case REVEAL_STEPPER_ANSWER: {
      const { set, index } = action.payload;
      const answer = set.answers[index];

      const shapes = answer.members ? answer.members.flatMap(a => splitShapeByPages(a, set.pages)) : splitShapeByPages(answer, set.pages);

      const shapesToAdd = shapes.map(shape => ({
        ...shape,
        id: `${shape.page}-${set.id}-${index}`,
        action: ShapeActions.REVEAL,
      }));

      return update(state, {
        answerLayerShownFor: {
          shapes: allShapes => {
            const filtered = allShapes.filter(x => !shapesToAdd.some(y => y.id === x.id));
            return update(filtered, {
              $push: shapesToAdd,
            });
          },
        },
      });
    }
    case HIDE_STEPPER_ANSWER: {
      const {
        answerLayerShownFor: { shapes },
      } = state;
      const { set, index } = action.payload;
      const answer = set.answers[index];

      const splitAnswerShapes = answer.members ? answer.members.flatMap(a => splitShapeByPages(a, set.pages)) : splitShapeByPages(answer, set.pages);

      const ids = splitAnswerShapes.map(shape => `${shape.page}-${set.id}-${index}`);

      return update(state, {
        answerLayerShownFor: {
          shapes: {
            $set: shapes.filter(shape => !ids.includes(shape.id)),
          },
        },
      });
    }

    case TOGGLE_MANUAL: {
      return update(state, {
        zoomLevel: { $set: ZoomLevel.BASE_ZOOM_LEVEL },
        zoomToFitMode: { $set: true },
      });
    }

    default: {
      return state;
    }
  }
}
