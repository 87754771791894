import React, { useEffect, useRef } from 'react';
import { number, string } from 'prop-types';
import PdfjsLinkTarget from '../enums/pdfjs-link-target';

const PDFViewer = ({ src, target, page }) => {
  const viewerRef = useRef();

  useEffect(() => {
    const iframe = document.createElement('iframe');
    const externalLinkTarget = target ? `&externallinktarget=${PdfjsLinkTarget[target]}` : '';
    const p = page ? `#page=${page}` : '';
    iframe.src = `/assets/pdfjs/web/viewer.html?file=${encodeURIComponent(src)}${externalLinkTarget}${p}`;
    iframe.width = '100%';
    iframe.height = '100%';

    viewerRef.current.appendChild(iframe);
  }, [page, src, target]);

  return (
    <div
      ref={viewerRef}
      id="viewer"
      data-testid="pdf-viewer"
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
};

PDFViewer.propTypes = {
  src: string.isRequired,
  target: string,
  page: number,
};

PDFViewer.defaultProps = {
  target: undefined,
  page: undefined,
};

export default PDFViewer;
