import React, { useState } from 'react';
import { shape, string, number } from 'prop-types';
import EllipsisVerticalIcon from '@pelckmans/business-components/icons/EllipsisVertical';

import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import SlideSetInfo from './SlideSetInfo';
import NotLicensedModal from '../../../dialogs/NotLicensedModal';

const UnlicenseSlideSetListItem = ({ slideSet }) => {
  const { name } = slideSet;

  const [notLicensedModalVisible, setNotLicensedModalVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      {notLicensedModalVisible &&
        createPortal(
          <NotLicensedModal title={t('notLicensed.accessContent.title')} message={t('notLicensed.accessContent.message')} onClose={() => setNotLicensedModalVisible(false)} />,
          document.body,
        )}
      <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
        <div className="pbb-list__link-wrap pbb-list__link-wrap--disabled">
          <button type="button" className="pbb-list__link pbb-flex-wrap" title={name} onClick={() => setNotLicensedModalVisible(true)}>
            <SlideSetInfo slideSet={slideSet} />
            <div className="pbb-list__actions">
              <div className="pbb-list__action">
                <EllipsisVerticalIcon />
              </div>
            </div>
          </button>
        </div>
      </li>
    </>
  );
};

UnlicenseSlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};

export default UnlicenseSlideSetListItem;
