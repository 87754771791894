import React, { useState, useEffect } from 'react';
import { bool, shape, string } from 'prop-types';
import ReactJWPlayer from 'react-jw-player';
import PDFViewer from '../../../../../components/pdf-viewer';
import api from '../../../../../services/api';
import ImageViewer from '../../../../../components/image-viewer';

// eslint-disable-next-line react/prop-types
const File = ({ medialinkId, file, type, imagePreview, onFullScreenClick, dialogSize, close, isFullScreen, modalPosition, jwplayer }) => {
  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    const getSignedUrl = async () => {
      const { data } = await api.get(`/medialinks/${medialinkId}/signedurl?extendedTimeValidity=true${file.s3file.preview ? '&preview=true' : ''}`);
      setSignedUrl(data.url);
    };
    getSignedUrl();
  }, [medialinkId, file.s3file]);

  function onReady() {
    const allVideoTags = [...document.querySelectorAll('video')]; // jwplayer renders a video tag for audio as well
    const player = window.jwplayer(medialinkId);
    // video/audio may only be autoplay if no other video tag is playing.
    // html5 video tag has no playing property. It has a paused property :)
    // so all paused props should be true to make this new player autostart
    const autoPlay = allVideoTags.every(x => x.paused);
    if (!player.isMuted && autoPlay) {
      player.play();
    }
  }
  switch (type) {
    case 'image':
      return signedUrl && <img src={signedUrl} alt="" className="img--fit" />;
    case 'video':
      return (
        signedUrl && (
          <ReactJWPlayer
            className="jw-video"
            playerId={medialinkId}
            playerScript="https://content.jwplatform.com/libraries/zyraJCSg.js"
            playlist={jwplayer?.ready ? signedUrl : [{ file: signedUrl }]}
            isAutoPlay={false}
            onReady={onReady}
            aspectRatio="inherit"
          />
        )
      );
    case 'audio':
      return (
        signedUrl && (
          <ReactJWPlayer
            playerId={medialinkId}
            playerScript="https://content.jwplatform.com/libraries/zyraJCSg.js"
            file={signedUrl}
            isAutoPlay={false}
            onReady={onReady}
            customProps={{ logo: undefined, width: 500, height: 40 }}
          />
        )
      );
    default:
      if (imagePreview) {
        return (
          signedUrl && (
            <ImageViewer
              close={close}
              dialogSize={dialogSize}
              onFullScreenClick={onFullScreenClick}
              id={medialinkId}
              src={signedUrl}
              isFullScreen={isFullScreen}
              modalPosition={modalPosition}
            />
          )
        );
      }

      return signedUrl && <PDFViewer src={signedUrl} target={file.pdfLinkTarget} />;
  }
};

File.propTypes = {
  medialinkId: string.isRequired,
  file: shape({
    s3file: shape({
      id: string,
    }),
    pdfLinkTarget: string,
  }).isRequired,
  type: string.isRequired,
  jwplayer: shape({
    ready: bool.isRequired,
  }),
};

File.defaultProps = {
  jwplayer: undefined,
};

export default File;
