import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { Button } from '@pelckmans/business-components/components/button';
import { func, number, string, bool } from 'prop-types';

import { calculateDialogWidth } from '../../../../../utils/h5p';

import './website-modal.scss';

const DIALOG_HEADER_HEIGHT = 85;

const H5P = url => url.includes('h5p.org') || url.includes('h5p.com');

const geogebra = url => url.includes('geogebra.org');

const WebsiteModal = ({ onClose, width, height, name, reload, url, iconClassName, className }) => {
  const { t } = useTranslation();
  const iframeRef = useRef();

  const reloadIframe = () => {
    if (iframeRef.current) {
      iframeRef.current.src = url;
    }
  };

  const dimensions = {
    width: H5P(url) ? calculateDialogWidth(width, height) : width,
    height: height + DIALOG_HEADER_HEIGHT,
  };

  return (
    <ModalDialog
      id="website-modal"
      onClose={onClose}
      closeTitle={t('actions.close')}
      closeViaEscape
      closeOnClickOutside
      zIndex={9999}
      fullScreen={!width && !height}
      width={dimensions.width ? `${dimensions.width}px` : undefined}
      height={H5P(url) || dimensions.height === DIALOG_HEADER_HEIGHT ? undefined : `${dimensions.height}px`}
      className={className}
    >
      <Dialog.Header data-testid="dialog-header" title={name} startAdornment={<i role="img" className={iconClassName} />}>
        {reload ? (
          <Button type="button" onClick={() => reloadIframe(url)} variant="primary" className="website-reload-btn">
            {t('actions.reload')}
          </Button>
        ) : null}
      </Dialog.Header>
      <Dialog.Body data-testid="dialog-body">
        {geogebra(url) ? (
          <iframe
            src={url}
            title={name}
            ref={iframeRef}
            className="iframe--no-border iframe--geogebra"
            // iOS 12 bug:
            //  when width of iframe is larger then window make sure it does not show a scrollbar which resizes the modal to the correct width
            //  width, height and scrolling needs to be present
            width={width ? `${width}px` : '100%'}
            height={height ? `${height}px` : '100%'}
            scrolling="no"
          />
        ) : (
          <iframe src={url} title={name} ref={iframeRef} className="iframe--no-border" allowFullScreen={!geogebra(url)} />
        )}
      </Dialog.Body>
    </ModalDialog>
  );
};

WebsiteModal.propTypes = {
  name: string.isRequired,
  url: string.isRequired,
  onClose: func.isRequired,
  iconClassName: string.isRequired,
  width: number,
  height: number,
  className: string,
  reload: bool,
};

WebsiteModal.defaultProps = {
  width: undefined,
  height: undefined,
  className: undefined,
  reload: false,
};

export default WebsiteModal;
