import React, { useMemo } from 'react';
import { bool, oneOf, arrayOf, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import orderBy from 'lodash/orderBy';

import materialSortings from '../../../../../../../../enums/materialSorting';

import Collapsible from '../../../../../../../../components/collapsible';
import TocNodeTitle from '../../../tocnode-title';
import MedialinkItem from './MedialinkItem';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../../../../enums/analytics';

function MedialinkList({ medialinks, sortedOn, allowCollapsing, ownMediaHidden }) {
  const [t] = useTranslation();

  const sortedUniqueCategories = useMemo(() => {
    const orderedMedialinkCategories = orderBy(medialinks, medialink => medialink.contentType?.category).map(medialink => medialink.normalizedCategory);

    return Array.from(new Set(orderedMedialinkCategories));
  }, [medialinks]);

  if (sortedOn === materialSortings.LESSON) {
    return (
      <Collapsible depth={2}>
        <Collapsible.Item>
          <Collapsible.Title>
            <span className="pbb-collapsible__title-text">
              <span className="pbb-collapsible__title">
                <TocNodeTitle tocNode={{ id: 'lesvolgorde', title: t('drawer.material.courseProgress') }} />
              </span>
            </span>
          </Collapsible.Title>
          <Collapsible.Content>
            <ul className="pbb-list pbb-list--borders">
              {medialinks.map(medialink => (
                <MedialinkItem key={medialink.id} medialink={medialink} subLocationId={ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL} />
              ))}
            </ul>
          </Collapsible.Content>
        </Collapsible.Item>
      </Collapsible>
    );
  }

  return (
    <Collapsible depth={2} forceEnable={allowCollapsing && !ownMediaHidden}>
      {sortedUniqueCategories.map(category => (
        <Collapsible.Item key={category} className="pbb-list__item">
          <Collapsible.Title>
            <span className="pbb-collapsible__title-text">
              <span className="pbb-collapsible__title">
                <TocNodeTitle tocNode={{ title: category }} />
              </span>
            </span>
          </Collapsible.Title>
          <Collapsible.Content>
            <ul className="pbb-list pbb-list--borders" data-testid={category}>
              {medialinks
                .filter(medialink => medialink.normalizedCategory === category)
                .map(medialink => (
                  <MedialinkItem key={medialink.id} medialink={medialink} subLocationId={ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL} />
                ))}
            </ul>
          </Collapsible.Content>
        </Collapsible.Item>
      ))}
    </Collapsible>
  );
}

MedialinkList.propTypes = {
  medialinks: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      contentType: shape({
        category: string,
      }),
      normalizedCategory: string.isRequired,
    }),
  ).isRequired,
  sortedOn: oneOf(Object.values(materialSortings)).isRequired,
  allowCollapsing: bool.isRequired,
  ownMediaHidden: bool.isRequired,
};

export default MedialinkList;
