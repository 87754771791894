import React from 'react';
import { shape, bool, string, arrayOf, object, func } from 'prop-types';
import { connect } from 'react-redux';
import ReactJWPlayer from 'react-jw-player';
import { withTranslation } from 'react-i18next';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { LtiExerciseModal } from '@pelckmans/business-components/components/lti-exercise-modal';

import MediaDialog from './media';
import RndDialog from './mediaDialogs/rnd';
import { getMedialinkById } from '../../../../selectors/medialinks';
import { getModuleById } from '../../../../selectors/module';
import { getUserMedia } from '../../../../selectors/userMedia';

import { getFileType } from '../../../../utils/filetype';
import AnchorPositions from '../../../../enums/anchorposition';
import MedialinkTypes from '../../../../enums/medialinktype';
import ExternalMediaTypes from '../../../../enums/externalmediatype';
import { closeDialog, moveMediaDialogToFront } from '../../../../actions/dialog';
import PDFViewer from '../../../../components/pdf-viewer';
import UserMaterialInfo from '../sidebar/drawer/material/components/user-material/UserMaterialInfo';
import { getTeacherFeaturesEnabledFor } from '../../../../selectors/digibooks';
import { tokenGetter } from '../../../../services/api';
import { getRoles } from '../../../../selectors/user';
import Role from '../../../../enums/roles';
import { getOpenMaterialAssignment } from '../../../../selectors/dialogs';
import getPlatformName from '../../../../utils/platform-name';
import { LTI_API_URI, STUDIO_GROUP, LANGUAGE } from '../../../../constants/constants';
import { fetchUserMaterial } from '../../../../actions/userMedia';

class MediaDialogs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.SIDEBAR_WIDTH = 44;
    this.PADDING = 90;

    const { dispatch, t } = props;

    this.tokenAccessor = { tokenGetter: tokenGetter(dispatch) };
    this.ltiExerciseModalTranslations = {
      confirmationModals: {
        title: t('businessComponents.ltiExerciseModal.confirmationModals.title'),
        body: {
          scorePrefix: t('businessComponents.ltiExerciseModal.confirmationModals.body.scorePrefix'),
          currentResult: t('businessComponents.ltiExerciseModal.confirmationModals.body.currentResult'),
          notRepeatable: t('businessComponents.ltiExerciseModal.confirmationModals.body.notRepeatable'),
          repeatable: t('businessComponents.ltiExerciseModal.confirmationModals.body.repeatable'),
          progression: {
            pending: t('businessComponents.ltiExerciseModal.confirmationModals.body.progression.pending'),
          },
        },
        actions: {
          cancel: t('businessComponents.ltiExerciseModal.confirmationModals.actions.cancel'),
          restart: t('businessComponents.ltiExerciseModal.confirmationModals.actions.restart'),
          close: t('businessComponents.ltiExerciseModal.confirmationModals.actions.close'),
        },
      },
      exerciseModal: {
        close: t('businessComponents.ltiExerciseModal.exerciseModal.close'),
      },
    };
  }

  componentDidMount() {
    this.initializeDialogCoordinates();
  }

  componentDidUpdate(prevProps) {
    const { medialinksForDialogs, userMediaForDialogs } = this.props;

    const openedMedialink = medialinksForDialogs.find(medialink => !prevProps.medialinksForDialogs.includes(medialink));
    const openedUserMedia = userMediaForDialogs.find(userMedia => !prevProps.userMediaForDialogs.includes(userMedia));

    if (openedMedialink) this.positionNewDialogForMedialink(openedMedialink);
    if (openedUserMedia) this.positionNewDialogForUserMedia(openedUserMedia);
  }

  getHorizontalOffset = dialogWidth => {
    const { activeDrawer, sidebarAnchor } = this.props;
    if (!!activeDrawer === true) {
      if (sidebarAnchor === AnchorPositions.LEFT) {
        return 405;
      }
      return 30;
    }
    return (window.innerWidth - dialogWidth) / 2;
  };

  setDimensions = (id, width, height) => {
    this.setState(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        width,
        height,
      },
    }));
  };

  setPosition = (id, x, y) => {
    this.setState(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        x,
        y,
      },
    }));
  };

  static getMediaTypeForUserMedia(userMedia) {
    return userMedia.href && userMedia.videoId ? 'video' : userMedia.file && getFileType(userMedia.file.mimeType);
  }

  getFirstAvailableSpaceToPosition(width, height) {
    const startX = this.isOnSmallScreen() // For small screens we dont take the sidebar into account.
      ? 50
      : 400;

    const intersectWith = (r1, r2) => {
      if (r2.x < r1.x + r1.width && r1.x < r2.x + r2.width && r2.y < r1.y + r1.height) {
        return r1.y < r2.y + r2.height;
      }
      return false;
    };

    const isInScreen = rect => rect.x + rect.width < window.innerWidth && rect.y + rect.height < window.innerHeight;

    const existingObjects = Object.values(this.state);

    // Check if there is an available space for the dialog without overlap.
    for (let y = 10; y < window.innerHeight; y += 25) {
      for (let x = startX; x < window.innerWidth; x += 25) {
        const r1 = {
          x,
          y,
          width,
          height,
        };

        const hasOverlap = existingObjects.some(rect => intersectWith(r1, rect));

        if (!hasOverlap && isInScreen(r1)) {
          return {
            width,
            height,
            x,
            y,
          };
        }
      }
    }

    const rect = {
      height,
      width,
      x: existingObjects[existingObjects.length - 1].x + 25,
      y: existingObjects[existingObjects.length - 1].y + 25,
    };
    // Try to overlap the last added if still fits on screen.
    if (isInScreen(rect)) {
      return rect;
    }
    // Gradually move left to try and fit on screen.
    let newX = existingObjects[existingObjects.length - 1].x - 25;
    const newY = existingObjects[existingObjects.length - 1].y + 25;

    while (newX > 0) {
      const newRect = {
        height,
        width,
        x: newX,
        y: newY,
      };

      if (isInScreen(newRect)) {
        return {
          height,
          width,
          x: newX,
          y: newY,
        };
      }

      newX -= 25;
    }
    // Doesn't fit anywhere, move to the topleft of the screen.
    return {
      width,
      height,
      x: 10,
      y: 10,
    };
  }

  getDefaultDimensionsFor(type) {
    switch (type) {
      case 'audio':
        return { width: 500, height: 120 };
      case 'video':
      case 'image':
        return { width: 600, height: 400 };
      default: {
        return this.isOnSmallScreen() // For small screens we dont take the sidebar into account.
          ? {
              width: window.innerWidth - this.SIDEBAR_WIDTH - this.PADDING,
              height: window.innerHeight - this.PADDING,
            }
          : {
              width: window.innerWidth - 435,
              height: window.innerHeight - this.PADDING,
            };
      }
    }
  }

  getShapeWhenCentered(width, height) {
    return {
      x: (window.innerWidth - width + (this.isOnSmallScreen() ? 0 : this.SIDEBAR_WIDTH)) / 2,
      y: (window.innerHeight - height) / 2,
      width,
      height,
    };
  }

  positionNewDialogForMedialink = medialink => {
    if (medialink.kind === MedialinkTypes.FILE) {
      const type = getFileType(medialink.file.s3file.mimeType);
      const { width, height } = this.getDefaultDimensionsFor(type);

      const newShape = this.getFirstAvailableSpaceToPosition(width, height);
      if (newShape) {
        this.setState({
          [medialink.id]: newShape,
        });
      }
    } else if ((medialink.kind === MedialinkTypes.EXTERNAL_MEDIA && medialink.externalMedia.type === ExternalMediaTypes.YOUTUBE) || medialink.videoId) {
      const { width, height } = this.getDefaultDimensionsFor('video');
      const newShape = this.getFirstAvailableSpaceToPosition(width, height);

      if (newShape) {
        this.setState({
          [medialink.id]: newShape,
        });
      }
    }
  };

  positionNewDialogForUserMedia = userMedia => {
    const { isMaterialAssignmentModalOpen } = this.props;

    const mediaType = MediaDialogs.getMediaTypeForUserMedia(userMedia);

    const { width, height } = this.getDefaultDimensionsFor(mediaType);

    const newShape = isMaterialAssignmentModalOpen ? this.getShapeWhenCentered(width, height) : this.getFirstAvailableSpaceToPosition(width, height);

    if (newShape) {
      this.setState({
        [userMedia.id]: newShape,
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  isOnSmallScreen() {
    return window.matchMedia('only screen and (max-width: 768px)').matches;
  }

  initializeDialogCoordinates() {
    const { medialinksForDialogs, userMediaForDialogs, sidebarAnchor } = this.props;

    const updatedState = medialinksForDialogs.reduce((acc, medialink) => {
      if (medialink.kind === MedialinkTypes.FILE) {
        const type = getFileType(medialink.file.s3file.mimeType);
        const { width, height } = this.getDefaultDimensionsFor(type);
        const centered = {
          x: (window.innerWidth - width + this.SIDEBAR_WIDTH) / 2,
          y: (window.innerHeight - height) / 2,
        };

        switch (type) {
          case 'audio': {
            acc[medialink.id] = {
              width,
              height,
              ...centered,
            };
            break;
          }
          case 'image':
          case 'video': {
            acc[medialink.id] = {
              width,
              height,
              ...centered,
            };
            break;
          }
          default: {
            if (this.isOnSmallScreen()) {
              acc[medialink.id] = {
                width,
                height,
                x: sidebarAnchor === AnchorPositions.LEFT ? this.SIDEBAR_WIDTH + this.PADDING / 2 : this.SIDEBAR_WIDTH,
                y: 20,
              };
              break;
            }
            acc[medialink.id] = {
              width,
              height,
              x: this.getHorizontalOffset(window.innerWidth - 435),
              y: 20,
            };
            break;
          }
        }
      } else if (medialink.kind === MedialinkTypes.EXTERNAL_MEDIA && medialink.externalMedia.type === ExternalMediaTypes.YOUTUBE) {
        const { width, height } = this.getDefaultDimensionsFor('video');
        const centered = {
          x: (window.innerWidth - width + this.SIDEBAR_WIDTH) / 2,
          y: (window.innerHeight - height) / 2,
        };
        acc[medialink.id] = {
          width,
          height,
          ...centered,
        };
      }

      return acc;
    }, {});

    const combinedState = userMediaForDialogs.reduce((acc, userMedia) => {
      const mediaType = MediaDialogs.getMediaTypeForUserMedia(userMedia);

      const { width, height } = this.getDefaultDimensionsFor(mediaType);
      let centered = {
        x: (window.innerWidth - width + this.SIDEBAR_WIDTH) / 2,
        y: (window.innerHeight - height) / 2,
      };

      const { dialogsInfo } = this.props;
      const currentDialogInfo = dialogsInfo.find(x => x.id === userMedia.id);
      if (currentDialogInfo && (currentDialogInfo.signedUrls || {}).previewUrl) {
        centered = {
          x: this.getHorizontalOffset(window.innerWidth - 435),
          y: 20,
        };
      }

      acc[userMedia.id] = {
        width,
        height,
        ...centered,
      };

      return acc;
    }, updatedState);

    this.setState(combinedState);
  }

  render() {
    const { dialogsInfo, medialinksForDialogs, userMediaForDialogs, dispatch, t, teacherFeaturesEnabled, superModuleId, userRoles } = this.props;

    return dialogsInfo.map(dialog => {
      const { [dialog.id]: currentDialog = {} } = this.state;

      if (dialog.entityType === 'user-material') {
        const userMedia = userMediaForDialogs.find(x => x.id === dialog.id);

        const onClose = () => dispatch(closeDialog(dialog.id));
        const onMoveDialogToFront = () => dispatch(moveMediaDialogToFront(dialog.id));

        if (userMedia.lti) {
          const isTeacher = userRoles.includes(Role.TEACHER);

          let ltiMaterial = userMedia;
          if (!userMedia.assignment && userMedia.shares && userMedia.shares[0]) {
            ltiMaterial = { ...userMedia, share: userMedia.shares[0] };
          }

          return (
            <LtiExerciseModal
              key={dialog.id}
              isOwner={isTeacher}
              headerElement={<Dialog.Header title={ltiMaterial.name} />}
              ltipsUrl={LTI_API_URI}
              platformName={getPlatformName(STUDIO_GROUP)}
              studioLanguage={LANGUAGE}
              translations={this.ltiExerciseModalTranslations}
              tokenAccessor={this.tokenAccessor}
              onClose={() => {
                onClose();

                if (ltiMaterial.userMaxScore) {
                  dispatch(fetchUserMaterial());
                }
              }}
              module={ltiMaterial.populatedModule}
              material={ltiMaterial}
              studioGroup={STUDIO_GROUP}
            />
          );
        }
        if (userMedia.href && userMedia.videoId) {
          // youtube
          const startParam = userMedia.start ? `&start=${userMedia.start}` : '';

          return (
            <RndDialog
              key={dialog.id}
              title={userMedia.name}
              icon="icon-bb-material-own"
              close={onClose}
              moveDialogToFront={onMoveDialogToFront}
              isInFront={dialog.isInFront}
              setDimensions={this.setDimensions}
              setPosition={this.setPosition}
              hideDownload
              id={userMedia.id}
              width={currentDialog.width}
              height={currentDialog.height}
              x={currentDialog.x}
              y={currentDialog.y}
            >
              <div className="jw-video">
                <div className="pbb-modal__iframe-wrapper">
                  <iframe title={userMedia.name} className="iframe--fill iframe--no-border" src={`https://www.youtube.com/embed/${userMedia.videoId}?autoplay=1${startParam}`} />
                </div>
              </div>
            </RndDialog>
          );
        }
        if (userMedia.file) {
          const mediaType = MediaDialogs.getMediaTypeForUserMedia(userMedia);

          if (['audio', 'video'].includes(mediaType)) {
            return (
              <RndDialog
                key={dialog.id}
                title={userMedia.name}
                icon="icon-bb-material-own"
                close={onClose}
                moveDialogToFront={onMoveDialogToFront}
                isInFront={dialog.isInFront}
                setDimensions={this.setDimensions}
                setPosition={this.setPosition}
                downloadLink={dialog.signedUrls.downloadUrl}
                resizeEnabled={mediaType === 'video'}
                id={userMedia.id}
                width={currentDialog.width}
                height={currentDialog.height}
                x={currentDialog.x}
                y={currentDialog.y}
              >
                <ReactJWPlayer
                  className="jw-video"
                  playerId={dialog.id}
                  playerScript="https://content.jwplatform.com/libraries/zyraJCSg.js"
                  file={dialog.signedUrls.url}
                  aspectRatio={mediaType === 'video' ? 'inherit' : undefined}
                  customProps={mediaType === 'audio' ? { logo: undefined, width: 500, height: 40 } : undefined}
                />
              </RndDialog>
            );
          }

          const pdfPreviewUrl = dialog.signedUrls.previewUrl;
          if (pdfPreviewUrl) {
            return (
              <RndDialog
                key={dialog.id}
                title={userMedia.name}
                icon="icon-bb-material-own"
                close={onClose}
                moveDialogToFront={onMoveDialogToFront}
                isInFront={dialog.isInFront}
                setDimensions={this.setDimensions}
                setPosition={this.setPosition}
                downloadLink={dialog.signedUrls.downloadUrl}
                resizeEnabled
                id={userMedia.id}
                width={currentDialog.width}
                height={currentDialog.height}
                x={currentDialog.x}
                y={currentDialog.y}
              >
                <PDFViewer src={pdfPreviewUrl} />
              </RndDialog>
            );
          }

          if (mediaType === 'image') {
            return (
              <ModalDialog id="user-media-preview-modal" onClose={onClose} closeViaEscape closeOnClickOutside fullScreen zIndex={9999} key={dialog.id}>
                <Dialog.Header title={userMedia.name} />
                <Dialog.Body>
                  <div className="pbb-modal__body">
                    <div className="pbb-modal__body-wrapper">
                      <UserMaterialInfo userMaterial={userMedia} isOwner={teacherFeaturesEnabled} editable={false} title={t('userMaterialModal.options_info')} />
                      <div className="pbb-modal__action" data-testid="action-container">
                        <a href={dialog.signedUrls.downloadUrl} target="_blank" rel="noopener noreferrer" className="pbb-btn pbb-btn--icon-left" data-testid="download-button">
                          <i className="icon-bb-modal-download" />
                          {t('options.download')}
                        </a>
                      </div>
                      <div className="pbb-modal__object">
                        <img src={dialog.signedUrls.url} alt={userMedia.name} className="img--fit" />
                      </div>
                    </div>
                  </div>
                </Dialog.Body>
              </ModalDialog>
            );
          }
        }

        throw new Error(`Unsupported UserMedia '${JSON.stringify(userMedia)}'`);
      } else {
        if (dialog.id === 'pop-up-manual') return null;
        return (
          <MediaDialog
            key={dialog.id}
            data-testid="media-dialog"
            medialinkId={dialog.id}
            isInFront={dialog.isInFront}
            superModuleId={superModuleId}
            medialink={medialinksForDialogs.find(x => x.id === dialog.id)}
            width={currentDialog.width}
            height={currentDialog.height}
            x={currentDialog.x}
            y={currentDialog.y}
            setDimensions={this.setDimensions}
            setPosition={this.setPosition}
            subLocationId={dialog.subLocationId}
          />
        );
      }
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const medialinksForDialogs = [];
  const userMediaForDialogs = [];

  ownProps.dialogsInfo.forEach(({ id, entityType }) => {
    if (id === 'pop-up-manual') return;
    if (entityType === 'user-material') {
      const userMedia = getUserMedia(state, id);
      userMedia.populatedModule = getModuleById(state, userMedia.module);
      userMediaForDialogs.push(userMedia);
    } else {
      medialinksForDialogs.push(getMedialinkById(state, id));
    }
  });

  return {
    userRoles: getRoles(state),
    medialinksForDialogs,
    userMediaForDialogs,
    module,
    teacherFeaturesEnabled: getTeacherFeaturesEnabledFor(state),
    isMaterialAssignmentModalOpen: Boolean(getOpenMaterialAssignment(state)),
  };
};

MediaDialogs.propTypes = {
  dialogsInfo: arrayOf(
    shape({
      id: string.isRequired,
      entityType: string,
      isInFront: bool.isRequired,
    }),
  ).isRequired,
  dispatch: func.isRequired,
  medialinksForDialogs: arrayOf(object).isRequired,
  userMediaForDialogs: arrayOf(object).isRequired,
  sidebarAnchor: string.isRequired,
  activeDrawer: string,
  teacherFeaturesEnabled: bool,
  t: func.isRequired,
  superModuleId: string,
  userRoles: arrayOf(string).isRequired,
  isMaterialAssignmentModalOpen: bool.isRequired,
};

MediaDialogs.defaultProps = {
  activeDrawer: undefined,
  teacherFeaturesEnabled: false,
  superModuleId: undefined,
};

export default withTranslation()(connect(mapStateToProps)(MediaDialogs));
