import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { MaterialAssignmentModal as AssignmentModal } from '@pelckmans/business-components/components/material-assignment-modal';
import { shape, string, func, oneOf } from 'prop-types';

import { closeMaterialAssignmentModal, openMedialink, openMinisite, openUserMaterial } from '../../../../../actions/dialog';
import { isEmbeddableLink } from '../../../../../utils/youtubeUrlParser';
import api, { tokenGetter } from '../../../../../services/api';
import { postEvent } from '../../../../../api/analyticsApi';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../enums/analytics';
import { STUDIO_GROUP } from '../../../../../constants/constants';

const mapMaterials = materials => materials.map(({ entityType, ...m }) => ({ ...m, type: entityType }));

const MaterialAssignmentModal = ({ dispatch, material, sharedBy, assignment }) => {
  const [t] = useTranslation();
  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);

  const translations = useMemo(
    () => ({
      close: t('actions.close'),
      cancel: t('actions.cancel'),
      confirm: t('actions.confirm'),
      open: t('actions.open'),
      title: t('materialAssignmentModal.title'),
      deadline: t('materialAssignmentModal.deadline', { date: format(new Date(assignment.to), 'dd/MM/yyyy') }),
      isDone: t('materialAssignmentModal.isDone'),
      task: t('materialAssignmentModal.task'),
    }),
    [assignment.to, t],
  );

  const handleClose = () => {
    dispatch(closeMaterialAssignmentModal());
  };

  const handleOpenMaterial = async () => {
    if (material.entityType === 'medialink') {
      if (material.kind === 'mini-site') return dispatch(openMinisite(material));

      return dispatch(openMedialink(material, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL));
    }

    if (material.href && !isEmbeddableLink(material)) {
      await postEvent({
        eventType: ANALYTICS_EVENT_TYPES.USER_MATERIAL_ACCESSED,
        objectId: material.id,
        subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
      });
      return window.open(material.href, '_blank', 'noopener,noreferrer');
    }
    const signedUrls = material.file ? (await api.get(`/studio/user/modules/${material.moduleId}/user-material/${material.id}/signed-url`)).data : undefined;

    return dispatch(openUserMaterial(material, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL, { signedUrls }));
  };

  const handleSubmit = async error => {
    if (error instanceof Error) throw error;

    handleClose();
  };

  return (
    <AssignmentModal
      translations={translations}
      moduleId={material.moduleId}
      assignmentId={assignment.id}
      tokenAccessor={tokenAccessor}
      onClose={handleClose}
      onOpenMaterial={handleOpenMaterial}
      materials={mapMaterials([material])}
      onSubmit={handleSubmit}
      title={material.name}
      sharedBy={sharedBy}
      studioGroup={STUDIO_GROUP}
    />
  );
};
MaterialAssignmentModal.propTypes = {
  dispatch: func.isRequired,
  material: shape({
    id: string.isRequired,
    name: string.isRequired,
    moduleId: string.isRequired,
    entityType: oneOf(['user-material', 'medialink']).isRequired,
  }).isRequired,
  assignment: shape({
    id: string.isRequired,
    to: string.isRequired,
  }).isRequired,
  sharedBy: string.isRequired,
};

export default MaterialAssignmentModal;
