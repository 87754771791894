import { arrayOf, shape, string, oneOfType, bool, number } from 'prop-types';

const sharedPropsForUserMaterial = {
  id: string.isRequired,
  name: string.isRequired,
  module: string.isRequired,
  node: string.isRequired,
  lastUpdatedAt: string.isRequired,
  maxScore: number.isRequired,
  userMaxScore: number.isRequired,
  isFavorite: bool,
  score: shape({
    maximum: number.isRequired,
    achieved: number.isRequired,
    achievable: number.isRequired,
  }),
};

const propsFromOwnerPerspective = {
  shared: bool.isRequired,
};

const propsFromSharedPerspective = {
  shares: arrayOf(
    shape({
      id: string.isRequired,
      user: shape({
        id: string.isRequired,
        firstName: string.isRequired,
        lastName: string.isRequired,
      }).isRequired,
      label: string,
    }).isRequired,
  ),
};

const userMaterialPropType = oneOfType([
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromOwnerPerspective,
    file: shape({
      id: string.isRequired,
      extension: string.isRequired,
      mimeType: string.isRequired,
      originalName: string.isRequired,
    }).isRequired,
  }),
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromSharedPerspective,
    file: shape({
      id: string.isRequired,
      extension: string.isRequired,
      mimeType: string.isRequired,
      originalName: string.isRequired,
    }).isRequired,
  }),
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromOwnerPerspective,
    href: string.isRequired,
  }),
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromSharedPerspective,
    href: string.isRequired,
  }),
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromOwnerPerspective,
    lti: shape({ ltiResource: string.isRequired, platform: string.isRequired }),
  }),
  shape({
    ...sharedPropsForUserMaterial,
    ...propsFromSharedPerspective,
    lti: shape({ ltiResource: string.isRequired, platform: string.isRequired }),
  }),
]);

export default userMaterialPropType;
