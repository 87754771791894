import { useEffect } from 'react';

export default function useClickOutside(ref, onClick) {
  useEffect(() => {
    function handleDocumentClick(event) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        onClick(event);
      }
    }

    // click to mousedown fixes PAKP-4044 but we are not sure why.
    // In fabric hebben we geen echte click. We kunnen die dus ook niet tegenhouden.
    // daarom op mousedown ipv click.
    document.addEventListener('mousedown', handleDocumentClick);
    document.addEventListener('touchstart', handleDocumentClick);

    return function cleanup() {
      document.removeEventListener('mousedown', handleDocumentClick);
      document.removeEventListener('touchstart', handleDocumentClick);
    };
  }, [ref, onClick]);

  return ref;
}
