import api from '../services/api';
import {
  WHITEPAGES_DELETE_SUCCESS,
  WHITEPAGES_FETCH_BY_NODEID_SUCCESS,
  WHITEPAGES_ADD_SUCCESS,
  WHITEPAGES_FETCH_SINGLE_SUCCESS,
  WHITEPAGES_SAVE_SUCCESS,
  WHITEPAGES_SAVE_OPTIMISTIC_UPDATE,
  WHITEPAGES_ADD_FAVORITE_SUCCESS,
  WHITEPAGES_REMOVE_FAVORITE_SUCCESS,
} from './actionNames';
import { getCurrentModuleId } from '../selectors/digibooks';
import { getVisibleChapterNodeIds } from '../selectors/toc';
import { getWhitepageIdsGroupedByTocNodeId, getWhitepageById } from '../selectors/whitepages';
import { setPlayerMode } from './playerMode';
import PlayerMode from '../enums/playerMode';
import { getCurrentWhitepage } from '../selectors/player';

const addWhitepageSuccess = whitepage => ({
  type: WHITEPAGES_ADD_SUCCESS,
  payload: { whitepage },
});

const fetchWhitepageSuccess = whitepage => ({
  type: WHITEPAGES_FETCH_SINGLE_SUCCESS,
  payload: { whitepage },
});

export const fetchWhitepage = whitepageId => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);
  const { id, nodeId } = getWhitepageById(state, whitepageId);
  const { data } = await api.get(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/whitepages/${id}`);

  dispatch(fetchWhitepageSuccess(data));
};

export function openWhitepage(whitepageId) {
  return async (dispatch, getState) => {
    const whitepage = getWhitepageById(getState(), whitepageId);

    if (!whitepage.url) {
      await dispatch(fetchWhitepage(whitepageId));
    }
    dispatch(setPlayerMode(PlayerMode.WHITEPAGE, whitepageId));
  };
}

export function addUserFavoriteWhitepage(whitepage) {
  return async (dispatch, getState) => {
    const moduleId = getCurrentModuleId(getState());
    await api.put(`/studio/user/modules/${moduleId}/favorites/whitepages/${whitepage.id}`);

    return dispatch({
      type: WHITEPAGES_ADD_FAVORITE_SUCCESS,
      payload: {
        moduleId,
        whitepageId: whitepage.id,
      },
    });
  };
}

export const addWhitepage = (nodeId, paperType, asFavorite) => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);

  const { data } = await api.post(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/whitepages`, { paperType });

  if (asFavorite) dispatch(addUserFavoriteWhitepage(data));
  dispatch(addWhitepageSuccess(data));

  dispatch(openWhitepage(data.id));
  return data;
};

const saveWhitepageSuccess = whitepage => ({
  type: WHITEPAGES_SAVE_SUCCESS,
  payload: { whitepage },
});

const saveWhitepageOptimisticUpdate = whitepage => ({
  type: WHITEPAGES_SAVE_OPTIMISTIC_UPDATE,
  payload: { whitepage },
});

export const saveWhitepage = (whitepage, updatedProps) => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);

  // Optimistic update
  dispatch(saveWhitepageOptimisticUpdate({ ...whitepage, ...updatedProps }));

  const { data } = await api.patch(`/studio/user/modules/${moduleId}/table-of-content/${whitepage.nodeId}/whitepages/${whitepage.id}`, updatedProps);

  dispatch(saveWhitepageSuccess(data));
};

const deleteWhitepageSuccess = (whitepageId, nodeId) => ({
  type: WHITEPAGES_DELETE_SUCCESS,
  payload: {
    whitepageId,
    nodeId,
  },
});

export const deleteWhitepage = (whitepageId, nodeId) => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);
  const currentWhitepage = getCurrentWhitepage(state);

  await api.delete(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/whitepages/${whitepageId}`);

  dispatch(deleteWhitepageSuccess(whitepageId, nodeId));

  if (currentWhitepage && currentWhitepage.id === whitepageId) {
    dispatch(setPlayerMode(PlayerMode.BOOK));
  }
};

const retrieveWhitepagesByNodeIdSuccess = whitepages => ({
  type: WHITEPAGES_FETCH_BY_NODEID_SUCCESS,
  payload: { whitepages },
});

export const retrieveWhitepagesByNodeId = nodeId => async (dispatch, getState) => {
  const state = getState();
  const moduleId = getCurrentModuleId(state);

  const response = await api.get(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/whitepages`);

  dispatch(retrieveWhitepagesByNodeIdSuccess(response.data.data));
};

export const retrieveWhitepagesForCurrentPages = () => (dispatch, getState) => {
  const state = getState();

  const chapterIds = getVisibleChapterNodeIds(state) || [];

  const promises = chapterIds.map(nodeId => (getWhitepageIdsGroupedByTocNodeId(state, nodeId) ? Promise.resolve() : dispatch(retrieveWhitepagesByNodeId(nodeId))));
  return Promise.all(promises);
};

export function removeUserFavoriteWhitepage(whitepage) {
  return async (dispatch, getState) => {
    const moduleId = getCurrentModuleId(getState());
    await api.delete(`/studio/user/modules/${moduleId}/favorites/whitepages/${whitepage.id}`);

    return dispatch({
      type: WHITEPAGES_REMOVE_FAVORITE_SUCCESS,
      payload: {
        moduleId,
        whitepageId: whitepage.id,
      },
    });
  };
}

export function toggleWhitepageFavorite(whitepage) {
  return dispatch => dispatch(whitepage.isFavorite ? removeUserFavoriteWhitepage(whitepage) : addUserFavoriteWhitepage(whitepage));
}
