import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { NOTES_SAVE_NOTE_SUCCESS, NOTES_DELETE_NOTE_SUCCESS, NOTES_FETCH_BY_NODEID_SUCCESS } from '../actions/actionNames';
import { note as noteSchema } from '../schemas';

const initialState = {
  byId: {},
  byTocNodeId: {},
};

const notesReducer = (state = initialState, action) => {
  switch (action && action.type) {
    case NOTES_FETCH_BY_NODEID_SUCCESS: {
      const { notes, nodeId } = action.payload;
      const normalized = normalize(notes, [noteSchema]);

      return update(state, {
        byId: { $merge: normalized.entities.notes || {} },
        byTocNodeId: {
          $merge: {
            [nodeId]: notes.map(note => note.id),
          },
        },
      });
    }

    case NOTES_DELETE_NOTE_SUCCESS: {
      const { noteId, nodeId } = action.payload;

      const notesForNodeId = state.byTocNodeId[nodeId].filter(x => x !== noteId);

      if (notesForNodeId.length) {
        return update(state, {
          byId: { $unset: [noteId] },
          byTocNodeId: {
            [nodeId]: { $set: notesForNodeId },
          },
        });
      }

      return update(state, {
        byId: { $unset: [noteId] },
        byTocNodeId: { $unset: [nodeId] },
      });
    }
    case NOTES_SAVE_NOTE_SUCCESS: {
      const { note } = action.payload;
      const updateNotesForNodeId = (notesForNodeId = []) => {
        if (notesForNodeId.some(x => x === note.id)) return notesForNodeId;
        return update(notesForNodeId, { $push: [note.id] });
      };

      return update(state, {
        byId: { [note.id]: { $set: note } },
        byTocNodeId: {
          [note.nodeId]: updateNotesForNodeId,
        },
      });
    }
    default:
      return state;
  }
};

export default notesReducer;
