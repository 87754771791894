/**
 * @description split rectangles which are on left and right page.
 * @param {{ top: Number, left: Number, width: Number, height: Number }} shape
 * @param {Number[]} spreadPages
 */
export function splitShapeByPages(shape, spreadPages) {
  if (shape.left < 0.5 && shape.left + shape.width > 0.5) {
    const left = {
      page: spreadPages[0],
      width: 0.5 - shape.left,
      height: shape.height,
      left: shape.left,
      top: shape.top,
    };

    const right = {
      page: spreadPages[1],
      width: shape.width - left.width,
      height: shape.height,
      left: 0.5,
      top: shape.top,
    };

    return [left, right];
  }

  return [
    {
      page: shape.left < 0.5 ? spreadPages[0] : spreadPages[1],
      ...shape,
    },
  ];
}
