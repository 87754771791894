import update from 'immutability-helper';
import { normalize } from 'normalizr';
import { tocnode, pageranges } from '../schemas';

import {
  INITIALIZE,
  INITIALIZE_SUCCESS,
  BOOKLAYER_PARSED,
  BOOKLAYER_RENDERED,
  SET_PLAYER_MODE,
  SET_INITIAL_TOOLBAR_POSITION,
  WHITEPAGE_RENDERED,
  TOGGLE_MANUAL,
  TOGGLE_POP_UP_MANUAL,
  TOGGLE_SOLUTIONS_PAGE,
} from '../actions/actionNames';
import PlayerModes from '../enums/playerMode';

const initialState = {
  digibookId: null,
  superModuleId: null,
  whitepageId: null,
  playerMode: PlayerModes.BOOK,
  isReady: false,
  isRendered: false,
  isWhitepageRendered: false,
  entities: {
    digibooks: {},
    files: {},
    modules: {},
    securedFiles: {},
    tocs: {},
    relatedDigibooks: {},
    tocnodes: {},
  },
  isManualVisible: undefined,
  isSolutionsPageVisible: undefined,
  isPopUpManualVisible: undefined,
};

function addPageRangesToNodes(nodes, pageRangesLookUpObject, hierarchy = []) {
  if (nodes) {
    return nodes.map(node => {
      const nodeCopy = { ...node };
      nodeCopy.hierarchy = hierarchy;
      const pageRange = pageRangesLookUpObject[node.id];

      if (pageRange) {
        nodeCopy.fromPage = pageRange.from;
        nodeCopy.toPage = pageRange.to;
        nodeCopy.keepMediaVisibleToPage = pageRange.keepMediaVisibleTo;
      }

      if (node.nodes) {
        nodeCopy.nodes = addPageRangesToNodes(node.nodes, pageRangesLookUpObject, [...hierarchy, node.id]);
      }

      return nodeCopy;
    });
  }
  return null;
}

export default function playerReducer(state = initialState, action) {
  switch (action && action.type) {
    case INITIALIZE: {
      return update(state, {
        digibookId: { $set: action.payload.id },
        superModuleId: { $set: action.payload.superModuleId },
        isReady: { $set: false },
      });
    }
    case INITIALIZE_SUCCESS: {
      const { entities, moduleId, toc, id, superModuleId } = action.payload;
      const entitiesUpdateObject = {
        digibooks: { $merge: entities.digibooks },
        files: { $merge: entities.files || {} },
        modules: { $merge: entities.modules || {} },
        securedFiles: { $merge: entities.securedFiles || {} },
        relatedDigibooks: { $merge: entities.relatedDigibooks || {} },
      };

      if (toc) {
        // if tocs for this book, normalize the toc into tocnodes for faster lookup
        const normalizedPageRanges = normalize(entities.digibooks[id].pageRanges || [], [pageranges]);
        const pageRangesLookUpObject = normalizedPageRanges.entities.pageRanges || {};
        const processedTOC = addPageRangesToNodes(toc, pageRangesLookUpObject);
        const normalizedProcessedToc = normalize(processedTOC, [tocnode]);

        entitiesUpdateObject.tocnodes = { $merge: normalizedProcessedToc.entities.nodes };
        entitiesUpdateObject.tocs = { $merge: { [moduleId]: normalizedProcessedToc.result } };
      }

      return update(state, {
        isReady: { $set: true },
        digibookId: { $set: id },
        superModuleId: { $set: superModuleId },
        entities: e => update(e, entitiesUpdateObject),
      });
    }
    case BOOKLAYER_PARSED: {
      const { id, totalPages } = action.payload;
      return update(state, {
        entities: entities =>
          update(entities, {
            digibooks: {
              [id]: {
                totalPages: { $set: totalPages },
              },
            },
          }),
      });
    }
    case BOOKLAYER_RENDERED: {
      return update(state, {
        isRendered: { $set: true },
      });
    }
    case SET_INITIAL_TOOLBAR_POSITION: {
      const { toolbarPosition } = action.payload;
      return update(state, {
        initialToolbarPosition: { $set: toolbarPosition },
      });
    }
    case SET_PLAYER_MODE: {
      const { playerMode, whitepageId } = action.payload;

      return update(state, {
        playerMode: { $set: playerMode },
        whitepageId: { $set: whitepageId },
      });
    }
    case WHITEPAGE_RENDERED: {
      return update(state, {
        isWhitepageRendered: { $set: true },
      });
    }
    case TOGGLE_MANUAL: {
      const { isManualVisible } = state;

      return update(state, {
        isManualVisible: { $set: !isManualVisible },
      });
    }
    case TOGGLE_POP_UP_MANUAL: {
      const { isPopUpManualVisible } = state;

      return update(state, {
        isPopUpManualVisible: { $set: !isPopUpManualVisible },
      });
    }
    case TOGGLE_SOLUTIONS_PAGE: {
      const { isSolutionsPageVisible } = state;

      return update(state, {
        isSolutionsPageVisible: { $set: !isSolutionsPageVisible },
      });
    }
    default: {
      return state;
    }
  }
}
