import { useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { calculateAngleInDegrees, calibratePoint } from '../utils';

export default function useDraftingCompassRotate() {
  const [rotation, setRotation] = useState(0);
  const [rotationDisplay, setRotationDisplay] = useState(rotation);

  const isRotating = useRef(false);
  const centerPoint = useRef({ x: 0, y: 0 });

  const handleMove = throttle(evt => {
    if (!isRotating.current) return;
    evt.preventDefault();

    const { clientX, clientY } = evt.changedTouches?.[0] || evt;
    const endPoint = {
      x: clientX,
      y: clientY,
    };

    const calibratedEndPoint = calibratePoint(endPoint, centerPoint.current);

    const { normalizedRotation, denormalizedRotation } = calculateAngleInDegrees(calibratedEndPoint);

    setRotation(normalizedRotation);
    setRotationDisplay(Math.abs(denormalizedRotation));
  }, 1000 / 60);

  function handleStop() {
    if (!isRotating.current) return;

    isRotating.current = false;

    document.body.removeEventListener('mousemove', handleMove);
    document.body.removeEventListener('touchmove', handleMove);

    document.body.removeEventListener('mouseup', handleStop);
    document.body.removeEventListener('touchend', handleStop);
  }

  function handleStart() {
    isRotating.current = true;

    const { left, top, width, height } = document.querySelector('#center-dot').getBoundingClientRect();
    centerPoint.current = {
      x: left + width / 2,
      y: top + height / 2,
    };

    document.body.addEventListener('mousemove', handleMove);
    document.body.addEventListener('touchmove', handleMove);

    document.body.addEventListener('mouseup', handleStop);
    document.body.addEventListener('touchend', handleStop);
  }

  return {
    rotate: handleStart,
    degrees: rotation,
    degreesDisplay: rotationDisplay,
  };
}
