import React, { Component } from 'react';
import classNames from 'classnames';
import { func, number } from 'prop-types';

class PageInput extends Component {
  state = {
    error: false,
    pageNumber: '',
    isFocused: false,
  };

  handleChange = e => this.setState({ error: false, pageNumber: e.target.value });

  handleFocus = () => this.setState({ isFocused: true });

  handleBlur = () => this.setState({ isFocused: false });

  handkeKeyDown = async e => {
    if (e.keyCode === 13) {
      const { onSubmit } = this.props;
      const page = parseInt(e.target.value, 10);
      const isValidPage = page >= 0 && !Number.isNaN(page);

      if (isValidPage) {
        this.setState({ pageNumber: '' });
        if (e.target) e.target.blur();
        return onSubmit(page);
      }

      return this.setState({ error: true });
    }
    return false;
  };

  render() {
    const { error, pageNumber, isFocused } = this.state;
    const { currentPageNumber } = this.props;

    return (
      <div
        className={classNames('pbb-page__input', {
          'pbb-page__input--noresult': error,
        })}
      >
        <input
          type="text"
          name="title"
          maxLength="3"
          placeholder={isFocused ? '' : currentPageNumber}
          value={pageNumber}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handkeKeyDown}
        />
      </div>
    );
  }
}

PageInput.propTypes = {
  onSubmit: func.isRequired,
  currentPageNumber: number.isRequired,
};

export default PageInput;
