/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/**
 * interface for drawing with math tools
 * {
 *  clampPointer()
 *  initInfo()
 *  reset()
 *  shouldCompletePath()
 * }
 */

import { MATH_TOOL_STROKE_WIDTH } from '../constants';
import { degreesToRadians, isPointAboveLine, isPointInRectangle, rotatePoint, findNearestPointOnSegment } from '../utils';

export default class RulerFreeDrawingStrategy {
  _ruler;

  _currentClampRect;

  _drawingLines = { top: undefined, bottom: undefined };

  _clampingRectangles = { top: undefined, bottom: undefined };

  _rulerPosition;

  _viewportTransform;

  _rotationPoint;

  _rotationInDegrees;

  constructor(viewportTransform, rulerElement) {
    this._ruler = rulerElement || document.getElementById('ruler');
    if (!this._ruler) throw new Error('Cannot use RulerDrawer when ruler is not rendered');
    this._viewportTransform = viewportTransform;
    this._rotationInDegrees = Number(this._ruler.dataset.angle);
  }

  initInfo(pencilWidth) {
    const mathToolsWrapper = document.getElementById('math-tools');
    const pixelsPerMM = Number(mathToolsWrapper.dataset.pixelsPerMm);
    const clampHeight = pixelsPerMM * 10;

    const wrapperTop = Number(mathToolsWrapper.dataset.top);
    const wrapperLeft = Number(mathToolsWrapper.dataset.left);

    this._rotationInDegrees = Number(this._ruler.dataset.angle);
    const rotationInRadians = degreesToRadians(this._rotationInDegrees);

    this._setPositionOfRuler(wrapperTop, wrapperLeft);

    this._setRotationPoint();

    this._setDrawingLines(rotationInRadians, pencilWidth);
    this._setClampingRectangles(clampHeight, rotationInRadians);
  }

  clampPointer(point) {
    const prevClampingRect = this._currentClampRect;

    this._setCurrentClampingRect(point);

    if (this._currentClampRect) {
      const drawLine = this._getDrawLine();
      if (drawLine) return findNearestPointOnSegment(point, drawLine);
    }
    if (!this._currentClampRect && prevClampingRect) {
      const cornerPoint = this._setPointerToRulerCorner(point, prevClampingRect);
      if (cornerPoint) return cornerPoint;
    }

    return point;
  }

  shouldCompletePath(event, canvas) {
    return this._isPointerOnMathTool(event, canvas) && !this._currentClampRect;
  }

  reset() {
    this._currentClampRect = undefined;
  }

  _setPointerToRulerCorner(point, prevClampingRect) {
    if (prevClampingRect === this._clampingRectangles.top) {
      const isPointUnderTopClampRect = !isPointAboveLine(point, [this._clampingRectangles.top[3], this._clampingRectangles.top[2]], this._rotationInDegrees);
      const isPointLeftOfRuler = !isPointAboveLine(point, [this._clampingRectangles.top[0], this._clampingRectangles.bottom[0]], (this._rotationInDegrees + 90) % 360);
      const isPointRightOfRuler = isPointAboveLine(point, [this._clampingRectangles.top[1], this._clampingRectangles.bottom[1]], (this._rotationInDegrees + 90) % 360);

      if (isPointUnderTopClampRect && isPointLeftOfRuler) return this._drawingLines.top[0];
      if (isPointUnderTopClampRect && isPointRightOfRuler) return this._drawingLines.top[1];
    }
    if (prevClampingRect === this._clampingRectangles.bottom) {
      const isPointAboveBottomClampRect = isPointAboveLine(point, [this._clampingRectangles.bottom[0], this._clampingRectangles.bottom[1]], this._rotationInDegrees);
      const isPointLeftOfRuler = !isPointAboveLine(point, [this._clampingRectangles.top[0], this._clampingRectangles.bottom[0]], (this._rotationInDegrees + 90) % 360);
      const isPointRightOfRuler = isPointAboveLine(point, [this._clampingRectangles.top[1], this._clampingRectangles.bottom[1]], (this._rotationInDegrees + 90) % 360);

      if (isPointAboveBottomClampRect && isPointLeftOfRuler) return this._drawingLines.bottom[0];
      if (isPointAboveBottomClampRect && isPointRightOfRuler) return this._drawingLines.bottom[1];
    }

    return undefined;
  }

  _isPointerOnMathTool(event, canvas) {
    return event.target !== canvas;
  }

  _setPositionOfRuler(wrapperTop, wrapperLeft) {
    const [scale, , , , leftOffset, topOffset] = this._viewportTransform;
    const backdrop = document.getElementById('ruler-backdrop').firstChild;

    const { height: rulerHeight, width: rulerWidth } = backdrop.getBBox();

    const paintableLeft = (wrapperLeft - leftOffset) / scale;
    const paintableRight = paintableLeft + rulerWidth;
    const paintableTop = (wrapperTop - topOffset) / scale - this._ruler.getBBox().y;
    const paintableBottom = paintableTop + rulerHeight;

    this._rulerPosition = {
      left: paintableLeft,
      top: paintableTop,
      right: paintableRight,
      bottom: paintableBottom,
    };
  }

  _setRotationPoint() {
    this._rotationPoint = {
      x: this._rulerPosition.left + Number(this._ruler.dataset.originX),
      y: this._rulerPosition.top,
    };
  }

  _setDrawingLines(rotationInRadians, pencilWidth) {
    const { left, top, right, bottom } = this._rulerPosition;
    const halfOfStrokeWidth = MATH_TOOL_STROKE_WIDTH / 2;
    const halfOfPencilWidth = pencilWidth / 2;

    const pointsLineTop = [
      { x: left, y: top - halfOfPencilWidth - halfOfStrokeWidth },
      { x: right, y: top - halfOfPencilWidth - halfOfStrokeWidth },
    ];
    const drawingLineTop = pointsLineTop.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const pointsLineBottom = [
      { x: left, y: bottom + halfOfPencilWidth + halfOfStrokeWidth },
      { x: right, y: bottom + halfOfPencilWidth + halfOfStrokeWidth },
    ];
    const drawingLineBottom = pointsLineBottom.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    this._drawingLines = { top: drawingLineTop, bottom: drawingLineBottom };
  }

  _setClampingRectangles(clampHeight, rotationInRadians) {
    const { left, top, right, bottom } = this._rulerPosition;

    const topClampPoints = [
      { x: left, y: top - clampHeight },
      { x: right, y: top - clampHeight },
      { x: right, y: top },
      { x: left, y: top },
    ];
    const clampingRectTop = topClampPoints.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const bottomClampPoints = [
      { x: left, y: bottom },
      { x: right, y: bottom },
      { x: right, y: bottom + clampHeight },
      { x: left, y: bottom + clampHeight },
    ];
    const clampingRectBottom = bottomClampPoints.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    this._clampingRectangles = { top: clampingRectTop, bottom: clampingRectBottom };
  }

  _setCurrentClampingRect(pointer) {
    if (!this._currentClampRect) {
      if (isPointInRectangle(pointer, this._clampingRectangles.top)) this._currentClampRect = this._clampingRectangles.top;
      if (!this._currentClampRect && isPointInRectangle(pointer, this._clampingRectangles.bottom)) this._currentClampRect = this._clampingRectangles.bottom;
    }

    const topLine = [this._clampingRectangles.top[3], this._clampingRectangles.top[2]];
    const leftLine = [this._clampingRectangles.top[3], this._clampingRectangles.bottom[0]];
    const rightLine = [this._clampingRectangles.top[2], this._clampingRectangles.bottom[1]];
    const bottomLine = [this._clampingRectangles.bottom[0], this._clampingRectangles.bottom[1]];

    if (this._currentClampRect === this._clampingRectangles.top) {
      const isAboveLeftLine = isPointAboveLine(pointer, leftLine, (this._rotationInDegrees + 90) % 360);
      const isUnderRightLine = !isPointAboveLine(pointer, rightLine, (this._rotationInDegrees + 90) % 360);
      const isUnderTopLine = !isPointAboveLine(pointer, topLine, this._rotationInDegrees);
      const keepClamping = isAboveLeftLine && isUnderRightLine && isUnderTopLine;

      if (keepClamping) return;
      if (!isPointInRectangle(pointer, this._clampingRectangles.top)) this._currentClampRect = undefined;
    }
    if (this._currentClampRect === this._clampingRectangles.bottom) {
      const isAboveBottomLine = isPointAboveLine(pointer, bottomLine, this._rotationInDegrees);
      const isUnderLeftLine = !isPointAboveLine(pointer, leftLine, (this._rotationInDegrees + 270) % 360);
      const isAboveRightLine = isPointAboveLine(pointer, rightLine, (this._rotationInDegrees + 270) % 360);

      const keepClamping = isAboveBottomLine && isUnderLeftLine && isAboveRightLine;

      if (keepClamping) return;
      if (!isPointInRectangle(pointer, this._clampingRectangles.bottom)) this._currentClampRect = undefined;
    }
  }

  _getDrawLine() {
    if (this._currentClampRect === this._clampingRectangles.top) {
      return this._drawingLines.top;
    }
    if (this._currentClampRect === this._clampingRectangles.bottom) {
      return this._drawingLines.bottom;
    }

    return undefined;
  }
}
