import api from '../services/api';

import { areMedialinksForChapterNodeFetched } from '../selectors/medialinks';
import { getVisibleChapterNodeIds } from '../selectors/toc';
import { getCurrentModuleId } from '../selectors/digibooks';

import {
  MEDIALINKS_FETCH_BY_NODEID,
  MEDIALINKS_FETCH_BY_IDS_SUCCESS,
  MEDIALINKS_FETCH_BY_NODEID_SUCCESS,
  MEDIALINKS_FETCH_BY_NODEID_FAILED,
  MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS,
  MEDIALINKS_ADD_FAVORITE_SUCCESS,
  MEDIALINKS_REMOVE_FAVORITE_SUCCESS,
  MEDIALINK_SHARED,
  MEDIALINK_SET_EXERCISE_SESSION_RESULT,
} from './actionNames';

function retrieveMedialinksByNodeIdStarted(nodeId) {
  return {
    type: MEDIALINKS_FETCH_BY_NODEID,
    payload: {
      nodeId,
    },
  };
}

export function retrieveMedialinksByIdsSuccess({ medialinkIds, medialinks }) {
  return {
    type: MEDIALINKS_FETCH_BY_IDS_SUCCESS,
    payload: {
      medialinkIds,
      medialinks,
    },
  };
}

function retrieveMedialinksByNodeIdSuccess({ nodeId, medialinks }) {
  return {
    type: MEDIALINKS_FETCH_BY_NODEID_SUCCESS,
    payload: {
      nodeId,
      medialinks,
    },
  };
}

function retrieveMedialinksByNodeIdFailed({ nodeId, reason }) {
  return {
    type: MEDIALINKS_FETCH_BY_NODEID_FAILED,
    payload: {
      nodeId,
      reason,
    },
  };
}

function retrieveMedialinksByNodeId(nodeId) {
  return async (dispatch, getState) => {
    dispatch(retrieveMedialinksByNodeIdStarted(nodeId));
    try {
      const state = getState();
      const moduleId = getCurrentModuleId(state);
      const response = await api.get(`/studio/modules/${moduleId}/table-of-content/${nodeId}/mediaLinks?includeDescendants=true&includeScore=true`);
      return dispatch(retrieveMedialinksByNodeIdSuccess({ nodeId, medialinks: response.data.data }));
    } catch (e) {
      return dispatch(retrieveMedialinksByNodeIdFailed({ nodeId, reason: e && e.response ? e.response.status : 400 }));
    }
  };
}

export function retrieveMedialinksForCurrentPages() {
  return (dispatch, getState) => {
    const state = getState();
    const chapterIds = getVisibleChapterNodeIds(state) || [];
    const promises = chapterIds.map(nodeId => {
      if (!areMedialinksForChapterNodeFetched(state, nodeId)) {
        return dispatch(retrieveMedialinksByNodeId(nodeId));
      }
      return null;
    });
    return Promise.all(promises);
  };
}

export function addUserFavoriteMedialink(medialink) {
  return async dispatch => {
    await api.put(`/studio/user/modules/${medialink.moduleId}/favorites/medialinks/${medialink.id}`);
    return dispatch({ type: MEDIALINKS_ADD_FAVORITE_SUCCESS, payload: { moduleId: medialink.moduleId, medialinkId: medialink.id } });
  };
}

export function removeUserFavoriteMedialink(medialink) {
  return async dispatch => {
    await api.delete(`/studio/user/modules/${medialink.moduleId}/favorites/medialinks/${medialink.id}`);
    return dispatch({ type: MEDIALINKS_REMOVE_FAVORITE_SUCCESS, payload: { moduleId: medialink.moduleId, medialinkId: medialink.id } });
  };
}

export function toggleMedialinkFavorite(medialink) {
  return dispatch => dispatch(medialink.isFavorite ? removeUserFavoriteMedialink(medialink) : addUserFavoriteMedialink(medialink));
}

export function retrieveMediaContentTypesForModule(moduleId) {
  return async dispatch => {
    const response = await api.get(`/studio/modules/${moduleId}/media-content-types/usage?includeSubModules=true`);
    return dispatch({
      type: MEDIALINKS_FETCH_CONTENTTYPES_SUCCESS,
      payload: {
        moduleId,
        contentTypes: response.data.data,
      },
    });
  };
}

export function setMedialinkShared(medialinkId, shares, assignments) {
  return {
    type: MEDIALINK_SHARED,
    payload: { medialinkId, shares, assignments },
  };
}

export function setMediaLinkExerciseSessionsResult(mediaLinkId, result) {
  return {
    type: MEDIALINK_SET_EXERCISE_SESSION_RESULT,
    payload: { mediaLinkId, result },
  };
}
