/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import React from 'react';
import { render } from 'react-dom';
import ColorPicker from '../../../modules/player/components/sidebar/tools/color-picker';
import { annotationTextColors } from '../../../enums/colors';
import Tools from '../../../enums/tools';

class FontColorPlugin {
  selectedColor = '';

  constructor() {
    this.handleColorPicker = this.handleColorPicker.bind(this);
  }

  init(editor) {
    this.jodit = editor;

    this.selectedColor = this.jodit.o.defaultColor;

    Config.prototype.controls.fontcolor = {
      ...Config.prototype.controls.fontcolor,
      exec: () => this.jodit.selection.focus(),
      popup: (_jodit, _current, close) => {
        const root = document.createElement('div');
        root.className = 'color-picker__popup';
        this.colorPickerRoot = root;

        render(<ColorPicker variant={Tools.TEXT_ANNOTATION} selectedColor={this.selectedColor} onColorClick={color => this.handleColorPicker(color, close)} />, root);
        return root;
      },
    };
  }

  handleColorPicker(newColor, closePopup) {
    this.selectedColor = newColor;
    const color = annotationTextColors[newColor];
    this.setIndicatorColor(color);
    this.jodit.execCommand('foreColor', false, color);

    closePopup();
  }

  setIndicatorColor(color) {
    const indicator = document.getElementById('text-editor-font-color-indicator');
    if (indicator) indicator.style.setProperty('--accent-color', color);
  }
}

Jodit.plugins.add('fontcolor', FontColorPlugin);
