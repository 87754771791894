import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StarIcon from '@pelckmans/business-components/icons/Star';
import StarOutlineIcon from '@pelckmans/business-components/icons/StarOutline';
import PrintIcon from '@pelckmans/business-components/icons/Print';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import { getPlayerMode } from '../../../../../../selectors/player';
import PlayerMode from '../../../../../../enums/playerMode';

function Footer() {
  const [t] = useTranslation();
  const { showOnlyFavoriteWhitepages, setShowOnlyFavoriteWhitepages } = useContext(UserSettingsContext);

  const activePlayerMode = useSelector(getPlayerMode);

  const handlePrint = () => {
    document.dispatchEvent(new CustomEvent('print-whitepage'));
  };

  return (
    <div className="pbb-footer">
      <div className="pbb-mask" />
      <div className="pbb-footer__wrapper">
        {activePlayerMode === PlayerMode.WHITEPAGE && (
          <button className="footer__button" type="button" onClick={handlePrint} title={t('drawer.toc.footer.buttons.print.tooltip')}>
            <PrintIcon />
          </button>
        )}
        <div className="footer__button-with-label">
          <span className="footer__button-label">
            {t(showOnlyFavoriteWhitepages ? 'drawer.material.footer.favorites.showAll' : 'drawer.material.footer.favorites.showOnlyFavorites')}
          </span>
          <button
            className="footer__button"
            type="button"
            title={t(showOnlyFavoriteWhitepages ? 'drawer.material.footer.favorites.showAll' : 'drawer.material.footer.favorites.showOnlyFavorites')}
            onClick={() => setShowOnlyFavoriteWhitepages(!showOnlyFavoriteWhitepages)}
            data-testid="filter-favorite-whitepages-button"
          >
            {showOnlyFavoriteWhitepages ? <StarIcon /> : <StarOutlineIcon />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
