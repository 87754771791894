import { AUTHENTICATE_SUCCESS, AUTHENTICATE_FAILURE, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE } from './actionNames';

export const authenticated = (accessToken, refreshToken) => ({
  type: AUTHENTICATE_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
  },
});

export const authenticationFailed = err => ({
  type: AUTHENTICATE_FAILURE,
  payload: {
    err,
  },
});

export const tokenRefreshed = (accessToken, refreshToken) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: {
    accessToken,
    refreshToken,
  },
});

export const tokenRefreshFailed = () => ({
  type: REFRESH_TOKEN_FAILURE,
});
