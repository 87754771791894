import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { throttle } from 'lodash';
import { useSelector } from 'react-redux';

import { getSaveTimeout } from '../utils';
import Tools from '../../../../../enums/tools';
import FabricService from '../../../services/fabric-service';
import playerMode from '../../../../../enums/playerMode';
import { getCurrentTool, getTools } from '../../../../../selectors/tools';
import MathTools from '../../book/math-tools/MathTools';

function useWhitepageDrawing({ service, whitepage, initialDrawings, viewportTransform }) {
  const drawingPaths = useRef([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledPersist = useCallback(
    throttle(
      () =>
        axios.put(whitepage.url, drawingPaths.current, {
          headers: {
            'Content-Type': 'application/json',
            'x-amz-acl': 'bucket-owner-full-control',
          },
        }),
      getSaveTimeout(),
    ),
    [whitepage.url, whitepage.id],
  );

  const selectionEraseHandler = useCallback(
    rect => {
      drawingPaths.current.push(rect.toJSON());
      service.current.markingsGroup.addWithUpdate(rect);
      throttledPersist();
      service.current.fabricCanvas.requestRenderAll();
    },
    [throttledPersist, service],
  );

  useEffect(() => {
    const fabricService = service.current;
    fabricService.addFreeDrawingListeners(path => {
      drawingPaths.current.push(path.toJSON());
    }, throttledPersist);

    return () => {
      fabricService.removeFreeDrawingListeners();
    };
  }, [throttledPersist, service]);

  useEffect(() => () => throttledPersist.flush(), [throttledPersist]);

  useEffect(() => {
    const fabricService = service.current;
    fabricService.addDragListeners(
      {
        [Tools.SELECTION_ERASER]: selectionEraseHandler,
      },
      () => {},
      () => {},
    );

    return () => {
      fabricService.removeDragListeners();
    };
  }, [service, selectionEraseHandler]);

  useEffect(() => {
    function clearWhitepage() {
      service.current.clearWhitepage();
      drawingPaths.current = [];
      axios.delete(whitepage.url);
    }

    document.addEventListener('erase-all-clicked', clearWhitepage);

    return () => {
      document.removeEventListener('erase-all-clicked', clearWhitepage);
    };
  }, [whitepage.url, service]);

  useEffect(() => {
    drawingPaths.current = [...initialDrawings];
  }, [initialDrawings]);

  useEffect(() => {
    service.current.showMarkings(drawingPaths.current, true, false);
  }, [service, initialDrawings]);

  function saveArc(position) {
    const circle = service.current.getTempCircleToPersist();

    circle.set(position);
    drawingPaths.current.push(circle.toJSON());

    service.current.markingsGroup.addWithUpdate(circle);

    throttledPersist();
  }

  const setFreeDrawingStrategy = useCallback(
    strategy => {
      // eslint-disable-next-line no-param-reassign
      service.current.fabricCanvas.freeDrawingBrush.freeDrawingStrategy = strategy;
    },
    [service],
  );

  useEffect(() => {
    service.current.fabricCanvas.set('viewportTransform', viewportTransform);
  }, [service, viewportTransform]);

  useEffect(() => {
    const vptHandler = e => {
      service.current.fabricCanvas.set('viewportTransform', e.detail);
    };

    document.addEventListener('canvas-panned', vptHandler);

    return () => {
      document.removeEventListener('canvas-panned', vptHandler);
    };
  }, [service]);

  useEffect(() => {
    service.current.renderAll();
  });

  return { saveArc, setFreeDrawingStrategy };
}

const PAGES_RENDERED = [];

export default function MarkingLayer({ whitepage, initialDrawings, viewportTransform, paper, onRefSet }) {
  const service = useRef(null);
  const currentTool = useSelector(getCurrentTool);
  const tools = useSelector(getTools);
  const containerRef = useRef();

  useEffect(() => {
    const {
      current: { clientWidth, clientHeight },
    } = containerRef;

    service.current = new FabricService('whitepage-marking-canvas', undefined, undefined, playerMode.WHITEPAGE);
    service.current.initialize(clientHeight, clientWidth);

    onRefSet(service);

    return () => {
      service.current.dispose();
    };
  }, [onRefSet]);

  useEffect(() => {
    service.current.clearBookPages();

    service.current.renderBookPage(paper, 'left', 'left');

    service.current.setBookPagesInvisible();
  }, [paper]);

  useEffect(() => {
    service.current.setCurrentTool(currentTool, { ...tools[currentTool] });
  }, [currentTool, tools]);

  const { saveArc, setFreeDrawingStrategy } = useWhitepageDrawing({ service, whitepage, initialDrawings, viewportTransform });

  useEffect(() => {
    const onResize = () => {
      service.current.setCanvasDimensions(containerRef.current.clientHeight, containerRef.current.clientWidth);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <div
        ref={containerRef}
        className={classNames('pbb-drawing-layer', {
          'pbb-drawing-layer--interactive': [Tools.PENCIL, Tools.MARKER, Tools.CLASSIC_ERASER, Tools.SELECTION_ERASER].includes(currentTool),
        })}
      >
        <canvas id="whitepage-marking-canvas" />
      </div>
      <MathTools
        bookDimensions={{ width: paper.width, height: paper.height }}
        pagesRendered={PAGES_RENDERED}
        viewPortTransform={viewportTransform}
        viewMode="whitepage"
        onDraftingCompassTempDraw={opts => {
          service.current.renderTempCircle(opts);
        }}
        onDraftingCompassFinishedDrawing={saveArc}
        setFreeDrawingStrategy={setFreeDrawingStrategy}
      />
    </>
  );
}

MarkingLayer.propTypes = {
  whitepage: PropTypes.object.isRequired,
  initialDrawings: PropTypes.array.isRequired,
  viewportTransform: PropTypes.array.isRequired,
  paper: PropTypes.object.isRequired,
  onRefSet: PropTypes.func.isRequired,
};
