import { NODE_TYPES } from './schema';

export function ListQueries() {
  return {
    queries: {
      isListItem(editor, block) {
        return block && block.type === NODE_TYPES.listItem;
      },
      getListItem(editor, block) {
        const possibleListItem = editor.value.document.getParent(block.key);
        return editor.isListItem(possibleListItem) ? possibleListItem : null;
      },
      isList(editor, block) {
        return block && [NODE_TYPES.unorderedList, NODE_TYPES.orderedList].includes(block.type);
      },
      getList(editor, block) {
        const possibleList = editor.value.document.getParent(block.key);
        return editor.isList(possibleList) ? possibleList : null;
      },
    },
  };
}
