import React, { useCallback, useMemo } from 'react';
import { number } from 'prop-types';
import { MATH_TOOL_STROKE_WIDTH } from '../../constants';

function DistanceMarkings({ pixelsPerMM, height, paddingLeft, maskWidth, maxAmountOfMM }) {
  const createMarking = useCallback(
    (position = 'top') => {
      const indicators = new Array(maxAmountOfMM + 1).fill(undefined).map((_, i) => {
        const halfOrFullCm = i === 0 || i % 5 === 0;

        const indicatorHeight = halfOrFullCm ? 3 * pixelsPerMM : 1.5 * pixelsPerMM;

        const x = i * pixelsPerMM + paddingLeft;
        const y1 = position === 'top' ? 0 : height - indicatorHeight;
        const y2 = position === 'top' ? indicatorHeight : height;

        return (
          <line key={`${x}-${y1}-${y2}`} x1={x} x2={x} y1={y1} y2={y2} stroke="currentColor" strokeWidth={halfOrFullCm ? MATH_TOOL_STROKE_WIDTH : MATH_TOOL_STROKE_WIDTH - 0.5} />
        );
      });

      return indicators;
    },
    [pixelsPerMM, height, paddingLeft, maxAmountOfMM],
  );

  const topMarkings = useMemo(() => createMarking(), [createMarking]);
  const bottomMarkings = useMemo(() => createMarking('bottom'), [createMarking]);

  return (
    <g data-testid="distance-markings">
      <g clipPath="url(#ruler-distance-markings-clip-path)">
        <g id="top-distance-markings">{topMarkings}</g>
        <g id="bottom-distance-markings">{bottomMarkings}</g>
      </g>
      <clipPath id="ruler-distance-markings-clip-path">
        <rect width={maskWidth} height={height} x={paddingLeft - 1} />
      </clipPath>
    </g>
  );
}

export default DistanceMarkings;

DistanceMarkings.propTypes = {
  pixelsPerMM: number.isRequired,
  height: number.isRequired,
  paddingLeft: number.isRequired,
  maskWidth: number.isRequired,
  maxAmountOfMM: number.isRequired,
};
