import { getCurrentModuleId } from '../selectors/digibooks';
import { getVisibleChapterNodeIds } from '../selectors/toc';
import { isTeacherAlike } from '../selectors/user';

import api from '../services/api';

import { SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS, SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS, SLIDE_SETS_SHARE, SHARED_SLIDE_SETS_FETCH_SUCCESS } from './actionNames';

function retrieveSlideSetsByNodeIdSuccess({ nodeId, slideSets }) {
  return {
    type: SLIDE_SETS_FETCH_BY_NODE_ID_SUCCESS,
    payload: {
      nodeId,
      slideSets,
    },
  };
}

function retrieveSlideSetsByNodeId(nodeId, optionalModuleId) {
  return async (dispatch, getState) => {
    const state = getState();
    const moduleId = optionalModuleId || getCurrentModuleId(state);
    const response = await api.get(`/studio/user/modules/${moduleId}/table-of-content/${nodeId}/slide-sets`, { params: { includeDescendants: true } });
    return dispatch(retrieveSlideSetsByNodeIdSuccess({ nodeId, slideSets: response.data }));
  };
}

export function retrievePublishedSlideSetsForCurrentPages() {
  return (dispatch, getState) => {
    const state = getState();
    const chapterIds = getVisibleChapterNodeIds(state) || [];
    const promises = chapterIds.map(nodeId => dispatch(retrieveSlideSetsByNodeId(nodeId)));
    return Promise.all(promises);
  };
}

export function deleteUserSlideSet(slideSet) {
  return async dispatch => {
    await api.delete(`/studio/user/modules/${slideSet.moduleId}/table-of-content/${slideSet.nodeId}/slide-set/${slideSet.id}`);
    return dispatch({
      type: SLIDE_SETS_REMOVE_SLIDE_SET_SUCCESS,
      payload: { slideSet },
    });
  };
}

export function duplicateSlideSet(slideSet) {
  return async dispatch => {
    const { data } = await api.post(`/studio/user/modules/${slideSet.moduleId}/table-of-content/${slideSet.nodeId}/slide-sets/${slideSet.id}/versions/${slideSet.versionId}/copy`);

    await dispatch(retrieveSlideSetsByNodeId(slideSet.nodeId, slideSet.moduleId));

    return data;
  };
}

function retrieveSharedSlideSets(moduleId) {
  return async dispatch => {
    const response = await api.get(`/studio/user/modules/${moduleId}/slide-sets/shared`);

    dispatch({
      type: SHARED_SLIDE_SETS_FETCH_SUCCESS,
      payload: { slideSets: response.data.data },
    });
  };
}

export function fetchSlideSets(moduleId) {
  return async (dispatch, getState) => {
    if (isTeacherAlike(getState())) {
      return dispatch(retrievePublishedSlideSetsForCurrentPages());
    }

    const isShareSlideSetEnabled = getState().featureToggles.SHARE_SLIDE_SET;
    if (!isShareSlideSetEnabled) return undefined;

    return dispatch(retrieveSharedSlideSets(moduleId));
  };
}

export function shareSlideSet(slideSetVersionId, shares) {
  return {
    type: SLIDE_SETS_SHARE,
    payload: {
      slideSetVersionId,
      shares,
    },
  };
}
