import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { func, oneOf, shape, string } from 'prop-types';
import Html from 'slate-html-serializer';
import { Value } from 'slate';
import GenericModal from '../common/GenericModal';
import dialogTypes from '../../../../../enums/dialogTypes';
import { closeDialog } from '../../../../../actions/dialog';
import { formatDate } from '../../../../../utils/intl';
import NoteEditor from '../../wysiwyg/NoteEditor';
import { RULES } from '../../wysiwyg/slate/serializerRules';
import { saveNote, deleteNote } from '../../../../../actions/notes';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import Info from './Info';
import { getTOCNodeById } from '../../../../../selectors/toc';
import Alert from '../../../../../components/alert';
import TranslationButton from '../../../../../components/translation-button';
import { getNoteById } from '../../../../../selectors/notes';
import { SLATE_MAX_LENGTH } from '../../wysiwyg/slate/schema';

const initialEditorState = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: '',
          },
        ],
      },
    ],
  },
});

const tabs = {
  INFO: 'info',
  DELETE: 'delete',
  NOTE: 'note',
};

const Modal = ({ dispatch, note, tocNode, activeTab }) => {
  const [t] = useTranslation();
  const [currentTab, setCurrentTab] = useState(tabs.NOTE);
  const serializer = useRef(null);
  const [editorState, setEditorState] = useState({ value: initialEditorState });

  const date = note.id ? formatDate(new Date(note.updatedAt)) : formatDate(new Date());
  const modalTitle = `${t('notes.note')} ${date}`;

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    serializer.current = new Html({ rules: RULES });
    if (note.note) {
      setEditorState({ value: serializer.current.deserialize(note.note) });
    }
  }, [note.note]);

  const handleClose = useCallback(() => dispatch(closeDialog(note.id, dialogTypes.NOTE_EDITOR)), [dispatch, note.id]);

  const handleSave = useCallback(() => {
    dispatch(
      saveNote({
        id: note.id,
        nodeId: tocNode.id,
        note: serializer.current.serialize(editorState.value),
      }),
    );
    handleClose();
  }, [dispatch, editorState.value, tocNode.id, handleClose, note.id]);

  const handleDelete = useCallback(() => {
    dispatch(
      deleteNote({
        noteId: note.id,
        nodeId: tocNode.id,
      }),
    );
    handleClose();
  }, [note.id, tocNode.id, dispatch, handleClose]);

  const footer = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return (
          <>
            <TranslationButton onClick={handleClose} label="notes.modal.buttons.cancel" className="pbb-btn--inverted" data-testid="cancel-btn" />
            <TranslationButton onClick={handleDelete} label="notes.modal.buttons.delete" className="pbb-btn--danger" data-testid="delete-btn" />
          </>
        );
      case tabs.INFO:
        return <TranslationButton onClick={handleClose} label="notes.modal.buttons.close" className="pbb-btn--inverted" data-testid="close-btn" />;
      case tabs.NOTE:
      default:
        return (
          <>
            <TranslationButton onClick={handleClose} label="notes.modal.buttons.cancel" className="pbb-btn--inverted" data-testid="cancel-btn" />

            <TranslationButton
              onClick={handleSave}
              label="notes.modal.buttons.save_and_exit"
              disabled={!editorState.value.document.text.length || editorState.value.document.text.length > SLATE_MAX_LENGTH}
              data-testid="save-btn"
            />
          </>
        );
    }
  }, [handleClose, editorState.value, handleSave, handleDelete, currentTab]);

  const tabBar = useMemo(
    () => (
      <Tabs onClick={setCurrentTab} activeTab={currentTab}>
        <Tab title={t('tabs.note')} id={tabs.NOTE} icon="icon-pin" />
        {note.id && <Tab title={t('tabs.info.title')} id={tabs.INFO} icon="icon-info" />}
        {note.id && <Tab title={t('tabs.delete')} id={tabs.DELETE} icon="icon-trash" />}
      </Tabs>
    ),
    [currentTab, t, note],
  );

  const tabContent = useMemo(() => {
    switch (currentTab) {
      case tabs.DELETE:
        return <Alert message={t('notes.modal.alerts.delete')} />;
      case tabs.INFO:
        return <Info note={note} tocNode={tocNode} />;
      case tabs.NOTE:
      default:
        return <NoteEditor editorState={editorState} setEditorState={setEditorState} />;
    }
  }, [currentTab, t, note, tocNode, editorState]);

  return (
    <GenericModal icon="icon-pin" close={handleClose} title={modalTitle} footerContent={footer} tabBar={tabBar} contentModifier="pbb-modal-note">
      {tabContent}
    </GenericModal>
  );
};

Modal.propTypes = {
  note: shape({
    id: string,
    nodeId: string,
    moduleId: string,
    note: string,
    createdAt: string,
    updatedAt: string,
  }),
  dispatch: func.isRequired,
  tocNode: shape({
    id: string.isRequired,
  }).isRequired,
  activeTab: oneOf([tabs.NOTE, tabs.INFO, tabs.DELETE]),
};

Modal.defaultProps = {
  note: {},
  activeTab: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  tocNode: getTOCNodeById(state)(ownProps.tocNodeId),
  note: getNoteById(state, ownProps.noteId),
});

export default connect(mapStateToProps)(Modal);
