import update from 'immutability-helper';
import { normalize } from 'normalizr';
import {
  WHITEPAGES_DELETE_SUCCESS,
  WHITEPAGES_FETCH_BY_NODEID_SUCCESS,
  WHITEPAGES_ADD_SUCCESS,
  WHITEPAGES_FETCH_SINGLE_SUCCESS,
  WHITEPAGES_SAVE_SUCCESS,
  WHITEPAGES_SAVE_OPTIMISTIC_UPDATE,
  RETRIEVE_FAVORITES_SUCCESS,
  WHITEPAGES_REMOVE_FAVORITE_SUCCESS,
  WHITEPAGES_ADD_FAVORITE_SUCCESS,
} from '../actions/actionNames';
import { whitepage as whitepageSchema } from '../schemas';

const initialState = {
  byId: {},
  byTocNodeId: {},
  favorites: {
    byModuleId: {},
  },
};

const whitepagesReducer = (state = initialState, action) => {
  switch (action && action.type) {
    case WHITEPAGES_FETCH_BY_NODEID_SUCCESS: {
      const { whitepages } = action.payload;

      const normalized = normalize(whitepages, [whitepageSchema]);

      const tocNodeIds = Array.from(new Set(whitepages.map(x => x.nodeId)));

      const byTocNodeId = tocNodeIds.reduce((acc, tocNodeId) => {
        acc[tocNodeId] = whitepages.filter(x => x.nodeId === tocNodeId).map(x => x.id);

        return acc;
      }, {});

      return update(state, {
        byId: { $merge: normalized.entities.whitepages || {} },
        byTocNodeId: { $merge: byTocNodeId },
      });
    }

    case WHITEPAGES_ADD_SUCCESS: {
      const { whitepage } = action.payload;

      const updateWhitepagesForNodeId = (whitepagesForNodeId = []) => {
        if (whitepagesForNodeId.some(x => x === whitepage.id)) return whitepagesForNodeId;
        return update(whitepagesForNodeId, { $push: [whitepage.id] });
      };

      return update(state, {
        byId: { [whitepage.id]: { $set: whitepage } },
        byTocNodeId: {
          [whitepage.nodeId]: updateWhitepagesForNodeId,
        },
      });
    }

    case WHITEPAGES_SAVE_OPTIMISTIC_UPDATE:
    case WHITEPAGES_SAVE_SUCCESS: {
      const { whitepage } = action.payload;

      return update(state, {
        byId: { [whitepage.id]: { $set: whitepage } },
      });
    }

    case WHITEPAGES_DELETE_SUCCESS: {
      const { whitepageId, nodeId } = action.payload;

      const whitepagesForNodeId = state.byTocNodeId[nodeId].filter(x => x !== whitepageId);

      if (whitepagesForNodeId.length) {
        return update(state, {
          byId: { $unset: [whitepageId] },
          byTocNodeId: {
            [nodeId]: { $set: whitepagesForNodeId },
          },
        });
      }

      return update(state, {
        byId: { $unset: [whitepageId] },
        byTocNodeId: { $unset: [nodeId] },
      });
    }

    case WHITEPAGES_FETCH_SINGLE_SUCCESS: {
      const { whitepage } = action.payload;

      return update(state, {
        byId: {
          $merge: { [whitepage.id]: whitepage },
        },
      });
    }

    case RETRIEVE_FAVORITES_SUCCESS: {
      const { favorites, moduleId } = action.payload;

      return update(state, {
        favorites: {
          byModuleId: {
            [moduleId]: { $set: favorites ? favorites.whitepages : [] },
          },
        },
      });
    }

    case WHITEPAGES_ADD_FAVORITE_SUCCESS: {
      const { whitepageId, moduleId } = action.payload;
      if (state.favorites.byModuleId[moduleId].indexOf(whitepageId) < 0) {
        return update(state, {
          favorites: {
            byModuleId: {
              [moduleId]: { $push: [whitepageId] },
            },
          },
        });
      }
      return state;
    }

    case WHITEPAGES_REMOVE_FAVORITE_SUCCESS: {
      const { whitepageId, moduleId } = action.payload;
      const idx = state.favorites.byModuleId[moduleId].indexOf(whitepageId);
      if (idx > -1) {
        return update(state, {
          favorites: {
            byModuleId: {
              [moduleId]: { $splice: [[idx, 1]] },
            },
          },
        });
      }
      return state;
    }
    default:
      return state;
  }
};

export default whitepagesReducer;
