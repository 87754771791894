import viewmodes from '../enums/viewmode';

/**
 * @description Given a pagenumber and the total amount of pages, calculate the corresponding spread.
 * @param {number} totalPages
 * @param {number} [page]
 *
 * @returns {[number?, number?]}
 */
export function calculateSpreadForPage(totalPages, page) {
  const cover = 0;
  const firstPage = 1;
  const backCover = totalPages + 1;

  if ([cover, firstPage, backCover].includes(page)) return [page];
  if (!page) return [];

  if (page % 2 === 0) {
    if (page === totalPages) return [page];
    return [page, page + 1];
  }

  return [page - 1, page];
}

/**
 * @description Given a valid spread, hide the non visible pages for that spread.
 * @param {[number?, number?]} spread
 * @param {number} currentPage
 * @param {"page"|"spread"} viewMode
 */
export function getVisiblePagesInSpread(spread, currentPage, viewMode) {
  if (currentPage === undefined) return undefined;
  if (!spread.includes(currentPage)) throw new Error(`Page ${currentPage} is not in given spread`);

  if (viewMode === viewmodes.PAGE) return spread.map(page => (page === currentPage ? page : null));

  return spread;
}
