import get from 'get-value';

export const getIsReady = state => get(state, ['player', 'isReady']);

export const getInitialToolbarPosition = state => get(state, ['player', 'initialToolbarPosition']);

export const getPlayerMode = state => get(state, ['player', 'playerMode']);

export const getCurrentWhitepage = state => {
  const currentWhitePageId = get(state, ['player', 'whitepageId']);

  return get(state, ['whitepages', 'byId', currentWhitePageId]);
};

export const getIsManualVisible = state => get(state, ['player', 'isManualVisible']);

export const getIsPopUpManualVisible = state => get(state, ['player', 'isPopUpManualVisible']);

export const getIsRendered = state => get(state, ['player', 'isRendered']);

export const getIsSolutionsPageVisible = state => get(state, ['player', 'isSolutionsPageVisible']);
