/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

// eslint-disable-next-line react/prop-types
export const element = TagName => ({ children, attributes }) => <TagName {...attributes}>{children}</TagName>;

const marks = {
  bold: element('strong'),
  italic: element('em'),
};

const blocks = {
  ol: element('ol'),
  ul: element('ul'),
  li: element('li'),
};

export { marks, blocks };
