import React from 'react';
import { number } from 'prop-types';
import { MATH_TOOL_STROKE_WIDTH } from '../../constants';

export default function Backdrop({ height, width }) {
  const radius = Math.ceil(height / 12);

  return (
    <g data-drag data-testid="backdrop" id="ruler-backdrop">
      <rect
        width={width - MATH_TOOL_STROKE_WIDTH * 2}
        height={height}
        fill="rgba(217,238,243, .75)"
        stroke="currentColor"
        strokeWidth={MATH_TOOL_STROKE_WIDTH}
        rx={radius}
        ry={radius}
        x={MATH_TOOL_STROKE_WIDTH / 2}
      />
    </g>
  );
}

Backdrop.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
};
