import React, { useRef, useEffect, useContext, useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { shape, string, number, object, bool, func, array } from 'prop-types';
import { useTranslation } from 'react-i18next';

import withDimensions from '../../../../hocs/with-dimensions';
import FabricService from '../../services/fabric-service';
import { getCurrentTool, getTools } from '../../../../selectors/tools';
import { setCurrentTool } from '../../../../actions/tools';
import { disableZoomToFitMode, setZoomLevel } from '../../../../actions/navigation';
import { getZoomLevel, getZoomToFitMode } from '../../../../selectors/navigation';
import { UserSettingsContext } from '../../context/user-settings-context';
import Tools from '../../../../enums/tools';
import useWhitepagePrinting from './hooks/use-whitepage-printing';
import { setPlayerMode } from '../../../../actions/playerMode';
import playerMode from '../../../../enums/playerMode';
import TextAnnotations from '../../../../components/text-annotations/text-annotations';
import { useWhitepageAnnotations } from './hooks/use-whitepage-annotations';
import MarkingLayer from './components/marking-layer';
import { WHITEPAGE_HEIGHT, WHITEPAGE_WIDTH } from './constants';
import { LANGUAGE } from '../../../../constants/constants';

/*
  This function is used to filter out annotations that are on top and below the whitepage.
  Annotations that are on top of the whitepage will shift to the bottom of the whitepage when printed (library issue).
  Annotations that are below the whitepage would add another page to the print.
*/
function getAnnotationsToPrint(annotations) {
  return annotations.filter(x => x.top < WHITEPAGE_HEIGHT && x.top > 0);
}

function useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, canvasWidth, canvasHeight) {
  useEffect(() => {
    if (zoomToFitMode) fabricService.current.scaleCanvasToFit(fabricService.current.zoomLevel);
  }, [fabricService, zoomToFitMode]);

  useEffect(() => {
    fabricService.current.handleCanvasResize({ width: canvasWidth, height: canvasHeight });
  }, [fabricService, canvasWidth, canvasHeight]);

  useEffect(() => {
    fabricService.current.setZoom(zoomLevel);
  }, [fabricService, zoomLevel]);
}

function useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewPort) {
  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (zoomLevel === 1 && zoomToFitMode)
      fabricService.current.shiftViewportForDrawer(openDrawer, () => {
        updateViewPort();
        fabricService.current.fabricCanvas.requestRenderAll();
      });
  }, [fabricService, openDrawer, zoomLevel, zoomToFitMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fabricService.current.setDrawerOpenSide(openDrawer);
  }, [fabricService, openDrawer]);
}

function Whitepage(props) {
  const {
    whitepage,
    currentTool,
    dimensions: { width, height },
    tools,
    zoomToFitMode,
    zoomLevel,
    paper,
    initialDrawings,
    initialAnnotations,
    dispatch,
  } = props;

  const { sidebarAnchor, isSidebarOpen } = useContext(UserSettingsContext);
  const fabricService = useRef(null);
  const [fabricServiceMarkings, setFabricServiceMarkings] = useState(null);

  const [viewportTransform, setViewportTransform] = useState();

  const { annotations, addAnnotation, editAnnotation, selectedAnnotation, setSelectedAnnotation, removeAnnotation } = useWhitepageAnnotations(whitepage, initialAnnotations);

  const printLoader = useWhitepagePrinting({
    currentTool,
    fabricService,
    fabricServiceMarkings,
    annotations: getAnnotationsToPrint(annotations),
    paper,
    whitepage,
  });

  const [t] = useTranslation();

  const zoomSelectionHandler = useCallback(
    nextZoom => {
      dispatch(setZoomLevel(nextZoom));
      dispatch(setCurrentTool(Tools.POINTER));
    },
    [dispatch],
  );

  const disableZoomToFit = useCallback(() => dispatch(disableZoomToFitMode()), [dispatch]);

  const openDrawer = isSidebarOpen ? sidebarAnchor : undefined;

  useEffect(() => {
    fabricService.current = new FabricService('white-page-canvas', undefined, undefined, playerMode.WHITEPAGE);
    fabricService.current.initialize(WHITEPAGE_HEIGHT, WHITEPAGE_WIDTH);

    return () => {
      fabricService.current.dispose();
    };
  }, []);

  const updateViewport = useCallback(() => {
    const fabricVpt = fabricService.current.getViewportTransform();

    if (!viewportTransform || viewportTransform.some((item, i) => item !== fabricVpt[i])) {
      setViewportTransform([...fabricVpt]);
    }
  }, [viewportTransform]);

  useEffect(() => {
    fabricService.current.addDragListeners(
      {
        [Tools.ZOOM_SELECT]: zoomSelectionHandler,
      },
      updateViewport,
      () => zoomToFitMode && disableZoomToFit(),
    );

    return () => {
      fabricService.current.removeDragListeners();
    };
  }, [zoomSelectionHandler, disableZoomToFit, zoomToFitMode, updateViewport]);

  useEffect(() => {
    function pinchHandler(zoomFactor) {
      dispatch(setZoomLevel(zoomFactor));
    }
    fabricService.current.addPinchListeners(pinchHandler);
  }, [dispatch]);

  useEffect(() => {
    fabricService.current.renderBookPage(paper, 'left', 'left');
  }, [paper]);

  useEffect(() => {
    fabricService.current.addWhitepageHeader(whitepage.title, () => dispatch(setPlayerMode(playerMode.BOOK)), t('whitepages.modal.buttons.close'));
  }, [dispatch, whitepage.title, t]);

  useCanvasZoom(fabricService, zoomLevel, zoomToFitMode, width, height);

  useCanvasSidebarPositioning(fabricService, openDrawer, zoomLevel, zoomToFitMode, updateViewport);

  useEffect(() => {
    if (fabricService.current) {
      fabricService.current.renderAll();
      updateViewport();
    }
  });

  useEffect(() => {
    fabricService.current.setCurrentTool(currentTool, { ...tools[currentTool] });
  }, [currentTool, tools]);

  const layers = useMemo(
    () =>
      viewportTransform
        ? [
            <TextAnnotations
              key={`text-annotations-${whitepage.id}`}
              annotations={annotations}
              addAnnotation={addAnnotation}
              editAnnotation={editAnnotation}
              selectedAnnotation={selectedAnnotation}
              setSelectedAnnotationId={setSelectedAnnotation}
              removeAnnotation={removeAnnotation}
              viewportTransform={viewportTransform}
              pageHeight={paper.height}
              pageWidth={paper.width}
              isSinglePage
              isRightPage={false}
              isStandalonePage
              isSolutionsPageVisible={false}
              currentTool={currentTool}
              sidebarAnchor={sidebarAnchor}
              lang={LANGUAGE}
            />,
            <MarkingLayer
              key={`markings-${whitepage.id}`}
              initialDrawings={initialDrawings}
              viewportTransform={viewportTransform}
              whitepage={whitepage}
              paper={paper}
              onRefSet={setFabricServiceMarkings}
            />,
          ]
        : [],
    [
      addAnnotation,
      annotations,
      currentTool,
      editAnnotation,
      initialDrawings,
      paper,
      removeAnnotation,
      selectedAnnotation,
      setSelectedAnnotation,
      sidebarAnchor,
      viewportTransform,
      whitepage,
    ],
  );

  return (
    <div className="canvas-wrapper">
      <div>
        <canvas id="white-page-canvas" data-testid="white-page-canvas" />
        {currentTool === Tools.TEXT_ANNOTATION ? layers.reverse() : layers}
      </div>
      {printLoader}
    </div>
  );
}

const mapStateToProps = state => ({
  currentTool: getCurrentTool(state),
  tools: getTools(state),
  zoomToFitMode: getZoomToFitMode(state),
  zoomLevel: getZoomLevel(state),
});

Whitepage.propTypes = {
  whitepage: shape({
    id: string.isRequired,
    url: string.isRequired,
    annotationsUrl: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  currentTool: string.isRequired,
  dimensions: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  tools: object.isRequired,
  zoomToFitMode: bool.isRequired,
  zoomLevel: number.isRequired,
  dispatch: func.isRequired,
  paper: object.isRequired,
  initialDrawings: array,
  initialAnnotations: array,
};

Whitepage.defaultProps = {
  initialDrawings: undefined,
  initialAnnotations: undefined,
};

export const ConnectedWhitepage = connect(mapStateToProps)(Whitepage);

export default withDimensions(ConnectedWhitepage);
