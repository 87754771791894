import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import RectangleTransparentIcon from '@pelckmans/business-components/icons/RectangleTransparent';
import Colors from '../../../../../enums/colors';

const buildColorClassName = tool => color => `pbb-${tool}-color pbb-${tool}-color--${color}`;

const ColorPicker = ({ onColorClick, variant, selectedColor }) => {
  const [activeColor, setActiveColor] = useState(selectedColor);
  const getColorClassName = buildColorClassName(variant);
  const { t } = useTranslation();

  const handleClick = color => {
    setActiveColor(color);
    onColorClick(color);
  };

  return (
    <div className={classNames('pbb-menu__colors', `pbb-menu__colors--${variant}`)}>
      {Object.values(Colors).map(color => (
        <button
          onClick={() => handleClick(color)}
          key={color}
          type="button"
          className={classNames(getColorClassName(color), 'color-picker__button', { 'color-picker__button--active': activeColor === color })}
          title={t(`colors.${color}`)}
        >
          <span className="indicator" />
        </button>
      ))}
      <button
        type="button"
        title={t('colors.transparent')}
        key="transparent"
        onClick={() => handleClick('transparent')}
        className={classNames(getColorClassName('transparent'), 'color-picker__button', { 'color-picker__button--active': activeColor === 'transparent' })}
      >
        <RectangleTransparentIcon />
      </button>
    </div>
  );
};

ColorPicker.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  selectedColor: string.isRequired,
};

export default ColorPicker;
