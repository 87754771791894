import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

export const SlideBackButton = ({ onClick }) => {
  const [t] = useTranslation();

  return (
    <button data-testid="button-close" className="pbb-slider-back__back" type="button" onClick={onClick} title={t('slider.hide')}>
      <i data-testid="icon" className="icon-bb-arrow-left" />
    </button>
  );
};

SlideBackButton.propTypes = {
  onClick: func.isRequired,
};

export default SlideBackButton;
