import React from 'react';
import { shape, string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EditableTextField } from '@pelckmans/business-components/components/editable-text-field';
import { MaterialIcon } from '@pelckmans/business-components/components/material-icon';
import { formatDate } from '../../../../../utils/intl';
import TocNodeTitle from '../../sidebar/drawer/tocnode-title';
import PaperType from '../../../../../enums/paperType';
import { saveWhitepage } from '../../../../../actions/whitepages';

const WhitePageInfo = ({ whitepage, tocNode, dispatch }) => {
  const [t] = useTranslation();

  const formattedUpdatedAt = formatDate(new Date(whitepage.updatedAt));
  const updateWhitepage = data => dispatch(saveWhitepage(whitepage, data));

  const updatePaperType = paperType => updateWhitepage({ paperType });
  const updateTitle = title => updateWhitepage({ title });

  return (
    <div className="pbb-modal__info-wrapper pbb-modal__info-wrapper--no-edit" data-testid="wrapper-info">
      <div className="pbb-modal__type">
        <div className="pbb-modal__type-icon">
          <MaterialIcon materialType="whitepage" paperType={whitepage.paperType} />
        </div>
      </div>
      <div className="pbb-modal__info">
        <div className="pbb-modal__title-wrapper">
          <EditableTextField initialValue={whitepage.title} onSave={updateTitle} title={t('whitepages.modal.info.edit_title')} minLength={1} maxLength={100} />
        </div>
        <table className="pbb-modal__specs">
          <tbody>
            <tr>
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.createdAt')}</td>
              <td className="pbb-modal__specs-info">{formatDate(new Date(whitepage.createdAt))}</td>
            </tr>
            <tr data-testid="updated-at-info">
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.updatedAt')}</td>
              <td className="pbb-modal__specs-info">{formattedUpdatedAt}</td>
            </tr>
            <tr>
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.linkedTo')}</td>
              <td className="pbb-modal__specs-info">
                <TocNodeTitle tocNode={tocNode} />
              </td>
            </tr>
            <tr>
              <td className="pbb-modal__specs-title">{t('tabs.info.labels.paperType')}</td>
              <td className="pbb-modal__specs-info">
                <span className="pbb-custom-select pbb-custom-select--sm pbb-custom-select--light-blue">
                  <select data-testid="select-whitepage-papertypes" defaultValue={whitepage.paperType} onChange={e => updatePaperType(e.target.value)}>
                    {Object.values(PaperType).map(type => (
                      <option key={type} value={type} data-testid={`papertype-${type}`}>
                        {t(`drawer.whitepages.types.${type}`)}
                      </option>
                    ))}
                  </select>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

WhitePageInfo.propTypes = {
  whitepage: shape({
    createdAt: string.isRequired,
    updatedAt: string,
    paperType: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  tocNode: shape({
    id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  dispatch: func.isRequired,
};

export default connect()(WhitePageInfo);
