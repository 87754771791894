import React from 'react';
import classnames from 'classnames';
import { string, bool, func } from 'prop-types';

const Tab = ({ id, icon, title, isActive, onClick }) => (
  <li
    data-testid={`${id}-li`}
    className={classnames('pbb-modal__nav-item', {
      'pbb-modal__nav-item--active': isActive,
    })}
    role="presentation"
    onClick={e => {
      e.preventDefault();
      onClick(id);
    }}
  >
    <a className="pbb-modal__nav-link" href="#info">
      {icon && <i data-testid={`${id}-i`} className={icon} />}
      {title}
    </a>
  </li>
);

Tab.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  icon: string,
  // Coming from parent
  onClick: func,
  isActive: bool,
};

Tab.defaultProps = {
  icon: '',
  isActive: false,
  onClick: () => {},
};

export default Tab;
