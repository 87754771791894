import React from 'react';
import { number } from 'prop-types';

export default function DegreesIndicator({ xPos, yPos, radius, fontSize, degrees }) {
  return (
    <g data-testid="degrees-indicator">
      <circle cx={xPos} cy={yPos} r={radius} fill="#fff" />
      <text x={xPos} y={yPos} textAnchor="middle" fill="currentColor" fontSize={fontSize} dy="0.3em">
        {degrees}&deg;
      </text>
    </g>
  );
}

DegreesIndicator.propTypes = {
  xPos: number.isRequired,
  yPos: number.isRequired,
  radius: number.isRequired,
  fontSize: number.isRequired,
  degrees: number.isRequired,
};
