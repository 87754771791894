import React from 'react';
import classNames from 'classnames';
import { string, node, bool, func } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const CustomRadioButton = ({ children, className, name, value, checked, onChange }) => {
  const key = `${value}`;
  return (
    <label className={classNames('radio', className)} htmlFor={key} key={key}>
      {children}
      <input type="radio" name={name} id={key} value={value} checked={checked} onChange={onChange} />
      <span className="checkround" />
    </label>
  );
};

CustomRadioButton.propTypes = {
  children: node.isRequired,
  value: string.isRequired,
  checked: bool.isRequired,
  name: string.isRequired,
  className: string,
  onChange: func.isRequired,
};

CustomRadioButton.defaultProps = {
  className: undefined,
};
