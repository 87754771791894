import { PORTAAL_URL, STUDIO_GROUP } from '../constants/constants';
import { join } from './url';

const getGroupsName = () => {
  switch (STUDIO_GROUP) {
    case 'SO':
      return 'mijn-klassen';
    case 'ES':
      return 'mes-groupes';
    case 'VO':
      return 'mijn-cursussen';
    default:
      return 'mijn-klassen';
  }
};

export default function getMyClassesLink() {
  return join(PORTAAL_URL, getGroupsName());
}
