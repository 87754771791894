import get from 'get-value';
import { getCurrentDigibook } from './digibooks';
import ViewMode from '../enums/viewmode';

export const getViewMode = state => get(state, ['navigation', 'viewMode']);
export const getCurrentPageNumber = state => get(state, ['navigation', 'currentPage']);
export const getClickedTocNode = state => get(state, ['navigation', 'clickedTocNode']);
export const getZoomLevel = state => get(state, ['navigation', 'zoomLevel']);
export const getZoomToFitMode = state => get(state, ['navigation', 'zoomToFitMode']);

export const canGotoNextPage = state => {
  const currentPage = getCurrentPageNumber(state);
  const digibook = getCurrentDigibook(state);
  const viewmode = getViewMode(state);

  if (digibook && digibook.totalPages) {
    if (digibook.backCover) {
      return currentPage <= digibook.totalPages;
    }
    if (viewmode === ViewMode.SPREAD && currentPage > 1) {
      return currentPage < digibook.totalPages - 1;
    }
    return currentPage < digibook.totalPages;
  }
  return false;
};

export const canGotoPreviousPage = state => {
  const currentPage = getCurrentPageNumber(state);
  const digibook = getCurrentDigibook(state);
  if (digibook && digibook.cover) {
    return currentPage > 0;
  }
  return currentPage > 1;
};

export const canToggleSpreadPageMode = state => {
  const currentPageNumber = getCurrentPageNumber(state);
  const digibook = getCurrentDigibook(state);

  if (digibook) {
    const isCover = currentPageNumber === 0;
    const isFirstPage = currentPageNumber === 1;
    const isLastPage = currentPageNumber === digibook.totalPages;
    const isBackCover = currentPageNumber === digibook.totalPages + 1;

    const hasEvenAmountOfPages = digibook.totalPages % 2 === 0; // when book has even page count, last page is single page

    if (isCover || isFirstPage || isBackCover || (isLastPage && hasEvenAmountOfPages)) return false;
    return true;
  }
  return false;
};
