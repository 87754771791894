/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */

import Tools from '../../../../../../enums/tools';
import { degreesToRadians, findNearestPointOnSegment, isPointAboveLine, isPointInRectangle, rotatePoint } from '../utils';

/**
 * interface for drawing with math tools
 * {
 *  clampPointer()
 *  initInfo()
 *  reset()
 *  shouldCompletePath()
 * }
 */

export default class SetSquareFreeDrawingStrategy {
  _viewportTransform;

  _pixelsPerMM;

  _position;

  _setSquareElement;

  _rotationInDegrees;

  _rotationPoint;

  _drawingLines = { top: undefined, right: undefined, left: undefined };

  _clampingRectangles = { top: undefined, right: undefined, left: undefined };

  _currentClampRect;

  constructor(viewportTransform, setSquareElement) {
    this._setSquareElement = setSquareElement || document.getElementById(Tools.SET_SQUARE);
    if (!this._setSquareElement) throw new Error('Cannot use set square drawing strategy when set square is not rendered');
    this._viewportTransform = viewportTransform;
    this._rotationInDegrees = Number(this._setSquareElement.dataset.angle);
  }

  initInfo(pencilWidth) {
    const mathToolsWrapper = document.getElementById('math-tools');
    const top = Number(mathToolsWrapper.dataset.top);
    const left = Number(mathToolsWrapper.dataset.left);
    const width = this._setSquareElement.width.baseVal.value;
    const height = this._setSquareElement.height.baseVal.value;
    const pixelsPerMM = Number(mathToolsWrapper.dataset.pixelsPerMm);
    const translateX = Number(this._setSquareElement.dataset.translateX);

    this._rotationInDegrees = Number(this._setSquareElement.dataset.angle);

    this._setPosition({ top, left, width, height }, translateX);
    this._setRotationPoint();

    this._setClampingRectangles(pixelsPerMM, degreesToRadians(this._rotationInDegrees));
    this._setDrawingLines(degreesToRadians(this._rotationInDegrees), pencilWidth);
  }

  clampPointer(pointer) {
    const prevClampRect = this._currentClampRect;
    this._setCurrentClampingRect(pointer);

    // used to draw around corner of 45 degree angle of set square
    if (prevClampRect === this._clampingRectangles.left && this._currentClampRect === this._clampingRectangles.right) {
      return this._drawingLines.right[1];
    }
    if (prevClampRect === this._clampingRectangles.right && this._currentClampRect === this._clampingRectangles.left) {
      return this._drawingLines.left[1];
    }

    const drawingLine = this._getDrawLine();
    if (drawingLine) {
      return findNearestPointOnSegment(pointer, drawingLine);
    }

    return pointer;
  }

  reset() {
    this._currentClampRect = undefined;
  }

  shouldCompletePath(event, canvas) {
    return event.target !== canvas && !this._currentClampRect;
  }

  _setPosition({ top, left, width, height }, translateX) {
    const [scale, , , , leftOffset, topOffset] = this._viewportTransform;

    const setSquareLeft = (left - leftOffset) / scale + translateX;
    const setSquareRight = (left - leftOffset) / scale + width + translateX;
    const setSquareTop = (top - topOffset) / scale;
    const setSquareBottom = (top - topOffset) / scale + height;

    this._position = { top: setSquareTop, left: setSquareLeft, right: setSquareRight, bottom: setSquareBottom };
  }

  _setRotationPoint() {
    const { left, top, right } = this._position;

    this._rotationPoint = { x: left + (right - left) / 2, y: top };
  }

  _setClampingRectangles(pixelsPerMM, rotationInRadians) {
    const clampHeight = 10 * pixelsPerMM;
    const { top, left, bottom, right } = this._position;

    const lengthHypotenuse = Math.sqrt(((right - left) / 2) ** 2 + (bottom - top) ** 2);

    const topClampRectNoRotation = [
      { x: left, y: top - clampHeight },
      { x: right, y: top - clampHeight },
      { x: right, y: top },
      { x: left, y: top },
    ];
    const topClampRect = topClampRectNoRotation.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const rightVertex = { x: right, y: top };
    const rightClampRectRotationInRad = degreesToRadians(135);
    const rightClampRectNoRotation = [
      rotatePoint({ x: right, y: top - clampHeight }, rightClampRectRotationInRad, rightVertex),
      rotatePoint({ x: right + lengthHypotenuse, y: top - clampHeight }, rightClampRectRotationInRad, rightVertex),
      rotatePoint({ x: right + lengthHypotenuse, y: top }, rightClampRectRotationInRad, rightVertex),
      rightVertex,
    ];
    const rightClampRect = rightClampRectNoRotation.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const leftVertex = { x: left, y: top };
    const leftClampRectRotationInRad = degreesToRadians(45);
    const leftClampRectNoRotation = [
      leftVertex,
      rotatePoint({ x: left + lengthHypotenuse, y: top }, leftClampRectRotationInRad, leftVertex),
      rotatePoint({ x: left + lengthHypotenuse, y: top + clampHeight }, leftClampRectRotationInRad, leftVertex),
      rotatePoint({ x: left, y: top + clampHeight }, leftClampRectRotationInRad, leftVertex),
    ];
    const leftClampRect = leftClampRectNoRotation.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    this._clampingRectangles = {
      top: topClampRect,
      right: rightClampRect,
      left: leftClampRect,
    };
  }

  _setDrawingLines(rotationInRadians, pencilWidth) {
    const halfOfPencilWidth = pencilWidth / 2;
    const { top, left, bottom, right } = this._position;
    const lengthHypotenuse = Math.sqrt(((right - left) / 2) ** 2 + (bottom - top) ** 2);

    const topDrawLineNoRotation = [
      { x: left, y: top - halfOfPencilWidth },
      { x: right, y: top - halfOfPencilWidth },
    ];
    const topDrawLine = topDrawLineNoRotation.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const rightVertex = { x: right, y: top };
    const rightDrawLineRotInRad = degreesToRadians(135);
    const rightDrawLineNoRotation = [
      rotatePoint({ x: right, y: top - halfOfPencilWidth }, rightDrawLineRotInRad, rightVertex),
      rotatePoint({ x: right + lengthHypotenuse, y: top - halfOfPencilWidth }, rightDrawLineRotInRad, rightVertex),
    ];
    const rightDrawLine = rightDrawLineNoRotation.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    const leftVertex = { x: left, y: top };
    const leftDrawLineRotInRad = degreesToRadians(45);
    const leftDrawLineNoRot = [
      rotatePoint({ x: left, y: top + halfOfPencilWidth }, leftDrawLineRotInRad, leftVertex),
      rotatePoint({ x: left + lengthHypotenuse, y: top + halfOfPencilWidth }, leftDrawLineRotInRad, leftVertex),
    ];
    const leftDrawLine = leftDrawLineNoRot.map(point => rotatePoint(point, rotationInRadians, this._rotationPoint));

    this._drawingLines = { top: topDrawLine, right: rightDrawLine, left: leftDrawLine };
  }

  _setCurrentClampingRect(pointer) {
    if (!this._currentClampRect) {
      if (isPointInRectangle(pointer, this._clampingRectangles.top)) this._currentClampRect = this._clampingRectangles.top;
      else if (isPointInRectangle(pointer, this._clampingRectangles.right)) this._currentClampRect = this._clampingRectangles.right;
      else if (isPointInRectangle(pointer, this._clampingRectangles.left)) this._currentClampRect = this._clampingRectangles.left;
    } else if (this._currentClampRect === this._clampingRectangles.top) {
      const topLine = [this._clampingRectangles.top[2], this._clampingRectangles.top[3]];
      const leftLine = [this._clampingRectangles.top[0], this._clampingRectangles.top[3]];
      const rightLine = [this._clampingRectangles.top[1], this._clampingRectangles.top[2]];

      const isBelowTopLine = !isPointAboveLine(pointer, topLine, this._rotationInDegrees);
      const isBelowLeftLine = !isPointAboveLine(pointer, leftLine, (this._rotationInDegrees + 270) % 360);
      const isAboveRightLine = isPointAboveLine(pointer, rightLine, (this._rotationInDegrees + 270) % 360);
      const keepClamping = isBelowTopLine && isBelowLeftLine && isAboveRightLine;

      if (keepClamping) return;
      if (!isPointInRectangle(pointer, this._clampingRectangles.top)) this._currentClampRect = undefined;
    } else if (this._currentClampRect === this._clampingRectangles.right) {
      const topLine = [this._clampingRectangles.right[2], this._clampingRectangles.right[3]];
      const leftLine = [this._clampingRectangles.right[0], this._clampingRectangles.right[3]];
      const rightLine = [this._clampingRectangles.right[1], this._clampingRectangles.right[2]];

      const isBelowTopLine = !isPointAboveLine(pointer, topLine, (this._rotationInDegrees + 135) % 360);
      const isBelowLeftLine = !isPointAboveLine(pointer, leftLine, (this._rotationInDegrees + 45) % 360);
      const isAboveRightLine = isPointAboveLine(pointer, rightLine, (this._rotationInDegrees + 45) % 360);

      const keepClamping = isBelowTopLine && isBelowLeftLine && isAboveRightLine;
      if (keepClamping) return;
      if (!isPointInRectangle(pointer, this._clampingRectangles.right)) {
        if (isPointInRectangle(pointer, this._clampingRectangles.left)) this._currentClampRect = this._clampingRectangles.left;
        else this._currentClampRect = undefined;
      }
    } else if (this._currentClampRect === this._clampingRectangles.left) {
      const topLine = [this._clampingRectangles.left[0], this._clampingRectangles.left[1]];
      const leftLine = [this._clampingRectangles.left[0], this._clampingRectangles.left[3]];
      const rightLine = [this._clampingRectangles.left[1], this._clampingRectangles.left[2]];

      const isAboveTopLine = isPointAboveLine(pointer, topLine, (this._rotationInDegrees + 45) % 360);
      const isBelowRightLine = !isPointAboveLine(pointer, rightLine, (this._rotationInDegrees + 135) % 360);
      const isAboveLeftLine = isPointAboveLine(pointer, leftLine, (this._rotationInDegrees + 135) % 360);

      const keepClamping = isAboveTopLine && isBelowRightLine && isAboveLeftLine;
      if (keepClamping) return;
      if (!isPointInRectangle(pointer, this._clampingRectangles.left)) {
        if (isPointInRectangle(pointer, this._clampingRectangles.right)) this._currentClampRect = this._clampingRectangles.right;
        else this._currentClampRect = undefined;
      }
    }
  }

  _getDrawLine() {
    switch (this._currentClampRect) {
      case this._clampingRectangles.top:
        return this._drawingLines.top;
      case this._clampingRectangles.right:
        return this._drawingLines.right;
      case this._clampingRectangles.left:
        return this._drawingLines.left;
      default:
        return undefined;
    }
  }
}
