import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { oneOf } from 'prop-types';
import ShowMarkingsIcon from '@pelckmans/business-components/icons/EyeInSquare';
import HideMarkingsIcon from '@pelckmans/business-components/icons/EyeStrikethroughInSquare';

import { UserSettingsContext } from '../../../context/user-settings-context';
import PlayerMode from '../../../../../enums/playerMode';
import ToolsEnum from '../../../../../enums/tools';
import { setCurrentTool } from '../../../../../actions/tools';

const { POINTER } = ToolsEnum;

export default function ToggleMarkingsButton({ playerMode }) {
  const { t } = useTranslation();
  const { showMarkings, setShowMarkings } = useContext(UserSettingsContext);

  const dispatch = useDispatch();

  const toggleMarkings = () => {
    dispatch(setCurrentTool(POINTER));
    setShowMarkings(!showMarkings);
  };

  return (
    <button
      type="button"
      onClick={toggleMarkings}
      title={showMarkings ? t('dock.buttons.hideMarkings.tooltip') : t('dock.buttons.showMarkings.tooltip')}
      disabled={playerMode === PlayerMode.WHITEPAGE}
    >
      {showMarkings ? <HideMarkingsIcon /> : <ShowMarkingsIcon />}
    </button>
  );
}

ToggleMarkingsButton.propTypes = {
  playerMode: oneOf(Object.values(PlayerMode)).isRequired,
};
