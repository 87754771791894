import { ERROR_OCCURRED } from './actionNames';

/**
 * @param {{status: Number, statusText: String, [message]: String, originalError: Object}} error
 */
export function errorOccurred(error) {
  return {
    type: ERROR_OCCURRED,
    payload: {
      error,
    },
  };
}
