import React from 'react';
import { oneOf, func, bool, string } from 'prop-types';

import ViewMode from '../../../../../enums/viewmode';

import TogglePage from '../../../../../../assets/images/toggle-page.svg';
import ToggleSpread from '../../../../../../assets/images/toggle-spread.svg';
import ToggleDisabled from '../../../../../../assets/images/toggle-disabled.svg';

const viewmodeToggleButton = ({ viewMode, handleToggleClick, disabled, title }) => (
  <button type="button" className="pbb-dock__switch-page" title={title} disabled={disabled} onClick={handleToggleClick}>
    {disabled ? <img alt={title} src={ToggleDisabled} /> : <img alt={title} src={viewMode === ViewMode.PAGE ? TogglePage : ToggleSpread} />}
  </button>
);

viewmodeToggleButton.propTypes = {
  viewMode: oneOf(Object.values(ViewMode)),
  handleToggleClick: func,
  disabled: bool,
  title: string,
};

viewmodeToggleButton.defaultProps = {
  viewMode: 'page',
  handleToggleClick: undefined,
  disabled: false,
  title: undefined,
};

export default viewmodeToggleButton;
