import { SET_PLAYER_MODE, ZOOM_TO_FIT } from './actionNames';
import { setCurrentMathTool } from './tools';

export function setPlayerMode(playerMode, whitepageId = null) {
  return dispatch => {
    dispatch({ type: ZOOM_TO_FIT });
    dispatch(setCurrentMathTool(undefined));
    dispatch({ type: SET_PLAYER_MODE, payload: { playerMode, whitepageId } });
  };
}
