const ANALYTICS_EVENT_TYPES = Object.freeze({
  LOGIN: 'login',
  MODULE_ACCESSED: 'module-accessed',
  NODE_ACCESSED: 'node-accessed',
  MEDIALINK_ACCESSED: 'medialink-accessed',
  APP_ACCESSED: 'app-accessed',
  DIGIBOOK_ACCESSED: 'digibook-accessed',
  DIGIBOOK_PAGE_VIEWED: 'digibook-page-viewed',
  USER_MATERIAL_ACCESSED: 'user-material-accessed',
  USER_WHITEPAGE_ACCESSED: 'user-whitepage-accessed',
  SLIDE_SET_ACCESSED: 'slide-set-accessed',
  SLIDE_SET_VIEWER_ACCESSED: 'slide-set-viewer-accessed',
  SLIDE_SET_CREATED: 'slide-set-created',
  SLIDE_SET_OVERVIEW_ACCESSED: 'slide-set-overview-accessed',
  SLIDE_SET_EDIT_MODE_ACCESSED: 'slide-set-edit-mode-accessed',
  SLIDE_SET_DUPLICATED: 'slide-set-duplicated',
});

const ANALYTICS_EVENT_SUBLOCATIONS = Object.freeze({
  SIDEBAR: 'codex-sidebar',
  SIDEPANEL: 'codex-sidepanel',
  SIDEPANEL_OPTIONS: 'codex-sidepanel-options',
  SIDEPANEL_ARROWS: 'codex-sidepanel-arrows',
  SIDEPANEL_DIRECT: 'codex-sidepanel-direct',
  BOOK_SWITCHER: 'codex-book-switcher',
  LINKAREA: 'codex-linkarea',
  DIALOG: 'codex-dialog',
  GESTURES: 'codex-gestures',
  KEYBOARD: 'codex-keyboard',
});

module.exports = {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_EVENT_SUBLOCATIONS,
};
