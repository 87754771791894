import React from 'react';
import { node, func, shape, number } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Maintenance from './maintenance';
import Error from './error';
import { getCurrentError } from '../selectors/error';
import { errorOccurred } from '../actions/error';
import Severity from '../enums/severity';
import AccessDeniedComponent from './access-denied-error';
import BackToPButton from './back-to-p-button';

class ErrorBoundary extends React.PureComponent {
  componentDidCatch(error) {
    const { dispatch } = this.props;
    dispatch(errorOccurred({ status: 500, originalError: error }));
  }

  render() {
    const { error, children, t } = this.props;
    if (error) {
      switch (error.status) {
        case 401:
        case 403:
          return <AccessDeniedComponent />;
        case 404:
          return (
            <Error title={t('pageNotFound')} severity={Severity.WARNING}>
              <BackToPButton />
            </Error>
          );
        case 503:
          return <Maintenance title={t('errors.maintenance.title')} message={t(`errors.maintenance.message`)} />;
        default:
          return <Error title={t('init.errors.other.title')} severity={Severity.ERROR} />;
      }
    }

    return children;
  }
}

const mapStateToProps = state => ({
  error: getCurrentError(state),
});

ErrorBoundary.propTypes = {
  children: node.isRequired,
  dispatch: func.isRequired,
  t: func.isRequired,
  error: shape({
    status: number.isRequired,
  }),
};

ErrorBoundary.defaultProps = {
  error: undefined,
};

export default withTranslation()(connect(mapStateToProps)(ErrorBoundary));
