import { getAudiencesByShareState } from '../utils/audiences';

export const trackShareMaterial = (args) => {
    const sharedAudiences = getAudiencesByShareState('shared', args.newAudiences, args.oldAudiences);
    const unsharedAudiences = getAudiencesByShareState('unshared', args.oldAudiences, args.newAudiences);


    const buildPayload = audiences => ({
        medialinkName: args.material.name,
        module: args.moduleName,
        medialinkType: args.material.kind,
        role: args.role,
        options: audiences,
    });

    // Share material
    if (sharedAudiences.length > 0) {
        if (args.onShare) args.onShare(buildPayload(sharedAudiences));
    }

    // Stop sharing material (share with no one / no audiences)
    if (args.newAudiences.length === 0) {
        if (args.onStop) args.onStop(buildPayload(args.oldAudiences));
    }

    // Unshare material (decrease number of audiences of an existing shared material)
    if (args.oldAudiences.length > 0 && args.newAudiences.length > 0 && unsharedAudiences.length > 0) {
        if (args.onUnshare) args.onUnshare(buildPayload(unsharedAudiences))
    }
};

export default { trackShareMaterial };
