import { useMemo, useReducer, useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import throttle from 'lodash/throttle';

import api from '../../../../../../services/api';
import LRUCache from '../../../../../../services/lru-cache';
import { onStoreError } from '../../../../../../store';
import { getActiveAnnotationSet } from '../../../../../../selectors/annotationSets';
import { getCurrentTool } from '../../../../../../selectors/tools';
import { getCurrentSpread, getVisiblePages } from '../../../../../../selectors/rendering';
import { getCurrentDigibook } from '../../../../../../selectors/digibooks';
import { migrateOldAnnotation } from '../migrate-old-annotation';
import { ANNOTATION_PADDING } from '../../../../../../components/text-annotations/constants';

const ActionTypes = {
  AVAILABLE_ANNOTATIONS_RECEIVED: 'AVAILABLE_ANNOTATIONS_RECEIVED',
  SPREAD_ANNOTATIONS_RECEIVED: 'SPREAD_ANNOTATIONS_RECEIVED',
  ADD_ANNOTATION: 'ADD_ANNOTATION',
  EDIT_ANNOTATION: 'EDIT_ANNOTATION',
  REMOVE_ANNOTATION: 'REMOVE_ANNOTATION',
  REMOVE_BATCH: 'REMOVE_BATCH',
  APPLY_TOOL_OPTIONS_TO_SELECTED_ANNOTATION: 'APPLY_TOOL_OPTIONS_TO_SELECTED_ANNOTATION',
  MOVE_ANNOTATION_TO_TOP: 'MOVE_ANNOTATION_TO_TOP',
  ANNOTATION_SET_CHANGED: 'ANNOTATION_SET_CHANGED',
};

function filterShapesForVisiblePages(shapes, pages, pageWidth) {
  if (!pages.includes(null)) return shapes; // dual page

  if (pages[1] === null) return shapes.filter(shape => shape.left < pageWidth || shape.left >= pageWidth * 2); // single page, left page

  return shapes.filter(shape => shape.left + (shape.width || 0) >= pageWidth || shape.left < 0);
}

function moveItemToEnd(arr, index) {
  const item = arr[index];

  return arr.filter((x, i) => i !== index).concat(item);
}

function migrateAnnotations(annotations, spreadWidth, spreadHeight) {
  return annotations.map(({ migrated, ...annotation }) => {
    if (annotation.fontId) return migrateOldAnnotation(annotation, spreadWidth, spreadHeight);
    if (!migrated) return annotation;

    return {
      ...annotation,
      top: annotation.top * spreadHeight - ANNOTATION_PADDING,
      left: annotation.left * spreadWidth - ANNOTATION_PADDING,
      width: annotation.width ? annotation.width * spreadWidth : annotation.width,
      height: !annotation.height || annotation.height > 1 ? annotation.height : annotation.height * spreadHeight,
    };
  });
}

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.AVAILABLE_ANNOTATIONS_RECEIVED: {
      const { bySpread, ...rest } = state;
      const { spreads } = action.payload;

      const spreadMap = Object.fromEntries(
        spreads.map(spreadKey => {
          const spread = spreadKey.split('/')[spreadKey.split('/').length - 1];
          const { [spread]: { data: annotationIds = [] } = {} } = bySpread;
          return [spread, { data: [...annotationIds], fetchAnnotationsNeeded: true, isDirty: false }];
        }),
      );

      return {
        bySpread: {
          ...bySpread,
          ...spreadMap,
        },
        ...rest,
      };
    }
    case ActionTypes.ANNOTATION_SET_CHANGED: {
      return {
        byId: {},
        bySpread: {},
      };
    }
    case ActionTypes.SPREAD_ANNOTATIONS_RECEIVED: {
      const { byId, bySpread } = state;
      const { spread, annotations = [] } = action.payload;
      const { [spread]: { data: annotationIds = [] } = {} } = bySpread;

      const annotationMap = Object.fromEntries(annotations.map(annotation => [annotation.id, annotation]));

      return {
        byId: {
          ...byId,
          ...annotationMap,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            fetchAnnotationsNeeded: undefined,
            data: [...Object.keys(annotationMap), ...annotationIds],
          },
        },
      };
    }
    case ActionTypes.ADD_ANNOTATION: {
      const { byId, bySpread } = state;
      const { spread, annotation } = action.payload;
      const { [spread]: { data: annotationIds = [] } = {} } = bySpread;

      return {
        byId: {
          ...byId,
          [annotation.id]: annotation,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            data: [...annotationIds, annotation.id],
          },
        },
      };
    }
    case ActionTypes.EDIT_ANNOTATION: {
      const { byId, ...rest } = state;
      const { shape, id } = action.payload;
      return {
        byId: {
          ...byId,
          [id]: {
            ...byId[id],
            ...shape,
          },
        },
        ...rest,
      };
    }
    case ActionTypes.REMOVE_ANNOTATION: {
      const { byId, bySpread } = state;
      const { annotationId } = action.payload;

      const [spread, spreadState] = Object.entries(bySpread).find(([, val]) => val.data && val.data.includes(annotationId));

      return {
        ...state,
        byId: {
          ...byId,
          [annotationId]: undefined,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...spreadState,
            data: (spreadState.data || []).filter(x => x !== annotationId),
          },
        },
      };
    }
    case ActionTypes.REMOVE_BATCH: {
      const { byId, bySpread } = state;
      const { ids, spread } = action.payload;

      const idsToDelete = ids.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: undefined,
        }),
        {},
      );

      return {
        ...state,
        byId: {
          ...byId,
          ...idsToDelete,
        },
        bySpread: {
          ...bySpread,
          [spread]: {
            ...bySpread[spread],
            data: bySpread[spread].data.filter(x => !ids.includes(x)),
          },
        },
      };
    }
    case ActionTypes.MOVE_ANNOTATION_TO_TOP: {
      const { bySpread } = state;
      const annotationId = action.payload;

      const [spread, spreadState] = Object.entries(bySpread).find(([, val]) => val.data && val.data.includes(annotationId));
      const index = spreadState.data.indexOf(annotationId);

      const reordered = moveItemToEnd(spreadState.data, index);

      return {
        ...state,
        bySpread: {
          ...bySpread,
          [spread]: {
            ...spreadState,
            data: reordered,
          },
        },
      };
    }
    default:
      throw new Error('unexpected action');
  }
}

function buildOptions(digibook) {
  return { headers: { Authorization: `Bearer ${digibook.systemToken}` } };
}

const TWO_HOURS_IN_MS = 1000 * 60 * 60 * 2;

function useAnnotations({ saveTimeout = 3000, pageWidth, pageHeight }) {
  const spreadPages = useSelector(getCurrentSpread);
  const digibook = useSelector(getCurrentDigibook);
  const visiblePages = useSelector(getVisiblePages);
  const currentTool = useSelector(getCurrentTool);
  const activeAnnotationSet = useSelector(getActiveAnnotationSet);

  const [selectedAnnotationId, setSelectedAnnotationId] = useState();
  const [dirtySpreads, setDirtySpreads] = useState({});
  const reduxDispatch = useDispatch();
  const signedUrlCache = useRef(new LRUCache(50, TWO_HOURS_IN_MS));

  const [annotationState, dispatch] = useReducer(reducer, {
    byId: {},
    bySpread: {},
  });

  const spread = useMemo(() => {
    if (spreadPages.length > 0) {
      return spreadPages.join('-');
    }
    return '';
  }, [spreadPages]);

  const spreadAnnotations = useMemo(() => {
    const { byId, bySpread } = annotationState;
    const { [spread]: { data: annotationIds = [] } = {} } = bySpread;
    return annotationIds.map(id => byId[id]);
  }, [spread, annotationState]);

  const getCachedSignedUrlForSpread = useCallback(
    async spreadKey => {
      const { current: cache } = signedUrlCache;
      if (!cache.get(spreadKey) && activeAnnotationSet?.id) {
        const options = buildOptions(digibook);
        const { data: signedUrlForSpread } = await api.get(
          `/studio/digibooks/${digibook.id}/annotation-sets/${activeAnnotationSet.id}/text-annotations/${spreadKey}/signed-url`,
          options,
        );

        cache.add(spreadKey, signedUrlForSpread);
        return signedUrlForSpread;
      }

      return cache.get(spreadKey);
    },
    [activeAnnotationSet?.id, digibook],
  );

  useEffect(() => {
    if (activeAnnotationSet?.id) {
      setDirtySpreads({});
      dispatch({ type: ActionTypes.ANNOTATION_SET_CHANGED });
      setSelectedAnnotationId(null);
      signedUrlCache.current.clear();
    }
  }, [activeAnnotationSet?.id]);

  useEffect(() => {
    async function getSpreadsWithAnnotations() {
      if (!activeAnnotationSet?.id) return;

      try {
        const options = buildOptions(digibook);
        const {
          data: { data: spreads },
        } = await api.get(`/studio/digibooks/${digibook.id}/annotation-sets/${activeAnnotationSet.id}/text-annotations`, options);

        dispatch({
          type: ActionTypes.AVAILABLE_ANNOTATIONS_RECEIVED,
          payload: {
            spreads,
          },
        });
      } catch (e) {
        reduxDispatch(onStoreError(e));
      }
    }

    if (digibook) {
      getSpreadsWithAnnotations();
    }
  }, [digibook, reduxDispatch, activeAnnotationSet?.id]);

  const spreadsBusyFetching = useRef({});

  useEffect(() => {
    async function getAnnotationsForCurrentSpread() {
      try {
        spreadsBusyFetching.current[spread] = true;

        const signedUrlForSpread = await getCachedSignedUrlForSpread(spread);
        const { data: annotations } = await axios.get(signedUrlForSpread);

        const migrated = migrateAnnotations(annotations, spread.split('-').length * pageWidth, pageHeight);

        dispatch({
          type: ActionTypes.SPREAD_ANNOTATIONS_RECEIVED,
          payload: {
            spread,
            annotations: migrated,
          },
        });

        spreadsBusyFetching.current[spread] = false;
      } catch (e) {
        reduxDispatch(onStoreError(e));
      }
    }

    const {
      bySpread: { [spread]: { fetchAnnotationsNeeded } = {} },
    } = annotationState;

    if (fetchAnnotationsNeeded && !spreadsBusyFetching.current[spread]) {
      getAnnotationsForCurrentSpread();
    }
  }, [digibook, annotationState, spread, getCachedSignedUrlForSpread, reduxDispatch, pageWidth, pageHeight]);

  const addAnnotation = useCallback(
    annotation => {
      dispatch({
        type: ActionTypes.ADD_ANNOTATION,
        payload: {
          annotation,
          spread: spreadPages.join('-'),
        },
      });

      setSelectedAnnotationId(annotation.id);
      setDirtySpreads(dirty => ({ ...dirty, [spread]: true }));
    },
    [spreadPages, spread],
  );

  const removeAnnotation = useCallback(
    annotationId => {
      dispatch({
        type: ActionTypes.REMOVE_ANNOTATION,
        payload: {
          annotationId,
        },
      });

      setDirtySpreads(dirty => ({ ...dirty, [spread]: true }));
    },
    [spread],
  );

  const removeBatch = useCallback(
    ids => {
      dispatch({
        type: ActionTypes.REMOVE_BATCH,
        payload: {
          ids,
          spread,
        },
      });

      setDirtySpreads(dirty => ({ ...dirty, [spread]: true }));
    },
    [spread],
  );

  const editAnnotation = useCallback(
    (id, changedAnnotation) => {
      dispatch({
        type: ActionTypes.EDIT_ANNOTATION,
        payload: {
          id,
          shape: changedAnnotation,
        },
      });

      setDirtySpreads(dirty => ({ ...dirty, [spread]: true }));
    },
    [spread],
  );

  useEffect(() => {
    setSelectedAnnotationId(null);
  }, [visiblePages, currentTool]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveAnnotations = useCallback(
    throttle(async annotations => {
      const signedUrl = await getCachedSignedUrlForSpread(spread);

      const annotationsToSave = annotations.filter(x => x.text !== '');

      await axios.put(signedUrl, annotationsToSave, {
        headers: {
          'Content-Type': 'application/json',
          'x-amz-acl': 'bucket-owner-full-control',
        },
      });

      setDirtySpreads(dirty => ({ ...dirty, [spread]: false }));
    }, saveTimeout),
    [spread, getCachedSignedUrlForSpread, saveTimeout],
  );

  /**
   * Flush the save when spread changes.
   */
  useEffect(() => () => saveAnnotations.flush(), [saveAnnotations, activeAnnotationSet]);

  useEffect(() => {
    if (selectedAnnotationId) {
      dispatch({
        type: ActionTypes.MOVE_ANNOTATION_TO_TOP,
        payload: selectedAnnotationId,
      });
    }
  }, [dispatch, selectedAnnotationId]);

  /**
   * Check if annotationstate has changed since last save. (bulk)
   */
  const isCurrentSpreadDirty = !!dirtySpreads[spread];

  /**
   * Save when spreadAnnotationState has changed since last save
   */
  useEffect(() => {
    async function save() {
      await saveAnnotations(spreadAnnotations);
    }

    if (isCurrentSpreadDirty) {
      save();
    }
  }, [saveAnnotations, isCurrentSpreadDirty, spreadAnnotations]);

  const selectedAnnotation = annotationState.byId[selectedAnnotationId];

  return [spreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation, removeBatch, removeAnnotation];
}

export default function useVisibleAnnotations({ saveTimeout, pageWidth, pageHeight }) {
  const visiblePages = useSelector(getVisiblePages);

  const [spreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation, removeBatch, removeAnnotation] = useAnnotations({
    saveTimeout,
    pageWidth,
    pageHeight,
  });

  const visibleSpreadAnnotations = useMemo(() => {
    if (spreadAnnotations.length === 0) return spreadAnnotations;

    return filterShapesForVisiblePages(spreadAnnotations, visiblePages, pageWidth);
  }, [spreadAnnotations, visiblePages, pageWidth]);

  useEffect(() => {
    function removeVisibleAnnotations() {
      if (visibleSpreadAnnotations.length > 0) removeBatch(visibleSpreadAnnotations.map(annotation => annotation.id));
    }

    document.addEventListener('erase-all-clicked', removeVisibleAnnotations);
    return () => {
      document.removeEventListener('erase-all-clicked', removeVisibleAnnotations);
    };
  }, [removeBatch, visibleSpreadAnnotations]);

  return [visibleSpreadAnnotations, addAnnotation, editAnnotation, setSelectedAnnotationId, selectedAnnotation, removeAnnotation];
}
