import update from 'immutability-helper';
import { INITIALIZE_SUCCESS } from '../actions/actionNames';

const initialState = {
  byId: {},
  byTocNodeId: {},
};

const sharedMediaReducer = (state = initialState, action) => {
  switch (action && action.type) {
    case INITIALIZE_SUCCESS: {
      const { sharedMedia } = action.payload;

      if (sharedMedia.length) {
        const byId = sharedMedia.reduce(
          (map, media) => ({
            ...map,
            [media.id]: {
              ...media,
              node: media.node.id || media.node,
            },
          }),
          {},
        );

        const byNodeId = Object.values(byId).reduce(
          (map, media) => ({
            ...map,
            [media.node]: [...(map[media.node] || []), media.id],
          }),
          {},
        );

        return update(state, {
          byId: { $merge: byId },
          byTocNodeId: { $merge: byNodeId },
        });
      }
      return state;
    }

    default:
      return state;
  }
};

export default sharedMediaReducer;
