/* eslint-disable consistent-return */
import React from 'react';
import { NODE_TYPES } from './schema';

const BLOCK_TAGS = {
  p: NODE_TYPES.paragraph,
  li: NODE_TYPES.listItem,
  ul: NODE_TYPES.unorderedList,
  ol: NODE_TYPES.orderedList,
};

const MARK_TAGS = {
  strong: NODE_TYPES.bold,
  em: NODE_TYPES.italic,
};

const RULES = [
  {
    deserialize(el, next) {
      const block = BLOCK_TAGS[el.tagName.toLowerCase()];
      if (block) {
        return {
          object: 'block',
          type: block,
          nodes: next(el.childNodes),
        };
      }
    },
  },
  {
    deserialize(el, next) {
      const mark = MARK_TAGS[el.tagName.toLowerCase()];
      if (mark) {
        return {
          object: 'mark',
          type: mark,
          nodes: next(el.childNodes),
        };
      }
    },
  },
  {
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case NODE_TYPES.paragraph:
            return <p>{children}</p>;
          case NODE_TYPES.unorderedList:
            return <ul>{children}</ul>;
          case NODE_TYPES.orderedList:
            return <ol>{children}</ol>;
          case NODE_TYPES.listItem:
            return <li>{children}</li>;
          default:
            return <>{children}</>;
        }
      }
    },
  },
  {
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case NODE_TYPES.bold:
            return <strong>{children}</strong>;
          case NODE_TYPES.italic:
            return <em>{children}</em>;
          default:
            return <>{children}</>;
        }
      }
    },
  },
];

export { RULES };
