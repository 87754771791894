import React from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from 'slate-react';
import Lists from '@convertkit/slate-lists';
import { object, func } from 'prop-types';
import { isKeyHotkey } from 'is-hotkey';
import classNames from 'classnames';
import Toolbar from './slate/Toolbar';
import { schema, NODE_TYPES, SLATE_TYPES, SLATE_MAX_LENGTH } from './slate/schema';
import { marks as markNodes, blocks as blockNodes } from './slate/nodes';
import { Button } from './slate/Button';
import { ListQueries } from './slate/ListQueryPlugin';

const plugins = [Lists(), ListQueries()];

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');

const NoteEditor = ({ editorState, setEditorState }) => {
  const [t] = useTranslation();
  const editorRef = React.useRef(null);
  const hasMark = type => editorState.value.activeMarks.some(mark => mark.type === type);
  const hasBlock = type => editorState.value.blocks.some(node => node.type === type);

  const onClickMarkButton = (e, type) => {
    e.preventDefault();
    editorRef.current.toggleMark(type);
  };

  const renderMarkButton = (type, label) => <Button active={hasMark(type)} onMouseDown={e => onClickMarkButton(e, type)} icon={label} title={t(`notes.editor.${type}`)} />;

  const onClickBlockButton = (e, type) => {
    e.preventDefault();

    const editor = editorRef.current;

    if ([NODE_TYPES.orderedList, NODE_TYPES.unorderedList].includes(type)) {
      editor.toggleList({ type });
    }
  };

  const renderBlockButton = (type, label) => {
    let isActive = hasBlock(type);

    if ([NODE_TYPES.orderedList, NODE_TYPES.unorderedList].includes(type)) {
      const editor = editorRef.current;
      const {
        value: { document, blocks },
      } = editorState;

      if (blocks.size > 0 && editor) {
        const parent = document.getParent(blocks.first().key);
        const list = editor.getList(parent);
        isActive = parent && list && list.type === type;
      }
    }

    return <Button active={isActive} onMouseDown={e => onClickBlockButton(e, type)} icon={label} title={t(`notes.editor.${type}`)} />;
  };

  const onChange = ({ value }) => setEditorState({ ...editorState, value });
  const onKeyDown = (event, editor, next) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = NODE_TYPES.bold;
    } else if (isItalicHotkey(event)) {
      mark = NODE_TYPES.italic;
    } else {
      return next();
    }

    event.preventDefault();
    return editor.toggleMark(mark);
  };
  const renderNode = nodeType => (props, editor, next) => {
    const nodes = { ...markNodes, ...blockNodes };
    // eslint-disable-next-line react/prop-types
    const { children, attributes, [nodeType]: node } = props;
    const Element = nodes[node.type];
    if (Element) {
      return <Element attributes={attributes}>{children}</Element>;
    }

    return next();
  };

  const renderMark = renderNode(SLATE_TYPES.mark);
  const renderBlock = renderNode(SLATE_TYPES.node);

  const renderCharacterCount = textLength => {
    return (
      <span
        data-testid="character-count"
        className={classNames('pbb-wysiwyg-character-count', {
          'pbb-wysiwyg-character-count--error': textLength > SLATE_MAX_LENGTH,
        })}
      >
        <span className="pbb-wysiwyg-character-count__length" data-testid="character-count-length">
          {textLength}
        </span>
        / {SLATE_MAX_LENGTH}
      </span>
    );
  };

  return (
    <>
      <Toolbar>
        <div className="pbb-wysiwyg-tools__buttons">
          {renderMarkButton(NODE_TYPES.bold, 'icon-bb-note-bold')}
          {renderMarkButton(NODE_TYPES.italic, 'icon-bb-note-italic')}
          {renderBlockButton(NODE_TYPES.unorderedList, 'icon-bb-note-ul')}
          {renderBlockButton(NODE_TYPES.orderedList, 'icon-bb-note-ol')}
        </div>
        {renderCharacterCount(editorState.value.document.text.length)}
      </Toolbar>

      <Editor
        ref={editorRef}
        schema={schema}
        value={editorState.value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        renderMark={renderMark}
        renderBlock={renderBlock}
        plugins={plugins}
        autoFocus
        className="pbb-wysiwyg-editor"
        data-testid="slate-editor"
      />
    </>
  );
};

NoteEditor.propTypes = {
  editorState: object.isRequired,
  setEditorState: func.isRequired,
};

export default NoteEditor;
