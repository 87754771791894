import React, { createContext, useState, useMemo, useCallback } from 'react';
import { oneOf, shape, string } from 'prop-types';

import MaterialSorting from '../../../enums/materialSorting';
import AnchorPosition from '../../../enums/anchorposition';
import DrawerMode from '../../../enums/drawermode';
import FeatureToggle from '../../../enums/feature-toggle';

import { getFeatureToggleFor } from '../../../utils/feature-toggle';
import api from '../../../services/api';

export const UserSettingsContext = createContext({
  materialSorting: MaterialSorting.CATEGORY,
  setMaterialSorting: () => {},
  sidebarAnchor: AnchorPosition.LEFT,
  setSidebarAnchor: () => {},
  activeDrawer: null,
  setActiveDrawer: () => {},
  showOnlyFavoriteMaterial: false,
  setShowOnlyFavoriteMaterial: () => {},
  showOnlyFavoriteWhitepages: false,
  setShowOnlyFavoriteWhitepages: () => {},
});

export function UserSettingsProvider(props) {
  const { initialOpenDrawer, initialToolbarPosition, module } = props;
  const possibleDrawerModes = getFeatureToggleFor(FeatureToggle.NOTES) ? Object.values(DrawerMode) : Object.values(DrawerMode).filter(x => x !== DrawerMode.NOTES);

  const openDrawer = possibleDrawerModes.find(x => x === initialOpenDrawer);

  const [materialSorting, setMaterialSorting] = useState(module.defaultSorting);
  const [sidebarAnchor, setSidebarAnchor] = useState(initialToolbarPosition);
  const [activeDrawer, setActiveDrawer] = useState(openDrawer);
  const [isSidebarOpen, setSidebarOpened] = useState(!!openDrawer);
  const [showMarkings, setShowMarkings] = useState(true);
  const [showOnlyFavoriteMaterial, setShowOnlyFavoriteMaterial] = useState(false);
  const [showOnlyFavoriteWhitepages, setShowOnlyFavoriteWhitepages] = useState(false);

  const updateSidebarAnchor = useCallback(
    position => {
      setSidebarAnchor(position);
      api.put(`/studio/user/modules/${module.id}/codex-player-settings/toolbar-position`, { toolbarPosition: position });
    },
    [module],
  );

  const memoizedState = useMemo(
    () => ({
      materialSorting,
      setMaterialSorting,
      sidebarAnchor,
      setSidebarAnchor: updateSidebarAnchor,
      activeDrawer,
      setActiveDrawer,
      isSidebarOpen,
      setSidebarOpened,
      showMarkings,
      setShowMarkings,
      showOnlyFavoriteMaterial,
      setShowOnlyFavoriteMaterial,
      showOnlyFavoriteWhitepages,
      setShowOnlyFavoriteWhitepages,
    }),
    [
      materialSorting,
      sidebarAnchor,
      activeDrawer,
      isSidebarOpen,
      showMarkings,
      showOnlyFavoriteMaterial,
      setShowOnlyFavoriteMaterial,
      updateSidebarAnchor,
      showOnlyFavoriteWhitepages,
      setShowOnlyFavoriteWhitepages,
    ],
  );

  return <UserSettingsContext.Provider value={memoizedState} {...props} />;
}

UserSettingsProvider.propTypes = {
  initialOpenDrawer: string,
  initialToolbarPosition: string,
  module: shape({
    id: string.isRequired,
    defaultSorting: oneOf(Object.values(MaterialSorting)),
  }).isRequired,
};

UserSettingsProvider.defaultProps = {
  initialOpenDrawer: undefined,
  initialToolbarPosition: AnchorPosition.LEFT,
};

export const UserSettingsConsumer = UserSettingsContext.Consumer;
