import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import thunkMiddleware from 'redux-thunk-recursion-detect';
import { usermarkings } from './middlewares/usermarkingspersister';

import { errorOccurred } from './actions/error';

import reducer from './reducers';
import tryExtractStatusInfo from './utils/extractErrorStatusInfo';

/**
 *
 * @param {Error} error
 */
export function onStoreError(error) {
  const statusInfo = tryExtractStatusInfo(error) || { status: 500 };
  statusInfo.originalError = error;

  return dispatch => dispatch(errorOccurred(statusInfo));
}

const errorHandlerMiddleWare = createThunkErrorHandlerMiddleware({ onError: onStoreError });

const store = createStore(reducer, composeWithDevTools(applyMiddleware(errorHandlerMiddleWare, thunkMiddleware, usermarkings)));

export default store;
