import React from 'react';
import { string } from 'prop-types';

export const ActiveNodeInfo = ({ prefix, title }) => (
  <div className="pbb-slider-back__info">
    <div className="pbb-slider-back__info-lesson">{prefix}</div>
    <div className="pbb-slider-back__info-title">{title}</div>
  </div>
);

ActiveNodeInfo.propTypes = {
  prefix: string,
  title: string,
};

ActiveNodeInfo.defaultProps = {
  prefix: undefined,
  title: undefined,
};

export default ActiveNodeInfo;
