import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrentDigibook } from "../../../../selectors/digibooks";

const useManualPageProvider = () => {
  const { manualMapping = [], totalPages, totalManualPages } = useSelector(getCurrentDigibook);

  const { bookPagesToExclude, manualPagesToExclude } = manualMapping.reduce((acc, mapping) => {
    if (mapping.excludeFrom === 'manual') acc.manualPagesToExclude.push(mapping);

    if (mapping.excludeFrom === 'book') acc.bookPagesToExclude.push(mapping);

    return acc;
  }, { bookPagesToExclude: [], manualPagesToExclude: [] });

  const bookPageNumbers = new Array(totalPages).fill().map((_, i) => i + 1)
    .filter(pageNumber => !bookPagesToExclude.some(range => pageNumber >= range.from && pageNumber <= range.to));

  const manualPageNumbers = new Array(totalManualPages).fill().map((_, i) => i + 1)
    .filter(pageNumber => !manualPagesToExclude.some(range => pageNumber >= range.from && pageNumber <= range.to));

  const mappedPages = useMemo(() => bookPageNumbers.reduce((acc, bookPageNumber, index) => ({
    ...acc,
    [bookPageNumber]: manualPageNumbers[index]
  }), {}), [bookPageNumbers, manualPageNumbers]);

  return mappedPages;
};

export default useManualPageProvider;
