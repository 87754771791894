import { POP_UP_MANUAL_FETCH_SUCCESS } from '../actions/actionNames';

export const initialState = {
  id: undefined,
  name: undefined,
  url: undefined,
};

export default function popUpManualReducer(state = initialState, action) {
  switch (action?.type) {
    case POP_UP_MANUAL_FETCH_SUCCESS: {
      return {
        id: action.payload.id,
        name: action.payload.name,
        url: action.payload.url,
      };
    }
    default: {
      return state;
    }
  }
}
