import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { arrayOf, number, func } from 'prop-types';

import tools from '../../../../../../enums/tools';
import CrossHair from './components/Crosshair';
import RadiusLine from './components/RadiusLine';
import useDraftingCompassResize from './useDraftingCompassResize';
import useDraftingCompassRotate from './useDraftingCompassRotate';
import getSizes from './sizes';
import useDraftingCompassToDraw from './useDraftingCompassToDraw';

const getArcOptions = arcOptions => ({
  ...arcOptions,
  originX: 'center',
  originY: 'center',
  fill: 'transparent',
});

const calculateCenter = mathToolWrapperEl => {
  const crosshair = document.getElementById('crosshair');
  const { width: crosshairWidth, height: crosshairHeight } = crosshair.getBoundingClientRect();

  const offsetLeft = mathToolWrapperEl.parentElement.clientWidth / 2 - crosshairWidth / 2;
  const offsetTop = mathToolWrapperEl.parentElement.clientHeight / 2 - crosshairHeight / 2;

  return { offsetLeft, offsetTop };
};

const DraftingCompass = forwardRef(({ pixelsPerMM, viewPortTransform, onTempDraw, onDrawingFinished, setCalculateCenter }, mathToolsRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const wrapper = useRef();

  const { CENTER, BUTTON_RADIUS } = getSizes(pixelsPerMM);

  const { rotate, degrees, degreesDisplay } = useDraftingCompassRotate(CENTER, wrapper, viewPortTransform);

  const { resize, radius } = useDraftingCompassResize({
    pixelsPerMM,
    viewPortTransform,
    degrees,
  });

  const drawTempArc = arcOptions => {
    onTempDraw(getArcOptions({ ...arcOptions, radius }));
  };

  const draw = useDraftingCompassToDraw({ degrees, rotate, mathToolsRef, viewPortTransform, drawTempArc, finishDrawing: onDrawingFinished });

  useEffect(() => {
    /**
     * calculate dimensions of svg in order to show correct width/height.
     */
    const { height, width } = wrapper.current.getBBox();

    setDimensions({ width, height });
  }, [pixelsPerMM, radius, degrees]);

  /**
   * When rotating we want to make sure to set the width to the hypotenuse of its width and height so that svg is not cut off.
   * Mainly done since Safari does not allow overflow:visible to show out of bounds elements on
   * an SVG.
   */
  const svgWidth = Math.sqrt(dimensions.width ** 2 + dimensions.height ** 2);

  useEffect(() => {
    setCalculateCenter(calculateCenter);
    return () => setCalculateCenter(undefined);
  }, [setCalculateCenter]);

  return (
    <svg
      className="math-tool"
      id={tools.DRAFTING_COMPASS}
      data-testid={tools.DRAFTING_COMPASS}
      xmlns="http://www.w3.org/2000/svg"
      width={Math.round(svgWidth)}
      viewBox={`0 -${BUTTON_RADIUS} ${Math.round(svgWidth)} ${dimensions.height}`}
      ref={wrapper}
    >
      <RadiusLine radius={radius} pixelsPerMM={pixelsPerMM} onResize={resize} onRotate={rotate} degrees={degrees} degreesDisplay={degreesDisplay} onDraw={draw} />
      <CrossHair pixelsPerMM={pixelsPerMM} />
    </svg>
  );
});

export default DraftingCompass;

DraftingCompass.propTypes = {
  pixelsPerMM: number.isRequired,
  viewPortTransform: arrayOf(number).isRequired,
  onTempDraw: func.isRequired,
  onDrawingFinished: func.isRequired,
  setCalculateCenter: func.isRequired,
};
