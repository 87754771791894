import get from 'get-value';
import { getTeacherFeaturesEnabledFor, getCurrentDigibook } from './digibooks';
import { getTOCNodes } from './toc';
import { getTocNodesForRanges } from '../utils/getTocNodesForRange';

export const getTocNodeIdsForSharedSolutions = state => {
  const teacherFeaturesEnabled = getTeacherFeaturesEnabledFor(state);
  if (teacherFeaturesEnabled) {
    return get(state, ['sharing', 'sharedSolutions']).map(s => s.tocNodeId);
  }

  const { allowedRanges } = getCurrentDigibook(state);

  if (allowedRanges && allowedRanges.length) {
    return getTocNodesForRanges(allowedRanges, getTOCNodes(state));
  }
  return [];
};
