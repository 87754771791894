import React from 'react';
import { shape, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import Error from './error';

import severity from '../enums/severity';
import BackToPButton from './back-to-p-button';

function AccessDeniedComponent({ history }) {
  const [t] = useTranslation();

  const showCloseButton = history.length === 1;

  return (
    <Error title={t('init.errors.bookNotLicensed.title')} description={t('init.errors.bookNotLicensed.description')} severity={severity.ERROR}>
      {!showCloseButton && <BackToPButton />}
      {showCloseButton && (
        <button type="button" className="pbb-btn pbb-btn--secondary" onClick={() => window.close()}>
          {t('errorScreen.close')}
        </button>
      )}
    </Error>
  );
}

AccessDeniedComponent.propTypes = {
  history: shape({
    length: number,
  }).isRequired,
};

export default withRouter(AccessDeniedComponent);
