// Keys map to the pdf link target server enum for ease of use (PdfjsLinkTarget[medialink.file.pdfLinkTarget])
// Values map to the PdfJS internal values:
// NONE: 0, SELF: 1, BLANK: 2, PARENT: 3, TOP: 4

const PdfjsLinkTarget = {
  'new-window': 2,
  'same-window': 4,
};

export default PdfjsLinkTarget;
