import React from 'react';
import { node, func, string } from 'prop-types';

export const Tabs = ({ children, onClick, activeTab }) => (
  <ul data-testid="tabs-ul" className="pbb-modal__nav-tabs">
    {React.Children.toArray(children).map(child =>
      React.cloneElement(child, {
        onClick,
        isActive: child.props.id === activeTab,
      }),
    )}
  </ul>
);

Tabs.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
  activeTab: string.isRequired,
};

export default Tabs;
