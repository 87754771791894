import axios from 'axios';

export function getSaveTimeout() {
  return 3000;
}

export function canvasToBase64(canvas, width, height) {
  return new Promise(resolve => {
    canvas.clone(clonedCanvas => {
      clonedCanvas.setWidth(width);
      clonedCanvas.setHeight(height);
      clonedCanvas.renderAll();

      const imageData = clonedCanvas.toDataURL('image/png');

      resolve(imageData);
    });
  });
}

function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const dataURL = reader.result;
      const base64 = dataURL.split(',')[1];

      return resolve(base64);
    };

    reader.readAsDataURL(blob);
  });
}

export async function addFontToPDF(doc, font, fileName, fontName, fontStyle) {
  const response = await axios.get(font, { responseType: 'blob' });

  const base64font = await blobToBase64(await response.data);

  doc.addFileToVFS(fileName, base64font);
  doc.addFont(fileName, fontName, fontStyle);
}
