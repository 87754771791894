import api from '../services/api';
import { MODULE_FETCH_SUCCESS } from './actionNames';

function retrieveModuleSuccess({ module }) {
  return {
    type: MODULE_FETCH_SUCCESS,
    payload: {
      module,
    },
  };
}

export default function retrieveModuleById(id) {
  return async dispatch => {
    const response = await api.get(`/studio/modules/${id}`);
    return dispatch(retrieveModuleSuccess({ module: response.data }));
  };
}
