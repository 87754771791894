/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { number } from 'prop-types';

export default function Numbers({ pixelsPerMM, height, fontSize, paddingLeft, maskWidth, maxAmountOfMM }) {
  const numbers = useMemo(
    () =>
      new Array(maxAmountOfMM + 1)
        .fill(undefined)
        .filter((_, i) => i === 0 || i % 10 === 0)
        .map((_, i) => (
          <text key={i} fill="currentColor" fontSize={fontSize} x={i * 10 * pixelsPerMM + paddingLeft} y={height / 2} textAnchor="middle" dy="0.3em">
            {i}
          </text>
        )),
    [fontSize, maxAmountOfMM, height, paddingLeft, pixelsPerMM],
  );

  return (
    <g data-testid="numbers">
      <g clipPath="url(#ruler-numbers-mask)">{numbers}</g>
      <clipPath id="ruler-numbers-mask">
        <rect width={maskWidth + 16} height={height} x={paddingLeft - 8} />
      </clipPath>
    </g>
  );
}

Numbers.propTypes = {
  pixelsPerMM: number.isRequired,
  height: number.isRequired,
  fontSize: number.isRequired,
  paddingLeft: number.isRequired,
  maskWidth: number.isRequired,
  maxAmountOfMM: number.isRequired,
};
