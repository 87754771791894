import { Dom } from 'jodit/esm/core/dom';
import { pluginSystem } from 'jodit/esm/core/global';
import { css } from 'jodit/esm/core/helpers';
import { Plugin } from 'jodit/esm/core/plugin';
import { Icon } from 'jodit/esm/core/ui';

export class resizeHandler extends Plugin {
  static requires = ['size'];

  /**
   * Plugin in resize process
   */
  isResized = false;

  /**
   * Start point
   */
  start = {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  };

  afterInit(editor) {
    const { height, width } = editor.o;

    if (height !== 'auto' || width !== 'auto') {
      editor.statusbar.setMod('resize-handle', true);

      editor.e.on(this.handle, 'mousedown touchstart', this.onHandleResizeStart.bind(this)).on(editor.ow, 'mouseup touchend', this.onHandleResizeEnd.bind(this));

      editor.container.appendChild(this.handle);
    }
  }

  /**
   * Handler: Click on handle - start resizing
   */
  onHandleResizeStart(e) {
    this.isResized = true;
    this.start.x = e.clientX;
    this.start.y = e.clientY;
    this.start.w = this.j.container.offsetWidth;
    this.start.h = this.j.container.offsetHeight;

    this.isResizing = true;
    this.j.lock();

    this.j.e.on(this.j.ow, 'mousemove touchmove', this.onHandleResize.bind(this));

    e.preventDefault();
  }

  /**
   * Handler: Mouse move after start resizing
   */
  onHandleResize(e) {
    if (!this.isResizing) {
      return;
    }

    const minHeight = this.start.h + (e.clientY - this.start.y) / this.j.renderedScale;

    [this.j.workplace, this.j.iframe, this.j.editor].map(elm => elm && css(elm, 'minHeight', minHeight));

    this.j.e.fire('setWidth', this.start.w + (e.clientX - this.start.x) / this.j.renderedScale);

    this.j.e.fire('resize');
  }

  /**
   * End of resizing
   */
  onHandleResizeEnd() {
    if (this.isResizing) {
      this.j.e.off(this.j.ow, 'mousemove touchmove', this.onHandleResize.bind(this));
      this.j.e.fire('resized', this.j.editor.clientWidth, this.j.editor.clientHeight);
      this.j.unlock();
      this.isResizing = false;
    }
  }

  /**
   * Resize handle
   */
  handle = this.j.c.div('jodit-editor__resize', Icon.get('resize_handler'));

  beforeDestruct() {
    Dom.safeRemove(this.handle);

    this.j.e.off(this.j.ow, 'mouseup touchsend', this.onHandleResizeEnd.bind(this));
  }
}

pluginSystem.add('resizeHandler', resizeHandler);
