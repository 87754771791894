import jwt from 'jwt-decode';
import axios from 'axios';

import Epoch from '../../utils/epoch';
import { PORTAAL_API_URL } from '../../constants/constants';
import { tokenRefreshed, tokenRefreshFailed } from '../../actions/auth';
import Storage from '../storage';
import StorageKey from '../storage-keys';

const TOKEN_GRACE_PERIOD_IN_SECONDS = 120;

export const baseApi = axios.create({
  baseURL: `${PORTAAL_API_URL}/api`,
});

let refreshTokenPromise;

export default function getAccessToken(dispatch) {
  if (refreshTokenPromise) return refreshTokenPromise;

  const parsedAccessToken = Storage.get(StorageKey.ACCESS_TOKEN);
  const parsedRefreshToken = Storage.get(StorageKey.REFRESH_TOKEN);

  if (!parsedAccessToken || !parsedRefreshToken) return Promise.resolve(null);

  const decodedToken = jwt(parsedAccessToken);

  const isJwtTokenExpired = Epoch.fromDate(new Date()) + TOKEN_GRACE_PERIOD_IN_SECONDS > decodedToken.exp;

  if (isJwtTokenExpired) {
    const query = new URLSearchParams({ token: parsedRefreshToken }).toString();

    refreshTokenPromise = baseApi
      .post(`oauth/refresh_token?${query}`)
      .then(({ data: { access_token: newAccessToken, refresh_token: newRefreshToken } }) => {
        Storage.set(StorageKey.ACCESS_TOKEN, newAccessToken);
        Storage.set(StorageKey.REFRESH_TOKEN, newRefreshToken);

        dispatch(tokenRefreshed(newAccessToken, newRefreshToken));

        refreshTokenPromise = null;
        return newAccessToken;
      })
      .catch(() => {
        Storage.remove(StorageKey.ACCESS_TOKEN);
        Storage.remove(StorageKey.REFRESH_TOKEN);

        dispatch(tokenRefreshFailed());

        refreshTokenPromise = null;
      });

    return refreshTokenPromise;
  }
  return Promise.resolve(parsedAccessToken);
}
