import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getVisiblePages } from '../../../../../../selectors/rendering';
import { getTotalPagesForDigibook } from '../../../../../../selectors/digibooks';

export default function useSpreadSpecs() {
  const visiblePages = useSelector(getVisiblePages);
  const totalPages = useSelector(getTotalPagesForDigibook);

  const isSinglePage = visiblePages.filter(x => x !== null).length === 1;
  const isRightPage = visiblePages[0] === null && typeof visiblePages[1] === 'number';
  const isStandalonePage =
    visiblePages[0] === 0 || // cover
    visiblePages[0] === 1 || // first page
    visiblePages[0] === totalPages + 1 || // backcover
    (visiblePages[0] === totalPages && !isRightPage);

  const specs = useMemo(
    () => ({
      isSinglePage,
      isRightPage,
      isStandalonePage,
    }),
    [isSinglePage, isRightPage, isStandalonePage],
  );

  return specs;
}
