export default function getSizes(pixelsPerMM) {
  const CROSSHAIR_DIAMETER = 13 * pixelsPerMM;
  const CENTER = CROSSHAIR_DIAMETER / 2;
  const CROSSHAIR_OUTER_CIRCLE_RADIUS = CROSSHAIR_DIAMETER / 3;
  const BUTTON_RADIUS = CROSSHAIR_OUTER_CIRCLE_RADIUS * 0.75;

  return {
    CROSSHAIR_DIAMETER,
    CENTER,
    CROSSHAIR_OUTER_CIRCLE_RADIUS,
    BUTTON_RADIUS,
  };
}
