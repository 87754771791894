/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { func, arrayOf, bool, shape, string, objectOf } from 'prop-types';
import { MenuItem } from '@pelckmans/business-components/components/action-menu';
import InfoIcon from '@pelckmans/business-components/icons/Info';
import BinIcon from '@pelckmans/business-components/icons/Bin';
import PushPinIcon from '@pelckmans/business-components/icons/PushPin';
import PlusInCircleIcon from '@pelckmans/business-components/icons/PlusInCircle';
import { openNotesEditorModal } from '../../../../../../actions/dialog';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { getNotesForActiveNodes } from '../../../../../../selectors/notes';
import { formatDate } from '../../../../../../utils/intl';
import Alert from '../../../../../../components/alert';
import Collapsible from '../../../../../../components/collapsible';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import TocNodeTitle from '../tocnode-title';

function AddButton({ onClick }) {
  const [t] = useTranslation();

  return (
    <li className="pbb-list__item">
      <div className="pbb-list__link-wrap">
        <button type="button" data-testid="add-note-button" onClick={onClick} className="pbb-list__link" title={t('drawer.notes.buttons.add_tooltip')}>
          <PlusInCircleIcon className="pbb-list__link-icon" />
          <span className="pbb-list__link-text">{t('drawer.notes.buttons.add')}</span>
        </button>
      </div>
    </li>
  );
}

AddButton.propTypes = {
  onClick: func.isRequired,
};

export function Notes({ dispatch, activeNodes, notesForActiveNodes }) {
  const [t] = useTranslation();

  const openNoteEditor = (tocNodeId, noteId, activeTab) => dispatch(openNotesEditorModal(tocNodeId, noteId, activeTab));

  return (
    <>
      <p className="pbb-drawer__intro">{t('drawer.notes.title')}</p>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-notes">
            {activeNodes.length ? (
              <Collapsible>
                {activeNodes.map(tocNode => {
                  const notes = notesForActiveNodes[tocNode.id] || [];
                  const sortedNotes = [...notes].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

                  return (
                    <Collapsible.Item key={tocNode.id}>
                      <Collapsible.Title>
                        <span className="pbb-collapsible__title-text">
                          {tocNode.displayPrefix && <span className="pbb-collapsible__subtitle">{tocNode.prefix}</span>}
                          <span className="pbb-collapsible__title">
                            <TocNodeTitle tocNode={tocNode} />
                          </span>
                        </span>
                      </Collapsible.Title>
                      <Collapsible.Content>
                        <ul className="pbb-list pbb-list--borders">
                          {sortedNotes.length > 0 && <AddButton onClick={() => openNoteEditor(tocNode.id)} />}
                          {sortedNotes.map(note => (
                            <li className="pbb-list__item" key={note.id} data-testid="notes" data-id={note.id}>
                              <div className="pbb-note">
                                <div className="pbb-note__caption">
                                  <span className="pbb-note__label">{formatDate(new Date(note.updatedAt))}</span>
                                  <div className="pbb-list__actions">
                                    <DrawerOptionsMenu>
                                      <MenuItem onClick={() => openNoteEditor(tocNode.id, note.id, 'note')}>
                                        <PushPinIcon />
                                        <span>{t('tabs.note')}</span>
                                      </MenuItem>
                                      <MenuItem onClick={() => openNoteEditor(tocNode.id, note.id, 'info')}>
                                        <InfoIcon />
                                        <span>{t('tabs.info.title')}</span>
                                      </MenuItem>
                                      <MenuItem onClick={() => openNoteEditor(tocNode.id, note.id, 'delete')}>
                                        <BinIcon />
                                        <span>{t('tabs.delete')}</span>
                                      </MenuItem>
                                    </DrawerOptionsMenu>
                                  </div>
                                </div>
                                <div className="pbb-note__body" dangerouslySetInnerHTML={{ __html: note.note }} />
                              </div>
                            </li>
                          ))}
                          {sortedNotes.length === 0 && (
                            <>
                              <li>
                                <Alert message={t('drawer.notes.no_notes')} className="pbb-alert--spaced" />
                              </li>
                              <AddButton onClick={() => openNoteEditor(tocNode.id)} />
                            </>
                          )}
                        </ul>
                      </Collapsible.Content>
                    </Collapsible.Item>
                  );
                })}
              </Collapsible>
            ) : (
              <Alert message={t('drawer.notes.no_notes')} />
            )}
          </div>
        </div>
      </SimpleBar>
    </>
  );
}

Notes.propTypes = {
  dispatch: func.isRequired,
  activeNodes: arrayOf(
    shape({
      id: string,
      title: string,
      prefix: string,
      displayPrefix: bool,
    }),
  ),
  notesForActiveNodes: objectOf(
    arrayOf(
      shape({
        id: string,
        moduleId: string,
        nodeId: string,
        note: string,
        createdAt: string,
        updatedAt: string,
      }),
    ),
  ),
};

Notes.defaultProps = {
  activeNodes: [],
  notesForActiveNodes: {},
};

const mapStateToProps = state => ({
  activeNodes: getActiveNodes(state),
  notesForActiveNodes: getNotesForActiveNodes(state),
});

export default connect(mapStateToProps)(Notes);
