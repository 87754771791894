import ShareIcon from '@pelckmans/business-components/icons/Share';
import SlideSetIcon from '@pelckmans/business-components/icons/SlideSet';
import SlideSetUserIcon from '@pelckmans/business-components/icons/SlideSetUser';
import { number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ReviewBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-label--alert pbb-list__label" data-testid="review-label">
      <span>{t('drawer.slideSets.states.review')}</span>
    </div>
  );
}

export function ShareBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-list__label">
      <ShareIcon className="bc-icon" />
      <span>{t('drawer.slideSets.states.shared')}</span>
    </div>
  );
}

export default function SlideSetInfo({ slideSet }) {
  const { name, state, shared } = slideSet;

  const hasBadges = state === 'REVIEW' || shared;

  return (
    <>
      <div className="pbb-flex">
        {slideSet.userId ? <SlideSetUserIcon className="pbb-list__link-icon" /> : <SlideSetIcon className="pbb-list__link-icon" />}
        <span className="pbb-list__link-text">{name}</span>
      </div>
      {hasBadges && (
        <div className="pbb-list__labels">
          {state === 'REVIEW' && <ReviewBadge />}
          {shared && <ShareBadge />}
        </div>
      )}
    </>
  );
}

SlideSetInfo.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};
