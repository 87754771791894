import React from 'react';
import { number, string, func } from 'prop-types';

export default function RoundedImageButton({ src, x, y, size, title, onClick, onMouseDown, onTouchStart, 'data-testid': dataTestId, className, id }) {
  return (
    <>
      {/** Clipping makes sure the button is treated as a circle (corners are not clickable). */}
      <clipPath id={`${src}-${x}-${y}`}>
        <circle cx={x + size / 2} cy={y + size / 2} r={size / 2} />
      </clipPath>
      <image
        id={id}
        className={className}
        role="button"
        href={src}
        height={size}
        width={size}
        x={x}
        y={y}
        clipPath={`url(#${src}-${x}-${y})`}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onClick}
        data-testid={dataTestId}
      >
        {title && <title>{title}</title>}
      </image>
    </>
  );
}

RoundedImageButton.propTypes = {
  src: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  size: number.isRequired,
  onClick: func,
  onMouseDown: func,
  onTouchStart: func,
  title: string,
  'data-testid': string,
  className: string,
  id: string,
};

RoundedImageButton.defaultProps = {
  title: undefined,
  onClick: undefined,
  onMouseDown: undefined,
  onTouchStart: undefined,
  'data-testid': undefined,
  className: undefined,
  id: undefined,
};
