/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useAnnotationLeftPosition from './hooks/use-annotation-left-position';
import Tools from '../../enums/tools';
import { getEventPositions } from './utils';
import { ANNOTATION_PADDING } from './constants';
import anchorposition from '../../enums/anchorposition';
import JoditEditor from './jodit-editor';
import DeleteTextConfirmationModal from '../../modules/player/components/dialogs/deleteTextConfirmation';

const DEFAULT_TEXT = '<p><br></p>';

export default function TextAnnotations({
  viewportTransform,
  pageWidth,
  pageHeight,
  annotations,
  addAnnotation,
  editAnnotation,
  setSelectedAnnotationId,
  selectedAnnotation,
  isSinglePage,
  isRightPage,
  isStandalonePage,
  isSolutionsPageVisible,
  removeAnnotation,
  currentTool,
  sidebarAnchor,
  lang,
}) {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const wrapper = useRef(null);
  const clickRef = useRef(null);
  const editorRef = useRef(null);

  const { getAnnotationLeft } = useAnnotationLeftPosition({ pageWidth, pageHeight, isSinglePage, isRightPage, isStandalonePage, isSolutionsPageVisible, sidebarAnchor });

  function handleAnnotationAdd(e) {
    if (e.target !== wrapper.current || currentTool !== Tools.TEXT_ANNOTATION || clickRef.current !== wrapper.current) return;

    const id = shortid.generate();
    const { left, top } = getEventPositions(
      {
        // offset with annotation padding and half the default font size so the user can start typing where he clicked
        top: e.clientY - (ANNOTATION_PADDING + 8) * viewportTransform[0],
        left: e.clientX - ANNOTATION_PADDING * viewportTransform[0],
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    const newAnnotation = {
      id,
      text: DEFAULT_TEXT,
      top,
      left,
      resized: false,
      isEdited: false,
    };

    addAnnotation(newAnnotation);
  }

  function handleAnnotationMove(e, { node }) {
    if (!selectedAnnotation) return;

    const { left, top, isOnSolutionsPage } = getEventPositions(
      {
        top: node.getBoundingClientRect().top,
        left: node.getBoundingClientRect().left,
      },
      {
        viewportTransform,
        isSinglePage,
        isRightPageOnSpread: isRightPage && !isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      },
    );

    if (isOnSolutionsPage) return;

    editAnnotation(selectedAnnotation.id, { ...selectedAnnotation, top, left });
  }

  const handleAnnotationResize = useCallback(
    (width, height) => {
      editAnnotation(selectedAnnotation.id, { ...selectedAnnotation, width, height, resized: true });
    },
    [editAnnotation, selectedAnnotation],
  );

  function updateActiveAnnotation(annotation) {
    setSelectedAnnotationId(annotation.id);
  }

  const handleDelete = () => {
    removeAnnotation(selectedAnnotation.id);
    setOpenConfirmationModal(false);
  };

  const onDeleteClick = useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  /**
   * Update annotation state if tool / selected annotation or visible pages change.
   */
  useEffect(() => {
    function hasTextContent(annotation) {
      const element = document.createElement('div');
      element.innerHTML = annotation.text;

      return element.textContent.trim() !== '';
    }

    // Clear empty annotations which are not active
    annotations.forEach(annotation => {
      if (annotation.id !== selectedAnnotation?.id && !hasTextContent(annotation)) {
        removeAnnotation(annotation.id);
      }
    });
  }, [currentTool, annotations, selectedAnnotation?.id, removeAnnotation]);

  const onAnnotationChange = useCallback(
    updatedProps => {
      editAnnotation(selectedAnnotation.id, { isEdited: true, ...updatedProps });
    },
    [editAnnotation, selectedAnnotation?.id],
  );

  useEffect(() => {
    setSelectedAnnotationId(null);
  }, [setSelectedAnnotationId, currentTool]);

  return (
    <>
      <div
        data-testid="text-annotation-layer"
        ref={wrapper}
        className={classNames('text-wrapper', {
          'text-wrapper--interactive': currentTool === Tools.TEXT_ANNOTATION,
        })}
        onMouseDown={e => {
          clickRef.current = e.target;
        }}
        onTouchStart={e => {
          clickRef.current = e.target;
        }}
        onClick={handleAnnotationAdd}
      >
        <div
          style={{
            position: 'absolute',
            transform: `matrix(${viewportTransform.join(',')})`,
          }}
        >
          {isSolutionsPageVisible && (
            // This avoids click events going through the solutions page
            <div
              className="solutions-page"
              style={{
                position: 'absolute',
                top: 0,
                left: sidebarAnchor === anchorposition.LEFT ? -pageWidth : pageWidth,
                width: pageWidth,
                height: pageHeight,
              }}
            />
          )}
          {annotations
            .filter(x => !x.fontId) // only new annotations
            .map(annotation => (
              <Draggable
                key={annotation.id}
                onStop={handleAnnotationMove}
                position={{
                  y: annotation.top,
                  x: getAnnotationLeft(annotation),
                }}
                scale={viewportTransform[0]}
                handle=".handlebar"
              >
                <div className="annotation-wrapper" data-testid={`annotation-${annotation.id}`}>
                  {annotation.id === selectedAnnotation?.id ? (
                    <JoditEditor
                      ref={editorRef}
                      annotation={annotation}
                      onChange={onAnnotationChange}
                      onResize={handleAnnotationResize}
                      onDelete={onDeleteClick}
                      scale={viewportTransform[0]}
                      lang={lang}
                    />
                  ) : (
                    <div
                      className="text-annotation-item"
                      style={{
                        height: annotation.resized ? `${annotation.height}px` : 'auto',
                        width: annotation.resized ? `${annotation.width}px` : 'max-content',
                        backgroundColor: annotation.backgroundColor,
                      }}
                      onClick={() => updateActiveAnnotation(annotation)}
                      dangerouslySetInnerHTML={{ __html: annotation.text }}
                    />
                  )}
                </div>
              </Draggable>
            ))}
        </div>
      </div>
      {openConfirmationModal && createPortal(<DeleteTextConfirmationModal onClose={() => setOpenConfirmationModal(false)} onDelete={handleDelete} />, document.body)}
    </>
  );
}

TextAnnotations.propTypes = {
  viewportTransform: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageWidth: PropTypes.number.isRequired,
  pageHeight: PropTypes.number.isRequired,
  annotations: PropTypes.arrayOf(PropTypes.object).isRequired,
  addAnnotation: PropTypes.func.isRequired,
  editAnnotation: PropTypes.func.isRequired,
  setSelectedAnnotationId: PropTypes.func.isRequired,
  selectedAnnotation: PropTypes.object,
  isSinglePage: PropTypes.bool.isRequired,
  isRightPage: PropTypes.bool.isRequired,
  isStandalonePage: PropTypes.bool.isRequired,
  isSolutionsPageVisible: PropTypes.bool,
  removeAnnotation: PropTypes.func.isRequired,
  currentTool: PropTypes.string.isRequired,
  sidebarAnchor: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['nl', 'fr']).isRequired,
};

TextAnnotations.defaultProps = {
  selectedAnnotation: undefined,
  isSolutionsPageVisible: false,
};
