import React from 'react';
import { func } from 'prop-types';
import qs from 'query-string';

import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { locationShape } from 'react-router-props';

import { authenticated, authenticationFailed } from '../../actions/auth';

export const AuthCallback = ({ location, onSuccess, onFailure }) => {
  const {
    err,
    access_token, // eslint-disable-line camelcase
    refresh_token, // eslint-disable-line camelcase
    returnUrl,
  } = qs.parse(location.search);

  if (err) {
    onFailure(err);
    return null; // Temporary situation until we got an unexpected error page
  }

  onSuccess(access_token, refresh_token);

  return <Redirect to={returnUrl || '/'} />;
};

AuthCallback.propTypes = {
  location: locationShape.isRequired,
  onSuccess: func.isRequired,
  onFailure: func.isRequired,
};

const mapDispatchToProps = {
  onSuccess: authenticated,
  onFailure: authenticationFailed,
};

export const ConnectedAuthCallback = connect(undefined, mapDispatchToProps)(AuthCallback);

export default withRouter(ConnectedAuthCallback);
