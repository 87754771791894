import React from 'react';
import SimpleBar from 'simplebar-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SlideSetList from './SlideSetList';
import { getActiveNodesForMediaLinks } from '../../../../../../selectors/toc';
import Alert from '../../../../../../components/alert';

function SlideSets() {
  const [t] = useTranslation();
  const activeNodes = useSelector(getActiveNodesForMediaLinks);

  return (
    <>
      <span data-testid="slideSetTitle" className="pbb-drawer__intro">
        {t('drawer.slideSets.title')}
      </span>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-material" data-testid="slideSetList">
            {activeNodes.length ? <SlideSetList /> : <Alert message={t('drawer.slideSets.noActiveNodes')} />}
          </div>
        </div>
      </SimpleBar>
      <div className="pbb-footer">
        <div className="pbb-mask" />
      </div>
    </>
  );
}

export default SlideSets;
