/* eslint-disable default-case */
const SLATE_TYPES = {
  block: 'block',
  mark: 'mark',
  node: 'node',
};
const NODE_TYPES = {
  paragraph: 'paragraph',
  listItem: 'list-item',
  orderedList: 'ordered-list',
  unorderedList: 'unordered-list',
  bold: 'bold',
  italic: 'italic',
};

export const SLATE_MAX_LENGTH = 1000;

const schema = {
  document: {
    normalize: (editor, { code, node }) => {
      switch (code) {
        case 'last_child_type_invalid': {
          editor.insertNodeByKey(node.key, node.nodes.size, {
            object: SLATE_TYPES.block,
            type: NODE_TYPES.paragraph,
          });
          break;
        }
      }
    },
  },
};

export { NODE_TYPES, SLATE_TYPES, schema };
