import axios from 'axios';
import pdfjs from 'pdfjs-dist/webpack';
import api from './api';

export default class PDFPageProviderService {
  static fetchAndParseFile(url, authorizationToken) {
    const { promise, cancel } = PDFPageProviderService.fetchFile(url, authorizationToken);

    const res = {
      cancel,
      promise: promise
        .then(response => {
          const objectUrl = PDFPageProviderService.createObjectUrl(response.data);
          return Promise.all([pdfjs.getDocument(objectUrl).promise, objectUrl]);
        })
        .then(([pdfDocument, objectUrl]) => {
          window.URL.revokeObjectURL(objectUrl); // object url is not needed anymore after this point. (pdf js needed it)
          res.isResolved = true;
          return pdfDocument;
        }),
    };
    return res;
  }

  /**
   * Fetches the file for an url.
   * @param {String} url The url on which the file can be fetched.
   * @return {RequestObject} RequestObject
   */
  static fetchFile(url, authorizationToken) {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const config = {
      responseType: 'blob',
      cancelToken,
    };
    if (authorizationToken) config.headers = { Authorization: `Bearer ${authorizationToken}` };

    return {
      promise: api.get(url, config),
      cancel: source.cancel,
    };
  }

  static createObjectUrl(blob) {
    return window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
  }
}
