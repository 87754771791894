import { PORTAAL_URL } from '../constants/constants';

export const buildModuleInPUrl = (language, superModule, moduleSlug, node = {}) => {
  const key = language !== 'nl' ? 'materiel' : 'materiaal';

  const queryParams = node.id ? `?nodeId=${node.id}` : '';

  const { subjectSlug, methodSlug, slug } = superModule;

  return `${PORTAAL_URL}${subjectSlug}/${methodSlug}/${slug}/${key}/${moduleSlug}${queryParams}`;
};
