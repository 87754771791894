import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { func, string, shape } from 'prop-types';
import Sizes from '../../../../../enums/sizes';
import tools from '../../../../../enums/tools';
import ColorPicker from './color-picker';

const buildColorClassName = tool => color => `pbb-${tool}-color pbb-${tool}-color--${color}`;

const ToolOptions = ({ variant, onColorClick, onSizeClick, currentOptions }) => {
  const getColorClassName = buildColorClassName(variant);
  const [t] = useTranslation();
  return (
    <>
      <ColorPicker onColorClick={onColorClick} variant={variant} selectedColor={currentOptions.color} />
      {variant !== tools.TEXT_MARKER && (
        <>
          <div className="divider-line" />
          <div className={classNames('pbb-menu__sizes', `pbb-menu__sizes--${variant}`)}>
            {Object.values(Sizes).map(size => (
              <button
                key={size}
                onClick={() => onSizeClick(size)}
                type="button"
                title={t(`sizes.tool.${size}`)}
                className={classNames(getColorClassName(currentOptions.color), `size--${size}`, 'pbb-sidebar__button', {
                  'pbb-sidebar__button--active': currentOptions.size === size,
                })}
              >
                {variant === tools.ANNOTATION ? <i role="img" className="pbb-sidebar__icon icon-bb-annotation" /> : <span className="indicator" />}
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
};

ToolOptions.propTypes = {
  variant: string.isRequired,
  onColorClick: func.isRequired,
  onSizeClick: func.isRequired,
  currentOptions: shape({
    color: string,
    size: string,
    backgroundColor: string,
  }),
};

ToolOptions.defaultProps = {
  currentOptions: {},
};

export default ToolOptions;
