import React, { useRef, useEffect, useCallback } from 'react';
import { arrayOf, number, func } from 'prop-types';

import Backdrop from './components/Backdrop';
import Tools from '../../../../../../enums/tools';
import Actions from './components/Actions';
import Markings from './components/Markings';
import SetSquareFreeDrawingStrategy from './setSquareFreeDrawingStrategy';
import useSetSquareResizing from './useSetSquareResizing';
import useSetSquareRotating from './useSetSquareRotating';
import DegreesIndicator from '../components/DegreesIndicator';
import { MATH_TOOL_STROKE_WIDTH } from '../constants';
import { degreesToRadians } from '../utils';

const BUTTONS_OFFSET_SIDES = 0.74;
const BUTTONS_OFFSET_TOP = 0.13;

const RULER_INITIAL_LENGTH_IN_MM = 140;
const RULER_MIN_LENGTH_IN_MM = 60;
const RULER_MAX_LENGTH_IN_MM = 500;
const RULER_PADDING_IN_MM = 10;

function calculateCenter(mathToolWrapperElement, viewportTransform, rotationInDegrees, size, radiusIndicator, translateX) {
  const offsetYDegreesIndicator = Math.sin(degreesToRadians(90 - rotationInDegrees)) * (size / 3 + radiusIndicator) * viewportTransform[0];
  const offsetTop = mathToolWrapperElement.parentElement.clientHeight / 2 - offsetYDegreesIndicator;

  const offsetXDegreesIndicator = Math.cos(degreesToRadians(90 - rotationInDegrees)) * (size / 3 + radiusIndicator) * viewportTransform[0];
  const offsetLeft =
    mathToolWrapperElement.parentElement.clientWidth / 2 - mathToolWrapperElement.getBoundingClientRect().width / 2 + offsetXDegreesIndicator - translateX * viewportTransform[0];

  return { offsetLeft, offsetTop };
}

export default function SetSquare({ pixelsPerMM, viewPortTransform, setFreeDrawingStrategy, setCalculateCenter }) {
  const rulerPadding = RULER_PADDING_IN_MM * pixelsPerMM;
  const getRenderSize = length => rulerPadding + (length * pixelsPerMM) / 2;

  const ref = useRef(null);
  const degreesRef = useRef(0);

  const initialSize = getRenderSize(RULER_INITIAL_LENGTH_IN_MM);
  const { resize, size } = useSetSquareResizing({
    initialSize,
    minSize: getRenderSize(RULER_MIN_LENGTH_IN_MM),
    maxSize: getRenderSize(RULER_MAX_LENGTH_IN_MM),
    viewPortTransform,
    offset: BUTTONS_OFFSET_SIDES,
    degrees: degreesRef.current,
  });

  const { rotate, degrees, degreesDisplay } = useSetSquareRotating({
    ref,
    initialSize,
    offsetTop: BUTTONS_OFFSET_TOP,
    offsetSide: BUTTONS_OFFSET_SIDES,
    scale: viewPortTransform[0],
  });
  degreesRef.current = degrees;

  const translateX = initialSize - size;

  const stableCalculateCenter = useCallback(
    (mathToolWrapperEl, viewportTransform) => calculateCenter(mathToolWrapperEl, viewportTransform, degrees, size, pixelsPerMM * 4, translateX),
    [degrees, pixelsPerMM, size, translateX],
  );

  useEffect(() => {
    setCalculateCenter(stableCalculateCenter);
    return () => setCalculateCenter(undefined);
  }, [setCalculateCenter, stableCalculateCenter]);

  useEffect(() => {
    setFreeDrawingStrategy(new SetSquareFreeDrawingStrategy(viewPortTransform, ref.current));
    return () => setFreeDrawingStrategy(undefined);
  }, [setFreeDrawingStrategy, viewPortTransform]);

  return (
    <svg
      className="math-tool"
      width={size * 2}
      height={size}
      id={Tools.SET_SQUARE}
      data-angle={degrees}
      data-testid={Tools.SET_SQUARE}
      role="img"
      style={{ transformOrigin: `${size}px 0px`, transform: `translateX(${translateX}px) rotate(${degrees}deg)` }}
      viewBox={`${-MATH_TOOL_STROKE_WIDTH} ${-MATH_TOOL_STROKE_WIDTH} ${2 * size + 2 * MATH_TOOL_STROKE_WIDTH} ${size + 2 * MATH_TOOL_STROKE_WIDTH}`}
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      data-translate-x={translateX}
    >
      <g>
        <Backdrop size={size} />
      </g>
      <g data-drag clipPath="url(#set-square-triangle-clip-path)">
        <Markings size={size} pixelsPerMM={pixelsPerMM} padding={rulerPadding} />
      </g>
      <g>
        <DegreesIndicator xPos={size} yPos={size / 3} radius={pixelsPerMM * 4} degrees={degreesDisplay} fontSize={pixelsPerMM * 2.5} />
        <Actions size={size} pixelsPerMM={pixelsPerMM} offsetTop={BUTTONS_OFFSET_TOP} offsetSide={BUTTONS_OFFSET_SIDES} onResize={resize} onRotate={rotate} />
      </g>
      <clipPath id="set-square-triangle-clip-path">
        <path
          d={`M${-MATH_TOOL_STROKE_WIDTH * 2} ${-MATH_TOOL_STROKE_WIDTH} L${2 * size + 4 * MATH_TOOL_STROKE_WIDTH} ${-MATH_TOOL_STROKE_WIDTH} L${size} ${size +
            2 * MATH_TOOL_STROKE_WIDTH} Z`}
        />
      </clipPath>
    </svg>
  );
}

SetSquare.propTypes = {
  pixelsPerMM: number.isRequired,
  viewPortTransform: arrayOf(number).isRequired,
  setFreeDrawingStrategy: func.isRequired,
  setCalculateCenter: func.isRequired,
};
