import React from 'react';
import classNames from 'classnames';
import { bool, func, string } from 'prop-types';

const Button = React.forwardRef(({ active, onMouseDown, icon, title }, ref) => (
  <button
    onMouseDown={onMouseDown}
    type="button"
    ref={ref}
    className={classNames('pbb-wysiwyg-tool', {
      'pbb-wysiwyg-tool--active': active,
    })}
    title={title}
  >
    <span className={classNames('pbb-wysiwyg-icon', icon)} data-testid="wysiwyg-btn-icon" />
  </button>
));

Button.propTypes = {
  active: bool,
  icon: string.isRequired,
  title: string.isRequired,
  onMouseDown: func.isRequired,
};

Button.defaultProps = {
  active: false,
};

export { Button };
