/* eslint-disable react/no-danger */
import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { oneOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@pelckmans/business-components/components/button';
import { ModalDialog, Dialog } from '@pelckmans/business-components/components/dialog';
import { ShareManager } from '@pelckmans/business-components/components/share-manager';
import Tabs from '../tabs/tabs';
import Tab from '../tabs/tab';
import Info from './info';
import { tokenGetter } from '../../../../../services/api';
import dialogTypes from '../../../../../enums/dialogTypes';
import { getCurrentDigibook, getTeacherFeaturesEnabledFor } from '../../../../../selectors/digibooks';
import { getModuleById } from '../../../../../selectors/module';
import { getMedialink } from '../../../../../selectors/medialinks';
import { getRoles } from '../../../../../selectors/user';
import { highestRole } from '../../../../../utils/roles';
import { closeDialog } from '../../../../../actions/dialog';
import { setMedialinkShared } from '../../../../../actions/medialinks';
import { trackShareMaterial } from '../../../../../ga-events/sharing-helpers';
import GoogleAnalyticsContext from '../../../../../contexts/GoogleAnalyticsContext';
import { sharingGaEventArgs } from '../../../../../ga-events';

import { errorOccurred } from '../../../../../actions/error';
import { STUDIO_GROUP } from '../../../../../constants/constants';
import { BulkSharingMessage } from './bulk-sharing-message';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import featureToggle from '../../../../../enums/feature-toggle';
import getMyClassesLink from '../../../../../utils/getMyClassesLink';

const tabs = {
  SHARING: 'sharing',
  INFO: 'info',
};

const MedialinkOptionsDialog = ({ medialinkId, superModuleId, activeTab }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const medialink = useSelector(state => getMedialink(state, medialinkId));
  const superModule = useSelector(state => getModuleById(state, superModuleId));
  const roles = useSelector(state => getRoles(state));

  const digibook = useSelector(getCurrentDigibook);
  const featureToggles = useSelector(state => state.featureToggles);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);
  const hasTeacherDigitalManualLicense = digibook && digibook.teacherDigitalManualLicense;

  const isSharingEnabled = getFeatureToggleFor(featureToggle.SHARING_MATERIAL);
  const displaySharingTab = teacherFeaturesEnabled && isSharingEnabled;
  const [currentTab, setCurrentTab] = useState(displaySharingTab ? tabs.SHARING : tabs.INFO);
  const [isModalVisible, setIsModalVisible] = useState(!hasTeacherDigitalManualLicense || !isSharingEnabled);

  const dispatch = useDispatch();
  const tokenAccessor = useMemo(() => ({ tokenGetter: tokenGetter(dispatch) }), [dispatch]);
  const handleError = useCallback(() => dispatch(errorOccurred({ status: 500, originalError: 'error in share manager component' })), [dispatch]);

  const { ga } = useContext(GoogleAnalyticsContext);

  const handleClose = () => {
    dispatch(closeDialog(medialink.id, dialogTypes.OPTIONS));
  };

  const handleSave = (shareInfo, oldShareInfo) => {
    const { audiences: newAudiences = [] } = shareInfo || {};
    const { audiences: oldAudiences = [] } = oldShareInfo || {};

    const shares = newAudiences
      .filter(x => !x.assignment)
      .map(audience => ({
        id: shareInfo.id,
        label: audience.label,
      }));

    const assignments = newAudiences.filter(x => x.assignment).map(audience => audience.assignment);

    dispatch(setMedialinkShared(medialink.id, shares, assignments));

    if (ga) {
      trackShareMaterial({
        newAudiences,
        oldAudiences,
        moduleName: superModule.name,
        material: { name: medialink.name, kind: medialink.kind },
        role: highestRole(roles),
        onShare: payload => {
          ga.event(sharingGaEventArgs.buildShareSingleMaterial(payload));
        },
        onUnshare: payload => {
          ga.event(sharingGaEventArgs.buildUnshareSingleMaterial(payload));
        },
        onStop: payload => {
          ga.event(sharingGaEventArgs.buildStopShareSingleMaterial(payload));
        },
      });
    }

    handleClose();
  };

  const nodeId = medialink.hierarchy[medialink.hierarchy.length - 1];

  const showModal = useCallback(() => setIsModalVisible(true), []);

  useEffect(() => {
    if (activeTab) setCurrentTab(activeTab);
  }, [activeTab]);

  return (
    <div
      className={classNames('pbb-modal-medialink-options', {
        'pbb-modal-wrapper--hidden': !isModalVisible,
        'pbb-modal-medialink-options--owner': displaySharingTab,
      })}
    >
      <ModalDialog id="medialink-options-modal" onClose={handleClose} closeViaEscape zIndex={10001} closeTitle={t('actions.close')}>
        <Dialog.Header data-testid="dialog-header" title={medialink.name} startAdornment={<i role="img" className={medialink.icon} />} />
        <Dialog.Body data-testid="dialog-body">
          <div className="pbb-modal__body">
            <Tabs onClick={setCurrentTab} activeTab={currentTab}>
              {displaySharingTab && <Tab title={t('tabs.sharing')} id={tabs.SHARING} icon="icon-bb-label-share" />}
              <Tab title={t('tabs.info.title')} id={tabs.INFO} icon="icon-bb-modal-info" />
            </Tabs>
            <div className="pbb-modal__body-wrapper pbb-modal__body-wrapper--no-pb">
              {hasTeacherDigitalManualLicense && isSharingEnabled && (
                <div
                  className={classNames('pbb-share-manager', {
                    'pbb-share-manager--hidden': currentTab !== tabs.SHARING,
                  })}
                >
                  <ShareManager
                    moduleId={medialink.moduleId}
                    materialId={medialink.id}
                    materialType="medialink"
                    nodeId={nodeId}
                    tokenAccessor={tokenAccessor}
                    onCancel={handleClose}
                    onSave={handleSave}
                    onGroupsFetched={showModal}
                    translations={{
                      sharingDisabled: t('shareManager.sharingDisabled'),
                      sharingEnabled: t('shareManager.sharingEnabled'),
                      cancel: t('shareManager.cancel'),
                      save: t('shareManager.save'),
                      rangeSeparator: t('shareManager.rangeSeparator'),
                      overrideRangeWarning: t('shareManager.overrideRangeWarning'),
                      noGroupsTitle: t('shareManager.noGroupsTitle'),
                      noGroupsMessage: t('shareManager.noGroupsMessage', {
                        myClassesLink: getMyClassesLink(),
                      }),
                      close: t('shareManager.close'),
                      search: t('shareManager.search'),
                      noSearchResults: t('shareManager.noSearchResults'),
                      asAssignment: t('shareManager.assignments.asAssignment'),
                      choosePeriod: t('shareManager.assignments.choosePeriod'),
                      multiple: t('shareManager.assignments.multiple'),
                      withRetry: t('shareManager.assignments.withRetry'),
                      onlyExerciseCanBeSharedAsAssignment: t('shareManager.assignments.onlyExerciseCanBeSharedAsAssignment'),
                      stopCurrentAssignmentsWhenSharingWithNone: t('shareManager.assignments.stopCurrentAssignmentsWhenSharingWithNone'),
                      stopSharingCurrentAssignment: t('shareManager.assignments.stopSharingCurrentAssignment'),
                    }}
                    onError={handleError}
                    enableSharing={false}
                    enableAssignment={featureToggles.ASSIGNMENTS}
                    enableMaterialAsAssignment={featureToggles.MATERIAL_ASSIGNMENTS}
                    mediaLinkKind={medialink.kind}
                    language={language === 'fr' ? 'fr' : 'nl'}
                    studioGroup={STUDIO_GROUP}
                  >
                    <BulkSharingMessage superModuleId={superModuleId} nodeId={nodeId} />
                  </ShareManager>
                </div>
              )}
              {!hasTeacherDigitalManualLicense && currentTab === tabs.SHARING && (
                <div className="bc-notification__notification">
                  <span dangerouslySetInnerHTML={{ __html: t('sharing.no_dhlk') }} />
                </div>
              )}
              {currentTab === tabs.INFO && <Info medialink={medialink} superModuleId={superModuleId} />}
            </div>
          </div>
        </Dialog.Body>
        {currentTab !== tabs.SHARING && (
          <Dialog.Footer data-testid="dialog-footer">
            <Button onClick={handleClose} data-testid="btn-close">
              {t('actions.close')}
            </Button>
          </Dialog.Footer>
        )}
      </ModalDialog>
    </div>
  );
};

MedialinkOptionsDialog.propTypes = {
  medialinkId: string.isRequired,
  superModuleId: string,
  activeTab: oneOf([tabs.SHARING, tabs.INFO]),
};

MedialinkOptionsDialog.defaultProps = {
  superModuleId: undefined,
  activeTab: undefined,
};

export default MedialinkOptionsDialog;
