import React, { createContext, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { node } from 'prop-types';

import { getCurrentDigibook } from '../../../selectors/digibooks';
import { getVisiblePages } from '../../../selectors/rendering';
import useAnalytics from '../../../hooks/useAnalytics';
import { ANALYTICS_EVENT_TYPES } from '../../../enums/analytics';

const TIMEOUT = 3000;

export const PageEventContext = createContext({
  capturePageEvent: () => {},
});

const PageEventContextProvider = ({ children }) => {
  const digibook = useSelector(getCurrentDigibook);
  const currentSpread = useSelector(getVisiblePages);

  const timeoutRef = useRef(null);
  const currentSpreadRef = useRef([0]);

  const analytics = useAnalytics();

  // Ref should be updated whenever the page changes
  currentSpreadRef.current = currentSpread;

  const capturePageEvent = useCallback(
    subLocationId => {
      const captureEvent = (subLocation, pageNumber) => {
        if (!pageNumber) return;

        const event = {
          eventType: ANALYTICS_EVENT_TYPES.DIGIBOOK_PAGE_VIEWED,
          objectId: digibook.id,
          subObjectId: pageNumber.toString(),
        };

        if (subLocation) event.subLocationId = subLocation;

        analytics.capture(event);
      };

      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        const spread = currentSpreadRef.current;
        spread.forEach(pageNumber => captureEvent(subLocationId, pageNumber));
      }, TIMEOUT);
    },
    [analytics, digibook.id],
  );

  return <PageEventContext.Provider value={{ capturePageEvent }}>{children}</PageEventContext.Provider>;
};

PageEventContextProvider.propTypes = {
  children: node.isRequired,
};

export default PageEventContextProvider;
