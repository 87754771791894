import anchorposition from '../../enums/anchorposition';
import { SIDEBAR_WIDTH } from '../../modules/player/components/dialogs/mediaDialogs/chooser';

export function getEventPositions(
  { top: clientTop, left: clientLeft },
  { viewportTransform, isSinglePage, isRightPageOnSpread, isSolutionsPageVisible, sidebarAnchor, pageWidth, pageHeight },
) {
  const sidebarOffset = sidebarAnchor === anchorposition.LEFT ? SIDEBAR_WIDTH : 0;
  let left = (clientLeft - viewportTransform[4] - sidebarOffset) / viewportTransform[0];
  const top = (clientTop - viewportTransform[5]) / viewportTransform[0];

  const isNextToSolutionsPageWithSidebarLeft = isSolutionsPageVisible && sidebarAnchor === anchorposition.LEFT && left <= -pageWidth && top >= 0 && top <= pageHeight;
  const isNextToSolutionsPageWithSidebarRight = isSolutionsPageVisible && sidebarAnchor === anchorposition.RIGHT && left >= pageWidth && top >= 0 && top <= pageHeight;

  const isOnTopOfSolutionsPageWithSidebarLeft = isSolutionsPageVisible && sidebarAnchor === anchorposition.LEFT && left < 0 && left >= -pageWidth && top >= 0 && top <= pageHeight;
  const isOnTopOfSolutionsPageWithSidebarRight =
    isSolutionsPageVisible && sidebarAnchor === anchorposition.RIGHT && left > pageWidth && left < pageWidth * 2 && top >= 0 && top <= pageHeight;
  /**
   * Annotations on the sides of the book always have to be stored correctly.
   * They are indicated trough their left property being:
   * left side: left < 0
   * right side: left > pageWidth
   */
  if (isSinglePage && isRightPageOnSpread && left > 0) left += pageWidth;
  if (isSinglePage && !isRightPageOnSpread && left > pageWidth) left += pageWidth;
  if (isNextToSolutionsPageWithSidebarLeft) left += pageWidth;
  if (isNextToSolutionsPageWithSidebarRight) left -= pageWidth;

  return { left, top, isOnSolutionsPage: isOnTopOfSolutionsPageWithSidebarLeft || isOnTopOfSolutionsPageWithSidebarRight };
}

export function getAnnotationLeftPosition(annotation, { isSinglePage, isRightPage, isStandalonePage, isSolutionsPageVisible, sidebarAnchor, pageWidth, pageHeight }) {
  const { top, left } = annotation;
  const solutionsPageWithSidebarLeft = isSolutionsPageVisible && sidebarAnchor === anchorposition.LEFT;
  const solutionsPageWithSidebarRight = isSolutionsPageVisible && sidebarAnchor === anchorposition.RIGHT;

  const isOnSolutionsPageWithSidebarRight = top >= 0 && top <= pageHeight && solutionsPageWithSidebarRight && left >= pageWidth && left <= pageWidth;
  const isRightPageOnSpread = isRightPage && !isStandalonePage;

  // move annotations on or besides the book page to the left
  if (solutionsPageWithSidebarLeft && left <= 0 && top >= 0 && top <= pageHeight) return left - pageWidth;

  // move annotations on the solutions page to the book page
  if (isOnSolutionsPageWithSidebarRight) return left - pageWidth;

  // annotations besides solutions page keep same position
  if (solutionsPageWithSidebarRight && left >= pageWidth && top >= 0 && top <= pageHeight) return left;

  // move annotations to the left because right page is start page
  if (isSinglePage && isRightPageOnSpread && left > 0) return left - pageWidth;

  // move annotations to the left because there is only one page (no spread)
  if (isSinglePage && left >= pageWidth) return left - pageWidth;

  return left;
}

/**
 * Allow dragging handlebars to grow with the editor.
 */
export async function updateHandlebars() {
  const workplace = document.querySelector('.jodit-workplace');

  const { clientWidth: width, clientHeight: height } = workplace;

  const handlebars = document.querySelectorAll('.handlebar');

  handlebars.forEach(async handlebar => {
    const { position } = handlebar.dataset;
    let x = 0;
    let y = 0;

    const HB_SIZE = 16;
    const styles = {};

    switch (position) {
      case 'left':
        x = -HB_SIZE / 2;
        y = 0;
        styles.width = `${HB_SIZE}px`;
        styles.height = `${height}px`;
        break;
      case 'right':
        x = width - HB_SIZE / 2;
        y = 0;
        styles.width = `${HB_SIZE}px`;
        styles.height = `${height}px`;
        break;
      case 'top':
        x = 0;
        y = -HB_SIZE / 2;
        styles.width = `${width}px`;
        styles.height = `${HB_SIZE}px`;
        break;
      case 'bottom':
        x = 0;
        y = height - HB_SIZE / 2;
        styles.width = `${width}px`;
        styles.height = `${HB_SIZE}px`;
        break;
      default:
        throw new Error('Invalid handlebar position');
    }

    styles.top = `${y}px`;
    styles.left = `${x}px`;

    Object.assign(handlebar.style, styles);
    Object.assign(handlebar, { width, height });
  });
}

export function updateFontSizeIcon(editor) {
  editor.toolbar.buttons
    .find(x => x.name === 'fontsize')
    .state.on('change.value', (property, prevValue, nextValue) => {
      const fontSizeIconWrapper = document.querySelector('.jodit-toolbar-button__button .jodit-icon_fontsize').parentElement;

      fontSizeIconWrapper.innerHTML = `<svg class="jodit-icon jodit-icon_fontsize" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" ><text x="50%" y="50%" lengthAdjust="spacing" font-size="1.25em" text-anchor="middle" dy=".3em" font-weight="bold">${nextValue}</text></svg>`;
    });
}
