/**
 * import base styles from jodit
 * ESM does not expose css, hence we do it here.
 */
import 'jodit/es2021/jodit.min.css';

/**
 * https://github.com/xdan/jodit?tab=readme-ov-file#esm-modules
 * Following plugins are available by default in the esm build:
 * 'about', 'backspace', 'bold', 'color', 'delete', 'dtd', 'enter', 'font', 'format-block',
 * 'hotkeys', 'iframe', 'image', 'inline-popup', 'link', 'ordered-list', 'placeholder',
 * 'powered-by-jodit', 'redo-undo', 'size', 'stat', 'table', 'wrap-nodes'
 * Rest can be added by importing them from the plugins folder.
 */
import 'jodit/esm/plugins/justify/justify';
import 'jodit/esm/plugins/tab/tab';
import 'jodit/esm/plugins/line-height/line-height';

/**
 * Import custom plugins
 */
import '../plugins/font-size-steppers';
import '../plugins/resize';
import '../plugins/symbols';
import '../plugins/delete-annotation';
import '../plugins/font-color';
import '../plugins/font-background-color';
import '../plugins/background-color';
import '../plugins/translations';

/**
 * Import custom icons
 */
import initJodit from '../init-jodit-icons';

initJodit();
