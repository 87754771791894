import api from '../services/api';

import { currentDigibookIdSelector } from '../selectors/digibooks';

import { ANNOTATION_SETS_FETCH_SUCCESS, SET_ACTIVE_ANNOTATION_SET, SET_CREATE_ANNOTATION_SET_LOADING } from './actionNames';
import { getActiveAnnotationSet } from '../selectors/annotationSets';
import { setCurrentTool } from './tools';
import Tools from '../enums/tools';

function retrieveDigibookAnnotationSetsSuccessAction(annotationSets) {
  return {
    type: ANNOTATION_SETS_FETCH_SUCCESS,
    payload: {
      annotationSets,
    },
  };
}

function setActiveAnnotationSetAction(annotationSet) {
  return {
    type: SET_ACTIVE_ANNOTATION_SET,
    payload: {
      annotationSet,
    },
  };
}

function setCreateAnnotationSetLoadingAction(loading) {
  return {
    type: SET_CREATE_ANNOTATION_SET_LOADING,
    payload: loading,
  };
}

export function setActiveAnnotationSet(annotationSet) {
  return async (dispatch, getState) => {
    const state = getState();

    const currentActive = getActiveAnnotationSet(state);
    if (currentActive?.id === annotationSet.id) {
      return Promise.resolve();
    }
    dispatch(setCurrentTool(Tools.POINTER));

    const digibookId = currentDigibookIdSelector(state);

    await api.patch(`/studio/digibooks/${digibookId}/annotation-sets/${annotationSet.id}`, { isActive: true });

    return dispatch(setActiveAnnotationSetAction(annotationSet));
  };
}

export function retrieveDigibookAnnotationSets(digibookId, templateName) {
  return async dispatch => {
    const response = await api.get(`/studio/digibooks/${digibookId}/annotation-sets`, { params: { templateName } });
    await dispatch(retrieveDigibookAnnotationSetsSuccessAction(response.data.data));
  };
}

export function setAnnotationSetName(annotationSetId, name, templateName) {
  return async (dispatch, getState) => {
    const state = getState();

    const digibookId = currentDigibookIdSelector(state);

    await api.patch(`/studio/digibooks/${digibookId}/annotation-sets/${annotationSetId}`, { name });
    return dispatch(retrieveDigibookAnnotationSets(digibookId, templateName));
  };
}

export function createAnnotationSet(templateName) {
  return async (dispatch, getState) => {
    const state = getState();
    const digibookId = currentDigibookIdSelector(state);

    dispatch(setCreateAnnotationSetLoadingAction(true));

    const { data: annotationSet } = await api.post(`/studio/digibooks/${digibookId}/annotation-sets`, { templateName });

    await dispatch(retrieveDigibookAnnotationSets(digibookId, templateName));
    dispatch(setActiveAnnotationSetAction(annotationSet));
    return dispatch(setCreateAnnotationSetLoadingAction(false));
  };
}

export function deleteAnnotationSet(annotationSetId, templateName) {
  return async (dispatch, getState) => {
    const state = getState();
    const digibookId = currentDigibookIdSelector(state);

    const activeAnnotationSet = getActiveAnnotationSet(state);

    await api.delete(`/studio/digibooks/${digibookId}/annotation-sets/${annotationSetId}`);
    await dispatch(retrieveDigibookAnnotationSets(digibookId, templateName));

    if (activeAnnotationSet.id === annotationSetId) {
      const newActiveAnnotationSet = getActiveAnnotationSet(getState());
      dispatch(setActiveAnnotationSetAction(newActiveAnnotationSet));
    }
  };
}

export function copyAnnotationSet(annotationSetId, templateName) {
  return async (dispatch, getState) => {
    const state = getState();

    const digibookId = currentDigibookIdSelector(state);

    const { data } = await api.post(`/studio/digibooks/${digibookId}/annotation-sets/${annotationSetId}`);
    await dispatch(retrieveDigibookAnnotationSets(digibookId, templateName));
    return dispatch(setActiveAnnotationSetAction(data));
  };
}
