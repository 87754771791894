/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useState } from 'react';
import LRUCache from '../../../services/lru-cache';

export const TextCacheContext = createContext(null);

export const TextCacheProvider = props => {
  const [cache] = useState(new LRUCache(50, 1000 * 60 * 10));

  return <TextCacheContext.Provider value={cache} {...props} />;
};
