import axios from 'axios';

import { PORTAAL_API_URL, STUDIO_GROUP } from '../constants/constants';
import getAccessToken from './utils/bearer-token';

export const BASE_URI = `${PORTAAL_API_URL}/api`;

const api = axios.create({
  baseURL: BASE_URI,
});

export const tokenGetter = dispatch => () => getAccessToken(dispatch);

function addStudioGroupInterceptor(config) {
  const newConfig = { ...config };
  if (config.url.startsWith('/studio/')) {
    newConfig.headers.StudioGroup = STUDIO_GROUP;
  } else {
    delete newConfig.headers.StudioGroup; // Remove StudioGroup header for non-studio/aws cloudfront/s3 requests
  }
  return newConfig;
}

if (api) {
  api.interceptors.request.use(addStudioGroupInterceptor);
}

export default api;
