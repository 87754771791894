import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nl from '../locales/nl.json';
import nlVo from '../locales/nl-vo.json';
import nlBao from '../locales/nl-bao.json';
import fr from '../locales/fr.json';
import { STUDIO_GROUP } from './constants/constants';

const resources = {
  nl: {
    SO: nl,
    VO: nlVo,
    BAO: nlBao,
  },
  fr: {
    ES: fr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.LANGUAGE || 'nl',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: STUDIO_GROUP,
    fallbackNS: 'SO',
  });

export default i18n;
