import { SET_CURRENT_TOOL, SET_COLOR, SET_SIZE, SET_BACKGROUND_COLOR, MARKING_CREATED, MARKINGS_CREATED, MARKINGS_DELETE_FOR_SPREAD, SET_CURRENT_MATH_TOOL } from './actionNames';
import { getSinglePageDimensions } from '../selectors/tools';

import MarkingPosition from '../enums/markingPosition';

export const setCurrentTool = tool => ({
  type: SET_CURRENT_TOOL,
  payload: {
    tool,
  },
});

export const setColor = color => ({
  type: SET_COLOR,
  payload: {
    color,
  },
});

export const setBackgroundColor = backgroundColor => ({
  type: SET_BACKGROUND_COLOR,
  payload: {
    backgroundColor,
  },
});

export const setSize = size => ({
  type: SET_SIZE,
  payload: {
    size,
  },
});

export const createMarking = (shape, pages) => ({
  type: MARKING_CREATED,
  payload: {
    shape,
    pages,
  },
});

export const createMarkings = (shapes, pages) => ({
  type: MARKINGS_CREATED,
  payload: {
    shapes,
    pages,
  },
});

export const deleteMarkingsForSpread = pages => ({
  type: MARKINGS_DELETE_FOR_SPREAD,
  payload: {
    pages,
  },
});

export function deleteMarkingsForSinglePage(pages, markingPosition) {
  return (dispatch, getState) => {
    const { width, height } = getSinglePageDimensions(getState());
    const left = markingPosition === MarkingPosition.RIGHT ? width : 0;

    const wholePageErasedShape = {
      type: 'rect',
      version: '3.2.0',
      originX: 'left',
      originY: 'top',
      left,
      top: 0,
      width,
      height,
      fill: 'rgb(0,0,0)',
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: 'butt',
      strokeDashOffset: 0,
      strokeLineJoin: 'miter',
      strokeMiterLimit: 4,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 1,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: '',
      fillRule: 'nonzero',
      paintFirst: 'fill',
      globalCompositeOperation: 'destination-out',
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0,
    };

    dispatch(createMarking(wholePageErasedShape, pages));
  };
}

export const setCurrentMathTool = tool => ({
  type: SET_CURRENT_MATH_TOOL,
  payload: {
    tool,
  },
});
