import isEqual from 'lodash/isEqual';

import audienceTypes from '../enums/audience-types'

export const getAudiencesByShareState = (state, audiences = [], otherAudiences = []) => {
    const shares = [];

    audiences.forEach(audience => {
        let otherAudience;
        switch (audience.audienceType) {
            case audienceTypes.SCHOOL:
                otherAudience = otherAudiences.find(p => p.audienceType === audienceTypes.SCHOOL && p.schoolId === audience.schoolId);
                break;
            case audienceTypes.SMARTSCHOOLSCHOOL:
                otherAudience = otherAudiences.find(p => p.audienceType === audienceTypes.SMARTSCHOOLSCHOOL && p.schoolId === audience.schoolId);
                break;
            case audienceTypes.GROUP:
                otherAudience = otherAudiences.find(p => p.audienceType === audienceTypes.GROUP && p.groupId === audience.groupId);
                break;
            case audienceTypes.MEMBER:
                otherAudience = otherAudiences.find(p => p.audienceType === audienceTypes.MEMBER && p.groupId === audience.groupId && p.userId === audience.userId);
                break;
            default:
                break;
        }

        if (!otherAudience) shares.push(audience);
        else {
            const { assignment } = audience;
            const { assignment: otherAssignment } = otherAudience;

            if (state === 'shared') {
                // update shared free exercise to assignment or update assignment data
                if (assignment && !isEqual(assignment, otherAssignment)) shares.push(audience);
            }

            if (state === 'unshared') {
                // unshare task (becomes shared free exercise)
                if (!otherAssignment && assignment) shares.push(otherAudience);
            }
        }

    });

    return shares;
};
