import { SHARING_GET_TEACHER_SHARED_SOLUTION_NODES } from '../actions/actionNames';

const initialState = {
  sharedSolutions: [],
};

const sharingReducer = (state = initialState, action) => {
  switch (action && action.type) {
    case SHARING_GET_TEACHER_SHARED_SOLUTION_NODES: {
      const { sharedSolutions } = action.payload;
      return {
        sharedSolutions,
      };
    }
    default:
      return state;
  }
};

export default sharingReducer;
