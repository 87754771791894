import {
  ADD_USER_MEDIA,
  USER_MEDIA_ADD_FAVORITE_SUCCESS,
  USER_MEDIA_REMOVE_FAVORITE_SUCCESS,
  USER_MEDIA_DELETE_SUCCESS,
  USER_MEDIA_SAVE_SUCCESS,
  USER_MEDIA_SHARED,
  USER_MEDIA_RETRIEVED,
} from './actionNames';
import api from '../services/api';
import { getCurrentModuleId, getTeacherFeaturesEnabledFor } from '../selectors/digibooks';

export const addUserMedia = userMedia => ({
  type: ADD_USER_MEDIA,
  payload: { userMedia },
});

export const setUserMediaShared = (userMediaId, shared, assignment) => ({
  type: USER_MEDIA_SHARED,
  payload: { userMediaId, shared, assignment },
});

const saveUserMediaSuccess = userMedia => ({
  type: USER_MEDIA_SAVE_SUCCESS,
  payload: { userMedia },
});

export const saveUserMedia = (userMedia, updatedProps) => async dispatch => {
  const { data } = await api.patch(`/studio/user/modules/${userMedia.module}/table-of-content/${userMedia.node}/user-material/${userMedia.id}`, updatedProps);

  dispatch(saveUserMediaSuccess(data));
};

export const addUserMediaFavorite = userMedia => async dispatch => {
  await api.put(`/studio/user/modules/${userMedia.module}/favorites/user-material/${userMedia.id}`);
  return dispatch({
    type: USER_MEDIA_ADD_FAVORITE_SUCCESS,
    payload: { moduleId: userMedia.module, userMediaId: userMedia.id },
  });
};

export const removeUserMediaFavorite = userMedia => async dispatch => {
  await api.delete(`/studio/user/modules/${userMedia.module}/favorites/user-material/${userMedia.id}`);
  return dispatch({
    type: USER_MEDIA_REMOVE_FAVORITE_SUCCESS,
    payload: { moduleId: userMedia.module, userMediaId: userMedia.id },
  });
};

export const toggleUserMediaFavorite = userMedia => dispatch => dispatch(userMedia.isFavorite ? removeUserMediaFavorite(userMedia) : addUserMediaFavorite(userMedia));

export const deleteUserMedia = userMedia => async dispatch => {
  await api.delete(`/studio/user/modules/${userMedia.module}/table-of-content/${userMedia.node}/user-material/${userMedia.id}`);

  return dispatch({
    type: USER_MEDIA_DELETE_SUCCESS,
    payload: {
      nodeId: userMedia.node,
      userMediaId: userMedia.id,
    },
  });
};

export function fetchUserMaterial(isTeacher, module) {
  return async (dispatch, getState) => {
    const teacherFeaturesEnabled = isTeacher === undefined ? getTeacherFeaturesEnabledFor(getState()) : isTeacher;
    const moduleId = module || getCurrentModuleId(getState());

    const userMediaResponse = teacherFeaturesEnabled
      ? await api.get(`/studio/user/modules/${moduleId}/user-material?includeScore=true`)
      : await api.get(`/studio/user/modules/${moduleId}/user-material/shared?includeScore=true`);

    dispatch({
      type: USER_MEDIA_RETRIEVED,
      payload: {
        userMedia: userMediaResponse.data.data,
      },
    });
  };
}
