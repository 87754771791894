import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { arrayOf, shape, string, bool, objectOf } from 'prop-types';
import sortBy from 'lodash/sortBy';
import SimpleBar from 'simplebar-react';
import Alert from '../../../../../../components/alert';
import { getActiveNodes } from '../../../../../../selectors/toc';
import { getWhitepagesForActiveNodes } from '../../../../../../selectors/whitepages';
import Collapsible from '../../../../../../components/collapsible';
import TocNodeTitle from '../tocnode-title';
import { getCurrentWhitepage } from '../../../../../../selectors/player';
import Footer from './footer';
import { UserSettingsContext } from '../../../../context/user-settings-context';
import AddWhitepageMenu from './components/AddWhitepageMenu';
import LicensedWhitePageListItem from './components/LicensedWhitePageListItem';
import UnlicensedWhitePageListItem from './components/UnlicensedWhitePageListItem';
import { getCurrentDigibook } from '../../../../../../selectors/digibooks';

export function Whitepages({ activeNodes, whitepagesForActiveNodes, currentWhitepage }) {
  const [t] = useTranslation();
  const { showOnlyFavoriteWhitepages } = useContext(UserSettingsContext);

  const sortByTitle = whitepages => sortBy(whitepages, 'title');

  const getWhitepagesForNode = useMemo(
    () => tocNodeId => {
      const whitepagesForNode = whitepagesForActiveNodes[tocNodeId];

      if (whitepagesForNode && showOnlyFavoriteWhitepages) {
        return whitepagesForNode.filter(w => w.isFavorite);
      }

      return whitepagesForNode || [];
    },
    [showOnlyFavoriteWhitepages, whitepagesForActiveNodes],
  );

  const digibook = useSelector(getCurrentDigibook);

  return (
    <>
      <p className="pbb-drawer__intro">{t('drawer.whitepages.title')}</p>
      <SimpleBar className="pbb-drawer__contentwrap">
        <div className="pbb-drawer__content">
          <div className="pbb-whitepages">
            {activeNodes.length ? (
              <Collapsible>
                {activeNodes.map(tocNode => {
                  const whitepages = sortByTitle(getWhitepagesForNode(tocNode.id));

                  return (
                    <Collapsible.Item key={tocNode.id}>
                      <Collapsible.Title>
                        <span className="pbb-collapsible__title-text">
                          {tocNode.displayPrefix && <span className="pbb-collapsible__subtitle">{tocNode.prefix}</span>}
                          <span className="pbb-collapsible__title">
                            <TocNodeTitle tocNode={tocNode} />
                          </span>
                        </span>
                      </Collapsible.Title>
                      <Collapsible.Content>
                        <ul className="pbb-list pbb-list--borders pbb-collapsible__content">
                          {whitepages.length > 0 && <AddWhitepageMenu tocNodeId={tocNode.id} showOnlyFavoriteWhitepages={showOnlyFavoriteWhitepages} />}
                          {whitepages.map(whitepage => {
                            if (digibook.teacherDigitalManualLicense)
                              return <LicensedWhitePageListItem key={whitepage.id} whitepage={whitepage} nodeId={tocNode.id} isActive={currentWhitepage?.id === whitepage.id} />;

                            return <UnlicensedWhitePageListItem key={whitepage.id} whitepage={whitepage} />;
                          })}
                          {whitepages.length === 0 && (
                            <>
                              <li>
                                <Alert
                                  message={t(showOnlyFavoriteWhitepages ? 'drawer.whitepages.no_fav_whitepages' : 'drawer.whitepages.no_whitepages')}
                                  className="pbb-alert--spaced"
                                />
                              </li>
                              <AddWhitepageMenu tocNodeId={tocNode.id} showOnlyFavoriteWhitepages={showOnlyFavoriteWhitepages} />
                            </>
                          )}
                        </ul>
                      </Collapsible.Content>
                    </Collapsible.Item>
                  );
                })}
              </Collapsible>
            ) : (
              <Alert message={t('drawer.whitepages.no_whitepages')} />
            )}
          </div>
        </div>
      </SimpleBar>
      <Footer />
    </>
  );
}

const mapStateToProps = state => ({
  activeNodes: getActiveNodes(state),
  whitepagesForActiveNodes: getWhitepagesForActiveNodes(state),
  currentWhitepage: getCurrentWhitepage(state),
});

Whitepages.propTypes = {
  activeNodes: arrayOf(
    shape({
      id: string,
      title: string,
      prefix: string,
      displayPrefix: bool,
    }),
  ),
  whitepagesForActiveNodes: objectOf(
    arrayOf(
      shape({
        id: string,
        moduleId: string,
        nodeId: string,
        createdAt: string,
        updatedAt: string,
      }),
    ),
  ),
  currentWhitepage: shape({
    id: string,
  }),
};

Whitepages.defaultProps = {
  activeNodes: [],
  whitepagesForActiveNodes: {},
  currentWhitepage: undefined,
};

export default connect(mapStateToProps)(Whitepages);
