/* eslint-disable class-methods-use-this */
import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import { render } from 'react-dom';
import React from 'react';
import { textMarkerColors, colors } from '../../../../enums/colors';
import Tools from '../../../../enums/tools';
import ColorPicker from './components/color-picker';
import { TRANSPARENT } from '../../../../constants/constants';

class FontBackgroundColorPlugin {
  selectedColorName = '';

  constructor() {
    this.handleColorPicker = this.handleColorPicker.bind(this);
  }

  init(editor) {
    this.jodit = editor;

    this.selectedColorName = 'transparent';

    Config.prototype.controls.fontbackgroundcolor = {
      ...Config.prototype.controls.fontbackgroundcolor,
      exec: () => this.jodit.selection.focus(),
      popup: (_jodit, _current, close) => {
        const root = document.createElement('div');
        root.className = 'color-picker__popup';
        this.colorPickerRoot = root;

        const handleColorClick = color => {
          this.handleColorPicker(color);
          if (!this.jodit.selection.sel.isCollapsed) this.setCaretAtEndOfSelection();
          close();
        };

        render(<ColorPicker variant={Tools.TEXT_MARKER} selectedColor={this.selectedColorName} onColorClick={handleColorClick} />, root);
        return root;
      },
    };

    this.jodit.events.one('afterInit', () => {
      this.setIndicatorColor(this.selectedColorName);
    });
  }

  handleColorPicker(colorName) {
    this.selectedColorName = colorName;

    this.setMarkingColor(colorName);
  }

  setMarkingColor(colorName) {
    this.jodit.execCommand('hilitecolor', false, textMarkerColors[colorName]);

    this.setIndicatorColor(colorName);
  }

  setIndicatorColor(colorName) {
    const color = colorName === 'transparent' ? TRANSPARENT : colors[colorName];

    const textMarker = document.getElementById('text-marker-icon');
    if (textMarker) textMarker.style.setProperty('--accent-color', color);
  }

  setCaretAtEndOfSelection() {
    this.jodit.selection.sel.collapseToEnd();
  }
}

Jodit.plugins.add('fontbackgroundcolor', FontBackgroundColorPlugin);
