export default class LRUCache {
  constructor(maxItems, timeout) {
    this.maxItems = maxItems;
    this.timeout = timeout;
    this.cache = new Map();

    this.pruneAllExpired = this.pruneAllExpired.bind(this);

    this.pruneInterval = setInterval(this.pruneAllExpired, 3000);
  }

  destroy() {
    clearInterval(this.pruneInterval);
  }

  add(key, value) {
    if (this.maxItems <= this.cache.size) {
      const firstKey = this.cache.keys().next().value;

      this.remove(firstKey);
    }

    const newItem = {
      item: value,
      timeout: Date.now() + this.timeout,
    };

    this.cache.set(key, newItem);
  }

  remove(key) {
    this.cache.delete(key);
  }

  get(key) {
    return this.cache.get(key) && this.cache.get(key).item;
  }

  pruneAllExpired() {
    // eslint-disable-next-line no-restricted-syntax
    this.cache.forEach((value, key) => {
      if (value.timeout < Date.now()) {
        this.cache.delete(key);
      }
    });
  }

  clear() {
    this.cache.clear();
  }
}
