import PDFService from './pdf-js-service';
import { PDF_SCALE, TRANSPARENT } from '../../../constants/constants';

export default function render(proxy, isAnswer, position) {
  const canvas = document.createElement('canvas');
  const pdfRender = isAnswer ? PDFService.renderOnCanvas(proxy, canvas, PDF_SCALE, TRANSPARENT) : PDFService.renderOnCanvas(proxy, canvas, PDF_SCALE);

  const promise = pdfRender.promise.then(() => {
    const dataUrl = canvas.toDataURL();
    canvas.width = 0; // This is to avoid the webkit out of memory bug. For some reason contexts dont get cleaned properly.
    canvas.height = 0;

    return {
      dataUrl,
      position,
      isAnswer,
    };
  });

  return {
    renderTask: pdfRender,
    promise,
  };
}
