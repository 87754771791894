import React from 'react';
import { render } from 'react-dom';
import './i18n';
import 'simplebar/dist/simplebar.css';
import 'react-date-range/dist/styles.css';
import '@pelckmans/business-components/css/business-components.css';
import '@pelckmans/business-components/css/business-components-modules.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import '../scss/index.scss';
import '../assets/favicon.ico';
import App from './app';

const environment = process.env.NODE_ENV || 'local';

Sentry.init({
  dsn: 'https://e7ea7b2537c74493b0967b2e409c1187@o1346714.ingest.sentry.io/6624898',
  integrations: [new BrowserTracing()],
  environment,
  // Low sample rate for tracking performance
  tracesSampleRate: 0.01,
});

render(<App />, document.getElementById('root'));
