import { useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';

import { calculateAngleInDegrees, calibratePoint, radiansToDegrees } from '../utils';

const calculateOffsetAngle = ({ rulerWidth, paddingLeft, rulerHeight, scale }) => {
  const offsetAngleInRad = Math.atan((rulerHeight * scale) / (rulerWidth * scale - paddingLeft * scale));
  return radiansToDegrees(offsetAngleInRad);
};

const calculateRotation = (normalizedRotation, offsetAngle) => {
  if (normalizedRotation - offsetAngle < 0) return 360 - (offsetAngle - normalizedRotation);
  return normalizedRotation - offsetAngle;
};

const calculateDisplayDegrees = (newRotationDisplay, offsetAngle) => {
  let degreesToDisplay;

  if (newRotationDisplay - offsetAngle < -180) degreesToDisplay = Math.round(180 + ((newRotationDisplay - offsetAngle) % 180));
  else degreesToDisplay = Math.round(newRotationDisplay - offsetAngle);

  return Math.abs(degreesToDisplay);
};

export default function useRulerRotating({ halfOfOffset, paddingLeft, rulerWidth, rulerHeight, ref, scale }) {
  const [rotation, setRotation] = useState(0);
  const [rotationDisplay, setRotationDisplay] = useState(rotation);
  const [offsetAngle, setOffsetAngle] = useState(0);

  const isRotating = useRef(false);
  const center = useRef({ x: 0, y: 0 });

  useEffect(() => {
    setOffsetAngle(calculateOffsetAngle({ rulerWidth, rulerHeight, paddingLeft, scale }));
  }, [rulerWidth, rulerHeight, paddingLeft, scale]);

  const handleMove = throttle(evt => {
    if (!isRotating.current) return;

    const { clientX, clientY } = evt.changedTouches?.[0] || evt;

    const calibratedRotatePoint = calibratePoint({ x: clientX, y: clientY }, center.current);
    const { normalizedRotation, denormalizedRotation } = calculateAngleInDegrees(calibratedRotatePoint);

    setRotation(calculateRotation(normalizedRotation, offsetAngle));
    setRotationDisplay(calculateDisplayDegrees(denormalizedRotation, offsetAngle));
  }, 1000 / 60);

  const handleStop = evt => {
    handleMove.cancel();
    handleMove(evt);

    isRotating.current = false;

    document.body.removeEventListener('mousemove', handleMove);
    document.body.removeEventListener('touchmove', handleMove);

    document.body.removeEventListener('mouseup', handleStop);
    document.body.removeEventListener('touchend', handleStop);
  };

  const handleStart = evt => {
    evt.preventDefault();
    isRotating.current = true;

    center.current = {
      x: ref.current.parentElement.getBoundingClientRect().left + paddingLeft * scale,
      y: ref.current.parentElement.getBoundingClientRect().top + halfOfOffset * scale,
    };

    document.body.addEventListener('mousemove', handleMove);
    document.body.addEventListener('touchmove', handleMove);

    document.body.addEventListener('mouseup', handleStop);
    document.body.addEventListener('touchend', handleStop);
  };

  return {
    rotate: handleStart,
    degrees: rotation,
    degreesDisplay: rotationDisplay,
  };
}
