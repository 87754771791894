import React from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Collapsible from '../../../../../../../components/collapsible';
import TocNodeTitle from '../../tocnode-title';
import MedialinkItem from './medialink/MedialinkItem';

/**
 * @deprecated
 * @description Component should be deleted when possible. Only being rendered in the material-list.
 */
function SharedMediaList({ sharedMedia, allowCollapsing, teacherFeaturesEnabled }) {
  const [t] = useTranslation();

  return (
    <Collapsible depth={2} className="shared-materials" forceEnable={allowCollapsing}>
      <Collapsible.Item>
        <Collapsible.Title>
          <span className="pbb-collapsible__title-text">
            <span className="pbb-collapsible__title">
              <TocNodeTitle
                tocNode={{
                  id: 'shared-media',
                  title: teacherFeaturesEnabled ? t('drawer.material.sharedMedia') : t('drawer.material.sharedByTeacher'),
                }}
              />
            </span>
          </span>
        </Collapsible.Title>
        <Collapsible.Content>
          <ul className="pbb-list pbb-list--borders">
            {sharedMedia.map(media => (
              <MedialinkItem key={media.id} medialink={media} isSharedMaterial />
            ))}
          </ul>
        </Collapsible.Content>
      </Collapsible.Item>
    </Collapsible>
  );
}

SharedMediaList.propTypes = {
  sharedMedia: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      href: string,
      icon: string,
      isFavorite: bool,
    }).isRequired,
  ).isRequired,
  allowCollapsing: bool.isRequired,
  teacherFeaturesEnabled: bool.isRequired,
};

export default SharedMediaList;
