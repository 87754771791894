const getPlatformName = studioGroup => {
  switch (studioGroup) {
    case 'BAO':
      return 'Pelckmans Basisonderwijs';
    case 'ES':
      return 'Pelckmans Portail';
    case 'SO':
      return 'Pelckmans Portaal';
    default:
      return 'Pelckmans NT2';
  }
};

export default getPlatformName;
