import update from 'immutability-helper';

import {
  SET_CURRENT_TOOL,
  TOGGLE_ANSWER_REVEAL,
  TOGGLE_ANSWER_HIDE,
  SET_SIZE,
  SET_COLOR,
  SET_BACKGROUND_COLOR,
  MARKING_CREATED,
  MARKINGS_CREATED,
  MARKINGS_LOADED,
  MARKINGS_DELETE_FOR_SPREAD,
  TOGGLE_ANSWER_STEPPER,
  BOOKLAYER_RENDERED,
  SET_CURRENT_MATH_TOOL,
  SET_ACTIVE_ANNOTATION_SET,
} from '../actions/actionNames';

import Tools from '../enums/tools';
import Colors from '../enums/colors';
import Sizes from '../enums/sizes';

export const initialState = {
  currentTool: Tools.POINTER,
  currentMathTool: undefined,
  tools: {
    [Tools.PENCIL]: {
      color: Colors.GREEN,
      size: Sizes.SM,
    },
    [Tools.MARKER]: {
      color: Colors.GREEN,
      size: Sizes.SM,
    },
    [Tools.TEXT_MARKER]: {
      color: Colors.GREEN,
    },
    [Tools.CLASSIC_ERASER]: {
      size: Sizes.MD,
    },
    [Tools.SELECTION_ERASER]: {},
    [Tools.RULER]: {},
  },
  markingsForPage: [],
  answerStepperMode: false,
};

export default function toolReducer(state = initialState, action) {
  switch (action && action.type) {
    case SET_CURRENT_TOOL: {
      const { tool } = action.payload;

      return update(state, {
        currentTool: { $set: tool },
      });
    }
    case TOGGLE_ANSWER_REVEAL: {
      const { currentTool } = state;
      const newTool = currentTool === Tools.ANSWER_REVEAL ? Tools.POINTER : Tools.ANSWER_REVEAL;

      return update(state, {
        currentTool: { $set: newTool },
      });
    }
    case TOGGLE_ANSWER_HIDE: {
      const { currentTool } = state;
      const newTool = currentTool === Tools.ANSWER_HIDE ? Tools.POINTER : Tools.ANSWER_HIDE;

      return update(state, {
        currentTool: { $set: newTool },
      });
    }
    case TOGGLE_ANSWER_STEPPER: {
      const { answerStepperMode } = state;

      return update(state, {
        answerStepperMode: { $set: !answerStepperMode },
        currentTool: { $set: Tools.POINTER },
      });
    }
    case SET_SIZE: {
      const { currentTool } = state;
      return update(state, {
        tools: {
          [currentTool]: {
            $merge: { size: action.payload.size },
          },
        },
      });
    }
    case SET_COLOR: {
      const { currentTool } = state;
      return update(state, {
        tools: {
          [currentTool]: {
            $merge: { color: action.payload.color },
          },
        },
      });
    }
    case SET_BACKGROUND_COLOR: {
      const { currentTool } = state;
      return update(state, {
        tools: {
          [currentTool]: {
            $merge: { backgroundColor: action.payload.backgroundColor },
          },
        },
      });
    }
    case MARKING_CREATED: {
      const { pages, shape } = action.payload;

      return update(state, {
        markingsForPage: {
          $push: [
            {
              pages,
              shape,
            },
          ],
        },
      });
    }
    case MARKINGS_CREATED: {
      const { pages, shapes } = action.payload;

      return update(state, {
        markingsForPage: {
          $push: shapes.map(shape => ({
            pages,
            shape,
          })),
        },
      });
    }
    case MARKINGS_LOADED: {
      const { pages, markings } = action.payload;

      return update(state, {
        markingsForPage: {
          $apply: coll => [
            ...coll.filter(marking => !marking.pages.includes(pages[0])), // only markings that are not of the pages for which new are loaded
            ...markings.map(m => ({ pages, shape: m })),
          ], // and now add the loaded ones
        },
      });
    }
    case MARKINGS_DELETE_FOR_SPREAD: {
      const { pages } = action.payload;

      return update(state, {
        markingsForPage: markings => markings.filter(m => !m.pages.includes(pages[0])),
      });
    }
    case BOOKLAYER_RENDERED: {
      const { singlePageDimensions } = action.payload;
      return update(state, { singlePageDimensions: { $set: singlePageDimensions } });
    }
    case SET_CURRENT_MATH_TOOL: {
      const { tool } = action.payload;

      return update(state, {
        currentMathTool: { $set: tool },
      });
    }
    case SET_ACTIVE_ANNOTATION_SET: {
      return update(state, { markingsForPage: { $set: [] } });
    }
    default:
      return state;
  }
}
