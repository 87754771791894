import get from 'get-value';
import { createSelector } from 'reselect';

import { getCurrentModuleId } from './digibooks';
import { getPageNumbersToShow } from './rendering';
import { getClickedTocNode } from './navigation';

export const getChapterIdsOfCurrentModule = state => get(state, ['player', 'entities', 'tocs', getCurrentModuleId(state)]);

export const getTOCNodes = state => get(state, ['player', 'entities', 'tocnodes']);

export const getTOCNodeById = state => tocNodeId => get(state, ['player', 'entities', 'tocnodes', tocNodeId]);

export const getActiveNodes = (state, useKeepMediaVisibleTo) => {
  const visiblePageNumbers = getPageNumbersToShow(state);
  const toc = getChapterIdsOfCurrentModule(state);
  if (!toc) return [];

  const visibleNodes = new Set();

  const isNodeActive = (page, node) => (useKeepMediaVisibleTo ? page >= node.fromPage && page <= node.keepMediaVisibleToPage : page >= node.fromPage && page <= node.toPage);

  const addNodesRecursive = (nodeId, level) => {
    const node = getTOCNodeById(state)(nodeId);

    if (visiblePageNumbers.some(n => isNodeActive(n, node))) {
      visibleNodes.add(nodeId);
    }

    if (node.nodes && level < 3) {
      node.nodes.forEach(subnode => addNodesRecursive(subnode, level + 1));
    }
  };

  toc.forEach(nodeId => {
    addNodesRecursive(nodeId, 1);
  });

  return [...visibleNodes].map(id => getTOCNodeById(state)(id));
};

export const getActiveNodesForMediaLinks = state => getActiveNodes(state, true);

/* for the current visible pages, return a list of level1 nodes that are visible or have visible childs */
export const getVisibleChapterNodeIds = state => {
  const nodes = getActiveNodesForMediaLinks(state);
  const ids = nodes.reduce((acc, node) => {
    const chapterNodeId = node.hierarchy && node.hierarchy[0] ? node.hierarchy[0] : node.id;
    if (acc.indexOf(chapterNodeId) < 0) acc.push(chapterNodeId);
    return acc;
  }, []);
  return ids;
};

/* in the toc sidebar, there is only one active node. If more active nodes are visible, select first of deepest */
export const getActiveNodeToHighlight = state => {
  const clickedTocNode = getClickedTocNode(state);
  if (clickedTocNode) return clickedTocNode;

  const nodes = getActiveNodes(state);
  if (nodes.length === 0) return undefined;

  let deepestNode = nodes[0];
  nodes.forEach(node => {
    if (node.hierarchy.length > deepestNode.hierarchy.length) {
      deepestNode = node;
    }
  });
  return deepestNode;
};

export const getExpandedNodes = state => {
  const activeNodeToHighLight = getActiveNodeToHighlight(state);
  if (!activeNodeToHighLight) return [];

  const expandedNodes = activeNodeToHighLight.hierarchy.map(id => getTOCNodeById(state)(id));

  if (activeNodeToHighLight.nodes && activeNodeToHighLight.nodes.length > 0) {
    expandedNodes.push(activeNodeToHighLight);
  }

  return expandedNodes;
};

export const getTocNodesInTocOrder = createSelector(getChapterIdsOfCurrentModule, getTOCNodes, (topLevelNodes, nodeMap) => {
  const nodes = [];

  function addNodesRecursively(nodeId) {
    const node = nodeMap[nodeId];
    nodes.push(node);

    if (node.nodes) {
      node.nodes.forEach(addNodesRecursively);
    }
  }

  topLevelNodes.forEach(addNodesRecursively);

  return nodes;
});
