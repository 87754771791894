import React, { useContext } from 'react';
import { OptionsMenu } from '@pelckmans/business-components/components/options-menu';
import { node } from 'prop-types';
import { useTranslation } from 'react-i18next';
import AnchorPosition from '../../../../../enums/anchorposition';
import { UserSettingsContext } from '../../../context/user-settings-context';

function DrawerOptionsMenu({ children }) {
  const { t } = useTranslation();

  const { sidebarAnchor } = useContext(UserSettingsContext);

  const getDirection = () => {
    if (sidebarAnchor === AnchorPosition.LEFT) return 'right';
    if (sidebarAnchor === AnchorPosition.RIGHT) return 'left';

    return 'right';
  };

  const target = document.getElementById('drawer-options-menu-container');

  return (
    // we use target to pull out the menu from the <SimpleBar /> component
    <OptionsMenu title={t('buttons.optionsButton.tooltip')} direction={getDirection()} boundingBoxPadding="10" viewScroll="close" portal={{ target }}>
      {children}
    </OptionsMenu>
  );
}

DrawerOptionsMenu.propTypes = {
  children: node.isRequired,
};

export default DrawerOptionsMenu;
