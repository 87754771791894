import { normalize } from 'normalizr';
import api from '../services/api';
import { digibook } from '../schemas';
import retrieveModuleById from './module';

import { setCurrentPage } from './navigation';
import { INITIALIZE, INITIALIZE_SUCCESS, BOOKLAYER_PARSED, BOOKLAYER_RENDERED, WHITEPAGE_RENDERED, TOGGLE_MANUAL, TOGGLE_POP_UP_MANUAL } from './actionNames';
import { retrieveUserFavoritesForModule, retrievePlayerSettingsFor } from './user';
import { retrieveMediaContentTypesForModule } from './medialinks';
import { getTeacherSharedSolutionNodes } from './sharing';
import { getFeatureToggleFor } from '../utils/feature-toggle';
import FeatureToggle from '../enums/feature-toggle';
import { fetchUserMaterial } from './userMedia';
import { retrieveDigibookAnnotationSets } from './annotationSets';
import { fetchSlideSets } from './slideSets';

import { getIsSlideSetsAccessEnabled } from '../selectors/slideSets';

function initializeStarted(id, superModuleId) {
  return {
    type: INITIALIZE,
    payload: {
      id,
      superModuleId,
    },
  };
}

function initializeSuccess({ id, entities, superModuleId, moduleId, toc, sharedMedia }) {
  return {
    type: INITIALIZE_SUCCESS,
    payload: {
      id,
      entities,
      moduleId,
      superModuleId,
      toc,
      sharedMedia,
    },
  };
}

export function initialize(id, superModuleId, initialPage, annotationSetTemplateName) {
  return async (dispatch, getState) => {
    dispatch(initializeStarted(id, superModuleId));

    const isUserMediaEnabled = getState().featureToggles.USER_MEDIA;

    const response = await api.get(`/studio/digibooks/${id}`);

    const { totalPages, teacherFeaturesEnabled } = response.data;

    await dispatch(retrieveDigibookAnnotationSets(id, annotationSetTemplateName));

    const moduleId = response.data.module.id;
    const normalized = normalize(response.data, digibook);

    const sidebar = await api.get(`/studio/modules/${moduleId}/table-of-content/nodes/sidebar`);

    const getSharedMediaForRole = async () => {
      if (teacherFeaturesEnabled) {
        return api.get(`/studio/user/modules/${moduleId}/bookmarks`);
      }

      return api.get(`/studio/user/modules/${moduleId}/shared-bookmarks`);
    };

    if (teacherFeaturesEnabled && getFeatureToggleFor(FeatureToggle.SHARING_MATERIAL)) {
      dispatch(getTeacherSharedSolutionNodes());
    }

    dispatch(retrieveUserFavoritesForModule(moduleId));
    dispatch(retrieveMediaContentTypesForModule(moduleId));

    await dispatch(retrieveModuleById(moduleId));
    await dispatch(retrievePlayerSettingsFor(moduleId));

    const sharedMedia = await getSharedMediaForRole();

    if (superModuleId) {
      if (superModuleId !== moduleId) {
        dispatch(retrieveModuleById(superModuleId));
      }

      try {
        const relatedDigibooks = await api.get(`/studio/modules/${superModuleId}/apps?platformLinkType=digibook&includeSubModules=true`);

        normalized.entities.relatedDigibooks = {
          [moduleId]: relatedDigibooks.data.data,
        };
      } catch (e) {
        normalized.entities.relatedDigibooks = { [moduleId]: [] };
      }
    }

    if (isUserMediaEnabled) {
      await dispatch(fetchUserMaterial(teacherFeaturesEnabled, moduleId));
    }

    dispatch(
      initializeSuccess({
        id: normalized.result,
        superModuleId,
        moduleId,
        entities: normalized.entities,
        toc: sidebar.data.data,
        sharedMedia: sharedMedia.data.data,
      }),
    );

    if (getIsSlideSetsAccessEnabled(getState())) {
      await dispatch(fetchSlideSets(moduleId));
    }

    if (totalPages) dispatch(setCurrentPage(initialPage || 0));
  };
}

export function bookLayerParsed(id, totalPages) {
  return {
    type: BOOKLAYER_PARSED,
    payload: {
      id,
      totalPages,
    },
  };
}

export function bookLayerRendered(singlePageDimensions) {
  return {
    type: BOOKLAYER_RENDERED,
    payload: {
      singlePageDimensions,
    },
  };
}

export function whitepageRendered() {
  return {
    type: WHITEPAGE_RENDERED,
  };
}

export function toggleManual() {
  return {
    type: TOGGLE_MANUAL,
  };
}

export function togglePopUpManual() {
  return {
    type: TOGGLE_POP_UP_MANUAL,
  };
}
