import { Jodit } from 'jodit';
import { Config } from 'jodit/esm/config';
import { iconMapping } from '../constants';

class TranslationsPlugin {
  init(editor) {
    this.jodit = editor;
    const { t } = this.jodit.options;

    iconMapping.forEach(({ name }) => {
      Config.prototype.controls[name].tooltip = t(`annotationTool.buttons.tooltip.${name}`);
    });

    Config.prototype.controls.fontsize.tooltip = t('annotationTool.buttons.tooltip.fontsize');
    Config.prototype.controls.align.tooltip = t('annotationTool.buttons.tooltip.align');
    Config.prototype.controls.fontbackgroundcolor.tooltip = t('annotationTool.buttons.tooltip.highlight');
  }
}

Jodit.plugins.add('translations', TranslationsPlugin);
