import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool } from 'prop-types';

import LogoNL from '../../assets/images/portaal_logo_blue.svg';
import InvertedLogoNL from '../../assets/images/portaal_white.svg';
import LogoFR from '../../assets/images/portail_logo_blue.svg';
import InvertedLogoFR from '../../assets/images/portail_white.svg';

function getLogo(language, inverted) {
  if (language === 'fr') return inverted ? InvertedLogoFR : LogoFR;
  return inverted ? InvertedLogoNL : LogoNL;
}

function PortaalLogo({ inverted }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const src = getLogo(language, inverted);

  return <img src={src} draggable={false} alt={t('portaalLogo.alt')} />;
}

PortaalLogo.propTypes = {
  inverted: bool,
};

PortaalLogo.defaultProps = {
  inverted: undefined,
};

export default PortaalLogo;
