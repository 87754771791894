import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Rnd } from 'react-rnd';
import { func, string, bool, node, number, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogHeader from '../common/header';

import { openMedialink } from '../../../../../actions/dialog';
import { getMedialink } from '../../../../../selectors/medialinks';
import { getCurrentModuleId } from '../../../../../selectors/digibooks';
import { getModuleById } from '../../../../../selectors/module';
import Confirmation from '../confirmation';
import { ANALYTICS_EVENT_SUBLOCATIONS } from '../../../../../enums/analytics';
import getGeneralConditionsLink from '../../../../../utils/getGeneralConditionsLink';

const getResizeEnabled = (resizeEnabled = false) => ({
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: resizeEnabled,
  bottomLeft: false,
  topLeft: false,
});

export const RndMediaDialog = ({
  title,
  icon,
  close,
  moveDialogToFront,
  isInFront,
  children,
  downloadLink,
  height,
  width,
  resizeEnabled,
  isImagePreview,
  x,
  y,
  currentModule,
  linkedMedia,
  dispatch,
  id,
  setDimensions,
  setPosition,
  hideDownload,
}) => {
  const [t] = useTranslation();
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [originalSize, setOriginalSize] = useState(undefined);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [positionTrigger, setPositionTrigger] = useState(false);

  const resizeHandleClasses = resizeEnabled ? { bottomRight: 'resizeHandle' } : null;
  const enableResizingProperty = getResizeEnabled(resizeEnabled && !isFullScreen);
  const downloadIsPreventedWithDemoProductLicense = currentModule
    ? currentModule.licenses.hasDemoProductLicenseForTeacher && !(currentModule.licenses.hasDocumentLicenseForTeacher || currentModule.licenses.hasDocumentLicenseForStudent)
    : undefined;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fullScreenSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  useEffect(() => {
    if (isFullScreen && (width !== window.innerWidth || height !== window.innerHeight)) {
      setDimensions(id, fullScreenSize.width, fullScreenSize.height);
    } else if (!isFullScreen && originalSize) {
      setDimensions(id, originalSize.width, originalSize.height);
      setOriginalSize(undefined);
    }
  }, [isFullScreen, fullScreenSize, height, width, id, setDimensions, originalSize]);

  const getDownloadLink = downloadLink && downloadIsPreventedWithDemoProductLicense ? null : downloadLink;

  const action = (
    <a
      href={getDownloadLink}
      onClick={
        !downloadLink || (downloadLink && downloadIsPreventedWithDemoProductLicense)
          ? () => {
              setConfirmationShown(true);
            }
          : null
      }
      target="_blank"
      rel="noopener noreferrer"
      className="pbb-modal__download"
      title={t('mediaDialog.header.buttons.download.tooltip')}
    >
      <i className="icon-bb-modal-download" />
    </a>
  );

  const handleClick = e => {
    e.stopPropagation();
    dispatch(openMedialink(linkedMedia, ANALYTICS_EVENT_SUBLOCATIONS.DIALOG));
  };

  return (
    <>
      <Rnd
        className={classNames('pbb-modal pbb-modal--media', {
          'pbb-modal--fullscreen': isFullScreen,
        })}
        forceposition={positionTrigger.toString()}
        minWidth={300}
        key={title}
        minHeight={120}
        bounds="parent"
        position={{ x, y }}
        size={isFullScreen ? fullScreenSize : { width, height }}
        onDragStart={moveDialogToFront}
        style={{
          zIndex: isInFront ? 99 : 1,
        }}
        onDragStop={(_e, d) => setPosition(id, d.x, d.y)}
        onResizeStart={() => document.querySelector('body').classList.add('react-draggable-transparent-selection')}
        onResizeStop={(_e, _d, ref) => {
          document.querySelector('body').classList.remove('react-draggable-transparent-selection');
          return setDimensions(id, ref.offsetWidth, ref.offsetHeight);
        }}
        dragHandleClassName="draggable"
        resizeHandleClasses={resizeHandleClasses}
        enableResizing={enableResizingProperty}
        onClick={moveDialogToFront}
        data-testid="rnd-dialog"
      >
        <div className="pbb-modal__dialog">
          <div className="pbb-modal__content">
            <DialogHeader draggable icon={icon} title={title} close={close} action={!hideDownload && action} />
            <div className="pbb-modal__body">
              <div className="pbb-modal__media">
                {isImagePreview
                  ? React.Children.map(children, child =>
                      React.cloneElement(child, {
                        modalPosition: { x, y },
                        onFullScreenClick: () => {
                          if (!isFullScreen) setOriginalSize({ width, height });
                          setIsFullScreen(!isFullScreen);
                          requestAnimationFrame(() => {
                            setPositionTrigger(!positionTrigger);
                          });
                        },
                        dialogSize: { width, height },
                        isFullScreen,
                        close,
                      }),
                    )
                  : children}
              </div>
            </div>
            <div className="pbb-modal__footer draggable">
              {linkedMedia && (
                <button className="pbb-transcription" type="button" onClick={handleClick}>
                  {t('mediaDialog.openTranscription')}
                </button>
              )}
              <span className="pbb-modal__drag" title={t('mediaDialog.footer.buttons.move.tooltip')}>
                <i className="icon-bb-modal-drag" />
              </span>
              {enableResizingProperty.bottomRight && (
                <span className="pbb-modal__resize" data-testid="modal-resize">
                  <i className="icon-bb-modal-resize" />
                </span>
              )}
            </div>
          </div>
        </div>
      </Rnd>

      {confirmationShown && downloadIsPreventedWithDemoProductLicense && (
        <Confirmation
          title={t('mediaDialog.hasDemoLicense.title')}
          message={t('mediaDialog.hasDemoLicense.message')}
          icon="icon-bb-modal-download"
          cancellationText={t('mediaDialog.noMediaDownloadModal.buttons.cancel')}
          onCancel={() => setConfirmationShown(false)}
        />
      )}

      {confirmationShown && !downloadIsPreventedWithDemoProductLicense && (
        <Confirmation
          title={t('mediaDialog.noMediaDownloadModal.title')}
          message={t('mediaDialog.noMediaDownloadModal.message', { conditionsURL: getGeneralConditionsLink() })}
          icon="icon-bb-modal-download"
          cancellationText={t('mediaDialog.noMediaDownloadModal.buttons.cancel')}
          onCancel={() => setConfirmationShown(false)}
        />
      )}
    </>
  );
};

RndMediaDialog.propTypes = {
  title: string.isRequired,
  icon: string,
  close: func.isRequired,
  moveDialogToFront: func.isRequired,
  isInFront: bool,
  children: node,
  downloadLink: string,
  height: number,
  width: number,
  resizeEnabled: bool,
  isImagePreview: bool,
  dispatch: func.isRequired,
  linkedMediaId: string, // eslint-disable-line react/no-unused-prop-types
  linkedMedia: shape({
    id: string.isRequired,
  }),
  x: number,
  y: number,
  setDimensions: func.isRequired,
  setPosition: func.isRequired,
  id: string.isRequired,
  hideDownload: bool,
  currentModule: shape({
    licenses: shape({
      hasDemoProductLicenseForTeacher: bool,
      hasDocumentLicenseForTeacher: bool,
      hasDocumentLicenseForStudent: bool,
    }),
  }),
};

RndMediaDialog.defaultProps = {
  icon: undefined,
  downloadLink: undefined,
  children: undefined,
  isInFront: false,
  resizeEnabled: true,
  isImagePreview: false,
  linkedMediaId: undefined,
  linkedMedia: undefined,
  height: undefined,
  width: undefined,
  x: 0, // Due to react-rnd implementation, this can never be undefined.
  y: 0,
  hideDownload: false,
  currentModule: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  currentModule: getModuleById(state, getCurrentModuleId(state)),
  linkedMedia: getMedialink(state, ownProps.linkedMediaId),
});

export default connect(mapStateToProps)(RndMediaDialog);
