import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import cookie from 'cookie';
import { ANALYTICS_KEY } from '../constants/constants';

export const useGoogleAnalytics = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line dot-notation
    if (cookie.parse(document.cookie)['_cl'] === 'true') {
      ReactGA.initialize(ANALYTICS_KEY, { testMode: process.env.NODE_ENV === 'test' });
      setInitialized(true);
    }
  }, []);

  return useMemo(() => ({ ga: initialized ? ReactGA : undefined }), [initialized]);
};

export default useGoogleAnalytics;
