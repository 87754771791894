import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import PortaalLogo from '../../../../../../components/portaal-logo';
import { STUDIO_GROUP } from '../../../../../../constants/constants';

export const OpenInPButton = ({ onClick }) => {
  const [t] = useTranslation();

  const showLogo = ['SO', 'ES'].includes(STUDIO_GROUP);

  return (
    <button data-testid="button-open" className="pbb-slider-back__open" type="button" onClick={onClick}>
      <span>{t('slider.open')}</span>
      {showLogo && <PortaalLogo />}
    </button>
  );
};

OpenInPButton.propTypes = {
  onClick: func.isRequired,
};

export default OpenInPButton;
