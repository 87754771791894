import { useCallback } from 'react';

import { getAnnotationLeftPosition } from '../utils';

export default function useAnnotationLeftPosition({ pageWidth, pageHeight, isSinglePage, isRightPage, isStandalonePage, isSolutionsPageVisible, sidebarAnchor }) {
  const getAnnotationLeft = useCallback(
    annotation =>
      getAnnotationLeftPosition(annotation, {
        isSinglePage,
        isRightPage,
        isStandalonePage,
        isSolutionsPageVisible,
        sidebarAnchor,
        pageWidth,
        pageHeight,
      }),
    [isRightPage, isSinglePage, isSolutionsPageVisible, isStandalonePage, pageWidth, pageHeight, sidebarAnchor],
  );

  return { getAnnotationLeft };
}
