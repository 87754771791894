import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { func, oneOf, bool, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ZoomInIcon from '@pelckmans/business-components/icons/ZoomIn';
import ZoomOutIcon from '@pelckmans/business-components/icons/ZoomOut';
import ZoomMaskIcon from '@pelckmans/business-components/icons/ZoomMask';
import ZoomToFitIcon from '@pelckmans/business-components/icons/ZoomToFit';

import ViewmodeToggleButton from './items/viewmodeToggleButton';
import { getViewMode, canToggleSpreadPageMode, getZoomLevel } from '../../../../selectors/navigation';
import { toggleViewMode, zoomIn, zoomOut, zoomToFit } from '../../../../actions/navigation';
import ViewMode from '../../../../enums/viewmode';
import ZoomLevel from '../../../../enums/zoomLevel';
import SolutionsPanel from './items/solutions';
import { setCurrentTool } from '../../../../actions/tools';
import tools from '../../../../enums/tools';
import { getCurrentTool } from '../../../../selectors/tools';
import PlayerMode from '../../../../enums/playerMode';
import { getIsManualVisible, getIsSolutionsPageVisible, getPlayerMode, getIsPopUpManualVisible } from '../../../../selectors/player';
import { toggleManual, togglePopUpManual } from '../../../../actions/player';
import { ManualButton } from './items/manualButton';
import { PopUpManualButton } from './items/PopUpManualButton';
import ToggleMarkingsButton from './items/toggleMarkingsButton';
import AnnotationSetsButton from './items/annotationSetsButton';
import { isTeacherAlike } from '../../../../selectors/user';

export const Dock = ({
  viewMode,
  handleToggleViewMode,
  pageSpreadButtonEnabled,
  handleZoomIn,
  handleZoomOut,
  handleZoomToFit,
  handleZoomToSelection,
  zoomLevel,
  digibook,
  isZoomSelectionMode,
  playerMode,
  handleManualVisibility,
  isManualVisible,
  isSolutionsPageVisible,
  handlePopUpManualVisibility,
  isPopUpManualVisible,
  teacherAlike,
}) => {
  const root = 'pbb-dock';
  const [t] = useTranslation();

  return (
    <div type="button" className={root}>
      <ul className={`${root}__wrapper`}>
        <li className={`${root}__annotations`}>
          {playerMode !== PlayerMode.WHITEPAGE && teacherAlike && <AnnotationSetsButton />}
          <ToggleMarkingsButton playerMode={playerMode} />
        </li>
        <li className={`${root}__wrapper__divider`} />
        {digibook && digibook.manual && playerMode !== PlayerMode.WHITEPAGE && (
          <>
            {digibook.manualType === 'manual-layer' && handleManualVisibility && (
              <li className={`${root}__manual ${root}__manual--layer`}>
                <ManualButton isEnabled={isManualVisible} onClick={handleManualVisibility} />
              </li>
            )}
            {digibook.manualType === 'pop-up-manual' && handlePopUpManualVisibility && (
              <li className={`${root}__manual ${root}__manual--pop-up`}>
                <PopUpManualButton isEnabled={isPopUpManualVisible} onClick={handlePopUpManualVisibility} />
              </li>
            )}
            <li className={`${root}__wrapper__divider`} />
          </>
        )}
        {digibook && digibook.answerLayer && playerMode !== PlayerMode.WHITEPAGE && (
          <>
            <li className={`${root}__solutions`}>
              <SolutionsPanel digibook={digibook} />
            </li>
            <li className={`${root}__wrapper__divider`} />
          </>
        )}
        <li className={`${root}__zoom`}>
          <button type="button" className="pbb-sidebar__zoom-plus" onClick={handleZoomIn} disabled={zoomLevel >= ZoomLevel.MAX_ZOOM_LEVEL} title={t('dock.buttons.zoomIn.tooltip')}>
            <ZoomInIcon />
          </button>
          <button
            type="button"
            className="pbb-sidebar__zoom-min"
            onClick={handleZoomOut}
            disabled={zoomLevel <= ZoomLevel.MIN_ZOOM_LEVEL}
            title={t('dock.buttons.zoomOut.tooltip')}
          >
            <ZoomOutIcon />
          </button>
          <button
            type="button"
            className={classNames('pbb-sidebar__zoom-drag', {
              'pbb-active': isZoomSelectionMode,
            })}
            onClick={() => handleZoomToSelection(isZoomSelectionMode ? tools.POINTER : tools.ZOOM_SELECT)}
            title={t('dock.buttons.zoomDrag.tooltip')}
          >
            <ZoomMaskIcon />
          </button>
          <button type="button" className="pbb-sidebar__zoom-1-on-1" onClick={handleZoomToFit} title={t('dock.buttons.zoomReset.tooltip')}>
            <ZoomToFitIcon />
          </button>
        </li>
        <li className={`${root}__wrapper__divider`} />
        <li className={`${root}__switch`}>
          <ViewmodeToggleButton
            viewMode={viewMode}
            handleToggleClick={handleToggleViewMode}
            disabled={!pageSpreadButtonEnabled || playerMode === PlayerMode.WHITEPAGE || isSolutionsPageVisible}
            title={t('dock.buttons.viewModeToggle.tooltip')}
          />
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = {
  handleToggleViewMode: toggleViewMode,
  handleZoomIn: zoomIn,
  handleZoomOut: zoomOut,
  handleZoomToFit: zoomToFit,
  handleZoomToSelection: setCurrentTool,
  handleManualVisibility: toggleManual,
  handlePopUpManualVisibility: togglePopUpManual,
};

const mapStateToProps = state => ({
  viewMode: getViewMode(state),
  pageSpreadButtonEnabled: canToggleSpreadPageMode(state),
  zoomLevel: getZoomLevel(state),
  isZoomSelectionMode: getCurrentTool(state) === tools.ZOOM_SELECT,
  playerMode: getPlayerMode(state),
  isManualVisible: getIsManualVisible(state),
  isSolutionsPageVisible: getIsSolutionsPageVisible(state),
  isPopUpManualVisible: getIsPopUpManualVisible(state),
  teacherAlike: isTeacherAlike(state),
});

Dock.propTypes = {
  digibook: shape({
    answerLayer: string,
  }),
  handleToggleViewMode: func.isRequired,
  pageSpreadButtonEnabled: bool,
  viewMode: oneOf(Object.values(ViewMode)),
  handleZoomIn: func.isRequired,
  handleZoomOut: func.isRequired,
  handleZoomToFit: func.isRequired,
  handleZoomToSelection: func.isRequired,
  zoomLevel: number.isRequired,
  isZoomSelectionMode: bool,
  playerMode: oneOf(Object.values(PlayerMode)).isRequired,
  handleManualVisibility: func,
  isManualVisible: bool,
  isSolutionsPageVisible: bool,
  isPopUpManualVisible: bool,
  handlePopUpManualVisibility: func,
  teacherAlike: bool.isRequired,
};

Dock.defaultProps = {
  digibook: undefined,
  viewMode: ViewMode.SPREAD,
  pageSpreadButtonEnabled: false,
  isZoomSelectionMode: false,
  isManualVisible: false,
  isSolutionsPageVisible: false,
  isPopUpManualVisible: false,
  handleManualVisibility: undefined,
  handlePopUpManualVisibility: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dock);
