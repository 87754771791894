import update from 'immutability-helper';
import {
  RETRIEVE_FAVORITES_SUCCESS,
  ADD_USER_MEDIA,
  USER_MEDIA_ADD_FAVORITE_SUCCESS,
  USER_MEDIA_REMOVE_FAVORITE_SUCCESS,
  USER_MEDIA_RETRIEVED,
  USER_MEDIA_DELETE_SUCCESS,
  USER_MEDIA_SAVE_SUCCESS,
  USER_MEDIA_SHARED,
} from '../actions/actionNames';
import { tryParseYoutubeVideoEmbedParams } from '../utils/youtubeUrlParser';

const initialState = {
  byId: {},
  byTocNodeId: {},
  favorites: {
    byModuleId: {},
  },
};

const userMediaReducer = (state = initialState, action) => {
  switch (action && action.type) {
    case USER_MEDIA_RETRIEVED: {
      const { userMedia } = action.payload;

      if (userMedia.length) {
        const byId = userMedia.reduce((map, media) => {
          const youTubeData = media.href ? tryParseYoutubeVideoEmbedParams(media.href) : {};
          return {
            ...map,
            [media.id]: { ...media, ...youTubeData },
          };
        }, {});

        const byNodeId = Object.values(byId).reduce(
          (map, media) => ({
            ...map,
            [media.node]: [...(map[media.node] || []), media.id],
          }),
          {},
        );

        return update(state, {
          byId: { $merge: byId },
          byTocNodeId: { $merge: byNodeId },
        });
      }
      return state;
    }

    case ADD_USER_MEDIA: {
      const { userMedia } = action.payload;

      const updateUserMediaForNode = (mediaForNode = []) => {
        if (mediaForNode.some(x => x === userMedia.id)) return mediaForNode;
        return update(mediaForNode, { $push: [userMedia.id] });
      };

      const youTubeData = userMedia.href ? tryParseYoutubeVideoEmbedParams(userMedia.href) : {};

      return update(state, {
        byId: { [userMedia.id]: { $set: { ...userMedia, ...youTubeData } } },
        byTocNodeId: {
          [userMedia.node]: updateUserMediaForNode,
        },
      });
    }

    case RETRIEVE_FAVORITES_SUCCESS: {
      const { favorites, moduleId } = action.payload;

      return update(state, {
        favorites: {
          byModuleId: {
            [moduleId]: { $set: favorites ? favorites.userMaterial : [] },
          },
        },
      });
    }

    case USER_MEDIA_ADD_FAVORITE_SUCCESS: {
      const { userMediaId, moduleId } = action.payload;
      if (state.favorites.byModuleId[moduleId].indexOf(userMediaId) < 0) {
        return update(state, { favorites: { byModuleId: { [moduleId]: { $push: [userMediaId] } } } });
      }
      return state;
    }

    case USER_MEDIA_REMOVE_FAVORITE_SUCCESS: {
      const { userMediaId, moduleId } = action.payload;
      const idx = state.favorites.byModuleId[moduleId].indexOf(userMediaId);
      if (idx > -1) {
        return update(state, { favorites: { byModuleId: { [moduleId]: { $splice: [[idx, 1]] } } } });
      }
      return state;
    }

    case USER_MEDIA_DELETE_SUCCESS: {
      const { userMediaId, nodeId } = action.payload;

      const userMediaByTocNodeId = state.byTocNodeId[nodeId].filter(x => x !== userMediaId);

      if (userMediaByTocNodeId.length) {
        return update(state, {
          byId: { $unset: [userMediaId] },
          byTocNodeId: {
            [nodeId]: { $set: userMediaByTocNodeId },
          },
        });
      }

      return update(state, {
        byId: { $unset: [userMediaId] },
        byTocNodeId: { $unset: [nodeId] },
      });
    }

    case USER_MEDIA_SAVE_SUCCESS: {
      const { userMedia } = action.payload;

      const youTubeData = userMedia.href ? tryParseYoutubeVideoEmbedParams(userMedia.href) : {};

      return update(state, {
        byId: { [userMedia.id]: { $merge: { ...userMedia, ...youTubeData } } },
      });
    }

    case USER_MEDIA_SHARED: {
      const { userMediaId, shared, assignment } = action.payload;

      return update(state, {
        byId: { [userMediaId]: { $set: { ...state.byId[userMediaId], shared, assignment } } },
      });
    }

    default:
      return state;
  }
};

export default userMediaReducer;
