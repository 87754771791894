const prefixKey = key => `p-player-${key}`;

/**
 * Retrieve an item
 * @param {string} key
 * @param {Storage} storage
 */
const get = (key, storage = sessionStorage) => storage.getItem(prefixKey(key));

/**
 * Store an item
 * @param {string} key
 * @param {string} data
 * @param {Storage} storage
 */
const set = (key, data, storage = sessionStorage) => storage.setItem(prefixKey(key), data);

/**
 * Remove an item
 * @param {any} key
 * @param {Storage} storage
 */
const remove = (key, storage = sessionStorage) => storage.removeItem(prefixKey(key));

/**
 * Clears the storage
 * @param {Storage} storage
 */
const clear = (storage = sessionStorage) => storage.clear();

export default {
  clear,
  get,
  remove,
  set,
};
