import api from '../services/api';

const API_URL = '/events/';
export const LOCATION_ID = 'codex';

export const postEvent = async (event) => {
  await api.post(API_URL, {
    ...event,
    locationId: LOCATION_ID,
    sessionStartDateTime: new Date().toISOString(),
  });
}
