export function getFeatureToggleFor(key) {
  // mention env vars explicitly to avoid stripping them out when building production
  const featureToggles = {
    FEATURE__BOOKWIDGET_USER_MATERIAL: process.env.FEATURE__BOOKWIDGET_USER_MATERIAL,
    FEATURE__MATH_TOOLS: process.env.FEATURE__MATH_TOOLS,
    FEATURE__MULTI_PAGE_PRINTS_WITHOUT_ANSWERS: process.env.FEATURE__MULTI_PAGE_PRINTS_WITHOUT_ANSWERS,
    FEATURE__NOTES: process.env.FEATURE__NOTES,
    FEATURE__SHARING_MATERIAL: process.env.FEATURE__SHARING_MATERIAL,
    FEATURE__WHITEPAGES: process.env.FEATURE__WHITEPAGES,
    FEATURE__SLIDE_SETS: process.env.FEATURE__SLIDE_SETS,
  };

  return featureToggles[`FEATURE__${key}`] === 'true' || false;
}
