const dialogTypes = {
  OPTIONS: 'options',
  CONFIRMATION: 'confirmation',
  TOCNODE_OPTIONS: 'tocnode_options',
  PRINT_PAGE: 'print_page',
  NOTE_EDITOR: 'note_editor',
  WHITEPAGE_OPTIONS: 'whitepage_options',
  ANNOTATION_SETS_MANAGEMENT: 'annotation_sets_management',
};

export default dialogTypes;
