const fileTypeDescriptions = [
  {
    filetype: 'word',
    mimetypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.ms-word.document.macroenabled.12',
      'application/vnd.ms-word.template.macroenabled.12',
    ],
  },
  {
    filetype: 'excel',
    mimetypes: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.template.macroEnabled.12',
      'application/vnd.ms-excel.addin.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
      'application/vnd.ms-excel.sheet.macroenabled.12',
      'application/vnd.ms-excel.template.macroenabled.12',
      'application/vnd.ms-excel.addin.macroenabled.12',
      'application/vnd.ms-excel.sheet.binary.macroenabled.12',
    ],
  },
  {
    filetype: 'powerpoint',
    mimetypes: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    ],
  },
  {
    filetype: 'pdf',
    mimetypes: ['application/pdf'],
  },
  {
    filetype: 'image',
    mimetypes: ['image/'],
  },
  {
    filetype: 'video',
    mimetypes: ['video/mp4'],
  },
  {
    filetype: 'audio',
    mimetypes: ['audio/mp4', 'audio/mpeg', 'audio/mp3'],
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getFileType = mimeType => {
  const ft = fileTypeDescriptions.find(mimeDescription => mimeDescription.mimetypes.find(m => mimeType.indexOf(m) > -1));
  return ft ? ft.filetype : 'unknown';
};
