import update from 'immutability-helper';

import { MODULE_FETCH_SUCCESS } from '../actions/actionNames';

const initialState = {
  byId: {},
};

export default function moduleReducer(state = initialState, action) {
  switch (action && action.type) {
    case MODULE_FETCH_SUCCESS: {
      const { module } = action.payload;
      return update(state, {
        byId: {
          [module.id]: { $set: module },
        },
      });
    }
    default: {
      return state;
    }
  }
}
