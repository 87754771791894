import { PORTAAL_URL, STUDIO_GROUP } from '../constants/constants';
import { join } from './url';

const getPathName = () => {
  switch (STUDIO_GROUP) {
    case 'ES':
      return 'conditions-generales';
    default:
      return 'algemene-voorwaarden';
  }
};

export default function getGeneralConditionsLink() {
  return join(PORTAAL_URL, getPathName());
}
